import React from 'react'
import Layout from 'features/layout/components/Layout'
import MenuMobile from '../components/MenuMobile'

const MenuMobilePage = () => {
  return (
    <Layout>
      <MenuMobile />
    </Layout>
  )
}

export default MenuMobilePage
