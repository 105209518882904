import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { sidebarList } from '../sidebarList'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import '../styles/left-sidebar.scss'

const Sidebar = (props) => {
  const { collapse } = props

  const location = useLocation()

  const [locationParam, setLocationParam] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([])

  useEffect(() => {
    const dataClone = [...sidebarList]
    let indexAdd = 0

    for (let i = 0; i < dataClone.length; i++) {
      if (dataClone[i] && dataClone[i].title === 'GROUP MANAGEMENT') {
        indexAdd = i + 1
      }

      // if (window.location.hostname === HOSTNAME.BRT) {
      //   if (dataClone[i] && dataClone[i].name === 'image') {
      //     dataClone[i].items.push({ title: 'Image Position', icon: 'fa fa-picture-o', url: '/image-position' })
      //   }
      // }
    }

    const showUserMenu = localStorage.getItem('show_user_menu')
    if (showUserMenu === 'true') {
      dataClone.splice(indexAdd, 0, {
        title: 'USER MANAGEMENT',
        items: [
          { title: 'User', icon: 'fa fa-user-circle-o', url: '/user' }
        ]
      })
    } else {
      //
    }

    setData(dataClone)
  }, [])

  useEffect(() => {
    if (data) {
      const parentEl = document.getElementById('adm-sidebar')
      const childEl = document.getElementById(`adm-menu-${location.pathname}`)
      if (parentEl && childEl) {
        parentEl.scrollTop = childEl.offsetTop - 400
      }
    }
  }, [data])

  useEffect(() => {
    if (location) {
      // eslint-disable-next-line no-useless-escape
      setLocationParam(location.pathname.replace(/^\/([^\/]*).*$/, '$1'))
    }
  }, [location])

  return (
    <div className="left_sidebar">
      <nav className="sidebar" id="adm-sidebar">
        {data.map((item, index) => {
          return (
            <ul key={index} className="metismenu">
              <li className="g_heading">{item.title}</li>
              {item.items.map((itemChild, indexChild) => {
                return (
                  <li
                    key={indexChild}
                    className={(itemChild.url === `/${locationParam}` || itemChild.url === `${location.pathname}`) ? 'active' : ''}
                    id={`adm-menu-${itemChild.url}`}
                  >
                    {collapse
                      ? <Tooltip title={itemChild.title} placement="right">
                          <Link to={itemChild.url}>
                            <i className={itemChild.icon}></i><span>{itemChild.title}</span>
                          </Link>
                        </Tooltip>
                      : <Link to={itemChild.url}>
                          <i className={itemChild.icon}></i><span>{itemChild.title}</span>
                        </Link>
                    }
                  </li>
                )
              })}
            </ul>
          )
        })}
      </nav>
    </div>
  )
}

Sidebar.propTypes = {
  collapse: PropTypes.any
}

Sidebar.defaultProps = {
}

export default Sidebar
