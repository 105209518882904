/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import { Row, Col } from 'components/ui'
import Pagination from '@mui/material/Pagination'
import Checkbox from '@mui/material/Checkbox'
import AlertDialog from 'components/ui/AlertDialog'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import { Select, Table } from 'antd'
import { toast } from 'react-toastify'
import 'antd/lib/table/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/space/style/index.css'
import 'antd/lib/pagination/style/index.css'
import '../styles/comment.scss'

const { Option } = Select

const Comment = (props) => {
  const queryClient = useQueryClient()

  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [idDelete, setIdDelete] = useState(null)

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery(['getComments', limit, offset], async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/comments/?is_verified=true&limit=${limit}&offset=${offset}`)
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `comments/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getComments')
    }
  })

  const mutationPut = useMutation(async (id) => {
    if (id) {
      return await getData('PUT', `comments/${id}/verify/`, {
        is_verified: false
      })
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getComments')
    }
  })

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Content',
      dataIndex: 'content_id',
      key: 'content_id',
      width: '25%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.entity) {
          return <div>{record.entity.title}</div>
        } else {
          return null
        }
      }
    },
    {
      title: 'User',
      key: 'user',
      align: 'center',
      width: '20%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.user) {
          return <div>{record.user.email}</div>
        } else {
          return null
        }
      }
    },
    {
      title: 'Comment',
      dataIndex: 'content',
      key: 'content',
      width: '25%'
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '15%'
    },
    {
      title: 'Verified',
      key: 'is_verified',
      align: 'center',
      width: '15%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.is_verified) {
          return <IconButton onClick={e => handleChangeVerified(e, record)}>
            <CheckIcon color="success" />
          </IconButton>
        } else {
          return <Checkbox />
        }
      }
    }
  ]

  useEffect(() => {
    if (dataList && limit) {
      let resultData = []
      const dataListData = dataList.data

      if (dataListData) {
        if (Array.isArray(dataListData)) {
          resultData = dataListData
          setTotal(dataListData.length)
          setTotalPage(Math.ceil(dataListData.length / limit))
        } else {
          resultData = dataListData.results
          setTotal(dataListData.total)
          setTotalPage(Math.ceil(dataListData.total / limit))
        }
      }

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        setData(dataListParse)
      }
    }
  }, [dataList, limit])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)

      setIsOpenDelete(false)
      toast.success('Comment deleted successfully')
    } catch (err) {
      setIsOpenDelete(false)
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  const handleChangeVerified = async (e, item) => {
    try {
      await mutationPut.mutateAsync(item && item.id)
    } catch (err) {
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  return (
    <div className="comments">
      <Row className="pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{
              x: 1000
            }}
            bordered
            pagination={false}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
    </div>
  )
}

export default Comment
