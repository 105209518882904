import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getData, deleteFormData } from 'common/utils'
import { Col, Row, Table } from 'components/ui'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import AlertDialog from 'components/ui/AlertDialog'
import { toast } from 'react-toastify'
import { Select } from 'antd'
import Pagination from '@mui/material/Pagination'
import ShareContentReleaseRibbonFilter from './ShareContentReleaseRibbonFilter'

const { Option } = Select

const ShareContentReleaseRibbon = () => {
  const queryClient = useQueryClient()

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  /* ----------------------------------------------- */
  /* --------------- Use State ------------------ */
  /* ----------------------------------------------- */
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [idDelete, setIdDelete] = useState('')
  const [isOpenDelete, setIsOpenDelete] = useState('')
  const [shareContentList, setShareContentList] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [totalPage, setTotalPage] = useState(1)
  const [current, setCurrent] = useState(1)

  /* ----------------------------------------------- */
  /* --------------- Fetch APIs ------------------ */
  /* ----------------------------------------------- */
  const { data: dataList, isLoading, isFetching } = useQuery(['getShareContentMapRibbon', searchFilter], async () => {
    let endpointTemp = ''
    if (searchFilter !== '') {
      endpointTemp += `&q=${searchFilter}`
    }
    return await getData(
      'GET',
      `tenants/${localStorage.getItem('tenant')}/share-content-map-ribbon-item?offset=${offset}&limit=${limit}${endpointTemp}`
    )
  })

  const mutationDelete = useMutation(async ({ idDelete }) => {
    if (idDelete) {
      return await deleteFormData('DELETE', 'ribbon-items/', idDelete)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getShareContentMapRibbon')
    }
  })

  /* ----------------------------------------------- */
  /* --------------- Use Effect ------------------ */
  /* ----------------------------------------------- */
  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results
      setShareContentList(resultData)
      setTotalPage(Math.ceil(dataList.data.total / limit))
      setOffset(0)
      setLimit(10)
    }
  }, [dataList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id || '')
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
    setIdDelete('')
  }

  const handleClickDelete = async () => {
    try {
      if (idDelete) {
        await mutationDelete.mutateAsync({ idDelete }).then(() => {
          setIsOpenDelete(false)
          setIdDelete('')
          toast.success('Delete Success')
        })
      }
    } catch (err) {
      toast.error('Something went wrong!')
    }
  }

  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }

  /* ----------------------------------------------- */
  /* --------------- Column Table ------------------ */
  /* ----------------------------------------------- */
  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete" onClick={(e) => handleClickShowModalDelete(e, record)}>
              <IconButton color="error">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Ribbon',
      key: 'ribbon',
      align: 'left',
      width: '20%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.ribbon?.name || '' }
          </div>
        )
      }
    },
    {
      title: 'Entity',
      key: 'entity',
      width: '25%',
      align: 'left',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.share_content_info?.entity?.title || '' }
          </div>
        )
      }
    },
    {
      title: 'Created by',
      key: 'create_by',
      width: '10%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.created_by || '' }
          </div>
        )
      }
    },
    {
      title: 'CreateAt',
      key: 'created_at',
      width: '12%',
      align: 'left',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.created_at || '' }
          </div>
        )
      }
    }
  ]

  return (
    <div className='share-content-release-ribbon'>
      <Row className='pt-4'>
        <Col md={12}>
          <ShareContentReleaseRibbonFilter
            onClickSearch={handleClickSearchFilter}
          />
        </Col>
      </Row>

      <Row className='pt-4'>
        <Col md={12}>
          <Table
            columns={columns}
            data={shareContentList}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
    </div>
  )
}

export default ShareContentReleaseRibbon
