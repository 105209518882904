import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodPin from '../components/VodPin'

const VodPinPage = (props) => {
  return (
    <Layout>
      <VodPin />
    </Layout>
  )
}

export default VodPinPage
