import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { formatThousand } from 'common/utils'
import Chart from 'chart.js/dist/chart'

const BarChart = (props) => {
  const { id, xLabel, yLabel, labels, datasets } = props

  const dataChart = {
    labels: labels,
    datasets: datasets
  }

  const config = {
    type: 'bar',
    data: dataChart,
    options: {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
            text: xLabel,
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        },
        y: {
          stacked: true,
          beginAtZero: true,
          title: {
            display: true,
            text: yLabel,
            font: {
              size: 14,
              weight: 'bold'
            }
          },
          ticks: {
            callback: (value, index, values) => {
              if (value % 1 === 0) {
                return formatThousand(value)
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (labels.length > 0 && datasets.length > 0) {
      const currentChart = Chart.getChart(id)

      if (currentChart) {
        //
      } else {
        const myChart = new Chart(
          document.getElementById(id),
          config
        )

        return () => {
          myChart.destroy()
        }
      }
    }
  }, [labels, datasets])

  return (
    <div>
      <canvas id={id}></canvas>
    </div>
  )
}

BarChart.propTypes = {
  id: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  labels: PropTypes.array,
  datasets: PropTypes.array
}

BarChart.defaultProps = {
  id: 'default-bar-chart',
  xLabel: '',
  yLabel: '',
  labels: [],
  datasets: []
}

export default BarChart
