/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import { Input, Button as ButtonAntd, Space, Select } from 'antd'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Table from 'components/ui/Table'
import CheckIcon from '@mui/icons-material/Check'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Pagination from '@mui/material/Pagination'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import 'antd/lib/select/style/index.css'

const { Option } = Select

const UserPermission = (props) => {
  const { id } = useParams()
  const searchInput = useRef()
  const queryClient = useQueryClient()

  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery(['getUserPermissionPermission', limit, offset], async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/permissions/?limit=${limit}&offset=${offset}`)
  })

  const { data: dataUserPermission } = useQuery(['getUserPermission', id], async () => {
    if (id) {
      return await getData('GET', `users/${id}/permissions/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    return await getData('PUT', `users/${id}/update-permissions/`, dataForm)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUserPermissionPermission')
      queryClient.invalidateQueries('getUserPermission')
    }
  })

  useEffect(() => {
    if (dataList && dataUserPermission) {
      const resultData = dataList.data && dataList.data.results
      const resultPermissionData = dataUserPermission.data

      if (Array.isArray(resultData) && Array.isArray(resultPermissionData)) {
        const dataListParse = resultData.map(item => {
          let status = false
          for (let i = 0; i < resultPermissionData.length; i++) {
            if (resultPermissionData[i] === item.id) {
              status = true
            }
          }

          return {
            key: item.id,
            status: status,
            ...item
          }
        })

        setTotal(dataList.data.total)
        setTotalPage(Math.ceil(dataList.data.total / limit))
        setData(dataListParse)
      }
    }
  }, [dataList, dataUserPermission])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters, confirm) => {
    clearFilters()
    setSearchText('')
    confirm()
  }

  const handleActiveStatus = async (e, idRecord) => {
    try {
      let activeList = []
      const resultPermissionData = dataUserPermission.data

      if (Array.isArray(resultPermissionData)) {
        activeList = [...resultPermissionData]
        activeList.push(idRecord)
      }

      await mutation.mutateAsync({
        permissions: activeList
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleUnactiveStatus = async (e, idRecord) => {
    try {
      let activeList = []
      const resultPermissionData = dataUserPermission.data

      if (Array.isArray(resultPermissionData)) {
        activeList = [...resultPermissionData]
        activeList = activeList.filter(item => {
          return item !== idRecord
        })
      }

      await mutation.mutateAsync({
        permissions: activeList
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getColumnSearchProps = dataIndex => ({
    // eslint-disable-next-line react/display-name
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonAntd
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 95 }}
          >
            Search
          </ButtonAntd>
          <ButtonAntd onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 95 }}>
            Reset
          </ButtonAntd>
          {/* <ButtonAntd
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </ButtonAntd> */}
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/display-name
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select(), 100)
      }
    },
    // eslint-disable-next-line react/display-name
    render: text => {
      return searchedColumn === dataIndex
        ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
          )
        : (
            text
          )
    }
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '75%',
      ellipsis: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      ...getColumnSearchProps('name')
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      width: '25%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.status) {
          return <IconButton onClick={e => handleUnactiveStatus(e, record.id)}>
            <CheckIcon color="success" />
          </IconButton>
        } else {
          return <Checkbox onChange={e => handleActiveStatus(e, record.id)} />
        }
      }
    }
  ]

  return (
    <div className="group-permission">
      {/* <div className="pt-4"></div> */}
      <Row className="pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            data={data}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>
    </div>
  )
}

export default UserPermission
