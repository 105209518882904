import React from 'react'
import Layout from 'features/layout/components/Layout'
import ShareContentReleaseRibbon from '../components/ShareContentReleaseRibbon'

const ShareContentReleaseRibbonPage = (props) => {
  return (
    <Layout>
      <ShareContentReleaseRibbon />
    </Layout>
  )
}

export default ShareContentReleaseRibbonPage
