import { HOSTNAME } from 'constant/appConstant'

export const sidebarList = (window.location.hostname === HOSTNAME.BRT || window.location.hostname === HOSTNAME.BRT_STAGING || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING)
  ? [
      {
        title: 'DASHBOARD',
        items: [
          { title: 'Home', icon: 'fa fa-home', url: '/' }
        ]
      },
      {
        title: 'MENU MANAGEMENT',
        items: [
          { title: 'Platform Menu', icon: 'fa fa-bars', url: '/menu-platform' },
          { title: 'Menu', icon: 'fa fa-bars', url: '/menu' }
        ]
      },
      {
        title: 'RIBBON MANAGEMENT',
        items: [
          { title: 'Ribbon', icon: 'fa fa-bars', url: '/ribbon' }
          // { title: 'Ribbon Item', icon: 'fa fa-bars', url: '/ribbon-item' }
        ]
      },
      {
        title: 'REPORT MANAGEMENT',
        items: (window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING)
          ? [
              { title: 'Report', icon: 'fa fa-file', url: '/report-data' }
            ]
          : [
              { title: 'Report', icon: 'fa fa-file', url: '/report' },
              { title: 'Statistical Report', icon: 'fa fa-file', url: '/report-statistic' }
            ]
      },
      {
        title: 'ENTITY MANAGEMENT',
        items: [
          { title: 'Entity', icon: 'fa fa-book', url: '/entityvod' },
          { title: 'Single Movie', icon: 'fa fa-book', url: '/singlemovie' },
          { title: 'Show / Drama', icon: 'fa fa-book', url: '/showdrama' },
          { title: 'Linear Channel', icon: 'fa fa-book', url: '/channel' },
          { title: 'EPG Timing', icon: 'fa fa-clock-o', url: '/epg-timing' }
        ]
      },
      {
        title: 'COMMENT MANAGEMENT',
        items: [
          { title: 'Comment', icon: 'fa fa-commenting', url: '/comments' },
          { title: 'Approve Comment', icon: 'fa fa-commenting', url: '/approve-comment' }
        ]
      },
      {
        title: 'IMAGE MANAGEMENT',
        name: 'image',
        items: [
          { title: 'Image Type', icon: 'fa fa-picture-o', url: '/image' }
        ]
      },
      {
        title: 'BANNER MANAGEMENT',
        name: 'banner',
        items: [
          { title: 'Banner Position', icon: 'fa fa-picture-o', url: '/banner-position' }
        ]
      },
      {
        title: 'PEOPLE MANAGEMENT',
        items: [
          { title: 'People', icon: 'fa fa-user', url: '/people' }
        ]
      },
      {
        title: 'METADATA MANAGEMENT',
        items: [
          { title: 'Metadata', icon: 'fa fa-puzzle-piece', url: '/metadata' }
        ]
      },
      {
        title: 'TRANSCODE MANAGEMENT',
        items: [
          { title: 'Add Job Transcode', icon: 'fa fa-file-video-o', url: '/transcode/add' },
          { title: 'Transcode', icon: 'fa fa-file-video-o', url: '/transcode/index' },
          { title: 'Transcode Not Mapping', icon: 'fa fa-file-video-o', url: '/transcode-not-mapping/index' }
        ]
      },
      // {
      //   title: 'CONTENT SHARING',
      //   items: [
      //     { title: 'Content Sharing By Me', icon: 'fa fa-share', url: '/share-by-me' },
      //     { title: 'Content Sharing With Me', icon: 'fa fa-share', url: '/share-with-me' },
      //     { title: 'Release Content Sharing', icon: 'fa fa-share', url: '/release-content-sharing' }
      //   ]
      // },
      // {
      //   title: 'RIBBON SHARING',
      //   items: [
      //     { title: 'Ribbon Sharing By Me', icon: 'fa fa-share', url: '/ribbon-sharing-by-me' },
      //     { title: 'Ribbon Sharing With Me', icon: 'fa fa-share', url: '/ribbon-sharing-with-me' }
      //   ]
      // },
      {
        title: 'GROUP MANAGEMENT',
        items: [
          { title: 'Group', icon: 'fa fa-users', url: '/group' }
        ]
      },
      // {
      //   title: 'USER MANAGEMENT',
      //   items: [
      //     { title: 'User', icon: 'fa fa-user-circle-o', url: '/user' }
      //   ]
      // },
      {
        title: 'PERMISSION MANAGEMENT',
        items: [
          { title: 'Permission', icon: 'fa fa-lock', url: '/permission' }
        ]
      },
      {
        title: 'INFO MANAGEMENT',
        items: [
          { title: 'FAQ', icon: 'fa fa-puzzle-piece', url: '/faq' },
          { title: 'About Us', icon: 'fa fa-info-circle', url: '/about-us' },
          { title: 'Ads Info', icon: 'fa fa-wpexplorer', url: '/ads-info' }
        ]
      }
    ]
  : window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING
    ? [
        {
          title: 'DASHBOARD',
          items: [
            { title: 'Home', icon: 'fa fa-home', url: '/' }
          ]
        },
        {
          title: 'MENU MANAGEMENT',
          items: [
            { title: 'Platform Menu', icon: 'fa fa-bars', url: '/menu-platform' },
            { title: 'Menu', icon: 'fa fa-bars', url: '/menu' },
            { title: 'Menu Mobile', icon: 'fa fa-bars', url: '/menu-mobile' },
            { title: 'Menu Slide', icon: 'fa fa-bars', url: '/menu-slide' }
          ]
        },
        {
          title: 'RIBBON MANAGEMENT',
          items: [
            { title: 'Ribbon', icon: 'fa fa-bars', url: '/ribbon' },
            { title: 'Ribbon Display', icon: 'fa fa-bars', url: '/ribbon-display' }
            // { title: 'Ribbon Item', icon: 'fa fa-bars', url: '/ribbon-item' }
          ]
        },
        {
          title: 'ENTITY MANAGEMENT',
          items: [
            { title: 'Entity', icon: 'fa fa-book', url: '/entityvod' },
            { title: 'Single Movie', icon: 'fa fa-book', url: '/singlemovie' },
            { title: 'Show / Drama', icon: 'fa fa-book', url: '/showdrama' },
            { title: 'Linear Channel', icon: 'fa fa-book', url: '/channel' }
          ]
        },
        {
          title: 'CRAWLER',
          items: [
            { title: 'Crawler', icon: 'fa fa-cloud-download', url: '/crawler' },
            { title: 'RSS', icon: 'fa fa-rss', url: '/rss' }
          ]
        },
        {
          title: 'COMMENT MANAGEMENT',
          items: [
            { title: 'Comment', icon: 'fa fa-commenting', url: '/comments' },
            { title: 'Approve Comment', icon: 'fa fa-commenting', url: '/approve-comment' }
          ]
        },
        {
          title: 'REPORT MANAGEMENT',
          items: [
            { title: 'Report', icon: 'fa fa-file', url: '/report-view-user' }
          ]
        },
        {
          title: 'IMAGE MANAGEMENT',
          name: 'image',
          items: [
            { title: 'Image Type', icon: 'fa fa-picture-o', url: '/image' }
          ]
        },
        {
          title: 'BANNER MANAGEMENT',
          name: 'banner',
          items: [
            { title: 'Banner Position', icon: 'fa fa-picture-o', url: '/banner-position' }
          ]
        },
        {
          title: 'PEOPLE MANAGEMENT',
          items: [
            { title: 'People', icon: 'fa fa-user', url: '/people' }
          ]
        },
        {
          title: 'METADATA MANAGEMENT',
          items: [
            { title: 'Metadata', icon: 'fa fa-puzzle-piece', url: '/metadata' }
          ]
        },
        {
          title: 'TRANSCODE MANAGEMENT',
          items: [
            { title: 'Add Job Transcode', icon: 'fa fa-file-video-o', url: '/transcode/add' },
            { title: 'Transcode', icon: 'fa fa-file-video-o', url: '/transcode/index' },
            { title: 'Transcode Not Mapping', icon: 'fa fa-file-video-o', url: '/transcode-not-mapping/index' }
          ]
        },
        // {
        //   title: 'CONTENT SHARING',
        //   items: [
        //     { title: 'Content Sharing By Me', icon: 'fa fa-share', url: '/share-by-me' },
        //     { title: 'Content Sharing With Me', icon: 'fa fa-share', url: '/share-with-me' },
        //     { title: 'Release Content Sharing', icon: 'fa fa-share', url: '/release-content-sharing' }
        //   ]
        // },
        // {
        //   title: 'RIBBON SHARING',
        //   items: [
        //     { title: 'Ribbon Sharing By Me', icon: 'fa fa-share', url: '/ribbon-sharing-by-me' },
        //     { title: 'Ribbon Sharing With Me', icon: 'fa fa-share', url: '/ribbon-sharing-with-me' }
        //   ]
        // },
        {
          title: 'TOPIC MANAGEMENT',
          items: [
            { title: 'Tag Topic', icon: 'fa fa-tag', url: '/tag-topic' }
          ]
        },
        {
          title: 'GROUP MANAGEMENT',
          items: [
            { title: 'Group', icon: 'fa fa-users', url: '/group' }
          ]
        },
        // {
        //   title: 'USER MANAGEMENT',
        //   items: [
        //     { title: 'User', icon: 'fa fa-user-circle-o', url: '/user' }
        //   ]
        // },
        {
          title: 'PERMISSION MANAGEMENT',
          items: [
            { title: 'Permission', icon: 'fa fa-lock', url: '/permission' }
          ]
        },
        {
          title: 'INFO MANAGEMENT',
          items: [
            { title: 'FAQ', icon: 'fa fa-puzzle-piece', url: '/faq' },
            { title: 'About Us', icon: 'fa fa-info-circle', url: '/about-us' },
            { title: 'Ads Info', icon: 'fa fa-wpexplorer', url: '/ads-info' },
            { title: 'Config', icon: 'fa fa-cog', url: '/config' },
            { title: 'App Version', icon: 'fa fa-cog', url: '/app-version' }
          ]
        }
      ]
    : (window.location.hostname === HOSTNAME.SDTV)
        ? [
            {
              title: 'DASHBOARD',
              items: [
                { title: 'Home', icon: 'fa fa-home', url: '/' }
              ]
            },
            {
              title: 'MENU MANAGEMENT',
              items: [
                { title: 'Platform Menu', icon: 'fa fa-bars', url: '/menu-platform' },
                { title: 'Menu', icon: 'fa fa-bars', url: '/menu' }
              ]
            },
            {
              title: 'RIBBON MANAGEMENT',
              items: [
                { title: 'Ribbon', icon: 'fa fa-bars', url: '/ribbon' }
                // { title: 'Ribbon Item', icon: 'fa fa-bars', url: '/ribbon-item' }
              ]
            },
            {
              title: 'REPORT MANAGEMENT',
              items: [
                { title: 'Report', icon: 'fa fa-file', url: '/report-content' }
              ]
            },
            {
              title: 'ENTITY MANAGEMENT',
              items: [
                { title: 'Entity', icon: 'fa fa-book', url: '/entityvod' },
                { title: 'Single Movie', icon: 'fa fa-book', url: '/singlemovie' },
                { title: 'Show / Drama', icon: 'fa fa-book', url: '/showdrama' },
                { title: 'Linear Channel', icon: 'fa fa-book', url: '/channel' }
              ]
            },
            {
              title: 'COMMENT MANAGEMENT',
              items: [
                { title: 'Comment', icon: 'fa fa-commenting', url: '/comments' },
                { title: 'Approve Comment', icon: 'fa fa-commenting', url: '/approve-comment' }
              ]
            },
            {
              title: 'IMAGE MANAGEMENT',
              name: 'image',
              items: [
                { title: 'Image Type', icon: 'fa fa-picture-o', url: '/image' }
              ]
            },
            {
              title: 'PEOPLE MANAGEMENT',
              items: [
                { title: 'People', icon: 'fa fa-user', url: '/people' }
              ]
            },
            {
              title: 'METADATA MANAGEMENT',
              items: [
                { title: 'Metadata', icon: 'fa fa-puzzle-piece', url: '/metadata' }
              ]
            },
            {
              title: 'TRANSCODE MANAGEMENT',
              items: [
                { title: 'Add Job Transcode', icon: 'fa fa-file-video-o', url: '/transcode/add' },
                { title: 'Transcode', icon: 'fa fa-file-video-o', url: '/transcode/index' },
                { title: 'Transcode Not Mapping', icon: 'fa fa-file-video-o', url: '/transcode-not-mapping/index' }
              ]
            },
            // {
            //   title: 'CONTENT SHARING',
            //   items: [
            //     { title: 'Content Sharing By Me', icon: 'fa fa-share', url: '/share-by-me' },
            //     { title: 'Content Sharing With Me', icon: 'fa fa-share', url: '/share-with-me' },
            //     { title: 'Release Content Sharing', icon: 'fa fa-share', url: '/release-content-sharing' }
            //   ]
            // },
            // {
            //   title: 'RIBBON SHARING',
            //   items: [
            //     { title: 'Ribbon Sharing By Me', icon: 'fa fa-share', url: '/ribbon-sharing-by-me' },
            //     { title: 'Ribbon Sharing With Me', icon: 'fa fa-share', url: '/ribbon-sharing-with-me' }
            //   ]
            // },
            {
              title: 'GROUP MANAGEMENT',
              items: [
                { title: 'Group', icon: 'fa fa-users', url: '/group' }
              ]
            },
            // {
            //   title: 'USER MANAGEMENT',
            //   items: [
            //     { title: 'User', icon: 'fa fa-user-circle-o', url: '/user' }
            //   ]
            // },
            {
              title: 'PERMISSION MANAGEMENT',
              items: [
                { title: 'Permission', icon: 'fa fa-lock', url: '/permission' }
              ]
            },
            {
              title: 'INFO MANAGEMENT',
              items: [
                { title: 'FAQ', icon: 'fa fa-puzzle-piece', url: '/faq' },
                { title: 'About Us', icon: 'fa fa-info-circle', url: '/about-us' },
                { title: 'Ads Info', icon: 'fa fa-wpexplorer', url: '/ads-info' }
              ]
            }
          ]
        : [
            {
              title: 'DASHBOARD',
              items: [
                { title: 'Home', icon: 'fa fa-home', url: '/' }
              ]
            },
            {
              title: 'MENU MANAGEMENT',
              items: [
                { title: 'Platform Menu', icon: 'fa fa-bars', url: '/menu-platform' },
                { title: 'Menu', icon: 'fa fa-bars', url: '/menu' },
                { title: 'Menu Mobile', icon: 'fa fa-bars', url: '/menu-mobile' }
              ]
            },
            {
              title: 'RIBBON MANAGEMENT',
              items: [
                { title: 'Ribbon', icon: 'fa fa-bars', url: '/ribbon' },
                { title: 'Ribbon Display', icon: 'fa fa-bars', url: '/ribbon-display' }
                // { title: 'Ribbon Item', icon: 'fa fa-bars', url: '/ribbon-item' }
              ]
            },
            {
              title: 'ENTITY MANAGEMENT',
              items: [
                { title: 'Entity', icon: 'fa fa-book', url: '/entityvod' },
                { title: 'Single Movie', icon: 'fa fa-book', url: '/singlemovie' },
                { title: 'Show / Drama', icon: 'fa fa-book', url: '/showdrama' },
                { title: 'Linear Channel', icon: 'fa fa-book', url: '/channel' },
                { title: 'EPG Timing', icon: 'fa fa-clock-o', url: '/epg-timing' }
              ]
            },
            {
              title: 'COMMENT MANAGEMENT',
              items: [
                { title: 'Comment', icon: 'fa fa-commenting', url: '/comments' },
                { title: 'Approve Comment', icon: 'fa fa-commenting', url: '/approve-comment' }
              ]
            },
            {
              title: 'IMAGE MANAGEMENT',
              name: 'image',
              items: [
                { title: 'Image Type', icon: 'fa fa-picture-o', url: '/image' }
              ]
            },
            {
              title: 'BANNER MANAGEMENT',
              name: 'banner',
              items: [
                { title: 'Banner Position', icon: 'fa fa-picture-o', url: '/banner-position' }
              ]
            },
            {
              title: 'PEOPLE MANAGEMENT',
              items: [
                { title: 'People', icon: 'fa fa-user', url: '/people' }
              ]
            },
            {
              title: 'METADATA MANAGEMENT',
              items: [
                { title: 'Metadata', icon: 'fa fa-puzzle-piece', url: '/metadata' }
              ]
            },
            {
              title: 'TRANSCODE MANAGEMENT',
              items: [
                { title: 'Add Job Transcode', icon: 'fa fa-file-video-o', url: '/transcode/add' },
                { title: 'Transcode', icon: 'fa fa-file-video-o', url: '/transcode/index' },
                { title: 'Transcode Not Mapping', icon: 'fa fa-file-video-o', url: '/transcode-not-mapping/index' }
              ]
            },
            {
              title: 'CONTENT SHARING',
              items: [
                { title: 'Content Sharing By Me', icon: 'fa fa-share', url: '/share-by-me' },
                { title: 'Content Sharing With Me', icon: 'fa fa-share', url: '/share-with-me' },
                { title: 'Release Content Sharing', icon: 'fa fa-share', url: '/release-content-sharing' }
              ]
            },
            {
              title: 'RIBBON SHARING',
              items: [
                { title: 'Ribbon Sharing By Me', icon: 'fa fa-share', url: '/ribbon-sharing-by-me' },
                { title: 'Ribbon Sharing With Me', icon: 'fa fa-share', url: '/ribbon-sharing-with-me' }
              ]
            },
            {
              title: 'GROUP MANAGEMENT',
              items: [
                { title: 'Group', icon: 'fa fa-users', url: '/group' }
              ]
            },
            // {
            //   title: 'USER MANAGEMENT',
            //   items: [
            //     { title: 'User', icon: 'fa fa-user-circle-o', url: '/user' }
            //   ]
            // },
            {
              title: 'PERMISSION MANAGEMENT',
              items: [
                { title: 'Permission', icon: 'fa fa-lock', url: '/permission' }
              ]
            },
            {
              title: 'INFO MANAGEMENT',
              items: [
                { title: 'FAQ', icon: 'fa fa-puzzle-piece', url: '/faq' },
                { title: 'About Us', icon: 'fa fa-info-circle', url: '/about-us' },
                { title: 'Ads Info', icon: 'fa fa-wpexplorer', url: '/ads-info' }
              ]
            }
          ]
