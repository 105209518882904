import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePickerMui from '@mui/lab/DateTimePicker'

const DateTimePicker = (props) => {
  const { className, label, name, size, control, required, disabled } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePickerMui
                disabled={disabled}
                label={label}
                ampm={false}
                inputFormat="dd-MM-yyyy HH:mm"
                mask="__-__-____ __:__"
                value={value || null}
                onChange={onChange}
                renderInput={(params) => <TextField {...params} size={size} fullWidth />}
              />
            </LocalizationProvider>
          )
        }}
      />
    </div>
  )
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.any
}

DateTimePicker.defaultProps = {
  className: '',
  label: '',
  name: '',
  size: 'small',
  required: false,
  disabled: false
}

export default DateTimePicker
