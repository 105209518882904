import React, { useEffect, useState, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { Image } from 'components/ui'
import { Link } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData, getUploadData, formatFormData } from 'common/utils'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Tree from 'components/ui/Tree'
import TextField from 'components/form/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AlertDialog from 'components/ui/AlertDialog'
import FormDialog from 'components/form/FormDialog'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import SortIcon from '@mui/icons-material/Sort'
import CheckIcon from '@mui/icons-material/Check'
import { Button as ButtonAntd } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import '../styles/banner-position.scss'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

// Preview Image Upload Style
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
}

const BannerPosition = (props) => {
  const { control, formState: { errors }, setError, getValues, setValue } = useForm()
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })
  const queryClient = useQueryClient()

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  // const mutationDelete = useMutation(async (id) => {
  //   if (id) {
  //     return await getData('DELETE', `propaganda-banner-positions/${id}/`)
  //   }
  // }, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries('getBannerPosition')
  //   }
  // })

  const [files, setFiles] = useState([])
  const [isOpenUploadImage, setIsOpenUploadImage] = useState(false)
  // const [isOpenPositionDelete, setIsOpenPositionDelete] = useState(false)
  const [isOpenItemDelete, setIsOpenItemDelete] = useState(false)
  const [idPostionDelete, setIdPositionDelete] = useState(null)
  const [idItemDelete, setIdItemDelete] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [positionInfo, setPositionInfo] = useState(null)
  const [ordInfo, setOrdInfo] = useState(0)
  // const [expandedKeys, setExpandedKeys] = useState([])

  // const [dataTree, setDataTree] = useState([
  //   {
  //     key: '0-0',
  //     name: 'Position 1',
  //     image: null,
  //     children: [
  //       { key: '0-0-0', name: '', image: 'https://brt.vn/static/media/bannertop.jpg', children: [] },
  //       { key: '0-0-1', name: '', image: 'https://brt.vn/static/media/banner-middle-3.jpg', children: [] }
  //     ]
  //   },
  //   { key: '0-1', name: 'Position 2', image: null, children: [] }
  // ])
  const [dataTree, setDataTree] = useState([])

  const { data: dataBannerPosition } = useQuery('getBannerPosition', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/propaganda-banner-positions/`)
  })

  const { data: dataItemInfo } = useQuery(['getInfoBannerItem', idItemDelete], async () => {
    if (idItemDelete) {
      return await getData('GET', `propaganda-banner-items/${idItemDelete}/`)
    }
  })

  const mutationBannerItem = useMutation(async (dataForm) => {
    if (dataForm) {
      if (isEdit) {
        return await getData('PUT', `propaganda-banner-items/${idItemDelete}`, dataForm)
      } else {
        return await getUploadData('POST', 'propaganda-banner-items/', dataForm)
      }
    }
  }, {
    onSuccess: () => {
      fetchBannerData()
      queryClient.invalidateQueries('getInfoBannerItem')
    }
  })

  const mutationBannerItemDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `propaganda-banner-items/${id}/`)
    }
  }, {
    onSuccess: () => {
      fetchBannerData()
    }
  })

  const mutationUpdateOrder = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('PUT', `propaganda-banner-positions/${dataForm.idPositionParam}/items-order/`, dataForm.orderList)
    }
  }, {
    onSuccess: () => {
      fetchBannerData()
    }
  })

  const fetchBannerData = () => {
    if (dataBannerPosition) {
      const resultData = dataBannerPosition.data
      if (resultData && Array.isArray(resultData)) {
        // const expandArrTemp = []

        const dataTemp = resultData.map(async (item, index) => {
          const resultBannerItem = await getData('GET', `propaganda-banner-positions/${item.id}/items/`)
          const resultBannerItemData = resultBannerItem && resultBannerItem.data
          const childArrTemp = Array.isArray(resultBannerItemData)
            ? resultBannerItemData.map((itemChild, indexChild) => {
              return {
                key: `0-${index}-${indexChild}`,
                id: itemChild.id,
                name: '',
                image: itemChild.url,
                children: []
              }
            })
            : []

          // if (Array.isArray(childArrTemp) && childArrTemp.length > 0) {
          //   expandArrTemp.push(`0-${index}`)
          // }

          return {
            key: `0-${index}`,
            id: item.id,
            name: item.name,
            code: item.code,
            is_active: item.is_active,
            image: null,
            children: childArrTemp
          }
        })

        Promise.all(dataTemp).then(data => {
          setDataTree(data)
          // setExpandedKeys(expandArrTemp)
        })
      }
    }
  }

  useEffect(() => {
    fetchBannerData()
  }, [dataBannerPosition])

  useEffect(() => {
    if (dataItemInfo) {
      const resultData = dataItemInfo.data
      if (resultData) {
        setValue('target_title', resultData.target_title)
        setValue('target_link', resultData.target_link)
        setOrdInfo(resultData.ord)
        setPositionInfo(resultData.position && resultData.position.id)

        // Image
        const urlData = resultData.url
        if (urlData && typeof urlData === 'string') {
          const urlTemp = urlData.substring(0, 5) === 'https' ? urlData : `https://static-ott.admon.com.vn/assets/${urlData}`

          setFiles([
            { preview: urlTemp }
          ])
        } else {
          setFiles([])
        }
      }
    }
  }, [dataItemInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  // const handleClickShowModalPositionDelete = (e, record) => {
  //   setIdPositionDelete(record.id)
  //   setIsOpenPositionDelete(true)
  // }

  // const handleClosePositionDelete = () => {
  //   setIsOpenPositionDelete(false)
  // }
  // const handleClickPositionDelete = async () => {
  //   try {
  //     await mutationDelete.mutateAsync(idPostionDelete)
  //     setIsOpenDelete(false)

  //     toast.success('Menu deleted successfully')
  //   } catch (err) {
  //     setIsOpenDelete(false)
  //     toast.error('Something went wrong!')
  //     console.log(err)
  //   }
  // }

  const handleClickShowModalItemDelete = (e, record) => {
    setIdItemDelete(record.id)
    setIsOpenItemDelete(true)
  }

  const handleCloseItemDelete = () => {
    setIsOpenItemDelete(false)
  }
  const handleClickItemDelete = async () => {
    try {
      await mutationBannerItemDelete.mutateAsync(idItemDelete)
      setIsOpenItemDelete(false)

      toast.success('Image deleted successfully')
    } catch (err) {
      setIsOpenItemDelete(false)
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  const handleClickShowModalUpload = (e, item) => {
    setIsOpenUploadImage(true)
    setIdPositionDelete(item.id)
    setIsEdit(false)

    setFiles([])
    setValue('target_title', '')
    setValue('target_link', '')
  }

  const handleClickShowModalEditUpload = (e, item) => {
    if (dataItemInfo) {
      const resultData = dataItemInfo.data
      if (resultData) {
        setValue('target_title', resultData.target_title)
        setValue('target_link', resultData.target_link)
        setOrdInfo(resultData.ord)
        setPositionInfo(resultData.position && resultData.position.id)

        // Image
        const urlData = resultData.url
        if (urlData && typeof urlData === 'string') {
          const urlTemp = urlData.substring(0, 5) === 'https' ? urlData : `https://static-ott.admon.com.vn/assets/${urlData}`

          setFiles([
            { preview: urlTemp }
          ])
        } else {
          setFiles([])
        }
      }
    }

    setIsOpenUploadImage(true)
    setIdItemDelete(item.id)
    setIsEdit(true)
  }

  const handleCloseUpload = () => {
    setIsOpenUploadImage(false)
    setFiles([])
  }

  const handleRemoveImage = () => {
    setFiles([])
  }

  const handleClickUpload = async () => {
    try {
      if (files.length > 0) {
        const fileUpload = files[0]
        console.log(fileUpload)

        if (isEdit) {
          if (fileUpload && fileUpload.path) {
            const formData = new FormData()
            formData.append('target_title', typeof getValues('target_title') === 'undefined' ? '' : formatFormData(getValues('target_title')))
            formData.append('target_link', typeof getValues('target_link') === 'undefined' ? '' : formatFormData(getValues('target_link')))
            formData.append('position', positionInfo)
            formData.append('ord', ordInfo)
            formData.append('tenant', localStorage.getItem('tenant'))
            formData.append('image', fileUpload)
            await mutationBannerItem.mutateAsync(formData)
          } else {
            await mutationBannerItem.mutateAsync({
              target_title: typeof getValues('target_title') === 'undefined' ? '' : formatFormData(getValues('target_title')),
              target_link: typeof getValues('target_link') === 'undefined' ? '' : formatFormData(getValues('target_link')),
              position: positionInfo,
              ord: ordInfo,
              tenant: localStorage.getItem('tenant')
            })
          }
          toast.success('Edit successfully')
        } else {
          const formData = new FormData()
          formData.append('target_title', typeof getValues('target_title') === 'undefined' ? '' : formatFormData(getValues('target_title')))
          formData.append('target_link', typeof getValues('target_link') === 'undefined' ? '' : formatFormData(getValues('target_link')))
          formData.append('position', idPostionDelete)
          formData.append('ord', 0)
          formData.append('tenant', localStorage.getItem('tenant'))
          formData.append('image', fileUpload)

          await mutationBannerItem.mutateAsync(formData)
        }

        setFiles([])
        setValue('target_title', '')
        setValue('target_link', '')
        setIsOpenUploadImage(false)
      } else {
        toast.warning('Please upload a file', {
          hideProgressBar: true,
          autoClose: 2000,
          position: 'top-right'
        })
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const onDragEnter = info => {
    // console.log(info)
  }

  const onDrop = info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children, key, callback)
        }
      }
    }
    const data = [...dataTree]

    // Find dragObject
    let dragObj
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj)
      })
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj)
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      })
    } else {
      let ar
      let i
      loop(data, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        ar.splice(i + 1, 0, dragObj)
      }
    }

    setDataTree(data)
  }

  const handleClickUpdateOrder = async (e, item) => {
    try {
      const arrTemp = []
      let indexTemp = 1

      for (let i = 0; i < dataTree.length; i++) {
        const itemId = dataTree[i] && dataTree[i].id
        if (itemId === item.id) {
          const itemChildren = dataTree[i] && dataTree[i].children

          if (Array.isArray(itemChildren)) {
            for (let j = 0; j < itemChildren.length; j++) {
              const itemChildrenId = itemChildren[j] && itemChildren[j].id

              arrTemp.push({
                id: itemChildrenId,
                ord: indexTemp
              })

              indexTemp += 1
            }
          }
        }
      }
      await mutationUpdateOrder.mutateAsync({
        idPositionParam: item.id,
        orderList: arrTemp
      })

      toast.success('Update order successfully')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="banner-position">
      <Row className="pb-4">
        <Col md={12}>
          <Link to="/banner-position/add">
            <Button className="float-right" variant="contained" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>

      <Tree
        draggable
        blockNode
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        treeData={dataTree}
        titleRender={(nodeData) => {
          const nodeKey = nodeData.key

          return (
            <div>
              {(typeof nodeKey === 'string' && nodeKey.length === 3)
                ? <>
                    <Row className="align-items-center">
                      <Col md={4}>
                        {nodeData.name}
                      </Col>
                      <Col md={8}>
                        <div className="float-right mr-2" onClick={e => handleClickShowModalUpload(e, nodeData)}>
                          <IconButton color="primary">
                            <UploadIcon />
                          </IconButton>
                          Upload
                        </div>
                        <div className="float-right mr-4" onClick={e => handleClickUpdateOrder(e, nodeData)}>
                          <IconButton color="info">
                            <SortIcon />
                          </IconButton>
                          Update Order
                        </div>
                        <Link to={`/banner-position/${nodeData.id}`} className="float-right mr-4 banner-position-edit">
                          <IconButton color="success">
                            <EditIcon />
                          </IconButton>
                          Edit
                        </Link>
                        <div className="float-right mr-5">
                          {nodeData.is_active &&
                            <div>
                              <IconButton color="success">
                                <CheckIcon />
                              </IconButton>
                              Active
                            </div>
                          }
                        </div>
                        {/* <div className="float-right mr-4 banner-position-delete" onClick={e => handleClickShowModalPositionDelete(e, nodeData)}>
                          <IconButton color="error">
                            <DeleteIcon />
                          </IconButton>
                          Delete
                        </div> */}
                      </Col>
                    </Row>
                  </>
                : <>
                    <Row className="align-items-center">
                      <Col md={4}>
                        <Image src={nodeData.image} width={280} height={60} />
                      </Col>
                      <Col md={8}>
                        <div className="float-right mr-4 banner-position-delete" onClick={e => handleClickShowModalItemDelete(e, nodeData)}>
                          <IconButton color="error">
                            <DeleteIcon />
                          </IconButton>
                          Delete
                        </div>
                        <div className="float-right mr-4 banner-position-edit" onClick={e => handleClickShowModalEditUpload(e, nodeData)}>
                          <IconButton color="success">
                            <EditIcon />
                          </IconButton>
                          Edit
                        </div>
                      </Col>
                    </Row>
                  </>
              }
            </div>
          )
        }}
      />

      {/* <AlertDialog
        isOpen={isOpenPositionDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleClosePositionDelete}
        onSave={handleClickPositionDelete}
      /> */}

      <AlertDialog
        isOpen={isOpenItemDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseItemDelete}
        onSave={handleClickItemDelete}
      />

      <FormDialog
        isOpen={isOpenUploadImage}
        title="Upload Image"
        maxWidth="md"
        onClose={handleCloseUpload}
        onSave={handleClickUpload}
      >
        <Row className="pt-3">
          <Col md={6}>
            <TextField label="Target Title" name="target_title" required control={control} error={errors.target_title} />
          </Col>
          <Col md={6}>
            <TextField label="Target Link" name="target_link" control={control} error={errors.target_link} />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={12}>
            <div className="pb-1">Image</div>
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
              </div>
            </section>
            <aside style={thumbsContainer}>
              {(files && files.length > 0) &&
                <div style={{ position: 'relative', width: '600px', height: '120px', margin: '20px auto 0 auto' }}>
                  {/* {!isEdit &&
                    <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                      <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveImage} />
                    </div>
                  } */}
                  <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                    <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveImage} />
                  </div>
                  {files.map((file, index) => (
                    <img
                      key={index}
                      src={file.preview}
                      style={{ width: '100%', height: '100%' }}
                    />
                  ))}
                </div>
              }
            </aside>
          </Col>
        </Row>
      </FormDialog>
    </div>
  )
}

export default BannerPosition
