import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useDropzone } from 'react-dropzone'
import { getData, callUploadApi, formatFormData } from 'common/utils'
import { toast } from 'react-toastify'
import { Select, Table } from 'antd'
import { HOSTNAME } from 'constant/appConstant'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Pagination from '@mui/material/Pagination'
import AlertDialog from 'components/ui/AlertDialog'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import SettingsIcon from '@mui/icons-material/Settings'
import 'antd/lib/table/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/space/style/index.css'
import 'antd/lib/select/style/index.css'
import '../styles/vod-epg.scss'

const { Option } = Select

const baseStyle = {
  // flex: 1,
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  // padding: '20px',
  // borderWidth: 2,
  // borderRadius: 2,
  // borderColor: '#eeeeee',
  // borderStyle: 'dashed',
  // backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  // outline: 'none',
  // transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  // borderColor: '#2196f3'
}

const acceptStyle = {
  // borderColor: '#00e676'
}

const rejectStyle = {
  // borderColor: '#ff1744'
}

const VodEpg = () => {
  const queryClient = useQueryClient()

  const [files, setFiles] = useState([])
  const [itemFiles, setItemFiles] = useState([])
  const [fileName, setFileName] = useState('')
  const [groupParam, setGroupParam] = useState(null)
  const [idDelete, setIdDelete] = useState(null)
  const [idUpload, setIdUpload] = useState(null)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenConfirmConvert, setIsOpenDConfirmConvert] = useState(false)
  const [isUploadCsvError, setIsUploadCsvError] = useState(false)
  const [idEPGConvert, setIdEPGConvert] = useState(null)

  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [dateValue, setDateValue] = useState(null)

  const [rowSelected, setRowSelected] = useState([])

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery(['getChannelFeed', groupParam, limit, offset, dateValue], async () => {
    if (groupParam) {
      if (dateValue) {
        const dateParse = formatFormData(dateValue)
        if (typeof dateParse === 'string' && dateParse.length === 10) {
          return await getData('GET', `vod-entities/${groupParam}/epg-feeds/?limit=${limit}&offset=${offset}&play_date=${dateParse}`)
        }
      } else {
        return await getData('GET', `vod-entities/${groupParam}/epg-feeds/?limit=${limit}&offset=${offset}`)
      }
    }
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `epg-feeds/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getChannelFeed')
    }
  })

  const mutationDeleteMultiple = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `tenants/${localStorage.getItem('tenant')}/epg-feeds/${id}`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getChannelFeed')
    }
  })

  const mutationConvert = useMutation(async (id) => {
    if (id) {
      return await getData('POST', `epg-feeds/${id}/convert-catchup`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getChannelFeed')
    }
  })

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const {
    getRootProps: getRootPropsItem,
    getInputProps: getInputPropsItem
  } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setItemFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  // Upload
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <Link to={`/epg/${record.id}`}>
                <IconButton color="success">
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
            {
              (window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.TERA_STAGING || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING || window.location.hostname === HOSTNAME.LOCALHOST) && (
                <Tooltip title="Convert To Vod">
                  <IconButton onClick={e => handleOpenFormConfirmConvert(e, record)}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          </div>
        )
      }
    },
    {
      title: 'Image',
      key: 'image',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.image) {
          return (
            <div>
              <img src={record.image.url} style={{ width: '100%' }} height={60} />
              <div {...getRootPropsItem({ style })} className="pt-1">
                <input {...getInputPropsItem()} />
                <Button variant="contained" size="small" color="inherit" onClick={e => handleClickUploadItem(e, record)}>Choose File</Button>
              </div>
            </div>
          )
        } else {
          return (
            <div {...getRootPropsItem({ style })}>
              <input {...getInputPropsItem()} />
              <Button variant="contained" size="small" color="inherit" onClick={e => handleClickUploadItem(e, record)}>Choose File</Button>
            </div>
          )
        }
      }
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: '30%'
    },
    {
      title: 'Start Time',
      key: 'start_at',
      dataIndex: 'start_at',
      width: '21%'
    },
    {
      title: 'End Time',
      key: 'end_at',
      dataIndex: 'end_at',
      width: '21%'
    },
    {
      title: 'Hot',
      key: 'hot',
      width: '8%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.is_hot) {
          return <CheckIcon color="success" />
        } else {
          return null
        }
      }
    }
  ]

  useEffect(() => {
    const urlString = window.location.href
    const url = new URL(urlString)
    const groupParamUrl = url.searchParams.get('group')
    setGroupParam(groupParamUrl)
  }, [])

  useEffect(() => {
    if (files && files.length > 0) {
      const fileUpload = files[0]
      setFileName(fileUpload.name)
    }
  }, [files])

  useEffect(() => {
    if (itemFiles && itemFiles.length > 0) {
      const fileUpload = itemFiles[0]

      const formData = new FormData()
      formData.append('image', fileUpload)
      callUploadApi('PUT', `epg-feeds/${idUpload}/upload-image/`, formData)
        .then(res => {
          const resultData = res.data
          if (resultData && resultData.success) {
            queryClient.invalidateQueries('getChannelFeed')
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [itemFiles])

  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map((item, index) => {
          return {
            key: item.id + index,
            ...item
          }
        })

        setTotal(dataList.data.total)
        setTotalPage(Math.ceil(dataList.data.total / limit))
        setData(dataListParse)
      }
    }
  }, [dataList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickUpload = async () => {
    if (files && files.length > 0) {
      const fileUpload = files[0]

      const formData = new FormData()
      formData.append('file', fileUpload)
      callUploadApi('PUT', `vod-entities/${groupParam}/upload-epgs/`, formData)
        .then(res => {
          const resultData = res.data
          if (resultData && resultData.success) {
            queryClient.invalidateQueries('getChannelFeed')
          }

          setIsUploadCsvError(false)
        })
        .catch(err => {
          setIsUploadCsvError(true)
          console.log(err)
        })
    } else {
      toast.warning('Please upload a file', {
        hideProgressBar: true,
        autoClose: 2000,
        position: 'top-right'
      })
    }
  }

  const handleClickUploadItem = (e, record) => {
    setIdUpload(record.id)
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleClickShowModalMultipleDelete = (e, record) => {
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickDelete = async () => {
    try {
      if (rowSelected.length > 0) {
        if (rowSelected.length === 1) {
          const idDelete = rowSelected[0] && rowSelected[0].id
          await mutationDelete.mutateAsync(idDelete)
        } else {
          let idStr = '?'
          for (let i = 0; i < rowSelected.length; i++) {
            if (i === rowSelected.length - 1) {
              idStr += 'id=' + (rowSelected[i] && rowSelected[i].id)
            } else {
              idStr += 'id=' + (rowSelected[i] && rowSelected[i].id) + '&'
            }
          }

          await mutationDeleteMultiple.mutateAsync(idStr)
        }

        setIsOpenDelete(false)
        setRowSelected([])
        toast.success('Entity deleted successfully')
      } else {
        await mutationDelete.mutateAsync(idDelete)
        setIsOpenDelete(false)
        toast.success('Entity deleted successfully')
      }
    } catch (err) {
      setIsOpenDelete(false)
      if (err.response) {
        //   dispatch(setErrorHtml(err.response.data))
      }

      // toast.error(ToastCustomError)
      console.log(err)
    }
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeDate = (value) => {
    setDateValue(value)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows)
    }
  }

  const handleClickExport = async () => {
    const resultExport = await getData(
      'GET',
      `vod-entities/${groupParam}/export-epg?time=${formatFormData(dateValue)}`
    )
    if (resultExport) {
      const resultData = resultExport.data
      if (resultData) {
        console.log(resultData)
        const link = document.createElement('a')
        link.download = 'report.xlsx'
        link.href = resultData.link
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const handleClickConvert = async () => {
    try {
      if (idEPGConvert && idEPGConvert !== '') {
        await mutationConvert.mutateAsync(idEPGConvert)

        setIsOpenDConfirmConvert(false)
        setIdEPGConvert(null)
        toast.success('Create Convert Catchup Successfully')
      } else {
        toast.success('Create Convert Catchup Failure')
        setIsOpenDConfirmConvert(false)
        setIdEPGConvert(null)
      }
    } catch (err) {
      if (err.response && err.response.status > 300) {
        const errData = err.response.data && err.response.data.errors
        toast.error(errData?.message || 'Something went wrong!')
      } else {
        toast.error('Something went wrong!')
      }
      setIdEPGConvert(null)
      setIsOpenDConfirmConvert(false)
    }
  }

  const handleOpenFormConfirmConvert = (e, record) => {
    setIdEPGConvert(record?.id || '')
    setIsOpenDConfirmConvert(true)
  }

  const handleCloseFormConfirmConvert = () => {
    setIdEPGConvert(null)
    setIsOpenDConfirmConvert(false)
  }

  return (
    <div className="vod-epg">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6}>
              <div className="pb-1">Upload Feed</div>
              <section className="d-inline-block">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <Button variant="contained" size="small" color="inherit">Choose File</Button>
                  <TextField variant="standard" value={fileName} onChange={() => { }} />
                </div>
              </section>
              <Button variant="contained" size="small" className="d-inline-block" startIcon={<UploadIcon />} onClick={handleClickUpload}>
                Upload
              </Button>
            </Col>
            <Col md={3}>
              <div className="pb-2"></div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={dateValue}
                  clearable
                  // inputFormat="dd/mm/yyyy"
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} size="small" variant="standard" fullWidth />}
                />
              </LocalizationProvider>
            </Col>

            {dateValue &&
              <Col md={2}>
                <div className="pb-1">&nbsp;</div>
                <Button variant="contained" size="small" startIcon={<DownloadIcon />} onClick={handleClickExport}>
                  Export
                </Button>
              </Col>
            }
          </Row>

          {isUploadCsvError &&
            <Row className="pt-1">
              <Col md={12}>
                <div style={{ color: 'red' }}>Error while reading this file</div>
              </Col>
            </Row>
          }
        </CardContent>
      </Card>

      <Row className="pt-5 pb-1">
        <Col md={8}>
          <div className="total-text">Total: {total}</div>
        </Col>

        {(rowSelected && rowSelected.length > 0) &&
          <Col md={4}>
            <Button className="float-right" variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleClickShowModalMultipleDelete}>
              Delete
            </Button>
          </Col>
        }
      </Row>

      <Row>
        <Col md={12}>
          <div className="table-antd">
            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading || isFetching}
              bordered
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
            />
          </div>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      <AlertDialog
        isOpen={isOpenConfirmConvert}
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to convert this item to vod?"
        onClose={handleCloseFormConfirmConvert}
        onSave={handleClickConvert}
      />

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
    </div>
  )
}

export default VodEpg
