import React from 'react'
import './styles/overlay.scss'

const Overlay = (props) => {
  return (
    <div className="overlay">
      <div className="loading"></div>
    </div>
  )
}

export default Overlay
