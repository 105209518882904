import React from 'react'
import PropTypes from 'prop-types'
import { formatSlug } from 'common/utils'
import { Row, Col } from 'components/ui'
import { HOSTNAME } from 'constant/appConstant'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Autocomplete from 'components/form/Autocomplete'

const VodTrailerForm = (props) => {
  // eslint-disable-next-line react/prop-types
  const { id, control, errors, setValue, statusList, onSourceId } = props

  const handleChangeName = (e) => {
    if (e && e.target) {
      if (setValue) {
        setValue('slug', formatSlug(e.target.value))
      }
    }
  }

  const handleChangeSourceId = (e) => {
    onSourceId(e?.target?.value)
  }

  return (
    <div className="vod-trailer-form">
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Trailer Number" name="episode" required control={control} error={errors.episode} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="Trailer Name" name="episode_name" required control={control} error={errors.episode_name} onHandleChange={handleChangeName} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField type="number" label="Runtime" name="runtime" control={control} error={errors.runtime} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField type="number" label="Order" name="ord" control={control} error={errors.ord} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Link play" name="link_play" control={control} error={errors.link_play} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="SourceID Play" name="source_id" control={control} error={errors.source_id} onHandleChange={handleChangeSourceId} />
        </Col>
      </Row>

      {(window.location.hostname === HOSTNAME.BRT || window.location.hostname === HOSTNAME.SDTV) &&
        <Row className="pt-4">
          <Col md={5}>
            <TextField type="number" label="Duration in minutes" name="duration_minute" control={control} error={errors.duration_minute} />
          </Col>
          <Col md={5} className="offset-md-2">
            <TextField type="number" label="Duration in seconds" name="duration_second" control={control} error={errors.duration_second} />
          </Col>
        </Row>
      }

      <Row className="pt-4">
        <Col md={5}>
          {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO)
            ? <Autocomplete
                options={statusList}
                label="Status"
                name="status"
                control={control}
                error={errors.status}
                disabled={!id}
              />
            : <Autocomplete
                options={statusList}
                label="Status"
                name="status"
                control={control}
                error={errors.status}
              />
          }
        </Col>

        {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) &&
          <Col md={5} className="offset-md-2">
            <TextField label="Reference Link" name="ref_link" control={control} error={errors.ref_link} />
          </Col>
        }
      </Row>
      <Row className="pt-4">
        <Col md={12}>
          <Checkbox name="released" className="pt-4 d-inline-block" control={control} label="Released State" />
          <Checkbox name="is_samsung_preview" className="pt-4 ml-4 d-inline-block" control={control} label="Samsung Preview" />
        </Col>
      </Row>
    </div>
  )
}

VodTrailerForm.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  statusList: PropTypes.array
}

VodTrailerForm.defaultProps = {
  statusList: [],
  status: null
}

export default VodTrailerForm
