import React from 'react'
import Layout from 'features/layout/components/Layout'
import MetaData from '../components/MetaData'

const MetaDataPage = (props) => {
  return (
    <Layout>
      <MetaData />
    </Layout>
  )
}

export default MetaDataPage
