import rootReducer from './slice'
import ribbonReducer from 'features/ribbon/slices/ribbonSlice'
import vodReducer from 'features/vod/slices/vodSlice'

const rootReducerExport = {
  root: rootReducer,
  ribbon: ribbonReducer,
  vod: vodReducer
}

export default rootReducerExport
