import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { Row, Col, Table } from 'components/ui'
import { Editor } from '@tinymce/tinymce-react'
import { setNewsReports } from 'features/vod/slices/vodSlice'
import { getData, deleteFormData } from 'common/utils'
import { useParams } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { Button as ButtonAntd, Image } from 'antd'
import TextField from 'components/form/TextField'
import Button from 'components/form/Button'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AlertDialog from 'components/ui/AlertDialog'
import FormDialog from 'components/form/FormDialog'
import Overlay from 'components/ui/Overlay'
import '../styles/vod-news-report.scss'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const VodNewsReports = (props) => {
  const { id: idVod } = useParams()
  const dispatch = useDispatch()
  const editorRef = useRef(null)
  const queryClient = useQueryClient()
  const newsReports = useSelector((state) => state.vod.newsReports)
  const { handleSubmit, control, setValue, formState: { errors }, setError, reset } = useForm()
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    multiple: true,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        image_url: URL.createObjectURL(file)
      })))
    }
  })

  // States
  const [dataNewsReports, setDataNewsReports] = useState([])
  const [isOpenAddNewsReport, setIsOpenAddNewsReport] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [files, setFiles] = useState([])
  const [updateFiles, setUpdateFiles] = useState([])
  const [idDelete, setIdDelete] = useState(null)
  const [idUpdate, setIdUpdate] = useState(null)
  const [description, setDescription] = useState('')

  const [visiblePosition, setVisiblePosition] = useState(-1)

  const [isLoading, setIsLoading] = useState(false)

  // Fetch API
  const mutation = useMutation(async ({ idVod, idUpdate, formData }) => {
    if (idUpdate) {
      return await getData('PUT', `entity_news_report/${idUpdate}`, formData)
    } else {
      return await getData('POST', 'entity_news_report/', formData)
    }
  }, {
    onSuccess: () => {
      // queryClient.invalidateQueries('getNewsReports')
      queryClient.invalidateQueries('getInfoVod')
    }
  })

  const mutationDelete = useMutation(async (newsReportId) => {
    if (newsReportId) {
      return await deleteFormData('DELETE', 'entity_news_report/', newsReportId)
    }
  }, {
    onSuccess: () => {
      // queryClient.invalidateQueries('getNewsReports')
      queryClient.invalidateQueries('getInfoVod')
    }
  })

  const { data: dataInfo } = useQuery(['getNewsReportInfo', idUpdate], async () => {
    if (idUpdate) {
      return await getData('GET', `entity_news_report/${idUpdate}/`)
    }
  })

  // UseEffects
  useEffect(() => {
    if (newsReports && Array.isArray(newsReports)) {
      setDataNewsReports(newsReports)
    }
  }, [newsReports])

  useEffect(() => {
    const resultData = dataInfo?.data
    if (resultData) {
      setValue('title', resultData.title)
      setValue('sub_title', resultData.sub_title)
      setValue('description', resultData.description)
      setValue('image_url', resultData?.image_url || '')
      setDescription(resultData?.description)

      if (Array.isArray(resultData.images)) {
        // setFiles(resultData.images)
        setUpdateFiles(resultData.images)
      }
    }
  }, [dataInfo])

  // Upload
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  // Handle Actions
  const handleClickShowModalAddNewsReport = () => {
    setIsOpenAddNewsReport(true)
  }

  const handleCloseAddNewsReport = () => {
    reset()
    setDescription('')
    setIdUpdate('')
    setIsOpenAddNewsReport(false)
  }

  const onSubmitAddNewsReport = async (data) => {
    try {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('title', data?.title || '')
      formData.append('sub_title', data?.sub_title || '')
      formData.append('tenant', localStorage.getItem('tenant'))
      formData.append('description', (editorRef?.current && editorRef?.current?.getContent()) || '')
      // if (files.length > 0) {
      //   formData.append('image', files[0])
      // }

      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i])
      }

      if (idVod) {
        formData.append('entity', idVod)
      }

      if (idUpdate) {
        const idArrTemp = updateFiles.map(item => {
          return item.id
        })
        formData.append('images_id', idArrTemp)
      }

      await mutation.mutateAsync({ idVod, idUpdate, formData }).then(res => {
        const resultData = res.data
        if (resultData) {
          const newsReportsTemp = [...newsReports]
          const newsReportId = resultData.id
          const index = newsReportsTemp.find(item => item.id === idUpdate)

          if (Array.isArray(newsReportsTemp)) {
            const currentKey = newsReportsTemp.length
            if (idUpdate) {
              newsReportsTemp[index.key] = {
                key: index.key,
                id: idUpdate,
                title: data?.title,
                sub_title: data?.sub_title,
                image_url: files.length > 0 ? files[0].image_url : data?.image_url
              }
            } else {
              newsReportsTemp.push({
                key: currentKey,
                id: newsReportId,
                title: data?.title,
                sub_title: data?.sub_title,
                image_url: files.length > 0 ? files[0].image_url : data?.image_url
              })
            }
          }
          dispatch(setNewsReports(newsReportsTemp))
          setIsOpenAddNewsReport(false)
          setFiles([])
          reset()
          setDescription('')

          setIsLoading(false)
        }
      })
    } catch (err) {
      setIsLoading(false)

      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      }
      console.log(err)
    }
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickDelete = async () => {
    try {
      if (Array.isArray(newsReports)) {
        const newsReportsTemp = newsReports.filter(item => item.id !== idDelete)
        setDataNewsReports(newsReportsTemp)
        dispatch(setNewsReports(newsReportsTemp))
        setIsOpenDelete(false)
      }
      if (idDelete) {
        await mutationDelete.mutateAsync(idDelete)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleUpdateNewsReport = (e, record) => {
    setIdUpdate(record.id)
    setIsOpenAddNewsReport(true)
  }

  const handleRemoveFiles = (e, index) => {
    const filesTemp = [...files]
    filesTemp.splice(index, 1)

    setFiles(filesTemp)
  }

  const handleRemoveUpdateFiles = (e, index) => {
    const filesTemp = [...updateFiles]
    filesTemp.splice(index, 1)

    setUpdateFiles(filesTemp)
  }

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '12%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton color="success" onClick={e => handleUpdateNewsReport(e, record)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Title',
      key: 'title',
      align: 'left',
      width: '20%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.title}
          </div>
        )
      }
    },
    {
      title: 'Sub Title',
      key: 'sub_title',
      align: 'left',
      width: '30%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.sub_title}
          </div>
        )
      }
    },
    {
      title: 'Preview',
      key: 'preview',
      align: 'left',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        const imgListTemp = record.images
        const firstItem = Array.isArray(imgListTemp) ? imgListTemp[0] : null

        return (
          <>
            {(Array.isArray(imgListTemp) && imgListTemp.length > 1)
              ? <div>
                  <div className="image-preview-container">
                    <Image
                      preview={{
                        visible: false
                      }}
                      width={100}
                      height={60}
                      src={firstItem?.image_url}
                      onClick={() => {
                        setVisiblePosition(index)
                      }}
                    />

                    {/* <div className="lastItemOverlay">
                      <div>Xem thêm</div>
                    </div> */}
                    <div
                      className="lastItemOverlayFirst"
                    ></div>
                    <div
                      className="lastItemOverlaySecond"
                    ></div>
                  </div>

                  <div
                    style={{
                      display: 'none'
                    }}
                  >
                    <Image.PreviewGroup
                      preview={{
                        visible: visiblePosition === index,
                        onVisibleChange: (vis) => {
                          if (vis === false) {
                            setVisiblePosition(-1)
                          }
                          if (vis === true) {
                            //
                          }
                        }
                        // onVisibleChange: (vis) => setVisible(vis)
                      }}
                    >
                      {Array.isArray(imgListTemp) && imgListTemp.map(item => {
                        return (
                          <Image key={item.id} width={100} height={60} src={item.image_url} />
                        )
                      })}
                    </Image.PreviewGroup>
                  </div>
                </div>
              : <div>
                  <Image width={100} height={60} src={firstItem?.image_url} />
                </div>
            }
          </>
        )
      }
    }
  ]

  return (
    <div className='vod-news-report'>
      <Row className="pt-2">
        <Col md={6}>
          <Button onClick={handleClickShowModalAddNewsReport} startIcon={<AddIcon />}>
            Add News Report
          </Button>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col md={12}>
          <Table
            columns={columns}
            data={dataNewsReports}
          // loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      {/* Form Dialog */}
      <FormDialog
        isOpen={isOpenAddNewsReport}
        title={ idUpdate ? 'Update News Report' : 'Add News Report' }
        maxWidth='md'
        onClose={handleCloseAddNewsReport}
        onSave={handleSubmit(onSubmitAddNewsReport)}
      >
        <Row className='pt-2'>
          <Col md={12}>
            <TextField
              label="Title"
              name="title"
              required
              control={control}
              error={errors.title}
            />
          </Col>
        </Row>
        <Row className='pt-4'>
          <Col md={12}>
            <TextField
              label="Sub Title"
              name="sub_title"
              control={control}
              error={errors.subTitle}
            />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={12}>
            <div className="description-title">Image</div>

            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
              </div>
            </section>

            {idUpdate
              ? <>
                  {updateFiles.map((file, index) => {
                    return (
                      <div key={index} style={{ position: 'relative', width: '150px', height: '150px', margin: '20px auto 0 auto' }}>
                        <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={e => handleRemoveUpdateFiles(e, index)} />
                        </div>

                        <img
                          key={index}
                          src={file.image_url}
                          style={{ width: '100%', height: '100%' }}
                        />
                        {/* {files.map((file, index) => (
                          <img
                            key={index}
                            src={file.image_url}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))} */}
                      </div>
                    )
                  })}

                  {files.map((file, index) => {
                    return (
                      <div key={index} style={{ position: 'relative', width: '150px', height: '150px', margin: '20px auto 0 auto' }}>
                        <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={e => handleRemoveFiles(e, index)} />
                        </div>

                        <img
                          key={index}
                          src={file.image_url}
                          style={{ width: '100%', height: '100%' }}
                        />
                        {/* {files.map((file, index) => (
                          <img
                            key={index}
                            src={file.image_url}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))} */}
                      </div>
                    )
                  })}
                </>
              : <>
                  {files.map((file, index) => {
                    return (
                      <div key={index} style={{ position: 'relative', width: '150px', height: '150px', margin: '20px auto 0 auto' }}>
                        <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={e => handleRemoveFiles(e, index)} />
                        </div>

                        <img
                          key={index}
                          src={file.image_url}
                          style={{ width: '100%', height: '100%' }}
                        />
                        {/* {files.map((file, index) => (
                          <img
                            key={index}
                            src={file.image_url}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))} */}
                      </div>
                    )
                  })}
                </>
            }
          </Col>
        </Row>
        <Row className="pt-4">
          <Col md={12}>
            <div className="description-title">Description</div>
            <Editor
              apiKey='8xwdiofw91cl5ikp80dpkij70sj7u8uziroarh8v1420aq6y'
              tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              // eslint-disable-next-line no-return-assign
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={description}
              init={{
                height: 350,
                branding: false,
                // plugins: [
                //   'advlist autolink lists link charmap preview anchor',
                //   'searchreplace visualblocks code fullscreen',
                //   'insertdatetime table paste code help wordcount'
                // ]
                plugins: 'advlist autolink lists link charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime table code help wordcount',
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                file_picker_callback: (cb, value, meta) => {
                  const input = document.createElement('input')
                  input.setAttribute('type', 'file')
                  input.setAttribute('accept', 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, text/plain, application/pdf, image/*')
                  input.onchange = function () {
                    const file = this.files[0]
                    const reader = new FileReader()

                    // FormData
                    const fd = new FormData()
                    const files = file
                    let filename = ''
                    // AJAX
                    let xhr = null
                    xhr = new XMLHttpRequest()
                    xhr.withCredentials = false

                    if (file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                      // fd.append('type', '25f572f5-3447-4bb5-8ff9-483aadf72258')
                      fd.append('image', files)
                      fd.append('tenant', localStorage.getItem('tenant'))
                      xhr.open('POST', `${process.env.REACT_APP_API_URL}images/`)
                    } else {
                      fd.append('file', files)
                      xhr.open('POST', `${process.env.REACT_APP_API_URL}tenants/${localStorage.getItem('tenant')}/upload_file/`)
                    }

                    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`)
                    xhr.onload = function () {
                      let json = null
                      if (xhr.status >= 300) {
                        alert('HTTP Error: ' + xhr.status)
                        return
                      }
                      json = JSON.parse(xhr.responseText)
                      if (!json) {
                        alert('Invalid JSON: ' + xhr.responseText)
                        return
                      }
                      filename = json.data?.url
                      reader.onload = function (e) {
                        cb(filename)
                      }
                      reader.readAsDataURL(file)
                    }
                    xhr.send(fd)
                  }

                  input.click()
                }
              }}
            />
          </Col>
        </Row>
      </FormDialog>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />

      {isLoading && <Overlay />}
    </div>
  )
}

export default VodNewsReports
