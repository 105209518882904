import React from 'react'
import Layout from 'features/layout/components/Layout'
import ReportUserView from '../components/ReportUserView'

const ReportUserViewPage = (props) => {
  return (
    <Layout>
      <ReportUserView />
    </Layout>
  )
}

export default ReportUserViewPage
