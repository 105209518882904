import React from 'react'
import Layout from 'features/layout/components/Layout'
import MenuMobileForm from '../components/MenuMobileForm'

const MenuMobileFormPage = () => {
  return (
    <Layout>
      <MenuMobileForm />
    </Layout>
  )
}

export default MenuMobileFormPage
