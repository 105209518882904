/* eslint-disable react/prop-types */
import React from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import './styles/video.scss'

export const VideoJS = (props) => {
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  const { options, src, onReady } = props

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current

      if (!videoElement) return

      const player = playerRef.current = videojs(videoElement, options, () => {
        // player.log('player is ready')
        onReady && onReady(player)
      })

    // You can update player in the `else` block here, for example:
    } else {
      const player = playerRef.current

      if (src) {
        player.src(src)
      }
    }
  }, [options, src, videoRef])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video ref={videoRef} className='video-js vjs-big-play-centered' />
    </div>
  )
}

export default VideoJS
