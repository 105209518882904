import React from 'react'
import Layout from 'features/layout/components/Layout'
import UserProfileUpdatePassword from '../components/UserProfileUpdatePassword'

const UserProfileUpdatePasswordPage = (props) => {
  return (
    <Layout>
      <UserProfileUpdatePassword />
    </Layout>
  )
}

export default UserProfileUpdatePasswordPage
