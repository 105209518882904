import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getData, formatFormData } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import AutocompleteMultiple from 'components/form/AutocompleteMultiple'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

const UserForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()

  const [groupList, setGroupList] = useState([])

  const { data: dataInfo } = useQuery(['getInfoUser', id], async () => {
    if (id) {
      return await getData('GET', `users/${id}/`)
    }
  })

  const { data: dataGroup } = useQuery('getUserGroup', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/active-groups/`)
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `users/${id}/`, dataForm)
      } else {
        return await getData('POST', 'users/', dataForm)
      }
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const dataResult = dataInfo.data

      if (dataResult) {
        setValue('username', dataResult.username)
        setValue('email', dataResult.email)
        setValue('password', '')
        setValue('is_active', dataResult.is_active)

        const groupArr = dataResult.groups
        if (Array.isArray(groupArr)) {
          const groupArrParse = groupArr.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
          setValue('groups', groupArrParse)
        }
      }
    }
  }, [dataInfo])

  useEffect(() => {
    if (dataGroup) {
      const resultData = dataGroup.data

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setGroupList(selectData)
      }
    }
  }, [dataGroup])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    let groupParam = []
    const groupArr = data.groups
    if (Array.isArray(groupArr)) {
      groupParam = groupArr.map(item => {
        return item.value
      })
    }

    try {
      if (id) {
        await mutation.mutateAsync({
          email: formatFormData(data.email),
          groups: groupParam,
          is_active: !!data.is_active
        })
        history.goBack()
      } else {
        if (data.password !== data.confirm_password) {
          setError('confirm_password', {
            type: 'manual',
            message: 'The password confirmation does not match'
          })
        } else {
          await mutation.mutateAsync({
            username: formatFormData(data.username),
            email: formatFormData(data.email),
            password: formatFormData(data.password),
            groups: groupParam,
            tenant: localStorage.getItem('tenant')
          })
          history.goBack()
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="user-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Username" name="username" required={!id} disabled={!!id} control={control} error={errors.username} />
            </Col>
          </Row>
          {!id &&
            <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="password" label="Password" name="password" required={!id} disabled={!!id} control={control} error={errors.password} />
            </Col>
          </Row>
          }
          {!id &&
            <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="password" label="Confirm password" name="confirm_password" required={!id} disabled={!!id} control={control} error={errors.confirm_password} />
            </Col>
          </Row>
          }
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Email" name="email" control={control} error={errors.email} />
            </Col>
          </Row>

          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <AutocompleteMultiple
                options={groupList}
                label="Groups"
                name="groups"
                control={control}
                error={errors.groups}
              />
            </Col>
          </Row>

          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox name="is_active" control={control} defaultChecked disabled={!id} label="Active" />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default UserForm
