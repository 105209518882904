import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Button from 'components/form/Button'
import Pagination from '@mui/material/Pagination'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Select, Tabs, Table } from 'antd'
import { toast } from 'react-toastify'
import VodFilter from './VodFilter'
import 'antd/lib/table/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/space/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/tabs/style/index.css'
import '../styles/vod-recommend.scss'

const { Option } = Select
const { TabPane } = Tabs

const VodRecommend = (props) => {
  const queryClient = useQueryClient()

  // const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [dataRecommend, setDataRecommend] = useState([])

  // Filter
  const [typeFilter, setTypeFilter] = useState(null)
  const [categoryFilter, setCategoryFilter] = useState(null)
  const [statusFilter, setStatusFilter] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')

  const [rowSelected, setRowSelected] = useState([])
  const [rowRecommendSelected, setRowRecommendSelected] = useState([])

  const [groupParam, setGroupParam] = useState(null)

  const { data: dataList, isLoading, isFetching } = useQuery(['getRecommendVodEntities', limit, offset, typeFilter, categoryFilter, statusFilter, searchFilter, groupParam], async () => {
    let endpointTemp = ''

    if (typeFilter) {
      endpointTemp += `&type=${typeFilter.value}`
    }
    if (categoryFilter) {
      endpointTemp += `&category=${categoryFilter.value}`
    }
    if (statusFilter) {
      endpointTemp += `&status=${statusFilter.value}`
    }
    if (searchFilter !== '') {
      endpointTemp += `&q=${searchFilter}`
    }

    if (groupParam) {
      return await getData('GET', `vod-entities/${groupParam}/available-recommendation/?order=-updated_at&limit=${limit}&offset=${offset}${endpointTemp}`)
    }
  })

  const { data: dataRecommendList } = useQuery(['getVodRecommend', groupParam], async () => {
    if (groupParam) {
      return await getData('GET', `vod-entities/${groupParam}/recommendation/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('POST', 'recommendation/', dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRecommendVodEntities')
      queryClient.invalidateQueries('getVodRecommend')
    }
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `recommendation/${id}`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRecommendVodEntities')
      queryClient.invalidateQueries('getVodRecommend')
    }
  })

  const mutationDeleteMultiple = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `tenants/${localStorage.getItem('tenant')}/recommendation/${id}`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRecommendVodEntities')
      queryClient.invalidateQueries('getVodRecommend')
    }
  })

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const columns = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: '60%'
    },
    {
      title: 'VOD Type',
      key: 'type',
      width: '30%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.type === 1
              ? 'SINGLE MOVIE'
              : record.type === 2
                ? 'SHOW/DRAMA'
                : record.type === 3
                  ? 'SEASON'
                  : record.type === 4
                    ? 'EPISODE'
                    : record.type === 5
                      ? 'CHANNEL'
                      : record.type === 6
                        ? 'TRAILER'
                        : record.type === 7
                          ? 'DOCUMENT'
                          : record.type === 8
                            ? 'BREAKING NEWS'
                            : record.type === 9
                              ? 'AUDIO'
                              : record.type === 10
                                ? 'EVENT'
                                : record.type === 11
                                  ? 'VERTICAL VIDEO'
                                  : record.type === 12
                                    ? 'SHOW AUDIO'
                                    : record.type === 17
                                      ? 'MULTI NEWSPAPER'
                                      : record.type === 18
                                        ? 'EMAGAZINE'
                                        : record.type === 19
                                          ? 'INTERNAL NEWS'
                                          : 'Unknown'}
          </div>
        )
      }
    }
  ]

  const columnsRecommend = [
    {
      title: 'Title',
      key: 'title',
      width: '60%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record.target && record.target.title}</div>
        )
      }
    },
    {
      title: 'VOD Type',
      key: 'type',
      width: '30%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record.target) {
          return (
            <div>
              {record.target.type === 1
                ? 'SINGLE MOVIE'
                : record.target.type === 2
                  ? 'SHOW/DRAMA'
                  : record.target.type === 3
                    ? 'SEASON'
                    : record.target.type === 4
                      ? 'EPISODE'
                      : record.target.type === 5
                        ? 'CHANNEL'
                        : 'TRAILER'}
            </div>
          )
        } else {
          return null
        }
      }
    }
  ]

  useEffect(() => {
    const urlString = window.location.href
    const url = new URL(urlString)
    const groupParamUrl = url.searchParams.get('group')
    setGroupParam(groupParamUrl)
  }, [])

  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        // setTotal(dataList.data.total)
        setTotalPage(Math.ceil(dataList.data.total / limit))
        setData(dataListParse)
      }
    }
  }, [dataList])

  useEffect(() => {
    if (dataRecommendList) {
      const resultData = dataRecommendList.data

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })
        setDataRecommend(dataListParse)
      }
    }
  }, [dataRecommendList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  // Filter
  const handleChangeTypeFilter = (value) => {
    setTypeFilter(value)
  }
  const handleChangeCategoryFilter = (value) => {
    setCategoryFilter(value)
  }
  const handleChangeStatusFilter = (value) => {
    setStatusFilter(value)
  }
  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleClickAdd = async () => {
    try {
      if (rowSelected.length > 0) {
        if (rowSelected.length === 1) {
          await mutation.mutateAsync({
            // is_active: true,
            source: groupParam,
            target: rowSelected[0] && rowSelected[0].id,
            tenant: localStorage.getItem('tenant')
          })
        } else {
          const arrTemp = rowSelected.map(item => {
            return {
              // is_active: true,
              source: groupParam,
              target: item && item.id,
              tenant: localStorage.getItem('tenant')
            }
          })

          await mutation.mutateAsync(arrTemp)
        }

        setRowSelected([])
        setRowRecommendSelected([])
        toast.success('Entity added successfully')
      } else {
        toast.warning('Please select entity', {
          hideProgressBar: true,
          autoClose: 2000,
          position: 'top-right'
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickDelete = async () => {
    try {
      if (rowRecommendSelected.length > 0) {
        if (rowRecommendSelected.length === 1) {
          const idDelete = rowRecommendSelected[0] && rowRecommendSelected[0].id
          await mutationDelete.mutateAsync(idDelete)
        } else {
          let idStr = '?'
          for (let i = 0; i < rowRecommendSelected.length; i++) {
            if (i === rowRecommendSelected.length - 1) {
              idStr += 'id=' + (rowRecommendSelected[i] && rowRecommendSelected[i].id)
            } else {
              idStr += 'id=' + (rowRecommendSelected[i] && rowRecommendSelected[i].id) + '&'
            }
          }

          await mutationDeleteMultiple.mutateAsync(idStr)
        }

        setRowSelected([])
        setRowRecommendSelected([])
        toast.success('Entity deleted successfully')
      } else {
        toast.warning('Please select entity', {
          hideProgressBar: true,
          autoClose: 2000,
          position: 'top-right'
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows)
    }
  }

  const rowRecommendSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowRecommendSelected(selectedRows)
    }
  }

  return (
    <div className="vod-recommend">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Entity List" key="1">
          <VodFilter
            onChangeType={handleChangeTypeFilter}
            onChangeCategory={handleChangeCategoryFilter}
            onChangeStatus={handleChangeStatusFilter}
            onClickSearch={handleClickSearchFilter}
          />

          {(rowSelected && rowSelected.length > 0) &&
            <Row className="pt-5">
              <Col md={12}>
                <Button className="float-right" startIcon={<AddIcon />} onClick={handleClickAdd}>
                  Add
                </Button>
              </Col>
            </Row>
          }

          <Row className={`pt-${(rowSelected && rowSelected.length > 0) ? '3' : '5'}`}>
            <Col md={12}>
              <div className="table-antd">
                <Table
                  columns={columns}
                  dataSource={data}
                  loading={isLoading || isFetching}
                  bordered
                  pagination={false}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={12}>
              <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
                {pageList && pageList.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>{item.label}</Option>
                  )
                })}
              </Select>

              <Pagination
                className="float-right"
                count={totalPage}
                page={current}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                color="standard"
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Recommend Entity" key="2">
          {(rowRecommendSelected && rowRecommendSelected.length > 0) &&
            <Row className="pt-3">
              <Col md={12}>
                <Button className="float-right" color="error" startIcon={<DeleteIcon />} onClick={handleClickDelete}>
                  Delete
                </Button>
              </Col>
            </Row>
          }

          <Row className="pt-3">
            <Col md={12}>
              <div className="table-antd">
                <Table
                  columns={columnsRecommend}
                  dataSource={dataRecommend}
                  // loading={isLoading || isFetching}
                  bordered
                  pagination={false}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowRecommendSelection
                  }}
                />
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default VodRecommend
