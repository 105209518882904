import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { HOSTNAME } from 'constant/appConstant'
import { getData } from 'common/utils'
import HomeChart from './HomeChart'
import '../styles/home.scss'

const Home = (props) => {
  const [id, setId] = useState(null)

  const { data: dataUserProfile } = useQuery(['getUserProfileHome', id], async () => {
    if (id) {
      return await getData('GET', `users/${id}/info`)
    }
  })

  useEffect(() => {
    setId(localStorage.getItem('profileid'))
  }, [])

  useEffect(() => {
    // console.log(dataUserProfile)
  }, [dataUserProfile])

  return (
    <div className="home">
      {window.location.hostname === HOSTNAME.SDTV &&
        <HomeChart />
      }
    </div>
  )
}

export default Home
