import React from 'react'
import Layout from 'features/layout/components/Layout'
import RssForm from '../components/RssForm'

const RssFormPage = (props) => {
  return (
    <Layout>
      <RssForm />
    </Layout>
  )
}

export default RssFormPage
