import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodEpg from '../components/VodEpg'

const VodEpgPage = (props) => {
  return (
    <Layout>
      <VodEpg />
    </Layout>
  )
}

export default VodEpgPage
