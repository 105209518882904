/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import Button from 'components/form/Button'
import Pagination from '@mui/material/Pagination'
import AlertDialog from 'components/ui/AlertDialog'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Select } from 'antd'
import 'antd/lib/pagination/style/index.css'
import { toast } from 'react-toastify'
import '../styles/core-list.scss'

const { Option } = Select

const CoreList = (props) => {
  const { code, endpoint, url, columns, limit, isAddNew, isAction, customAction } = props

  const queryClient = useQueryClient()

  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [limitData, setLimitData] = useState(10)
  const [current, setCurrent] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [idDelete, setIdDelete] = useState(null)
  const [columnData, setColumnData] = useState([])

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery([`get${code}`, limitData, offset], async () => {
    if (url === 'ribbon-item') {
      return await getData('GET', `${endpoint}?limit=${limitData}&offset=${offset}`)
    } else {
      if (url === 'group') {
        return await getData('GET', `tenants/${localStorage.getItem('tenant')}/${endpoint}/?order=level&limit=${limitData}&offset=${offset}`)
      } else {
        return await getData('GET', `tenants/${localStorage.getItem('tenant')}/${endpoint}/?limit=${limitData}&offset=${offset}`)
      }
    }
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      if (url === 'ribbon-item') {
        return await getData('DELETE', `ribbon-items/${id}/`)
      } else if (url === 'tag-topic') {
        return await getData('DELETE', `tag/${id}/`)
      } else if (url === 'menu-mobile') {
        return await getData('DELETE', `menu-bottom/${id}/`)
      } else {
        return await getData('DELETE', `${endpoint}/${id}/`)
      }
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(`get${code}`)
    }
  })

  useEffect(() => {
    if (limit) {
      setLimitData(limit)
    }
  }, [limit])

  useEffect(() => {
    if (Array.isArray(columns)) {
      const columnTemp = [
        {
          title: 'Action',
          key: 'operation',
          fixed: 'left',
          align: 'center',
          width: customAction !== null ? (url === 'user' ? '20%' : '15%') : '10%',
          // eslint-disable-next-line react/display-name
          render: (text, record, index) => {
            return (
              <div>
                {/* <Tooltip title="Delete">
                  <i
                    className="fa fa-trash"
                    style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }}
                    onClick={e => handleClickShowModalDelete(e, record)}
                  ></i>
                </Tooltip> */}
                {/* <Tooltip title="Edit">
                  <Link to={`/${url}/${record.id}`}>
                    <i className="fa fa-pencil" style={{ color: 'green', fontSize: '20px', cursor: 'pointer', marginLeft: '15px' }}></i>
                  </Link>
                </Tooltip> */}
                <Tooltip title="Delete">
                  <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <Link to={url === 'ribbon-item' ? `/entityvod/${record.entity && record.entity.id}` : `/${url}/${record.id}`}>
                    <IconButton color="success">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                {(customAction !== null && typeof customAction === 'function') && customAction(record)}
              </div>
            )
          }
        }
      ]

      // const columnParse = columns.map(item => {
      //   if (item.isSearch) {
      //     return {
      //       ...item,
      //       ...getColumnSearchProps(item.dataIndex)
      //     }
      //   } else {
      //     return {
      //       ...item
      //     }
      //   }
      // })

      const columnConcat = columnTemp.concat(columns)

      if (isAction) {
        setColumnData(columnConcat)
      } else {
        setColumnData(columns)
      }
    }
  }, [columns])

  useEffect(() => {
    if (dataList && limitData) {
      let resultData = []
      const dataListData = dataList.data

      if (dataListData) {
        if (Array.isArray(dataListData)) {
          resultData = dataListData
          setTotal(dataListData.length)
          setTotalPage(Math.ceil(dataListData.length / limitData))
        } else {
          resultData = dataListData.results
          setTotal(dataListData.total)
          setTotalPage(Math.ceil(dataListData.total / limitData))
        }
      }

      // // Tạm ẩn user admin
      // if (Array.isArray(resultData)) {
      //   const dataListParse = resultData.map(item => {
      //     return {
      //       key: item.id,
      //       ...item
      //     }
      //   })
      //   setData(dataListParse)
      // }

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        if (url === 'user') {
          const dataTemp = dataListParse.filter(item => {
            return item.username !== 'admin'
          })
          setData(dataTemp)
        } else {
          setData(dataListParse)
        }
      }
    }
  }, [dataList, limitData])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limitData)
  }

  const handleChangeLimit = (value) => {
    setLimitData(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)

      setIsOpenDelete(false)
      toast.success(`${code} deleted successfully`)
    } catch (err) {
      setIsOpenDelete(false)
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  // const handleChangePagination = (page, pageSize) => {
  //   setCurrent(page)
  //   setOffset((page - 1) * limitData)
  // }

  // const handleShowSizeChange = (current, size) => {
  //   setLimitData(size)
  // }

  return (
    <div className="core-list">
      <Row className="pb-2">
        {isAddNew &&
          <Col md={12}>
            <Link to={`/${url}/add`}>
              <Button className="float-right" startIcon={<AddIcon />}>
                Add New
              </Button>
            </Link>
          </Col>
        }
      </Row>
      <Row className="pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columnData}
            data={data}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={12}>
          {/* <Pagination
            className="float-right"
            showSizeChanger
            current={current}
            total={total}
            pageSize={limitData}
            pageSizeOptions={[10, 20, 50]}
            onChange={handleChangePagination}
            onShowSizeChange={handleShowSizeChange}
          /> */}

          {(url !== 'image') &&
            <Select className="float-right ml-4" value={limitData} onChange={handleChangeLimit} style={{ width: 110 }}>
              {pageList && pageList.map((item, index) => {
                return (
                  <Option key={index} value={item.value}>{item.label}</Option>
                )
              })}
            </Select>
          }

          {(url !== 'image') &&
            <Pagination
              className="float-right"
              count={totalPage}
              page={current}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              color="standard"
            />
          }
        </Col>
      </Row>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
    </div>
  )
}

CoreList.propTypes = {
  code: PropTypes.string,
  endpoint: PropTypes.string,
  url: PropTypes.string,
  columns: PropTypes.array,
  limit: PropTypes.number,
  isAddNew: PropTypes.bool,
  isAction: PropTypes.bool,
  customAction: PropTypes.func
}

CoreList.defaultProps = {
  code: '',
  endpoint: '',
  url: '',
  columns: [],
  limit: 10,
  isAddNew: true,
  isAction: true
}

export default CoreList
