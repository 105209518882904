import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getData, formatChartDate } from 'common/utils'
import Spinner from 'components/ui/Spinner'
import BarChart from 'components/chart/BarChart'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import DownloadIcon from '@mui/icons-material/Download'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Button from 'components/form/Button'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

const HomeChart = (props) => {
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const [dateMonthList, setDateMonthList] = useState([])
  const [hitCountList, setHitCountList] = useState([])

  const { data: dataDaily, isLoading, isFetching } = useQuery(['getContentDaily', fromDate, toDate], async () => {
    if (fromDate && toDate) {
      const fromDateFormat = formatChartDate(fromDate)
      const toDateFormat = formatChartDate(toDate)

      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/stats/content-daily-data/?from_date=${fromDateFormat}&to_date=${toDateFormat}`)
    }
  })

  useEffect(() => {
    if (dataDaily) {
      const resultData = dataDaily.data
      if (resultData) {
        const arrData = resultData.data
        if (Array.isArray(arrData)) {
          const dateMonthListTemp = arrData.map(item => {
            return item.date_month
          })
          const hitCountListTemp = arrData.map(item => {
            return item.hit_count
          })

          setDateMonthList(dateMonthListTemp)
          setHitCountList(hitCountListTemp)
        }
      }
    }
  }, [dataDaily])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeFromDate = (value) => {
    setFromDate(value)
  }

  const handleChangeToDate = (value) => {
    setToDate(value)
  }

  const handleClickExport = async () => {
    const fromDateFormat = formatChartDate(fromDate)
    const toDateFormat = formatChartDate(toDate)

    const resultExport = await getData(
      'GET',
      `tenants/${localStorage.getItem('tenant')}/stats/content-daily-export/?from_date=${fromDateFormat}&to_date=${toDateFormat}`
    )
    if (resultExport) {
      const resultData = resultExport.data
      if (resultData) {
        const link = document.createElement('a')
        link.download = 'report.xlsx'
        link.href = resultData.excel_url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  return (
    <div>
      <Card>
        <CardContent>
          <Row className="pt-2">
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From Date"
                  value={fromDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeFromDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To Date"
                  value={toDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeToDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col className="offset-1" md={5}>
              <Button startIcon={<DownloadIcon />} onClick={handleClickExport}>Export CSV</Button>
            </Col>
          </Row>
        </CardContent>
      </Card>

      <div className="mt-5">
        {(isLoading || isFetching)
          ? <Spinner />
          : <BarChart
              id="daily-chart"
              xLabel="Date"
              yLabel="Hit"
              labels={dateMonthList}
              datasets={[{
                label: 'Hit',
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                barThickness: 30,
                data: hitCountList
              }]}
            />
          }
      </div>
    </div>
  )
}

export default HomeChart
