import React from 'react'
import Layout from 'features/layout/components/Layout'
import ReportContent from '../components/ReportContent'

const ReportContentPage = (props) => {
  return (
    <Layout>
      <ReportContent />
    </Layout>
  )
}

export default ReportContentPage
