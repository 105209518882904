import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const Error = (props) => {
  const errorHtml = useSelector((state) => state.root.errorHtml)

  useEffect(() => {
    console.log(errorHtml)
  }, [errorHtml])

  return (
    <div className="error">
      {errorHtml &&
        <div dangerouslySetInnerHTML={{
          __html: errorHtml
        }}></div>
      }
    </div>
  )
}

export default Error
