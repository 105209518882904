import React from 'react'
import PropTypes from 'prop-types'
import { Switch as SwitchAntd } from 'antd'

const Switch = (props) => {
  const { className, label, disabled, checked } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <SwitchAntd
        checkedChildren={label}
        unCheckedChildren={label}
        checked={checked}
        disabled={disabled}
      />
    </div>
  )
}

Switch.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool
}

Switch.defaultProps = {
  className: '',
  label: '',
  disabled: false,
  checked: true
}

export default Switch
