import React from 'react'
import Layout from 'features/layout/components/Layout'
import BannerPosition from '../components/BannerPosition'

const BannerPositionPage = (props) => {
  return (
    <Layout>
      <BannerPosition />
    </Layout>
  )
}

export default BannerPositionPage
