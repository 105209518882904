import React from 'react'
import Layout from 'features/layout/components/Layout'
import AdsInfo from '../components/AdsInfo'

const AdsInfoPage = (props) => {
  return (
    <Layout>
      <AdsInfo />
    </Layout>
  )
}

export default AdsInfoPage
