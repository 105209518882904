/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { getData, getCrawlerData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import Pagination from '@mui/material/Pagination'
import { Select } from 'antd'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DownloadIcon from '@mui/icons-material/Download'
import CrawlerFilter from './CrawlerFilter'
import { toast } from 'react-toastify'
import 'antd/lib/select/style/index.css'

const { Option } = Select

const Crawler = (props) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const timeoutRef = useRef()

  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])

  const [categoryFilter, setCategoryFilter] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')

  const { data: dataList, isLoading, isFetching } = useQuery(['getContentRssList', limit, offset, categoryFilter, searchFilter], async () => {
    let endpointTemp = ''

    if (categoryFilter) {
      endpointTemp += `&category=${categoryFilter.label}`
    }

    if (searchFilter !== '') {
      endpointTemp += `&title=${searchFilter}`
    }

    return await getCrawlerData('GET', `vtcnews/content-rss?limit=${limit}&offset=${offset}${endpointTemp}`)
  })

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [timeoutRef])

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  useEffect(() => {
    if (dataList) {
      const resultList = dataList.results
      if (Array.isArray(resultList)) {
        const dataTemp = resultList.map(item => {
          return {
            key: item.id,
            ...item
          }
        })
        setData(dataTemp)
        setTotal(dataList.total)
        setTotalPage(Math.ceil(dataList.total / limit))
      }
    }
  }, [dataList])

  const columns = [
    {
      title: 'Thumbnail',
      key: 'thumbnail',
      width: '5%',
      align: 'center',
      // ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            {(record.thumbnail_url && record.thumbnail_url !== '')
              ? <>
                  <img src={record.thumbnail_url} width={60} height={60} />
                  <div style={{ marginTop: '5px', cursor: 'pointer' }} onClick={e => handleClickDownloadImage(e, record)}>
                    <DownloadIcon color='info' />
                  </div>
                </>
              : <></>
            }
          </div>
        )
      }
    },
    {
      title: 'Title',
      key: 'title',
      width: '25%',
      // ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <a key={index} href={record.link} target="_blank" rel="noopener noreferrer" className='link-rss-crawler'>
            {record?.title}
          </a>
        )
      }
    },
    {
      title: 'Category',
      key: 'category_title',
      width: '10%',
      // ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record?.category_title}</div>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '6%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.status === 1
              ? <div style={{ color: '#1976d2' }}>Initial</div>
              : record.status === 2
                ? <div style={{ color: '#ed6c02' }}>Progressing</div>
                : record.status === 3
                  ? <div style={{ color: '#2e7d32' }}>Success</div>
                  : record.status === 4
                    ? <div style={{ color: '#d32f2f' }}>Failure</div>
                    : <></>}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'operation',
      // fixed: 'left',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {(record.status === 1 || record.status === 4)
              ? <Button size='small' variant='contained' startIcon={<CloudDownloadIcon />} onClick={e => handleClickCrawlData(e, record)}>
                  Crawl data
                </Button>
              : record.status === 3
                ? <>
                    {(record.entity_id !== '' && record.entity_id !== null)
                      ? <Button size='small' variant='contained' color='success' startIcon={<VisibilityIcon />} onClick={e => handleClickViewEntity(e, record)}>
                          View
                        </Button>
                      : <Button size='small' variant='contained' color='success' startIcon={<AddIcon />} onClick={e => handleClickCreateEntity(e, record)}>
                          Create entity
                        </Button>
                    }
                  </>
                : <></>
            }
          </div>
        )
      }
    }
  ]

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleChangeCategoryFilter = (value) => {
    setCategoryFilter(value)
  }

  const handleClickCrawlData = async (e, record) => {
    if (record) {
      try {
        // eslint-disable-next-line no-unused-vars
        const result = await getCrawlerData('GET', `vtcnews/crawler-content/${record.id}`)

        toast.success('Crawl data successfully')
        queryClient.invalidateQueries('getContentRssList')

        timeoutRef.current = setTimeout(() => {
          queryClient.invalidateQueries('getContentRssList')
        }, 2000)
      } catch (err) {
        console.log(err)
        toast.error('Something went wrong!')
      }
    }
  }

  const handleClickCreateEntity = async (e, record) => {
    if (record) {
      try {
        // eslint-disable-next-line no-unused-vars
        const result = await getData('POST', 'vod-entities/', {
          category: 1,
          title: record.title,
          slug: record.slug,
          type: 17,
          short_description: record.short_description,
          long_description: record.description,
          ref_link: record.link,
          tenant: localStorage.getItem('tenant')
        })

        if (result) {
          // eslint-disable-next-line no-unused-vars
          const resultCheck = await getCrawlerData('PUT', `vtcnews/link-entity-content/${record.id}`, {
            entity_id: result.data?.id
          })
          queryClient.invalidateQueries('getContentRssList')
        }

        toast.success('Entity created successfully')
      } catch (err) {
        console.log(err)
        toast.error('Something went wrong!')
      }
    }
  }

  const handleClickViewEntity = (e, record) => {
    history.push(`/entityvod/${record?.entity_id}`)
  }

  const handleClickDownloadImage = async (e, record) => {
    const thumbnailUrl = record.thumbnail_url
    if (typeof thumbnailUrl === 'string') {
      const image = await fetch(record.thumbnail_url)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = thumbnailUrl.split('/').pop()
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }

  return (
    <div className="vod">
      <CrawlerFilter
        onChangeCategory={handleChangeCategoryFilter}
        onClickSearch={handleClickSearchFilter}
      />

      <Row className="pt-4">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            data={data}
            // onChange={handleChangeTable}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>
    </div>
  )
}

export default Crawler
