import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import axios from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import Navbar from './Navbar'
import LeftSidebar from './LeftSidebar'
// import RightSidebar from './RightSidebar'
import { HOSTNAME } from 'constant/appConstant'
import { useScript } from 'common/hook'
import imgUser from 'components/ui/styles/images/user.png'
import imgLogo from 'components/ui/styles/images/logo.png'
import imgLogoTHVL from 'components/ui/styles/images/logo_thvl.png'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/layout.scss'

const Layout = (props) => {
  const { children } = props

  useScript('/vendor/theme/theme.js')

  const location = useLocation()
  const { id } = useParams()

  const [title, setTitle] = useState('Dashboard')
  const [collapse, setCollapse] = useState(false)
  // const [username, setUsername] = useState('')

  // useEffect(() => {
  //   if (localStorage.getItem('username')) {
  //     setUsername(localStorage.getItem('username'))
  //   }
  // }, [])

  useEffect(() => {
    if (location) {
      if (location.pathname === '/') {
        setTitle('Dashboard')
      } else if (location.pathname === '/user') {
        setTitle('User List')
      } else if (location.pathname === '/user/add') {
        setTitle('Add User')
      } else if (location.pathname === `/user/${id}`) {
        setTitle('Edit User')
      } else if (location.pathname === `/user/${id}/permission`) {
        setTitle('User Permission List')
      } else if (location.pathname === '/profile') {
        setTitle('Profile')
      } else if (location.pathname === '/change-password') {
        setTitle('Change Password')
      } else if (location.pathname === `/user/${id}/reset-password`) {
        setTitle('Reset Password')
      } else if (location.pathname === '/group') {
        setTitle('Group List')
      } else if (location.pathname === '/group/add') {
        setTitle('Add Group')
      } else if (location.pathname === `/group/${id}`) {
        setTitle('Edit Group')
      } else if (location.pathname === `/group/${id}/permission`) {
        setTitle('Group Permission List')
      } else if (location.pathname === '/permission') {
        setTitle('Permission List')
      } else if (location.pathname === '/tenant') {
        setTitle('Tenant List')
      } else if (location.pathname === '/tenant/add') {
        setTitle('Add Tenant')
      } else if (location.pathname === `/tenant/${id}`) {
        setTitle('Edit Tenant')
      } else if (location.pathname === '/menu-platform') {
        setTitle('Platform Menu List')
      } else if (location.pathname === '/menu-platform/add') {
        setTitle('Add Platform Menu')
      } else if (location.pathname === `/menu-platform/${id}`) {
        setTitle('Edit Platform Menu')
      } else if (location.pathname === '/menu') {
        setTitle('Menu List')
      } else if (location.pathname === '/menu/add') {
        setTitle('Add Menu')
      } else if (location.pathname === `/menu/${id}`) {
        setTitle('Edit Menu')
      } else if (location.pathname === '/menu-slide') {
        setTitle('Menu Slide')
      } else if (location.pathname === '/menu-slide/add') {
        setTitle('Add Menu Slide')
      } else if (location.pathname === `/menu-slide/${id}`) {
        setTitle('Edit Menu Slide')
      } else if (location.pathname === '/ribbon') {
        setTitle('Ribbon List')
      } else if (location.pathname === `/ribbon-item/${id}`) {
        setTitle('Ribbon Item List')
      } else if (location.pathname === '/ribbon-item') {
        setTitle('Ribbon Item List')
      } else if (location.pathname === '/ribbon/add') {
        setTitle('Add Ribbon')
      } else if (location.pathname === `/ribbon/${id}`) {
        setTitle('Edit Ribbon')
      } else if (location.pathname === '/people') {
        setTitle('People List')
      } else if (location.pathname === '/people/add') {
        setTitle('Add People')
      } else if (location.pathname === `/people/${id}`) {
        setTitle('Edit People')
      } else if (location.pathname === '/people-types/add') {
        setTitle('Add People Type')
      } else if (location.pathname === `/people-types/${id}`) {
        setTitle('Edit People Type')
      } else if (location.pathname === '/entityvod') {
        setTitle('Entity VOD List')
      } else if (location.pathname === '/singlemovie') {
        setTitle('Single Movie List')
      } else if (location.pathname === '/showdrama') {
        setTitle('Show/Drama List')
      } else if (location.pathname === '/channel') {
        setTitle('Linear Channel List')
      } else if (location.pathname === '/entityvod/add') {
        setTitle('Add Entity VOD')
      } else if (location.pathname === `/entityvod/${id}`) {
        setTitle('Edit Entity VOD')
      } else if (location.pathname === '/season') {
        setTitle('Season List')
      } else if (location.pathname === '/episode') {
        setTitle('Episode List')
      } else if (location.pathname === '/recommendation') {
        setTitle('Recommendation')
      } else if (location.pathname === '/nice-clip') {
        setTitle('Nice Clip')
      } else if (location.pathname === '/vod-metadata') {
        setTitle('Metadata')
      } else if (location.pathname === '/epg') {
        setTitle('EPG')
      } else if (location.pathname === `/epg/${id}`) {
        setTitle('Edit EPG')
      } else if (location.pathname === '/faq') {
        setTitle('FAQ')
      } else if (location.pathname === '/about-us') {
        setTitle('About Us')
      } else if (location.pathname === '/ads-info') {
        setTitle('Ads Info')
      } else if (location.pathname === '/crawler') {
        setTitle('Crawler')
      } else if (location.pathname === '/rss') {
        setTitle('RSS')
      } else if (location.pathname === '/rss/add') {
        setTitle('Add RSS')
      } else if (location.pathname === `/rss/${id}`) {
        setTitle('Edit RSS')
      } else if (location.pathname === '/metadata') {
        setTitle('Metadata')
      } else if (location.pathname === '/metadata/add') {
        setTitle('Add Metadata')
      } else if (location.pathname === `/metadata/${id}`) {
        setTitle('Edit Metadata')
      } else if (location.pathname === '/image') {
        setTitle('Image Type')
      } else if (location.pathname === '/banner-position') {
        setTitle('Banner Position')
      } else if (location.pathname === '/banner-position/add') {
        setTitle('Add Banner Position')
      } else if (location.pathname === '/image/add') {
        setTitle('Add Image Type')
      } else if (location.pathname === `/image/${id}`) {
        setTitle('Edit Image Type')
      } else if (location.pathname === '/transcode/add') {
        setTitle('Transcode VOD')
      } else if (location.pathname === `/transcode/edit/${id}`) {
        setTitle('Transcode VOD')
      } else if (location.pathname === '/transcode/index') {
        setTitle('Transcode')
      } else if (location.pathname === '/transcode-not-mapping/index') {
        setTitle('Transcode Not Mapping')
      } else if (location.pathname === '/report') {
        setTitle('Report')
      } else if (location.pathname === '/report-data') {
        setTitle('Report')
      } else if (location.pathname === '/report-content') {
        setTitle('Report')
      } else if (location.pathname === '/report-view-user') {
        setTitle('Report')
      } else if (location.pathname === '/report-statistic') {
        setTitle('Statistical Report')
      } else if (location.pathname === '/comments') {
        setTitle('Comment List')
      } else if (location.pathname === '/approve-comment') {
        setTitle('Approve Comment')
      } else if (location.pathname === '/ribbon-display') {
        setTitle('Ribbon Display List')
      } else if (location.pathname === '/ribbon-display/add') {
        setTitle('Add Ribbon Display')
      } else if (location.pathname === `/ribbon-display/${id}`) {
        setTitle('Update Ribbon Display')
      } else if (location.pathname === '/entityvod-pin') {
        setTitle('Entity VOD Pin List')
      } else if (location.pathname === `/entityvod-pin/${id}`) {
        setTitle('Update Entity VOD Pin')
      } else if (location.pathname === '/menu-mobile') {
        setTitle('Menu Mobile List')
      } else if (location.pathname === '/menu-mobile/add') {
        setTitle('Add Menu Mobile')
      } else if (location.pathname === `/menu-mobile/${id}`) {
        setTitle('Update Menu Mobile')
      } else if (location.pathname === '/share-by-me') {
        setTitle('Content Sharing By Me')
      } else if (location.pathname === '/share-with-me') {
        setTitle('Content Sharing With Me')
      } else if (location.pathname === '/release-share-content') {
        setTitle('Release Content Sharing')
      } else if (location.pathname === '/tag-topic') {
        setTitle('Tag Topic List')
      } else if (location.pathname === '/tag-topic/add') {
        setTitle('Add Tag Topic')
      } else if (location.pathname === `/tag-topic/${id}`) {
        setTitle('Edit Tag Topic')
      } else if (location.pathname === 'ribbon-sharing-with-me') {
        setTitle('Ribbon Sharing With Me')
      } else if (location.pathname === 'ribbon-sharing-by-me') {
        setTitle('Ribbon Sharing By Me')
      } else if (location.pathname === '/epg-timing') {
        setTitle('EPG Timing')
      } else if (location.pathname === `/epg-timing-update/${id}`) {
        setTitle('EPG Timing')
      } else if (location.pathname === '/config') {
        setTitle('Config')
      } else if (location.pathname === '/config/add') {
        setTitle('Add Config')
      } else if (location.pathname === `/config/${id}`) {
        setTitle('Edit Config')
      } else if (location.pathname === '/app-version') {
        setTitle('App Version')
      } else if (location.pathname === '/app-version/add') {
        setTitle('Add App Version')
      } else if (location.pathname === `/app-version/${id}`) {
        setTitle('Edit App Version')
      }
    }
  }, [location])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickLogout = async () => {
    localStorage.removeItem('token')
    window.location.reload()
    // try {
    //   if (localStorage.getItem('token')) {
    //     const sessionId = localStorage.getItem('token')
    //     // eslint-disable-next-line no-unused-vars
    //     const resultLogout = await axios({
    //       headers: {
    //         Authorization: `Bearer ${sessionId}`
    //       },
    //       method: 'GET',
    //       url: `${process.env.REACT_APP_API_URL}logout`
    //     })

    //     localStorage.removeItem('token')
    //     window.location.reload()
    //   }
    // } catch (err) {
    //   if (err.response) {
    //     const data = err.response.data

    //     if (typeof data === 'string') {
    //       alert('Session expired, please login again.')
    //       localStorage.removeItem('token')
    //       window.location.reload()
    //     }
    //   }
    //   console.log(err)
    // }
  }

  const handleCollapse = (value) => {
    setCollapse(value)
  }

  return (
    <>
      <Navbar
        logo={window.location.hostname === HOSTNAME.THVLI ? imgLogoTHVL : imgLogo}
        onLogout={handleClickLogout}
        onCollapse={handleCollapse}
      />

      <div className="main_content" id="main-content">
        <LeftSidebar avatar={imgUser} collapse={collapse} />
        {/* <RightSidebar /> */}

        <div className="page">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#">{title}</a>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fa fa-align-justify"></i>
              </button> */}
          </nav>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        theme="colored"
        position="top-center"
        autoClose={4000}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Layout.defaultProps = {
  className: ''
}

export default Layout
