import React, { useEffect, useState, useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { getData, formatFormData, getUploadData } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TreeSelectGroup from 'components/ui/TreeSelectGroup'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { Button as ButtonAntd } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const MenuSlideForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const [files, setFiles] = useState([])
  const [icon, setIcon] = useState(null)

  const [menuItem, setMenuItem] = useState(null)
  const [menuList, setMenuList] = useState([])
  const [menuItemList, setMenuItemList] = useState([])

  const { data: dataMenu } = useQuery('getMenuSlideFormMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataMenuItem } = useQuery(['getMenuSlideFormMenuItem', menuList], async () => {
    const resultTemp = []

    try {
      if (menuList && menuList.length > 0) {
        for (let i = 0; i < menuList.length; i++) {
          const resultApi = await getData('GET', `menu/${menuList[i] && menuList[i].id}/menu-items/`)
          const resultData = resultApi && resultApi.data
          if (Array.isArray(resultData)) {
            const dataParse = resultData.map((item, index) => {
              // Sub Item
              const childrenArrTemp = []
              const childrenArr = item.sub_menu_items
              if (Array.isArray(childrenArr)) {
                for (let i = 0; i < childrenArr.length; i++) {
                  childrenArrTemp.push({
                    value: childrenArr[i] && childrenArr[i].id,
                    title: childrenArr[i] && childrenArr[i].name,
                    children: []
                  })
                }
              }

              return {
                title: item.name,
                value: item.id,
                children: childrenArrTemp
              }
            })

            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              selectable: false,
              children: dataParse
            })
          } else {
            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              children: []
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    return resultTemp
  })

  const { data: dataInfo } = useQuery(['getInfoMenuItemCategory', id], async () => {
    if (id) {
      return await getData('GET', `menu-item-category/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `menu-item-category/${id}/`, dataForm)
      } else {
        return await getData('POST', 'menu-item-category/', dataForm)
      }
    }
  })

  const mutationUpload = useMutation(async (dataForm) => {
    if (id) {
      return await getUploadData('PUT', `menu-item-category/${id}/`, dataForm)
    } else {
      return await getUploadData('POST', 'menu-item-category/', dataForm)
    }
  })

  // Upload
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  useEffect(() => {
    setValue('ord', 1)
  }, [])

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results
      setMenuList(resultData)
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataMenuItem) {
      setMenuItemList(dataMenuItem)
    }
  }, [dataMenuItem])

  useEffect(() => {
    if (dataInfo && Array.isArray(menuItemList)) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('title', resultData.title)
        setValue('ord', resultData.ord)
        setValue('menu_item', resultData.menu_item)
        setValue('is_active', resultData.is_active)

        // Image
        if (resultData.url) {
          setFiles([
            { preview: resultData.url }
          ])
        } else {
          setFiles([])
        }

        // Icon
        setIcon(resultData.icon)

        // const menuItemFind = menuItemList.find(item => item.value === resultData.parent)
        // setValue('parent', menuItemFind)
      }
    }
  }, [dataInfo, menuItemList])

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach(file => URL.revokeObjectURL(file.preview))
  // }, [files])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        if (icon) {
          await mutation.mutateAsync({
            title: formatFormData(data.title),
            ord: data.ord === '' ? 0 : data.ord,
            menu_item: data.menu_item,
            is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
            icon: icon
          })
        } else {
          if (files.length > 0) {
            const fileUpload = files[0]

            const formData = new FormData()
            formData.append('title', formatFormData(data.title))
            formData.append('ord', data.ord ? data.ord : 0)
            formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
            formData.append('menu_item', data.menu_item)
            formData.append('image', fileUpload)
            await mutationUpload.mutateAsync(formData)
          } else {
            await mutation.mutateAsync({
              title: formatFormData(data.title),
              ord: data.ord === '' ? 0 : data.ord,
              menu_item: data.menu_item,
              is_active: typeof data.is_active === 'undefined' ? true : data.is_active
            })
          }
        }
      } else {
        if (files.length > 0) {
          const fileUpload = files[0]

          const formData = new FormData()
          formData.append('title', formatFormData(data.title))
          formData.append('ord', data.ord ? data.ord : 0)
          formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
          formData.append('menu_item', data.menu_item)
          formData.append('tenant', localStorage.getItem('tenant'))
          formData.append('image', fileUpload)
          await mutationUpload.mutateAsync(formData)
        } else {
          await mutation.mutateAsync({
            title: formatFormData(data.title),
            ord: data.ord === '' ? 0 : data.ord,
            menu_item: data.menu_item,
            is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
            tenant: localStorage.getItem('tenant')
          })
        }
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleRemoveImage = () => {
    setFiles([])
    setIcon(null)
  }

  const handleChangeMenuItem = value => {
    setMenuItem(value)
  }

  return (
    <div className="menu-item-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Title" name="title" required control={control} error={errors.title} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="number" label="Position" name="ord" control={control} error={errors.ord} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TreeSelectGroup
                style={{ width: '100%' }}
                control={control}
                error={errors.menu_item}
                required
                label="Menu Item"
                name="menu_item"
                value={menuItem}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={menuItemList}
                placeholder="Please select"
                treeDefaultExpandAll
                bordered
                onChange={handleChangeMenuItem}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <div className="pb-1">Icon</div>
              {(files && files.length > 0)
                ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                    <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                      <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveImage} />
                    </div>
                    {files.map((file, index) => (
                      <img
                        key={index}
                        src={file.preview}
                        style={{ width: '100%', height: '100%' }}
                      />
                    ))}
                  </div>
                : <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and drop some files here, or click to select files</p>
                    </div>
                  </section>
              }
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox name="is_active" control={control} defaultChecked label="Active" />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default MenuSlideForm
