import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import { getData } from 'common/utils'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Autocomplete from 'components/form/Autocomplete'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

const VodPinForm = () => {
  const { id } = useParams()
  const history = useHistory()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()

  // States
  const [ribbonList, setRibbonList] = useState([])

  // Fetch APIs
  const { data: dataInfo } = useQuery(['getEntityVodPin', id], async () => {
    if (id) {
      return await getData('GET', `vod-entity-pin/${id}/`)
    }
  })

  const { data: dataRibbon } = useQuery('getRibbonsInfo', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/ribbons_info/?limit=50`)
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `vod-entity-pin/${id}/`, dataForm)
      }
    }
  })

  // Effects
  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('position', resultData.position)
        setValue('is_active', resultData.is_active)

        const ribbonFind = ribbonList.find(item => item.value === resultData?.ribbon?.id)
        setValue('content', { value: resultData.content.id, label: resultData.content.title })
        setValue('ribbon', ribbonFind)
      }
    }
  }, [dataInfo, ribbonList])

  useEffect(() => {
    if (dataRibbon) {
      const resultData = dataRibbon?.data?.results || []

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setRibbonList(selectData)
      }
    }
  }, [dataRibbon])

  // Handle Events
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          ribbon: data.ribbon.value,
          position: data.position,
          content: data.content.value,
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            if (item === 'non_field_errors') {
              setError('slug', {
                type: 'manual',
                message: Array.isArray(errItemArr) && errItemArr[0]
              })
            } else {
              setError(item, {
                type: 'manual',
                message: Array.isArray(errItemArr) && errItemArr[0]
              })
            }
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className='vod-pin-form'>
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                options={ribbonList}
                label="Ribbon"
                name="ribbon"
                required
                control={control}
                error={errors.ribbon}
                // onHandleChange={handleChangeMenu}
              />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                label="Entity (ReadOnly)"
                name="content"
                required={false}
                disabled
                control={control}
                error={errors.content}
                // onHandleChange={handleChangeMenu}
              />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Position" name="position" type='number' required control={control} error={errors.position}/>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox label="Active" name="is_active" defaultChecked control={control} />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default VodPinForm
