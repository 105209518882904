import React from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getData, formatFormData } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

const UserProfileUpdatePassword = (props) => {
  const history = useHistory()
  const { handleSubmit, control, formState: { errors }, setError } = useForm()

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('PUT', 'users/update-password/', dataForm)
    }
  })

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      await mutation.mutateAsync({
        current_password: formatFormData(data.current_password),
        confirm_password: formatFormData(data.confirm_password),
        new_password: formatFormData(data.new_password)
      })
      history.push('/profile')
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="user-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField type="password" label="Current password" name="current_password" required control={control} error={errors.current_password} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="password" label="New password" name="new_password" required control={control} error={errors.new_password} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="password" label="Confirm new password" name="confirm_password" required control={control} error={errors.confirm_password} />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default UserProfileUpdatePassword
