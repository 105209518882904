import React, { useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getData, formatFormData } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

const GroupForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()

  const { data: dataInfo } = useQuery(['getInfoGroup', id], async () => {
    if (id) {
      return await getData('GET', `groups/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `groups/${id}/`, dataForm)
      } else {
        return await getData('POST', 'groups/', dataForm)
      }
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const dataResult = dataInfo.data

      if (dataResult) {
        setValue('name', dataResult.name)
        setValue('level', dataResult.level)
        setValue('is_full_control', dataResult.is_full_control)
        setValue('is_active', dataResult.is_active)
      }
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          name: formatFormData(data.name),
          level: formatFormData(data.level),
          is_full_control: typeof data.is_full_control === 'undefined' ? true : data.is_full_control,
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active
        })
      } else {
        await mutation.mutateAsync({
          name: formatFormData(data.name),
          level: formatFormData(data.level),
          is_full_control: typeof data.is_full_control === 'undefined' ? true : data.is_full_control,
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
          tenant: localStorage.getItem('tenant')
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="group-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Name" name="name" required control={control} error={errors.name} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="number" label="Level" name="level" control={control} error={errors.level} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox name="is_full_control" defaultChecked control={control} label="Full Control" />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="offset-md-3">
              <Checkbox name="is_active" defaultChecked control={control} label="Active" />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default GroupForm
