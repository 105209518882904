/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { getCrawlerData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import Button from 'components/form/Button'
import Pagination from '@mui/material/Pagination'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Tooltip from '@mui/material/Tooltip'
import { Select } from 'antd'
import { toast } from 'react-toastify'
// import CrawlerFilter from './CrawlerFilter'
import 'antd/lib/select/style/index.css'

const { Option } = Select

const Rss = (props) => {
  const queryClient = useQueryClient()

  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])

  const { data: dataList, isLoading, isFetching } = useQuery(['getRssList', limit, offset], async () => {
    return await getCrawlerData('GET', `vtcnews/?limit=${limit}&offset=${offset}`)
  })

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  useEffect(() => {
    if (dataList) {
      const resultList = dataList.results
      if (Array.isArray(resultList)) {
        const dataTemp = resultList.map(item => {
          return {
            key: item.id,
            ...item
          }
        })
        setData(dataTemp)
        setTotal(dataList.total)
        setTotalPage(Math.ceil(dataList.total / limit))
      }
    }
  }, [dataList])

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '5%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Edit">
              <Link to={`/rss/${record.id}`}>
                <IconButton color="success">
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Title',
      key: 'title',
      width: '25%',
      // ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <a key={index} href={record.link} target="_blank" rel="noopener noreferrer" className='link-rss-crawler'>
            {record?.title}
          </a>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.is_active === true
              ? 'Active'
              : ''}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'operation',
      // fixed: 'left',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Button size='small' variant='contained' startIcon={<CloudDownloadIcon />} onClick={e => handleClickCrawlData(e, record)}>
              Crawl data
            </Button>
          </div>
        )
      }
    }
  ]

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickCrawlData = async (e, record) => {
    if (record) {
      try {
        // eslint-disable-next-line no-unused-vars
        const result = await getCrawlerData('GET', `vtcnews/crawler-rss/${record.id}`)

        toast.success('Crawl data successfully')
        queryClient.invalidateQueries('getRssList')
      } catch (err) {
        console.log(err)
        toast.error('Something went wrong!')
      }
    }
  }

  return (
    <div className="vod">
      {/* <CrawlerFilter
        onChangeType={handleChangeTypeFilter}
        onChangeCategory={handleChangeCategoryFilter}
        onChangeStatus={handleChangeStatusFilter}
        onClickSearch={handleClickSearchFilter}
      /> */}

      <Row className="pt-1">
        <Col md={12}>
          <Link to='/rss/add'>
            <Button className="float-right" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            data={data}
            // onChange={handleChangeTable}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>
    </div>
  )
}

export default Rss
