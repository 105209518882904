import React from 'react'
import Layout from 'features/layout/components/Layout'
import AboutUs from '../components/AboutUs'

const AboutUsPage = (props) => {
  return (
    <Layout>
      <AboutUs />
    </Layout>
  )
}

export default AboutUsPage
