import React from 'react'
import Layout from 'features/layout/components/Layout'
import RibbonForm from '../components/RibbonForm'

const RibbonFormPage = (props) => {
  return (
    <Layout>
      <RibbonForm />
    </Layout>
  )
}

export default RibbonFormPage
