import React from 'react'
import { Controller } from 'react-hook-form'
import TextFieldMui from '@mui/material/TextField'
import AutocompleteMui from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'

const AutocompleteMultipleTagTopic = (props) => {
  const { className, label, name, fullWidth, freeSolo, size, required, disabled, options, control, error, inputValue, onInputChange, onHandleChange } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          return (
            <AutocompleteMui
              multiple
              freeSolo={freeSolo}
              disabled={disabled}
              fullWidth={fullWidth}
              size={size}
              options={options}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              disableClearable
              inputValue={inputValue || ''}
              onInputChange={onInputChange}
              onChange={(e, newValue) => {
                onChange(newValue)

                if (onHandleChange && typeof onHandleChange === 'function') {
                  onHandleChange(newValue)
                }
              }}
              value={value || []}
              renderInput={(params) =>
                <TextFieldMui
                  {...params}
                  label={label}
                  required={required}
                  error={!!error}
                  helperText={error
                    ? error.type === 'required'
                      ? `${label} required`
                      : error.message
                    : null
                  }
                />
              }
            />

          )
        }}
      />
    </div>
  )
}

AutocompleteMultipleTagTopic.propTypes = {
  className: PropTypes.string,
  freeSolo: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  control: PropTypes.any,
  error: PropTypes.any,
  inputValue: PropTypes.any,
  onInputChange: PropTypes.func,
  onHandleChange: PropTypes.func
}

AutocompleteMultipleTagTopic.defaultProps = {
  className: '',
  freeSolo: false,
  label: '',
  name: '',
  fullWidth: false,
  size: 'small',
  required: false,
  disabled: false,
  options: []
}

export default AutocompleteMultipleTagTopic
