import React from 'react'
import Layout from 'features/layout/components/Layout'
import Crawler from '../components/Crawler'

const CrawlerPage = (props) => {
  return (
    <Layout>
      <Crawler />
    </Layout>
  )
}

export default CrawlerPage
