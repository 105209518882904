import React from 'react'
import Layout from 'features/layout/components/Layout'
import PeopleTypeForm from '../components/PeopleTypeForm'

const PeopleTypeFormPage = (props) => {
  return (
    <Layout>
      <PeopleTypeForm />
    </Layout>
  )
}

export default PeopleTypeFormPage
