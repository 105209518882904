import React from 'react'
import Layout from 'features/layout/components/Layout'
import Permission from '../components/Permission'

const PermissionPage = (props) => {
  return (
    <Layout>
      <Permission />
    </Layout>
  )
}

export default PermissionPage
