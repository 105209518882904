/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Table } from 'components/ui'
import { Image, Select } from 'antd'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import Pagination from '@mui/material/Pagination'
import Button from 'components/form/Button'
import Tooltip from '@mui/material/Tooltip'
import AlertDialog from 'components/ui/AlertDialog'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { toast } from 'react-toastify'
import 'antd/lib/pagination/style/index.css'

const { Option } = Select

const TagTopic = (props) => {
  const queryClient = useQueryClient()

  const [searchText, setSearchText] = useState('')
  const [searchFilter, setSearchFilter] = useState('')

  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [idDelete, setIdDelete] = useState(null)

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <Link to={`/tag-topic/${record.id}`}>
                <IconButton color="success">
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true
      // sorter: (a, b) => {
      //   return a.name.localeCompare(b.name)
      // }
    },
    {
      title: 'Image',
      key: 'image',
      align: 'left',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        const urlImageTemp = record?.url_image

        return (
          <div>
            <Image width={100} height={60} src={urlImageTemp} />
          </div>
        )
      }
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at'
    }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery(['getTagTopic', limit, offset, searchFilter], async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/tags/?limit=${limit}&offset=${offset}&title=${searchFilter}`)
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `tag/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getTagTopic')
    }
  })

  useEffect(() => {
    if (dataList && limit) {
      let resultData = []
      const dataListData = dataList.data

      if (dataListData) {
        if (Array.isArray(dataListData)) {
          resultData = dataListData
          setTotal(dataListData.length)
          setTotalPage(Math.ceil(dataListData.length / limit))
        } else {
          resultData = dataListData.results
          setTotal(dataListData.total)
          setTotalPage(Math.ceil(dataListData.total / limit))
        }
      }

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        setData(dataListParse)
      }
    }
  }, [dataList, limit])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)

      setIsOpenDelete(false)
      toast.success('Tag Topic deleted successfully')
    } catch (err) {
      setIsOpenDelete(false)
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleClickSearch = () => {
    setSearchFilter(searchText)
  }

  const handleClearSearch = () => {
    setSearchText('')
    setSearchFilter('')
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      setSearchFilter(searchText)
    }
  }

  return (
    <div className="people">
      <Card>
        <CardContent>
          <Row className="pt-2">
            <Col md={5}>
              <TextField
                size="small"
                fullWidth
                label="Search by name"
                variant="standard"
                value={searchText}
                onChange={handleChangeSearch}
                onKeyDown={handleEnter}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchText !== '' &&
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Col>
          </Row>
        </CardContent>
      </Card>

      <Row className="pt-5">
        <Col md={12}>
          <div className="core-list">
            <Row className="pb-2">
              <Col md={12}>
                <Link to="/tag-topic/add">
                  <Button className="float-right" startIcon={<AddIcon />}>
                    Add New
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row className="pb-1">
              <Col md={12}>
                <div className="total-text">Total: {total}</div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Table
                  columns={columns}
                  data={data}
                  loading={isLoading || isFetching}
                />
              </Col>
            </Row>
            <Row className="pt-4">
              <Col md={12}>
                {/* <Pagination
                  className="float-right"
                  showSizeChanger
                  current={current}
                  total={total}
                  pageSize={limitData}
                  pageSizeOptions={[10, 20, 50]}
                  onChange={handleChangePagination}
                  onShowSizeChange={handleShowSizeChange}
                /> */}

                <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
                  {pageList && pageList.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>{item.label}</Option>
                    )
                  })}
                </Select>

                <Pagination
                  className="float-right"
                  count={totalPage}
                  page={current}
                  onChange={handleChangePage}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  color="standard"
                />
              </Col>
            </Row>

            <AlertDialog
              isOpen={isOpenDelete}
              type="delete"
              title="Notification"
              contentText="This action cannot be undone. Are you sure you want to delete this item?"
              onClose={handleCloseDelete}
              onSave={handleClickDelete}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TagTopic
