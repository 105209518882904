import React from 'react'
import Layout from 'features/layout/components/Layout'
import Group from '../components/Group'

const GroupPage = (props) => {
  return (
    <Layout>
      <Group />
    </Layout>
  )
}

export default GroupPage
