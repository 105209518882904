import React, { useEffect, useState, useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { getData, formatSlug } from 'common/utils'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import { CloseOutlined } from '@ant-design/icons'
import { Button as ButtonAntd } from 'antd'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Autocomplete from 'components/form/Autocomplete'
import TreeSelectGroup from 'components/ui/TreeSelectGroup'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const MenuBottomType = [
  { value: 0, label: 'Normal' },
  { value: 1, label: 'Special' }
]

const MenuMobileForm = () => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()
  const { getRootProps: getRootPropsIcon, getInputProps: getInputPropsIcon, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      setFilesIcon(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })
  const { getRootProps: getRootPropsIconActive, getInputProps: getInputPropsIconActive } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      setFilesIconActive(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })
  const { getRootProps: getRootPropsBgImage, getInputProps: getInputPropsBgImage } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      setFilesBgImage(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })
  const { getRootProps: getRootPropsBgImageMobile, getInputProps: getInputPropsBgImageMobile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      setFilesBgImageMobile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const [menuItem, setMenuItem] = useState(null)
  const [menuList, setMenuList] = useState([])
  const [menuItemList, setMenuItemList] = useState([])

  // States
  const [filesIcon, setFilesIcon] = useState([])
  const [filesIconActive, setFilesIconActive] = useState([])
  const [filesBgImage, setFilesBgImage] = useState([])
  const [filesBgImageMobile, setFilesBgImageMobile] = useState([])

  const [urlIconUpdate, setUrlIconUpdate] = useState('')
  const [urlIconImageUpdate, setUrlIconImageUpdate] = useState('')
  const [urlIconActiveUpdate, setUrlIconActiveUpdate] = useState('')
  const [urlIconActiveImageUpdate, setUrlIconActiveImageUpdate] = useState('')
  const [urlBackgroundWebUpdate, setUrlBackgroundWebUpdate] = useState('')
  const [urlBackgroundWebImageUpdate, setUrlBackgroundWebImageUpdate] = useState('')
  const [urlBackgroundMobileUpdate, setUrlBackgroundMobileUpdate] = useState('')
  const [urlBackgroundMobileImageUpdate, setUrlBackgroundMobileImageUpdate] = useState('')

  // Fetch APIs
  const { data: dataInfo } = useQuery(['getInfoMenuBottom', id], async () => {
    if (id) {
      return await getData('GET', `menu-bottom/${id}/`)
    }
  })

  const { data: dataMenu } = useQuery('getMenuMobileFormMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataMenuItem } = useQuery(['getMenuMobileFormMenuItem', menuList], async () => {
    const resultTemp = []

    try {
      if (menuList && menuList.length > 0) {
        for (let i = 0; i < menuList.length; i++) {
          const resultApi = await getData('GET', `menu/${menuList[i] && menuList[i].id}/menu-items/`)
          const resultData = resultApi && resultApi.data
          if (Array.isArray(resultData)) {
            const dataParse = resultData.map((item, index) => {
              // Sub Item
              const childrenArrTemp = []
              const childrenArr = item.sub_menu_items
              if (Array.isArray(childrenArr)) {
                for (let i = 0; i < childrenArr.length; i++) {
                  childrenArrTemp.push({
                    value: childrenArr[i] && childrenArr[i].id,
                    title: childrenArr[i] && childrenArr[i].name,
                    children: []
                  })
                }
              }

              return {
                title: item.name,
                value: item.id,
                children: childrenArrTemp
              }
            })

            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              selectable: false,
              children: dataParse
            })
          } else {
            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              children: []
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    return resultTemp
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `menu-bottom/${id}/`, dataForm)
      } else {
        return await getData('POST', 'menu-bottom/', dataForm)
      }
    }
  })

  const mutationUploadIcon = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `menu-bottom/${id}/upload_icon`, dataForm)
      }
    }
  })

  const mutationUploadIconActive = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `menu-bottom/${id}/upload_icon_active`, dataForm)
      }
    }
  })

  const mutationUploadBgImage = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `menu-bottom/${id}/upload_bg`, dataForm)
      }
    }
  })

  const mutationUploadBgImageMobile = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `menu-bottom/${id}/upload_bg_mobile`, dataForm)
      }
    }
  })

  // Upload
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  // Effects
  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('name', resultData.name)
        setValue('name_web', resultData.name_web)
        setValue('name_ctv', resultData.name_ctv)
        setValue('slug', resultData.slug)
        setValue('ord', resultData.ord)
        setValue('is_active', resultData.is_active)
        setValue('color_hex_code', resultData.color_hex_code)
        setValue('color_hex_code_active', resultData.color_hex_code_active)
        setValue('text_color_hex_code', resultData.text_color_hex_code)
        setValue('bg_color_hex_code', resultData.bg_color_hex_code)
        setValue('menu_item', resultData.menu_item)
        // Type
        const typeFind = MenuBottomType.find(item => item.value === resultData?.type)
        setValue('type', typeFind)
        // Images
        setValue('bg_mobile_url', resultData.bg_mobile_url)
        setValue('bg_url', resultData.bg_url)
        setValue('url', resultData.url) // Icon
        setValue('url_active', resultData.url_active) // Icon Active

        if (resultData.url && resultData.url !== '') {
          setUrlIconUpdate(resultData.url)
        }
        if (resultData.url_active && resultData.url_active !== '') {
          setUrlIconActiveUpdate(resultData.url_active)
        }
        if (resultData.bg_url && resultData.bg_url !== '') {
          setUrlBackgroundWebUpdate(resultData.bg_url)
        }
        if (resultData.bg_mobile_url && resultData.bg_mobile_url !== '') {
          setUrlBackgroundMobileUpdate(resultData.bg_mobile_url)
        }

        setUrlIconImageUpdate(resultData.icon)
        setUrlIconActiveImageUpdate(resultData.icon_active)
        setUrlBackgroundWebImageUpdate(resultData.bg_image_url)
        setUrlBackgroundMobileImageUpdate(resultData.bg_image_mobile_url)
      }
    }
  }, [dataInfo])

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results
      setMenuList(resultData)
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataMenuItem) {
      setMenuItemList(dataMenuItem)
    }
  }, [dataMenuItem])

  const dataApiUpdate = (data) => {
    const objTemp = {}

    if (filesIcon.length <= 0) {
      if (urlIconUpdate && urlIconUpdate !== '' && urlIconImageUpdate !== '') {
        //
      } else {
        objTemp.icon = ''
      }
    }

    if (filesIconActive.length <= 0) {
      if (urlIconActiveUpdate && urlIconActiveUpdate !== '' && urlIconActiveImageUpdate !== '') {
        //
      } else {
        objTemp.icon_active = ''
      }
    }

    if (filesBgImage.length <= 0) {
      if (urlBackgroundWebUpdate && urlBackgroundWebUpdate !== '' && urlBackgroundWebImageUpdate !== '') {
        //
      } else {
        objTemp.bg_image_url = ''
      }
    }

    if (filesBgImageMobile.length <= 0) {
      if (urlBackgroundMobileUpdate && urlBackgroundMobileUpdate !== '' && urlBackgroundMobileImageUpdate !== '') {
        //
      } else {
        objTemp.bg_image_mobile_url = ''
      }
    }

    if (id) {
      return {
        ...{
          name: data.name,
          name_web: data.name_web,
          name_ctv: data.name_ctv,
          slug: data.slug,
          type: data.type.value,
          ord: data.ord,
          color_hex_code: data?.color_hex_code || '',
          color_hex_code_active: data?.color_hex_code_active || '',
          text_color_hex_code: data?.text_color_hex_code || '',
          bg_color_hex_code: data?.bg_color_hex_code || '',
          menu_item: data.menu_item,
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active
        },
        ...objTemp
      }
    } else {
      return {
        ...{
          name: data.name,
          name_web: data.name_web,
          name_ctv: data.name_ctv,
          slug: data.slug,
          type: data.type.value,
          ord: data.ord,
          color_hex_code: data?.color_hex_code || '',
          color_hex_code_active: data?.color_hex_code_active || '',
          text_color_hex_code: data?.text_color_hex_code || '',
          bg_color_hex_code: data?.bg_color_hex_code || '',
          menu_item: data.menu_item,
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
          tenant: localStorage.getItem('tenant')
        },
        ...objTemp
      }
    }
  }

  // Handle Func
  const onSubmit = async (data) => {
    try {
      const dataTemp = dataApiUpdate(data)

      await mutation.mutateAsync(dataTemp)

      if (id) {
        // Upload Icon
        if (filesIcon && filesIcon.length > 0) {
          const formDataIcon = new FormData()
          formDataIcon.append('image', filesIcon[0])
          await mutationUploadIcon.mutateAsync(formDataIcon)
        }
        // Upload Icon Active
        if (filesIconActive && filesIconActive.length > 0) {
          const formDataIconActive = new FormData()
          formDataIconActive.append('image', filesIconActive[0])
          await mutationUploadIconActive.mutateAsync(formDataIconActive)
        }
        // Upload Bg Image
        if (filesBgImage && filesBgImage.length > 0) {
          const formDataBgImage = new FormData()
          formDataBgImage.append('image', filesBgImage[0])
          await mutationUploadBgImage.mutateAsync(formDataBgImage)
        }
        // Upload Bg Image Mobile
        if (filesBgImageMobile && filesBgImageMobile.length > 0) {
          const formDataBgImageMobile = new FormData()
          formDataBgImageMobile.append('image', filesBgImageMobile[0])
          await mutationUploadBgImageMobile.mutateAsync(formDataBgImageMobile)
        }
      }

      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            if (item === 'non_field_errors') {
              setError('slug', {
                type: 'manual',
                message: Array.isArray(errItemArr) && errItemArr[0]
              })
            } else {
              setError(item, {
                type: 'manual',
                message: Array.isArray(errItemArr) && errItemArr[0]
              })
            }
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleRemoveIcon = () => {
    setFilesIcon([])
  }

  const handleRemoveIconUpdate = () => {
    setUrlIconUpdate('')
  }

  const handleRemoveIconActive = () => {
    setFilesIconActive([])
  }

  const handleRemoveIconActiveUpdate = () => {
    setUrlIconActiveUpdate('')
  }

  const handleRemoveBgImage = () => {
    setFilesBgImage([])
  }

  const handleRemoveBackgroundWebUpdate = () => {
    setUrlBackgroundWebUpdate('')
  }

  const handleRemoveBgImageMobile = () => {
    setFilesBgImageMobile([])
  }

  const handleRemoveBackgroundMobileUpdate = () => {
    setUrlBackgroundMobileUpdate('')
  }

  const handleChangeTitle = (e) => {
    if (e && e.target) {
      if (setValue) {
        setValue('slug', formatSlug(e.target.value))
      }
    }
  }

  const handleChangeMenuItem = value => {
    setMenuItem(value)
  }

  return (
    <div className='menu-mobile-form'>
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Name" name="name" required control={control} error={errors.name} onHandleChange={handleChangeTitle} />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Name (Website)" name="name_web" required control={control} error={errors.name_web} />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Name (CTV)" name="name_ctv" required control={control} error={errors.name_ctv} />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Slug" name="slug" required control={control} error={errors.slug} />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                options={MenuBottomType}
                label="Type"
                name="type"
                required
                control={control}
                error={errors.type}
                // onHandleChange={handleChangeMenu}
              />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Order" name="ord" type='number' required control={control} error={errors.ord} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Color Hex Code" name="color_hex_code" control={control} error={errors.color_hex_code} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Color Hex Code Active" name="color_hex_code_active" control={control} error={errors.color_hex_code_active} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Text Color Hex Code" name="text_color_hex_code" control={control} error={errors.text_color_hex_code} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Background Color Hex Code" name="bg_color_hex_code" control={control} error={errors.bg_color_hex_code} />
            </Col>
          </Row>

          {id &&
            <>
              <Row className="pt-4">
                <Col md={6} className="offset-md-3">
                  <div className="pb-1">Icon</div>
                  {(filesIcon && filesIcon.length > 0)
                    ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                        <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveIcon} />
                        </div>
                        {filesIcon.map((file, index) => (
                          <img
                            key={index}
                            src={file.preview}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))}
                      </div>
                    : <>
                        {(urlIconUpdate && urlIconUpdate !== '' && urlIconImageUpdate !== '')
                          ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                              <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                                <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveIconUpdate} />
                              </div>
                              <img
                                src={urlIconUpdate}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          : <section>
                              <div {...getRootPropsIcon({ style })}>
                                <input {...getInputPropsIcon()} />
                                <p>Drag and drop some files here, or click to select files</p>
                              </div>
                            </section>
                        }
                      </>
                  }
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={6} className="offset-md-3">
                  <div className="pb-1">Icon Active</div>
                  {(filesIconActive && filesIconActive.length > 0)
                    ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                        <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveIconActive} />
                        </div>
                        {filesIconActive.map((file, index) => (
                          <img
                            key={index}
                            src={file.preview}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))}
                      </div>
                    : <>
                        {(urlIconActiveUpdate && urlIconActiveUpdate !== '' && urlIconActiveImageUpdate !== '')
                          ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                              <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                                <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveIconActiveUpdate} />
                              </div>
                              <img
                                src={urlIconActiveUpdate}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          : <section>
                              <div {...getRootPropsIconActive({ style })}>
                                <input {...getInputPropsIconActive()} />
                                <p>Drag and drop some files here, or click to select files</p>
                              </div>
                            </section>
                        }
                      </>
                  }
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={6} className="offset-md-3">
                  <div className="pb-1">Background Image Web</div>
                  {(filesBgImage && filesBgImage.length > 0)
                    ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                        <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveBgImage} />
                        </div>
                        {filesBgImage.map((file, index) => (
                          <img
                            key={index}
                            src={file.preview}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))}
                      </div>
                    : <>
                        {(urlBackgroundWebUpdate && urlBackgroundWebUpdate !== '' && urlBackgroundWebImageUpdate !== '')
                          ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                              <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                                <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveBackgroundWebUpdate} />
                              </div>
                              <img
                                src={urlBackgroundWebUpdate}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          : <section>
                              <div {...getRootPropsBgImage({ style })}>
                                <input {...getInputPropsBgImage()} />
                                <p>Drag and drop some files here, or click to select files</p>
                              </div>
                            </section>
                        }
                      </>
                  }
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={6} className="offset-md-3">
                  <div className="pb-1">Background Image Mobile</div>
                  {(filesBgImageMobile && filesBgImageMobile.length > 0)
                    ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                        <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveBgImageMobile} />
                        </div>
                        {filesBgImageMobile.map((file, index) => (
                          <img
                            key={index}
                            src={file.preview}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))}
                      </div>
                    : <>
                        {(urlBackgroundMobileUpdate && urlBackgroundMobileUpdate !== '' && urlBackgroundMobileImageUpdate !== '')
                          ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                              <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                                <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveBackgroundMobileUpdate} />
                              </div>
                              <img
                                src={urlBackgroundMobileUpdate}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          : <section>
                              <div {...getRootPropsBgImageMobile({ style })}>
                                <input {...getInputPropsBgImageMobile()} />
                                <p>Drag and drop some files here, or click to select files</p>
                              </div>
                            </section>
                        }
                      </>
                  }
                </Col>
              </Row>
            </>
          }

          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TreeSelectGroup
                style={{ width: '100%' }}
                control={control}
                error={errors.menu_item}
                // required
                label="Menu Item"
                name="menu_item"
                value={menuItem}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={menuItemList}
                placeholder="Please select"
                treeDefaultExpandAll
                bordered
                onChange={handleChangeMenuItem}
              />
            </Col>
          </Row>

          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox label="Active" name="is_active" defaultChecked control={control} />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default MenuMobileForm
