import React from 'react'
import Layout from 'features/layout/components/Layout'
import Image from '../components/Image'

const ImagePage = (props) => {
  return (
    <Layout>
      <Image />
    </Layout>
  )
}

export default ImagePage
