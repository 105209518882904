import React from 'react'
import PropTypes from 'prop-types'
import { Image as ImageAntd } from 'antd'
import 'antd/lib/image/style/index.css'

const Image = (props) => {
  const { className, src, width, height } = props

  return (
    <div className={`adm-img${className === '' ? '' : (' ' + className)}`}>
      <ImageAntd src={src} width={width} height={height} />
    </div>
  )
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

Image.defaultProps = {
  className: '',
  src: '',
  width: 100,
  height: 100
}

export default Image
