import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ribbonId: null
}

const ribbonSlice = createSlice({
  name: 'ribbon',
  initialState,
  reducers: {
    setRibbonId (state, action) {
      state.ribbonId = action.payload
    }
  }
})

export const {
  setRibbonId
} = ribbonSlice.actions
export default ribbonSlice.reducer
