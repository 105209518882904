import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  imageList: [],
  newsReports: []
}

const vodSlice = createSlice({
  name: 'vod',
  initialState,
  reducers: {
    setImageList (state, action) {
      state.imageList = action.payload
    },
    setNewsReports (state, action) {
      state.newsReports = action.payload
    }
  }
})

export const {
  setImageList,
  setNewsReports
} = vodSlice.actions
export default vodSlice.reducer
