import React, { useEffect, useState, useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { formatFormData, getData, formatSlug } from 'common/utils'
import { toast } from 'react-toastify'
import Table from 'components/ui/Table'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { Button as ButtonAntd, Image } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const TagTopicForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  // eslint-disable-next-line no-unused-vars
  const [searchFilter, setSearchFilter] = useState(null)
  const [dataTable, setDataTable] = useState([])

  const { data: dataList, isLoading, isFetching } = useQuery(['getTagTopicFormList', searchFilter], async () => {
    const searchFilterText = (!searchFilter || searchFilter === '') ? null : searchFilter

    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/tags/?limit=100&offset=0&title=${searchFilterText}`)
  })

  const { data: dataInfo } = useQuery(['getInfoTagTopic', id], async () => {
    if (id) {
      return await getData('GET', `tag/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `tag/${id}/`, dataForm)
      } else {
        return await getData('POST', 'tag/', dataForm)
      }
    }
  })

  const [files, setFiles] = useState([])
  const [icon, setIcon] = useState(null)

  // Upload
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true
    },
    {
      title: 'Image',
      key: 'image',
      align: 'left',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        const urlImageTemp = record?.url_image

        return (
          <div>
            <Image width={100} height={60} src={urlImageTemp} />
          </div>
        )
      }
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at'
    }
  ]

  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('title', resultData.title)
        setValue('slug', resultData.slug)

        setSearchFilter(resultData.title)

        // Image
        if (resultData.url_image) {
          setFiles([
            { preview: resultData.url_image }
          ])
        } else {
          setFiles([])
        }

        // Icon
        setIcon(resultData.url)
      }
    }
  }, [dataInfo])

  useEffect(() => {
    if (dataList) {
      let resultData = []
      const dataListData = dataList.data

      if (dataListData) {
        if (Array.isArray(dataListData)) {
          resultData = dataListData
          // setTotal(dataListData.length)
          // setTotalPage(Math.ceil(dataListData.length / limit))
        } else {
          resultData = dataListData.results
          // setTotal(dataListData.total)
          // setTotalPage(Math.ceil(dataListData.total / limit))
        }
      }

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        setDataTable(dataListParse)
      }
    }
  }, [dataList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        const formData = new FormData()
        formData.append('title', formatFormData(data.title))
        formData.append('slug', formatFormData(data.slug))

        if (icon) {
          //
        } else {
          if (Array.isArray(files) && files.length > 0) {
            const fileImage = files[0]
            formData.append('image', fileImage)
          }
        }

        await mutation.mutateAsync(formData)
      } else {
        const formData = new FormData()
        formData.append('title', formatFormData(data.title))
        formData.append('slug', formatFormData(data.slug))
        formData.append('tenant', localStorage.getItem('tenant'))

        if (Array.isArray(files) && files.length > 0) {
          const fileImage = files[0]
          formData.append('image', fileImage)
        }

        await mutation.mutateAsync(formData)
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleChangeTitle = (e) => {
    if (e && e.target) {
      if (setValue) {
        setValue('slug', formatSlug(e.target.value))
        setSearchFilter(e.target.value)
      }
    }
  }

  const handleRemoveImage = () => {
    setFiles([])
    setIcon(null)
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="people-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Title" name="title" required control={control} error={errors.title} onHandleChange={handleChangeTitle} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Slug" name="slug" required control={control} error={errors.slug} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <div className="pb-1">Image</div>
              {(files && files.length > 0)
                ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                    <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                      <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveImage} />
                    </div>
                    {files.map((file, index) => (
                      <img
                        key={index}
                        src={file.preview}
                        style={{ width: '100%', height: '100%' }}
                      />
                    ))}
                  </div>
                : <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and drop some files here, or click to select files</p>
                    </div>
                  </section>
              }
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>

      <Row className="mt-5">
        <Col md={12}>
          <Table
            columns={columns}
            data={dataTable}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
    </div>
  )
}

export default TagTopicForm
