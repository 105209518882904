import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getData } from 'common/utils'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import AutocompleteMultiple from 'components/form/AutocompleteMultiple'

const UserProfile = (props) => {
  const history = useHistory()
  const { control, setValue, formState: { errors } } = useForm()

  const [id, setId] = useState(null)
  const [groupList, setGroupList] = useState([])

  const { data: dataUserProfile } = useQuery(['getUserProfile', id], async () => {
    if (id) {
      return await getData('GET', `users/${id}/info`)
    }
  })

  useEffect(() => {
    setId(localStorage.getItem('profileid'))
  }, [])

  useEffect(() => {
    if (dataUserProfile) {
      const resultData = dataUserProfile.data
      if (resultData) {
        setValue('username', resultData.username)
        setValue('email', resultData.email)

        const groups = resultData.groups
        if (Array.isArray(groups)) {
          const selectData = groups.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
          setGroupList(selectData)
        }
      }
    }
  }, [dataUserProfile])

  useEffect(() => {
    if (groupList) {
      setValue('groups', groupList)
    }
  }, [groupList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="user-profile">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Username" name="username" disabled control={control} error={errors.username} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Email" name="email" disabled control={control} error={errors.email} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <AutocompleteMultiple
                options={groupList}
                label="Groups"
                name="groups"
                disabled
                control={control}
                error={errors.groups}
              />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default UserProfile
