import React from 'react'
import Layout from 'features/layout/components/Layout'
import TagTopic from '../components/TagTopic'

const TagTopicPage = (props) => {
  return (
    <Layout>
      <TagTopic />
    </Layout>
  )
}

export default TagTopicPage
