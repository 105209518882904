import React from 'react'
import Layout from 'features/layout/components/Layout'
import TranscodeNotMapping from '../components/TranscodeNotMapping'

const TranscodeNotMappingPage = (props) => {
  return (
    <Layout>
      <TranscodeNotMapping />
    </Layout>
  )
}

export default TranscodeNotMappingPage
