import React from 'react'
import Layout from 'features/layout/components/Layout'
import Ribbon from '../components/Ribbon'

const RibbonPage = (props) => {
  return (
    <Layout>
      <Ribbon />
    </Layout>
  )
}

export default RibbonPage
