import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getData, formatFormData } from 'common/utils'
import { Select } from 'antd'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Table from 'components/ui/Table'
import Pagination from '@mui/material/Pagination'
import Autocomplete from '@mui/material/Autocomplete'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import '../styles/report.scss'
import 'antd/lib/select/style/index.css'

const { Option } = Select

const ReportStatistic = (props) => {
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [user, setUser] = useState(null)

  const [total, setTotal] = useState(0)
  const [totalViews, setTotalViews] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [orderSort, setOrderSort] = useState('desc')
  const [data, setData] = useState([])

  const [menuId, setMenuId] = useState(null)
  const [menuItem, setMenuItem] = useState(null)
  const [menuItemList, setMenuItemList] = useState([])
  const [ribbon, setRibbon] = useState(null)
  const [ribbonList, setRibbonList] = useState([])

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataUser } = useQuery('getReportStatisticUser', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/users/`)
  })

  const { data: dataMenu } = useQuery('getReportStatisticMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataMenuItem } = useQuery(['getReportStatisticMenuItem', menuId], async () => {
    if (menuId) {
      return await getData('GET', `menu/${menuId}/menu-items/`)
    }
  })

  const { data: dataRibbon } = useQuery(['getRibbon', menuItem], async () => {
    if (menuItem) {
      return await getData('GET', `menu-items/${menuItem.value}/ribbons/`)
    } else {
      return null
    }
  })

  const { data: dataReport, isLoading, isFetching } = useQuery(['getReportStatistic', menuItem, ribbon, user, limit, offset, fromDate, toDate, orderSort], async () => {
    let endpointTemp = ''
    if (user) {
      endpointTemp += `&user_id=${user.value}`
    }
    if (menuItem) {
      endpointTemp += `&menu_item_id=${menuItem.value}`
    }
    if (ribbon) {
      endpointTemp += `&ribbon_id=${ribbon.value}`
    }

    if (user || menuItem || ribbon) {
      const fromDateParam = formatFormData(fromDate)
      const toDateParam = formatFormData(toDate)

      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/statistic-vodentity?limit=${limit}&page=${offset}&start_time=${fromDateParam}&end_time=${toDateParam}&view_sort=${orderSort}${endpointTemp}`)
    }
  })

  useEffect(() => {
    if (dataUser) {
      const resultData = dataUser.data
      if (resultData) {
        const itemList = resultData.results
        if (Array.isArray(itemList)) {
          const itemArrTemp = itemList.map(item => {
            return {
              label: item.username,
              value: item.id
            }
          })
          setUserList(itemArrTemp)
        }
      }
    }
  }, [dataUser])

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data
      if (resultData) {
        const arrTemp = resultData.results
        if (Array.isArray(arrTemp)) {
          const firstItem = arrTemp[0]
          if (firstItem) {
            setMenuId(firstItem.id)
          }
        }
      }
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataMenuItem) {
      const resultData = dataMenuItem.data
      if (Array.isArray(resultData)) {
        const arrTemp = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })

        setMenuItemList(arrTemp)
      }
    }
  }, [dataMenuItem])

  useEffect(() => {
    if (dataRibbon) {
      const resultData = dataRibbon.data
      if (Array.isArray(resultData)) {
        const arrTemp = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })

        setRibbonList(arrTemp)
      }
    } else {
      setRibbonList([])
      setRibbon(null)
    }
  }, [dataRibbon])

  useEffect(() => {
    if (dataReport) {
      const resultData = dataReport.data
      if (resultData) {
        setTotal(resultData.total)
        setTotalPage(Math.ceil(resultData.total / limit))

        const itemList = resultData.results
        if (Array.isArray(itemList)) {
          const itemArrTemp = itemList.map(item => {
            return {
              key: item.id,
              ...item
            }
          })
          setData(itemArrTemp)
        }

        const totalViewTemp = resultData.total_views
        if (totalViewTemp) {
          setTotalViews(totalViewTemp.real_views__sum)
        }
      }
    }
  }, [dataReport])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeUser = (e, value) => {
    setUser(value)
  }

  const handleChangeMenuItem = (e, value) => {
    setMenuItem(value)
  }

  const handleChangeRibbon = (e, value) => {
    setRibbon(value)
  }

  const handleChangeFromDate = value => {
    setFromDate(value)
  }

  const handleChangeToDate = value => {
    setToDate(value)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset(value - 1)
    // setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleChangeTable = (pagination, filters, sorter) => {
    if (sorter) {
      if (sorter.columnKey === 'real_views') {
        if (sorter.order === 'ascend') {
          setOrderSort('asc')
        } else if (sorter.order === 'descend') {
          setOrderSort('desc')
        } else {
          setOrderSort('desc')
        }
      }
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '60%',
      ellipsis: true
    },
    {
      title: 'Views',
      dataIndex: 'real_views',
      key: 'real_views',
      width: '20%',
      ellipsis: true,
      sorter: true
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
      ellipsis: true
    }
  ]

  return (
    <div className="report">
      <Card>
        <CardContent>
          <Row className="pt-2 align-items-end">
            <Col md={3}>
              <Autocomplete
                fullWidth
                size="small"
                value={menuItem}
                onChange={(e, newValue) => handleChangeMenuItem(e, newValue)}
                options={menuItemList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Menu" />}
              />
            </Col>
            <Col md={3}>
              <Autocomplete
                fullWidth
                size="small"
                value={ribbon}
                onChange={(e, newValue) => handleChangeRibbon(e, newValue)}
                options={ribbonList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Ribbon" />}
              />
            </Col>
            <Col md={2}>
              <Autocomplete
                fullWidth
                size="small"
                value={user}
                onChange={(e, newValue) => handleChangeUser(e, newValue)}
                options={userList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="User" />}
              />
            </Col>
            <Col md={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="From Date"
                  value={fromDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeFromDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="To Date"
                  value={toDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeToDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
          </Row>
        </CardContent>
      </Card>

      <Row className="pt-5 pb-1">
        <Col md={12}>
          <div className="total-text">{total} items, Total Views: {totalViews}</div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            data={data}
            onChange={handleChangeTable}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>
    </div>
  )
}

export default ReportStatistic
