import React from 'react'
import Layout from 'features/layout/components/Layout'
import MenuSlideForm from '../components/MenuSlideForm'

const MenuSlideFormPage = (props) => {
  return (
    <Layout>
      <MenuSlideForm />
    </Layout>
  )
}

export default MenuSlideFormPage
