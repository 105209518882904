import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { getData } from 'common/utils'
import { Col, Row, Table } from 'components/ui'
import { Button, Checkbox, FormControlLabel, Popover, TextField, Typography } from '@mui/material'
import { Select } from 'antd'
import { toast } from 'react-toastify'
import Pagination from '@mui/material/Pagination'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete'
import PreviewIcon from '@mui/icons-material/Preview'

import RibbonSharingWithMeFilter from './RibbonSharingWithMeFilter'
import FormDialog from 'components/form/FormDialog'
// import FormDialog from 'components/form/FormDialog'

const { Option } = Select

const pageList = [
  { value: 10, label: '10 / page' },
  { value: 20, label: '20 / page' },
  { value: 50, label: '50 / page' }
]

const RibbonSharingWithMe = () => {
  const queryClient = useQueryClient()
  const urlString = window.location.href
  const url = new URL(urlString)
  const searchParamsUrl = url.searchParams.get('q')

  const [dataRibbonSharingWithMe, setDataRibbonSharingWithMe] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [current, setCurrent] = useState(1)
  const [searchFilter, setSearchFilter] = useState('')

  // View More Info
  const [dataMoreInfo, setDataMoreInfo] = useState({})
  const [isOpenViewMoreInfo, setIsOpenViewMoreInfo] = useState(false)
  const [anchorElViewMoreInfoRibbonSharing, setAnchorElViewMoreInfoRibbonSharing] = useState(null)

  // Update Ribbon Sharing Content With Me
  const [dataFormUpdate, setDataFormUpdate] = useState(null)
  const [isOpenFormUpdate, setIsOpenFormUpdate] = useState(false)
  const [idRibbonSharing, setIdRibbonSharing] = useState('')
  const [menuData, setMenuData] = useState([])
  const [menuSelect, setMenuSelect] = useState(null)
  const [menuItemData, setMenuItemData] = useState([])
  const [menuItemSelect, setMenuItemSelect] = useState(null)
  const [menuBottomData, setMenuBottomData] = useState([])
  const [menuBottomSelect, setMenuBottomSelect] = useState(null)

  /* ----------------------------------------------- */
  /* --------------- Fetch APIs ------------------ */
  /* ----------------------------------------------- */
  const { data: dataList, isLoading, isFetching } = useQuery(['getRibbonsShareWithMe', searchFilter], async () => {
    let endpointTemp = ''
    if (searchFilter !== '') {
      endpointTemp += `&q=${searchFilter}`
    }
    return await getData(
      'GET',
      `tenants/${localStorage.getItem('tenant')}/share-ribbon-with-me?offset=${offset}&limit=${limit}${endpointTemp}`
    )
  })
  const { data: menuList } = useQuery(['getMenuList', isOpenFormUpdate, menuData], async () => {
    if (isOpenFormUpdate && menuData.length <= 0) {
      return await getData(
        'GET',
        `tenants/${localStorage.getItem('tenant')}/menu?offset=0&limit=20`
      )
    }
  })
  const { data: menuItemList } = useQuery(['getMenuItemList', menuSelect, menuItemData], async () => {
    if (menuSelect && menuItemData.length <= 0) {
      return await getData(
        'GET',
        `menu/${menuSelect.value}/menu-items?offset=0&limit=20`
      )
    }
  })
  const { data: menuBottomList } = useQuery(['getMenuBottomList', isOpenFormUpdate, menuBottomData], async () => {
    if (isOpenFormUpdate && menuBottomData.length <= 0) {
      return await getData(
        'GET',
        `tenants/${localStorage.getItem('tenant')}/menu-bottom-short-info?offset=0&limit=20`
      )
    }
  })
  const mutationUpdateRibbonSharing = useMutation(async ({ idRibbonSharing, dataForm }) => {
    if (idRibbonSharing && dataForm) {
      return await getData('PUT', `share-ribbon/${idRibbonSharing}/shared-ribbon-update`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonsShareWithMe')
    }
  })

  /* ----------------------------------------------- */
  /* --------------- UseEffect ------------------ */
  /* ----------------------------------------------- */
  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results
      setDataRibbonSharingWithMe(resultData)
      setTotalPage(Math.ceil(dataList.data.total / limit))
      setOffset(0)
      setLimit(10)
    }
  }, [dataList])
  useEffect(() => {
    if (searchParamsUrl) {
      setSearchFilter(searchParamsUrl)
    }
  }, [searchParamsUrl])
  useEffect(() => {
    if (menuList) {
      const resultData = menuList.data && menuList.data.results
      const data = resultData.map((item) => {
        return {
          value: item.id,
          label: item.name
        }
      })
      setMenuData(data)
    }
  }, [menuList])
  useEffect(() => {
    if (menuItemList) {
      const resultData = menuItemList?.data || []
      const data = resultData.map((item) => {
        return {
          value: item.id,
          label: item.name
        }
      })
      setMenuItemData(data)
    }
  }, [menuItemList])
  useEffect(() => {
    if (menuBottomList) {
      const resultData = menuBottomList.data && menuBottomList.data.results
      const data = resultData.map((item) => {
        return {
          value: item.id,
          label: item.name
        }
      })
      setMenuBottomData(data)
    }
  }, [menuBottomList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */

  // View More Info
  const handleClickViewMoreInfo = (event, dataMoreInfo) => {
    setIsOpenViewMoreInfo(true)
    setDataMoreInfo(dataMoreInfo)
    setAnchorElViewMoreInfoRibbonSharing(event.currentTarget)
  }
  const handleCloseViewMoreInfo = () => {
    setDataMoreInfo(null)
    setIsOpenViewMoreInfo(false)
    setAnchorElViewMoreInfoRibbonSharing(null)
  }
  // View More Info

  // Change Page
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }
  // Change Page

  // Filter
  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }
  // Filter

  // Update Ribbon Sharing Content With Me
  const handleOpenFormUpdate = (record) => {
    setIsOpenFormUpdate(true)
    setIdRibbonSharing(record.shared_ribbon)
    setDataFormUpdate({
      tenant_id: localStorage.getItem('tenant'),
      is_active: record?.is_active || false,
      hidden: record?.hidden || false
    })
  }
  const handleCloseFormUpdate = () => {
    setIsOpenFormUpdate(false)
    setMenuSelect(null)
  }
  const handleSelectMenu = (value) => {
    setMenuSelect(value)
  }
  const handleSelectMenuItem = (value) => {
    setMenuItemSelect(value)
  }
  const handleSelectMenuBottom = (value) => {
    setMenuBottomSelect(value)
  }
  const handleChangeActive = (e, checked) => {
    const newFormUpdate = {
      is_active: checked,
      hidden: dataFormUpdate.hidden,
      tenant_id: dataFormUpdate.tenant_id
    }
    setDataFormUpdate(newFormUpdate)
  }
  const handleChangeHidden = (e, checked) => {
    const newFormUpdate = {
      is_active: dataFormUpdate.is_active,
      hidden: checked,
      tenant_id: dataFormUpdate.tenant_id
    }
    setDataFormUpdate(newFormUpdate)
  }
  const handleUpdateRibbonSharingTenant = async () => {
    if (idRibbonSharing) {
      const dataForm = {
        menu_item: menuItemSelect?.value || '',
        menu_bottom: menuBottomSelect?.value || '',
        tenant_id: dataFormUpdate.tenant_id,
        hidden: dataFormUpdate.hidden,
        is_active: dataFormUpdate.is_active
      }
      await mutationUpdateRibbonSharing.mutateAsync({
        idRibbonSharing, dataForm
      }).then(() => {
        setIdRibbonSharing('')
        setDataFormUpdate(null)
        setMenuSelect(null)
        setMenuItemData([])
        setMenuItemSelect(null)
        setMenuBottomSelect(null)
        setIsOpenFormUpdate(false)
        toast.success('Update Ribbon Sharing Success')
      }).catch(() => {
        toast.error('Something went wrong!')
      })
    }
  }
  // Update Ribbon Sharing Content With Me

  /* ----------------------------------------------- */
  /* --------------- Columns ------------------ */
  /* ----------------------------------------------- */
  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Edit">
              <IconButton color="success"
                onClick={() => handleOpenFormUpdate(record)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Entity">
              <Link to={`/ribbon-item/${record.ribbon.id}`}>
                <IconButton color="primary">
                  <PreviewIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Ribbon',
      key: 'ribbon-name',
      align: 'left',
      width: '18%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.ribbon?.name || '' }
          </div>
        )
      }
    },
    {
      title: 'Type',
      key: 'type',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={`${index}-type`}>
            {
              record?.ribbon?.type === 0
                ? 'Master Banner'
                : record?.ribbon?.type === 1
                  ? 'Poster'
                  : record?.ribbon?.type === 2
                    ? 'Thumbnail'
                    : record?.ribbon?.type === 3
                      ? 'Banner'
                      : record?.ribbon?.type === 4
                        ? 'List'
                        : record?.ribbon?.type === 5
                          ? 'Circle'
                          : record?.ribbon?.type === 6
                            ? 'Pano'
                            : record?.ribbon?.type === 7
                              ? 'Special News'
                              : record?.ribbon?.type === 8
                                ? 'Trending'
                                : record?.ribbon?.type === 9
                                  ? 'Poster News'
                                  : record?.ribbon?.type === 10
                                    ? 'Audio'
                                    : record?.ribbon?.type === 11
                                      ? 'Auto Play'
                                      : record?.ribbon?.type === 12
                                        ? 'Album'
                                        : 'Banner Top'
            }
          </div>
        )
      }
    },
    {
      title: 'Menu Item',
      key: 'menu_item',
      align: 'left',
      width: '12%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.menu_item?.name || '' }
          </div>
        )
      }
    },
    {
      title: 'Hidden',
      key: 'hidden',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            {
              record?.hidden
                ? <IconButton color="success">
                    <CheckIcon />
                  </IconButton>
                : <IconButton color="error">
                    <CloseIcon />
                  </IconButton>
            }
          </div>
        )
      }
    },
    {
      title: 'Active',
      key: 'active',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            {
              record?.is_active
                ? <IconButton color="success">
                    <CheckIcon />
                  </IconButton>
                : <IconButton color="error">
                    <CloseIcon />
                  </IconButton>
            }
          </div>
        )
      }
    },
    {
      title: 'Owner',
      key: 'owner',
      align: 'left',
      width: '12%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.owner?.name || '' }
          </div>
        )
      }
    },
    {
      title: 'More info',
      key: 'more_info',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            <Button
              aria-describedby={isOpenViewMoreInfo ? 'simple-popover' : ''}
              variant="contained" onClick={(e) => handleClickViewMoreInfo(e, record)}
            >
              View
            </Button>
          </div>
        )
      }
    }
  ]
  return (
    <div className='ribbon-sharing-with-me'>
      <Row className='pt-4'>
        <Col md={12}>
          <RibbonSharingWithMeFilter
            onClickSearch={handleClickSearchFilter}
            searchFilter={searchFilter}
          />
        </Col>
      </Row>
      <Row className='pt-4'>
        <Col md={12}>
          <Table
            columns={columns}
            data={dataRibbonSharingWithMe}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      {/* Popover View More info */}
      <Popover
        id={isOpenViewMoreInfo ? 'simple-popover' : ''}
        open={isOpenViewMoreInfo}
        anchorEl={anchorElViewMoreInfoRibbonSharing}
        onClose={handleCloseViewMoreInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {
          dataMoreInfo && (
            <div>
              <Typography sx={{ p: 2 }}>
                <span style={{ fontWeight: 600, marginRight: 2 }}>Order:</span>{dataMoreInfo?.ord}
              </Typography>
              {
                dataMoreInfo?.menu_bottom && (
                  <Typography sx={{ p: 2 }}>
                    <span style={{ fontWeight: 600, marginRight: 2 }}>Menu Bottom:</span>{dataMoreInfo?.menu_bottom?.name || ''}
                  </Typography>
                )
              }
              {
                dataMoreInfo?.expire_at && (
                  <Typography sx={{ p: 2 }}>
                    <span style={{ fontWeight: 600, marginRight: 2 }}>
                      Expire At:</span>{dataMoreInfo?.expire_at || ''}
                  </Typography>
                )
              }
              {
                dataMoreInfo?.reason_on_hold && (
                  <Typography sx={{ p: 2 }}>
                    <span style={{ fontWeight: 600, marginRight: 2 }}>
                      Reason:
                    </span>{dataMoreInfo?.reason_on_hold || ''}
                  </Typography>
                )
              }
            </div>
          )
        }
      </Popover>
      {/* Popover View More info */}

      {/* Update Shared Ribbon With Me Data */}
      <FormDialog
        isOpen={isOpenFormUpdate}
        onClose={handleCloseFormUpdate}
        onSave={handleUpdateRibbonSharingTenant}
        type='form_update_share_ribbon_tenant'
        title='Update Ribbon Sharing'
        dividers={true}
        isSave={true}
        isDisabled={!menuItemSelect?.value}
      >
        <Row className="pt-2">
          <Col md={12}>
            <Autocomplete
              aria-required
              fullWidth
              value={menuSelect}
              onChange={(e, newValue) => handleSelectMenu(newValue)}
              options={menuData}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Menu Platform" />}
            />
          </Col>
        </Row>
        <Row className="pt-2">
          <Col md={12}>
            <Autocomplete
              aria-required
              fullWidth
              value={menuItemSelect}
              onChange={(e, newValue) => handleSelectMenuItem(newValue)}
              options={menuItemData}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Menu Page" />}
            />
          </Col>
        </Row>
        <Row className="pt-2">
          <Col md={12}>
            <Autocomplete
              fullWidth
              value={menuBottomSelect}
              onChange={(e, newValue) => handleSelectMenuBottom(newValue)}
              options={menuBottomData}
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              renderInput={(params) => <TextField {...params} label="Menu Bottom" />}
            />
          </Col>
        </Row>
        <Row className="pt-2">
          <Col md={12}>
            <FormControlLabel
              value="active"
              control={
                <Checkbox
                  name="is_active"
                  checked={dataFormUpdate?.is_active || false}
                  onChange={(e, checked) => handleChangeActive(e, checked)}
                />
              }
              label="Active"
              labelPlacement="start"
            />
          </Col>
        </Row>
        <Row className="pt-2 pb-2">
          <Col md={12}>
            <FormControlLabel
              value="hidden"
              control={
                <Checkbox
                  name="hidden"
                  checked={dataFormUpdate?.hidden || false}
                  onChange={(e, checked) => handleChangeHidden(e, checked)}
                />
              }
              label="Hidden"
              labelPlacement="start"
            />
          </Col>
        </Row>
      </FormDialog>
      {/* Update Shared Ribbon With Me Data */}
    </div>
  )
}

export default RibbonSharingWithMe
