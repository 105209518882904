import React from 'react'
import Layout from 'features/layout/components/Layout'
import TranscodeEdit from '../components/TranscodeEdit'

const TranscodeEditPage = (props) => {
  return (
    <Layout>
      <TranscodeEdit />
    </Layout>
  )
}

export default TranscodeEditPage
