import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodEpgForm from '../components/VodEpgForm'

const VodEpgFormPage = (props) => {
  return (
    <Layout>
      <VodEpgForm />
    </Layout>
  )
}

export default VodEpgFormPage
