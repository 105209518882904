import React from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { getData } from 'common/utils'
import CoreList from 'features/core/components/CoreList'
import Switch from '@mui/material/Switch'
// import Tooltip from '@mui/material/Tooltip'
// import IconButton from '@mui/material/IconButton'
// import EditIcon from '@mui/icons-material/Edit'
// import DeleteIcon from '@mui/icons-material/Delete'

const RibbonDisplay = () => {
  const queryClient = useQueryClient()
  // States

  // Fetch APIs
  const mutation = useMutation(async ({ id, dataForm }) => {
    if (id && dataForm) {
      return await getData('PUT', `ribbon_display/${id}/active`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonDisplay')
    }
  })

  // Handle Event
  const handleChangeStatus = async (e, id) => {
    try {
      await mutation.mutateAsync({
        id: id,
        dataForm: {
          is_active: e.target.checked
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Ribbon (Parent)',
      key: 'ribbon_parent',
      width: '16%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record?.parent?.name || ''}</div>
        )
      }
    },
    {
      title: 'Ribbon (Child)',
      key: 'type',
      width: '20%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={`ribbon-child-${index}`}>
            {record?.ribbon?.name || ''}
          </div>
        )
      }
    },
    {
      title: 'Position',
      key: 'position',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <div key={`position-${index}`}>{record.position}</div>
      }
    },
    {
      title: 'Limit',
      key: 'limit',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <div key={`limit-${index}`}>{record.limit}</div>
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Switch checked={record.is_active} onChange={e => handleChangeStatus(e, record.id)} />
      }
    },
    {
      title: 'Entity (Sponsor)',
      key: 'content',
      width: '25%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <div key={`content-${index}`}>{record?.content?.title || ''}</div>
      }
    }
  ]

  return (
    <div className='ribbon-display'>
      <CoreList
        code="RibbonDisplay"
        endpoint="ribbon_display"
        url="ribbon-display"
        columns={columns}
      />
    </div>
  )
}

export default RibbonDisplay
