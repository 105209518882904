import React from 'react'
import PropTypes from 'prop-types'
import { TreeSelect as TreeSelectAntd } from 'antd'
import 'antd/lib/tree-select/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/empty/style/index.css'

const TreeSelect = (props) => {
  const { className, value, treeData, showSearch, onChange } = props

  return (
    <div className={`adm-tree-select${className === '' ? '' : (' ' + className)}`}>
      <TreeSelectAntd
        showSearch={showSearch}
        // allowClear
        style={{ width: '100%' }}
        value={value}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        placeholder="Please select"
        treeDefaultExpandAll
        bordered
        size="large"
        onChange={onChange}
        treeNodeFilterProp="title"
      />
    </div>
  )
}

TreeSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  treeData: PropTypes.array,
  showSearch: PropTypes.bool,
  onChange: PropTypes.func
}

TreeSelect.defaultProps = {
  className: '',
  treeData: [],
  showSearch: false
}

export default TreeSelect
