import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Button from 'components/form/Button'
import Pagination from '@mui/material/Pagination'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Select, Tabs, Table } from 'antd'
import { toast } from 'react-toastify'
import 'antd/lib/table/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/space/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/tabs/style/index.css'
import '../styles/vod-recommend.scss'

const { Option } = Select
const { TabPane } = Tabs

const VodMetadata = (props) => {
  const queryClient = useQueryClient()

  // const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [dataRecommend, setDataRecommend] = useState([])

  const [rowSelected, setRowSelected] = useState([])
  const [rowRecommendSelected, setRowRecommendSelected] = useState([])

  const [groupParam, setGroupParam] = useState(null)

  const { data: dataList, isLoading, isFetching } = useQuery(['getAvailabelMetadata', limit, offset, groupParam], async () => {
    if (groupParam) {
      return await getData('GET', `vod-entities/${groupParam}/available-metadata/?order=-updated_at&limit=${limit}&offset=${offset}`)
    }
  })

  const { data: dataVodMetaData } = useQuery(['getVodMetadata', groupParam], async () => {
    if (groupParam) {
      return await getData('GET', `vod-entities/${groupParam}/vod-metadata/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('POST', 'vod-metadata/', dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getAvailabelMetadata')
      queryClient.invalidateQueries('getVodMetadata')
    }
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `vod-metadata/${id}`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getAvailabelMetadata')
      queryClient.invalidateQueries('getVodMetadata')
    }
  })

  const mutationDeleteMultiple = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `tenants/${localStorage.getItem('tenant')}/vod-metadata/${id}`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getAvailabelMetadata')
      queryClient.invalidateQueries('getVodMetadata')
    }
  })

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: '60%'
    },
    {
      title: 'Value',
      key: 'value',
      width: '30%',
      dataIndex: 'value'
    }
  ]

  const columnsRecommend = [
    {
      title: 'Name',
      key: 'name',
      width: '60%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record.metadata && record.metadata.name}</div>
        )
      }
    },
    {
      title: 'Value',
      key: 'value',
      width: '30%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record.metadata && record.metadata.value}</div>
        )
      }
    }
  ]

  useEffect(() => {
    const urlString = window.location.href
    const url = new URL(urlString)
    const groupParamUrl = url.searchParams.get('group')
    setGroupParam(groupParamUrl)
  }, [])

  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        // setTotal(dataList.data.total)
        setTotalPage(Math.ceil(dataList.data.total / limit))
        setData(dataListParse)
      }
    }
  }, [dataList])

  useEffect(() => {
    if (dataVodMetaData) {
      const resultData = dataVodMetaData.data

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })
        setDataRecommend(dataListParse)
      }
    }
  }, [dataVodMetaData])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeLimit = (value) => {
    setLimit(value)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleClickAdd = async () => {
    try {
      if (rowSelected.length > 0) {
        if (rowSelected.length === 1) {
          await mutation.mutateAsync({
            // is_active: true,
            entity: groupParam,
            metadata: rowSelected[0] && rowSelected[0].id,
            tenant: localStorage.getItem('tenant')
          })
        } else {
          const arrTemp = rowSelected.map(item => {
            return {
              // is_active: true,
              entity: groupParam,
              metadata: item && item.id,
              tenant: localStorage.getItem('tenant')
            }
          })

          await mutation.mutateAsync(arrTemp)
        }

        setRowSelected([])
        setRowRecommendSelected([])
        toast.success('Entity added successfully')
      } else {
        toast.warning('Please select entity', {
          hideProgressBar: true,
          autoClose: 2000,
          position: 'top-right'
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickDelete = async () => {
    try {
      if (rowRecommendSelected.length > 0) {
        if (rowRecommendSelected.length === 1) {
          const idDelete = rowRecommendSelected[0] && rowRecommendSelected[0].id
          await mutationDelete.mutateAsync(idDelete)
        } else {
          let idStr = '?'
          for (let i = 0; i < rowRecommendSelected.length; i++) {
            if (i === rowRecommendSelected.length - 1) {
              idStr += 'id=' + (rowRecommendSelected[i] && rowRecommendSelected[i].id)
            } else {
              idStr += 'id=' + (rowRecommendSelected[i] && rowRecommendSelected[i].id) + '&'
            }
          }

          await mutationDeleteMultiple.mutateAsync(idStr)
        }

        setRowSelected([])
        setRowRecommendSelected([])
        toast.success('Entity deleted successfully')
      } else {
        toast.warning('Please select entity', {
          hideProgressBar: true,
          autoClose: 2000,
          position: 'top-right'
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows)
    }
  }

  const rowRecommendSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowRecommendSelected(selectedRows)
    }
  }

  return (
    <div className="vod-recommend">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Find Metadata" key="1">
          {(rowSelected && rowSelected.length > 0) &&
            <Row className="pt-3">
              <Col md={12}>
                <Button className="float-right" startIcon={<AddIcon />} onClick={handleClickAdd}>
                  Add
                </Button>
              </Col>
            </Row>
          }

          <Row className={`pt-${(rowSelected && rowSelected.length > 0) ? '3' : '3'}`}>
            <Col md={12}>
              <div className="table-antd">
                <Table
                  columns={columns}
                  dataSource={data}
                  loading={isLoading || isFetching}
                  bordered
                  pagination={false}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={12}>
              <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
                {pageList && pageList.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>{item.label}</Option>
                  )
                })}
              </Select>

              <Pagination
                className="float-right"
                count={totalPage}
                page={current}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                color="standard"
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Metadata List" key="2">
          {(rowRecommendSelected && rowRecommendSelected.length > 0) &&
            <Row className="pt-3">
              <Col md={12}>
                <Button className="float-right" color="error" startIcon={<DeleteIcon />} onClick={handleClickDelete}>
                  Delete
                </Button>
              </Col>
            </Row>
          }

          <Row className="pt-3">
            <Col md={12}>
              <div className="table-antd">
                <Table
                  columns={columnsRecommend}
                  dataSource={dataRecommend}
                  // loading={isLoading || isFetching}
                  bordered
                  pagination={false}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowRecommendSelection
                  }}
                />
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default VodMetadata
