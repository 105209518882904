/**
  * Danh sách menu
*/

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { getData } from 'common/utils'
import { setRibbonId } from 'features/ribbon/slices/ribbonSlice'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Tree from 'components/ui/Tree'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Button from 'components/form/Button'
import AlertDialog from 'components/ui/AlertDialog'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import MenuIcon from '@mui/icons-material/Menu'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import '../styles/menu-item.scss'

const MenuItem = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const [menuList, setMenuList] = useState([])
  const [menu, setMenu] = useState(null)

  const [dataTree, setDataTree] = useState([])
  const [idDelete, setIdDelete] = useState(null)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  // const [expandedKeys, setExpandedKeys] = useState([])

  const { data: dataMenu } = useQuery('getMenuItemMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataMenuItem } = useQuery(['getMenuItemMenuItem', menu], async () => {
    if (menu) {
      return await getData('GET', `menu/${menu.value}/menu-items/`)
    }
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `menu-items/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getMenuItemMenuItem')
    }
  })

  const mutationUpdateOrder = useMutation(async (dataForm) => {
    if (dataForm && menu) {
      return await getData('PUT', `menu/${menu.value}/menu-items-order/`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getMenuItemMenuItem')
    }
  })

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setMenuList(selectData)
      }
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataMenuItem) {
      const resultData = dataMenuItem.data

      if (Array.isArray(resultData)) {
        const dataTemp = resultData.map((item, index) => {
          const childrenArrTemp = []
          const childrenArr = item.sub_menu_items
          if (Array.isArray(childrenArr)) {
            for (let i = 0; i < childrenArr.length; i++) {
              childrenArrTemp.push({
                key: `0-${index}-${i}`,
                id: childrenArr[i] && childrenArr[i].id,
                title: childrenArr[i] && childrenArr[i].name,
                url: childrenArr[i] && childrenArr[i].url,
                is_active: childrenArr[i] && childrenArr[i].is_active,
                children: []
              })
            }
          }

          return {
            key: `0-${index}`,
            id: item.id,
            title: item.name,
            url: item.url,
            is_active: item.is_active,
            children: childrenArrTemp
          }
        })

        setDataTree(dataTemp)
        // setExpandedKeys(['0-0', '0-0-0', '0-0-0-0'])
      }
    }
  }, [dataMenuItem])

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      setMenu(menuList[0])
    }
  }, [menuList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickViewRibbon = (e, record) => {
    dispatch(setRibbonId(record.id))
    history.push('/ribbon')
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)
      setIsOpenDelete(false)

      toast.success('Menu deleted successfully')
    } catch (err) {
      setIsOpenDelete(false)
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  const handleChangeMenu = (e, value) => {
    setMenu(value)
  }

  const onDragEnter = info => {
    // console.log(info)
  }

  const onDrop = info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children, key, callback)
        }
      }
    }
    const data = [...dataTree]

    // Find dragObject
    let dragObj
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj)
      })
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj)
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      })
    } else {
      let ar
      let i
      loop(data, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        ar.splice(i + 1, 0, dragObj)
      }
    }

    setDataTree(data)
  }

  const handleClickUpdateOrder = async () => {
    try {
      const arrTemp = []
      let indexTemp = 1
      let indexChildTemp = dataTree.length

      for (let i = 0; i < dataTree.length; i++) {
        const itemId = dataTree[i] && dataTree[i].id
        const itemChildren = dataTree[i] && dataTree[i].children

        if (Array.isArray(itemChildren)) {
          for (let j = 0; j < itemChildren.length; j++) {
            const itemChildrenId = itemChildren[j] && itemChildren[j].id

            arrTemp.push({
              id: itemChildrenId,
              parent: itemId,
              ord: indexChildTemp
            })

            indexChildTemp += 1
          }
        }

        arrTemp.push({
          id: itemId,
          parent: null,
          ord: indexTemp
        })

        indexTemp += 1
      }
      await mutationUpdateOrder.mutateAsync(arrTemp)

      toast.success('Update order successfully')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="menu-item">
      <Row>
        <Col md={4}>
          <Autocomplete
            fullWidth
            disableClearable
            size="small"
            options={menuList}
            value={menu}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(e, newValue) => handleChangeMenu(e, newValue)}
            renderInput={(params) => <TextField {...params} label="Platform Menu" />}
          />
        </Col>
        <Col md={8}>
          {/* <div className="pb-1">&nbsp;</div> */}
          <Link to="/menu/add">
            <Button className="float-right" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Tree
            className="draggable-tree"
            // expandedKeys={expandedKeys}
            draggable
            blockNode
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            treeData={dataTree}
            titleRender={(nodeData) => {
              return (
                <div>
                  {nodeData.url
                    ? <>
                        <img className="d-inline-block float-left" src={nodeData.url} width={60} height={50} />
                        <div className="d-inline-block pl-4 pt-2 float-left">{nodeData.title}</div>
                      </>
                    : <span>{nodeData.title}</span>
                  }
                  <div className="menu-view float-right mr-2" onClick={e => handleClickViewRibbon(e, nodeData)}>
                    <IconButton>
                      <MenuIcon />
                    </IconButton>
                    View Ribbon
                  </div>
                  <div className="float-right mr-4 menu-delete" onClick={e => handleClickShowModalDelete(e, nodeData)}>
                    <IconButton color="error">
                      <DeleteIcon />
                    </IconButton>
                    Delete
                  </div>
                  <Link to={`/menu/${nodeData.id}`} className="menu-edit float-right mr-4">
                    <IconButton color="success">
                      <EditIcon />
                    </IconButton>
                    Edit
                  </Link>
                  <div className="float-right mr-5">
                    {nodeData.is_active &&
                      <div>
                        <IconButton color="success">
                          <CheckIcon />
                        </IconButton>
                        Active
                      </div>
                    }
                  </div>
                </div>
              )
            }}
          />
        </Col>
      </Row>

      {(dataTree && dataTree.length > 0) &&
        <Row className="pt-4">
          <Col md={12}>
            <Button onClick={handleClickUpdateOrder}>
              Update Order
            </Button>
          </Col>
        </Row>
      }

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
    </div>
  )
}

export default MenuItem
