import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { getData, formatFormData, formatFormDateTime } from 'common/utils'
import { toast } from 'react-toastify'
import parse from 'date-fns/parse'
import { Tabs } from 'antd'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import DateTimePicker from 'components/form/DateTimePicker'
import Button from 'components/form/Button'
import Checkbox from 'components/form/Checkbox'
import VodEpgImage from './VodEpgImage'
import 'antd/lib/tabs/style/index.css'
import { HOSTNAME } from 'constant/appConstant'

const { TabPane } = Tabs

const VodEpgForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()

  const [images, setImages] = useState([])

  const { data: dataInfo } = useQuery(['getChannelFeedInfo', id], async () => {
    if (id) {
      return await getData('GET', `epg-feeds/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `epg-feeds/${id}/`, dataForm)
      }
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('title', resultData.title)
        setValue('is_hot', resultData.is_hot)
        setValue('source_id', resultData?.source_id)

        const startAtDate = parse(resultData.start_at, 'yyyy-MM-dd HH:m:s', new Date())
        setValue('start_at', startAtDate)

        const endAtDate = parse(resultData.end_at, 'yyyy-MM-dd HH:m:s', new Date())
        setValue('end_at', endAtDate)

        // Images
        setImages(resultData.images)
      }
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      const idImageArr = Array.isArray(images) && images.map(item => {
        return {
          image: item.image && item.image.id
        }
      })

      if (id) {
        await mutation.mutateAsync({
          title: formatFormData(data.title),
          start_at: formatFormDateTime(data.start_at),
          end_at: formatFormDateTime(data.end_at),
          is_hot: typeof data.is_hot === 'undefined' ? false : data.is_hot,
          images: idImageArr,
          source_id: data?.source_id || ''
        })
      } else {
        //
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleChangeImage = (value) => {
    setImages(value)
  }

  return (
    <div className="vod-epg-form">
      <Card>
        <CardContent>
          <Tabs defaultActiveKey="1">
            <TabPane tab="INFO" key="1">
              <Row className="pt-3">
                <Col md={12}>
                  <TextField label="Title" name="title" required control={control} error={errors.title} />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={6}>
                  <DateTimePicker label="Start time" name="start_at" control={control} />
                </Col>
                <Col md={6}>
                  <DateTimePicker label="End time" name="end_at" control={control} />
                </Col>
              </Row>
              {
                (window.location.hostname === HOSTNAME.BRT || window.location.hostname === HOSTNAME.BRT_STAGING || window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.LOCALHOST) && (
                  <Row className="pt-3">
                    <Col md={12}>
                      <TextField label="SourceID" name="source_id" control={control} error={errors.source_id} />
                    </Col>
                  </Row>
                )
              }
              <Row className="pt-4">
                <Col md={12}>
                  <Checkbox name="is_hot" className="d-inline-block" control={control} label="Hot Event" />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="IMAGES" key="2">
              <VodEpgImage dataImageList={images} onChangeImage={handleChangeImage} />
            </TabPane>
          </Tabs>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default VodEpgForm
