import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { setImageList } from 'features/vod/slices/vodSlice'
import { Row, Col, Table, Image } from 'components/ui'
import Autocomplete from '@mui/material/Autocomplete'
import TextFieldMui from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import TextField from 'components/form/TextField'
import AlertDialog from 'components/ui/AlertDialog'
import FormDialog from 'components/form/FormDialog'
import Button from 'components/form/Button'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData, formatFormData, callUploadApi } from 'common/utils'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

// Preview Image Upload Style
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
}

const VodImage = (props) => {
  const dispatch = useDispatch()
  const imageList = useSelector((state) => state.vod.imageList)
  const queryClient = useQueryClient()
  const { handleSubmit, control, formState: { errors }, setError } = useForm()
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const { data: dataList } = useQuery('getImageTypesUpload', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/image-types/`)
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('POST', 'image-types/', dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getImageTypesUpload')
    }
  })

  const [imageTypeList, setImageTypeList] = useState([])
  const [dataImageList, setDataImageList] = useState([])
  const [imageType, setImageType] = useState(null)
  const [files, setFiles] = useState([])
  const [idDelete, setIdDelete] = useState(null)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenImageType, setIsOpenImageType] = useState(false)
  const [isOpenUploadImage, setIsOpenUploadImage] = useState(false)

  const [errorImageType, setErrorImageType] = useState(false)

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: 90,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Preview',
      key: 'preview',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <Image src={record.preview} width={100} height={60} />
        )
      }
    },
    {
      title: 'Image Type',
      dataIndex: 'image_type',
      key: 'image_type'
    }
  ]

  // Upload
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      // files.forEach(file => URL.revokeObjectURL(file.preview))

      // Remove all image
      dispatch(setImageList([]))
    }
  }, [])

  useEffect(() => {
    if (imageList) {
      setDataImageList(imageList)
    }
  }, [imageList])

  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setImageTypeList(selectData)
      }
    }
  }, [dataList])

  // useEffect(() => {
  //   if (imageTypeList && imageTypeList.length > 0) {
  //     setImageType(imageTypeList[0])
  //   }
  // }, [imageTypeList])

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach(file => URL.revokeObjectURL(file.preview))
  // }, [files])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onImageTypeSubmit = async (data) => {
    try {
      await mutation.mutateAsync({
        name: formatFormData(data.name),
        tenant: localStorage.getItem('tenant')
      })
      setIsOpenImageType(false)
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      }
      console.log(err)
    }
  }

  const handleClickShowModalUpload = () => {
    setIsOpenUploadImage(true)
  }

  const handleCloseUpload = () => {
    setIsOpenUploadImage(false)
    setFiles([])
    setErrorImageType(false)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleClickShowModalImageType = () => {
    setIsOpenImageType(true)
  }

  const handleCloseImageType = () => {
    setIsOpenImageType(false)
  }

  const handleChangeImageType = (e, value) => {
    setImageType(value)

    if (value) {
      setErrorImageType(false)
    }
  }

  const handleClickDelete = async () => {
    try {
      if (Array.isArray(imageList)) {
        const imageListTemp = imageList.filter(item => item.id !== idDelete)
        dispatch(setImageList(imageListTemp))
        setIsOpenDelete(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickUpload = () => {
    if (imageType) {
      setErrorImageType(false)

      if (files.length > 0) {
        const fileUpload = files[0]

        // Upload to server
        const formData = new FormData()
        formData.append('type', imageType && imageType.value)
        formData.append('tenant', localStorage.getItem('tenant'))
        formData.append('image', fileUpload)
        callUploadApi('POST', 'images/', formData)
          .then(res => {
            const resultData = res.data && res.data.data
            if (resultData) {
              const imageListTemp = [...imageList]
              const imageId = resultData.id

              if (Array.isArray(imageListTemp)) {
                const currentKey = imageListTemp.length
                imageListTemp.push({
                  key: currentKey,
                  id: imageId,
                  image_type: imageType && imageType.value,
                  // type: fileUpload.type,
                  preview: fileUpload.preview
                })
              }
              dispatch(setImageList(imageListTemp))
              setIsOpenUploadImage(false)
              setFiles([])
              setErrorImageType(false)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    } else {
      setErrorImageType(true)
    }
  }

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ))

  return (
    <div className="vod-image">
      <Row className="pt-2">
        <Col md={6}>
          <Button onClick={handleClickShowModalUpload} startIcon={<AddIcon />}>
            Add Image
          </Button>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col md={12}>
          <Table
            columns={columns}
            data={dataImageList}
          // loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <FormDialog
        isOpen={isOpenUploadImage}
        title="Upload Image"
        onClose={handleCloseUpload}
        onSave={handleClickUpload}
        customButton={<Button variant="contained" color="success" className="ml-2" onClick={handleClickShowModalImageType}>Add Image Type</Button>}
      >
        <Row className="pt-2">
          <Col md={12}>
            <Autocomplete
              fullWidth
              size="small"
              options={imageTypeList}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              disableClearable
              onChange={handleChangeImageType}
              value={imageType}
              renderInput={(params) =>
                <TextFieldMui
                  {...params}
                  label="Image Type"
                  required
                  error={errorImageType}
                  helperText={errorImageType ? 'This field is required' : null}
                />
              }
            />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={12}>
            <div className="pb-1">Image</div>
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
              </div>
            </section>
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
          </Col>
        </Row>
      </FormDialog>

      <FormDialog
        isOpen={isOpenImageType}
        title="Add Image Type"
        onClose={handleCloseImageType}
        onSave={handleSubmit(onImageTypeSubmit)}
      >
        <Row>
          <Col md={12}>
            <TextField label="Name" name="name" required control={control} error={errors.name} />
          </Col>
        </Row>
      </FormDialog>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
    </div>
  )
}

export default VodImage
