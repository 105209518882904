import React, { useRef, useState } from 'react'
import { Image } from 'components/ui'
import { Input, Button as ButtonAntd, Space, Badge, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
// import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import CoreList from 'features/core/components/CoreList'
import CheckIcon from '@mui/icons-material/Check'
// import Tooltip from '@mui/material/Tooltip'
// import IconButton from '@mui/material/IconButton'
// import EditIcon from '@mui/icons-material/Edit'

const MenuMobile = () => {
  const searchInput = useRef()

  // States
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  // Fetch APIs

  // Handle Func
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters, confirm) => {
    clearFilters()
    setSearchText('')
    confirm()
  }

  const getColumnSearchProps = dataIndex => ({
    // eslint-disable-next-line react/display-name, react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonAntd
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 95 }}
          >
            Search
          </ButtonAntd>
          <ButtonAntd onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 95 }}>
            Reset
          </ButtonAntd>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/display-name
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select(), 100)
      }
    },
    // eslint-disable-next-line react/display-name
    render: text => {
      return searchedColumn === dataIndex
        ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
          )
        : (
            text
          )
    }
  })

  const columns = [
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   fixed: 'left',
    //   align: 'center',
    //   width: '10%',
    //   // eslint-disable-next-line react/display-name
    //   render: (text, record, index) => {
    //     return (
    //       <div>
    //         <Tooltip title="Edit">
    //           <Link to={`/menu-mobile/${record.id}`}>
    //             <IconButton color="success">
    //               <EditIcon />
    //             </IconButton>
    //           </Link>
    //         </Tooltip>
    //       </div>
    //     )
    //   }
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ellipsis: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      ...getColumnSearchProps('name')
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            { record.type === 1 ? 'Special' : 'Normal' }
          </div>
        )
      }
    },
    {
      title: 'Hex Code',
      dataIndex: 'hex_code',
      key: 'hex_code',
      width: '15%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {
              record.color_hex_code && (
                <Badge count={record.color_hex_code}>
                  <Tag color={record.color_hex_code} style={{ padding: 4 }}>Color InActive</Tag>
                </Badge>
              )
            }
            {
              record.color_hex_code_active && (
                <Badge count={record.color_hex_code_active}>
                  <Tag color={record.color_hex_code_active} style={{ padding: 4 }}>Color Active</Tag>
                </Badge>
              )
            }
            {
              record.bg_color_hex_code && (
                <Badge count={record.bg_color_hex_code}>
                  <Tag color={record.bg_color_hex_code} style={{ padding: 4 }}>Background Color</Tag>
                </Badge>
              )
            }
            {
              record.text_color_hex_code && (
                <Badge count={record.text_color_hex_code}>
                  <Tag color={record.text_color_hex_code} style={{ padding: 4 }}>Text Color</Tag>
                </Badge>
              )
            }
          </div>
        )
      }
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <>
          {
            (record?.url && record?.url !== '' && record?.icon !== 'null')
              ? <Image src={record.url} width={100} height={60} />
              : <div></div>
          }
          </>
        )
      }
    },
    {
      title: 'Icon Active',
      dataIndex: 'icon_active',
      key: 'icon_active',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <>
          {
            (record?.url_active && record?.url_active !== '' && record?.icon_active !== 'null')
              ? <Image src={record.url_active} width={100} height={60} />
              : <div></div>
          }
          </>
        )
      }
    },
    {
      title: 'Bg Image',
      dataIndex: 'bg_image_url',
      key: 'bg_image_url',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <>
          {
            (record?.bg_url && record?.bg_url !== '' && record?.bg_image_url !== 'null')
              ? <Image src={record.bg_url} width={100} height={60} />
              : <div></div>
          }
          </>
        )
      }
    },
    {
      title: 'Bg Mobile Image',
      dataIndex: 'bg_image_mobile_url',
      key: 'bg_image_mobile_url',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <>
          {
            (record?.bg_mobile_url && record?.bg_mobile_url !== '' && record?.bg_image_mobile_url !== 'null')
              ? <Image src={record.bg_mobile_url} width={100} height={60} />
              : <div></div>
          }
          </>
        )
      }
    },
    {
      title: 'Active',
      key: 'is_active',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.is_active) {
          return <CheckIcon color="success" />
        } else {
          return null
        }
      },
      sorter: (a, b) => {
        return b.is_active - a.is_active
      }
    }
  ]

  return (
    <div className="menu-mobile">
      <CoreList
        code="MenuMobile"
        endpoint="menu-bottom"
        url="menu-mobile"
        columns={columns}
        isAddNew={true}
        isAction={true}
        // isAction={false}
      />
    </div>
  )
}

export default MenuMobile
