import React from 'react'
import Layout from 'features/layout/components/Layout'
import Rss from '../components/Rss'

const RssPage = (props) => {
  return (
    <Layout>
      <Rss />
    </Layout>
  )
}

export default RssPage
