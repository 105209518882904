import React, { useEffect, useState, useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { getData, formatFormData, getUploadData, formatSlug } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Autocomplete from 'components/form/Autocomplete'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { Button as ButtonAntd } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const MenuItemForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const [menuItemList, setMenuItemList] = useState([])
  const [menuList, setMenuList] = useState([])
  const [menu, setMenu] = useState(null)
  const [files, setFiles] = useState([])
  const [icon, setIcon] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const [menuTypeList, setMenuTypeList] = useState([
    { value: 0, label: 'Horizontal' },
    { value: 1, label: 'Vertical' }
  ])

  const { data: dataInfo } = useQuery(['getInfoMenuItem', id], async () => {
    if (id) {
      return await getData('GET', `menu-items/${id}/`)
    }
  })

  const { data: dataMenuItem } = useQuery(['getMenuItemFormMenuItem', menu], async () => {
    if (menu) {
      return await getData('GET', `menu/${menu.value}/menu-items/`)
    }
  })

  const { data: dataMenu } = useQuery('getMenuItemMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `menu-items/${id}/`, dataForm)
      } else {
        return await getData('POST', 'menu-items/', dataForm)
      }
    }
  })

  const mutationUpload = useMutation(async (dataForm) => {
    if (id) {
      return await getUploadData('PUT', `menu-items/${id}/`, dataForm)
    } else {
      return await getUploadData('POST', 'menu-items/', dataForm)
    }
  })

  // Upload
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  useEffect(() => {
    setValue('ord', 1)
    setValue('type', { value: 0, label: 'Horizontal' })
  }, [])

  useEffect(() => {
    if (dataInfo && menuList) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('name', resultData.name)
        setValue('slug', resultData.slug)
        setValue('ord', resultData.ord)
        setValue('is_active', resultData.is_active)
        setValue('hidden', !resultData.hidden)

        // Image
        if (resultData.url) {
          setFiles([
            { preview: resultData.url }
          ])
        } else {
          setFiles([])
        }

        // Icon
        setIcon(resultData.icon)

        // Type
        const menuTypeFind = menuTypeList.find(item => item.value === resultData.type)
        setValue('type', menuTypeFind)

        const menuFind = menuList.find(item => item.value === resultData.menu)
        setMenu(menuFind)
        setValue('menu', menuFind)

        // const menuItemFind = menuItemList.find(item => item.value === resultData.parent)
        // setValue('parent', menuItemFind)
      }
    }
  }, [dataInfo, menuList])

  useEffect(() => {
    if (menuItemList) {
      if (dataInfo) {
        const resultData = dataInfo.data
        if (resultData) {
          const menuItemFind = menuItemList.find(item => item.value === resultData.parent)
          setValue('parent', menuItemFind)
        }
      }
    }
  }, [menuItemList])

  useEffect(() => {
    if (dataMenuItem) {
      const resultData = dataMenuItem.data

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setMenuItemList(selectData)
      }
    }
  }, [dataMenuItem])

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setMenuList(selectData)
      }
    }
  }, [dataMenu])

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach(file => URL.revokeObjectURL(file.preview))
  // }, [files])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        if (icon) {
          await mutation.mutateAsync({
            name: formatFormData(data.name),
            slug: formatFormData(data.slug),
            type: data.type && data.type.value,
            ord: data.ord === '' ? 0 : data.ord,
            is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
            hidden: typeof data.hidden === 'undefined' ? false : !data.hidden,
            parent: data.parent && data.parent.value,
            menu: data.menu && data.menu.value,
            icon: icon
          })
        } else {
          if (files.length > 0) {
            const fileUpload = files[0]

            const formData = new FormData()
            formData.append('name', formatFormData(data.name))
            formData.append('slug', formatFormData(data.slug))
            formData.append('ord', data.ord ? data.ord : 0)
            formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
            formData.append('hidden', typeof data.hidden === 'undefined' ? false : !data.hidden)
            if (data.type) {
              formData.append('type', data.type.value)
            }
            if (data.parent) {
              formData.append('parent', data.parent.value)
            }
            if (data.menu) {
              formData.append('menu', data.menu.value)
            }
            formData.append('image', fileUpload)
            await mutationUpload.mutateAsync(formData)
          } else {
            await mutation.mutateAsync({
              name: formatFormData(data.name),
              slug: formatFormData(data.slug),
              type: data.type && data.type.value,
              ord: data.ord === '' ? 0 : data.ord,
              is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
              hidden: typeof data.hidden === 'undefined' ? false : !data.hidden,
              parent: data.parent && data.parent.value,
              menu: data.menu && data.menu.value
            })
          }
        }
      } else {
        if (files.length > 0) {
          const fileUpload = files[0]

          const formData = new FormData()
          formData.append('name', formatFormData(data.name))
          formData.append('slug', formatFormData(data.slug))
          formData.append('ord', data.ord ? data.ord : 0)
          formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
          formData.append('hidden', typeof data.hidden === 'undefined' ? false : !data.hidden)
          if (data.type) {
            formData.append('type', data.type.value)
          }
          if (data.parent) {
            formData.append('parent', data.parent.value)
          }
          if (data.menu) {
            formData.append('menu', data.menu.value)
          }
          formData.append('tenant', localStorage.getItem('tenant'))
          formData.append('image', fileUpload)
          await mutationUpload.mutateAsync(formData)
        } else {
          await mutation.mutateAsync({
            name: formatFormData(data.name),
            slug: formatFormData(data.slug),
            type: data.type && data.type.value,
            ord: data.ord === '' ? 0 : data.ord,
            is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
            hidden: typeof data.hidden === 'undefined' ? false : !data.hidden,
            parent: data.parent && data.parent.value,
            menu: data.menu && data.menu.value,
            tenant: localStorage.getItem('tenant')
          })
        }
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleChangeMenu = (value) => {
    setMenu(value)
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleRemoveImage = () => {
    setFiles([])
    setIcon(null)
  }

  const handleChangeName = (e) => {
    if (e && e.target) {
      setValue('slug', formatSlug(e.target.value))
    }
  }

  return (
    <div className="menu-item-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Name" name="name" required control={control} error={errors.name} onHandleChange={handleChangeName} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Slug" name="slug" required control={control} error={errors.slug} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                options={menuTypeList}
                label="Menu Type"
                name="type"
                required
                control={control}
                error={errors.type}
                // onHandleChange={handleChangeMenu}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                options={menuList}
                label="Platform Menu"
                name="menu"
                required
                control={control}
                error={errors.menu}
                onHandleChange={handleChangeMenu}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                options={menuItemList}
                label="Parent"
                name="parent"
                control={control}
                error={errors.parent}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="number" label="Order" name="ord" control={control} error={errors.ord} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <div className="pb-1">Icon</div>
              {(files && files.length > 0)
                ? <div style={{ position: 'relative', width: '150px', height: '150px', margin: '0 auto' }}>
                    <div style={{ position: 'absolute', top: '-10%', right: '-10%' }}>
                      <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveImage} />
                    </div>
                    {files.map((file, index) => (
                      <img
                        key={index}
                        src={file.preview}
                        style={{ width: '100%', height: '100%' }}
                      />
                    ))}
                  </div>
                : <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and drop some files here, or click to select files</p>
                    </div>
                  </section>
              }
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox name="is_active" control={control} defaultChecked label="Active" />
              <Checkbox name="hidden" control={control} defaultChecked label="Show in list" />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default MenuItemForm
