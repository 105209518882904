import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

const FormDialog = (props) => {
  const { className, isOpen, dividers, title, maxWidth, onClose, onSave, customButton, children, isSave, isDisabled } = props

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isOpen])

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    // setOpen(false)
    onClose(event, reason)
  }

  return (
    <div className={`form-dialog${className === '' ? '' : (' ' + className)}`}>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={maxWidth}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
        PaperProps={{
          sx: {
            maxHeight: '80vh'
          }
        }}
      >
        <DialogTitle id="form-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent dividers={dividers}>
          {children}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={handleClose}>{isSave ? 'Cancel' : 'Close'} </Button>
          {customButton}

          {isSave &&
            <Button variant="contained" color="primary" disabled={isDisabled} onClick={onSave}>Save</Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

FormDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  dividers: PropTypes.bool,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  customButton: PropTypes.any,
  isSave: PropTypes.bool,
  isDisabled: PropTypes.bool
}

FormDialog.defaultProps = {
  className: '',
  isOpen: false,
  dividers: false,
  title: '',
  maxWidth: 'sm',
  customButton: null,
  isSave: true,
  isDisabled: false
}

export default FormDialog
