import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const AlertDialog = (props) => {
  const { className, isOpen, type, title, contentText, onClose, onSave } = props

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isOpen])

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    setOpen(false)
    onClose(event, reason)
  }

  return (
    <div className={`alert-dialog${className === '' ? '' : (' ' + className)}`}>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={handleClose}>Cancel</Button>

          {type === 'delete'
            ? <Button variant="contained" color="error" onClick={onSave}>Delete</Button>
            : <Button variant="contained" color="primary" onClick={onSave}>Save</Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

AlertDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  contentText: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}

AlertDialog.defaultProps = {
  className: '',
  isOpen: false,
  type: '',
  title: '',
  contentText: ''
}

export default AlertDialog
