import React from 'react'
import Layout from 'features/layout/components/Layout'
import TranscodeAdd from '../components/TranscodeAdd'

const TranscodeAddPage = (props) => {
  return (
    <Layout>
      <TranscodeAdd />
    </Layout>
  )
}

export default TranscodeAddPage
