import React, { useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { formatFormData, getData } from 'common/utils'
import { toast } from 'react-toastify'
import parse from 'date-fns/parse'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import Autocomplete from 'components/form/Autocomplete'
import Datepicker from 'components/form/Datepicker'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

const genderList = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
  { value: 3, label: 'Unknown' }
]

const PeopleForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()

  const { data: dataInfo } = useQuery(['getInfoPeople', id], async () => {
    if (id) {
      return await getData('GET', `people/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `people/${id}/`, dataForm)
      } else {
        return await getData('POST', 'people/', dataForm)
      }
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('name', resultData.name)
        setValue('is_active', resultData.is_active)

        const genderFind = genderList.find(item => item.value === resultData.gender)
        setValue('gender', genderFind)

        if (resultData.birthday) {
          const birthday = parse(resultData.birthday, 'dd-MM-yyyy', new Date())
          setValue('birthday', birthday)
        }
      }
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          name: data.name,
          gender: data.gender ? data.gender.value : 1,
          birthday: formatFormData(data.birthday),
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active
        })
      } else {
        await mutation.mutateAsync({
          name: formatFormData(data.name),
          gender: data.gender ? data.gender.value : 1,
          birthday: formatFormData(data.birthday),
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
          tenant: localStorage.getItem('tenant')
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleChangeGender = (value) => {
    // setMenu(value)
  }

  return (
    <div className="people-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={4} className="offset-md-4">
              <TextField label="Name" name="name" required control={control} error={errors.name} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <Autocomplete
                options={genderList}
                label="Gender"
                name="gender"
                control={control}
                error={errors.type}
                onHandleChange={handleChangeGender}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <Datepicker label="Birthday" name="birthday" control={control} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <Checkbox name="is_active" control={control} defaultChecked label="Active" />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default PeopleForm
