import React from 'react'
import Layout from 'features/layout/components/Layout'
import TagTopicForm from '../components/TagTopicForm'

const TagTopicFormPage = (props) => {
  return (
    <Layout>
      <TagTopicForm />
    </Layout>
  )
}

export default TagTopicFormPage
