import React from 'react'
import Layout from 'features/layout/components/Layout'
import Faq from '../components/Faq'

const FaqPage = (props) => {
  return (
    <Layout>
      <Faq />
    </Layout>
  )
}

export default FaqPage
