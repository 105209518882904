import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { TreeSelect } from 'antd'
import 'antd/lib/tree-select/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/empty/style/index.css'
import './styles/treeselect-group.scss'

const TreeSelectGroup = (props) => {
  const { label, name, treeData, control, error, required } = props

  return (
    <div className="treeselect-group">
      <label htmlFor={name} className="form-label mb-1">{label} {required && <span>*</span>}</label>
      <Controller
        name={name}
        control={control}
        // defaultValue={''}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          return (
            <TreeSelect
              style={{ width: '100%' }}
              value={value}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder="Please select"
              treeDefaultExpandAll
              bordered
              size="large"
              status={error ? 'error' : null}
              onSelect={onChange}
            />
          )
        }}
      />

      {error
        ? error.type === 'required'
          ? <div className="text-danger pt-1">
            {label} required
          </div>
          : <div className="text-danger pt-1">
            {error.message}
          </div>
        : <div className="text-danger pt-1"></div>
      }
    </div>
  )
}

TreeSelectGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  treeData: PropTypes.array,
  required: PropTypes.bool,
  control: PropTypes.any,
  error: PropTypes.any
}

TreeSelectGroup.defaultProps = {
  label: '',
  name: '',
  treeData: [],
  required: false
}

export default TreeSelectGroup
