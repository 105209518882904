import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { getData } from 'common/utils'
import { Row, Col } from 'components/ui'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { setErrorHtml } from 'common/slice'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import FormDialog from 'components/form/FormDialog'
import Autocomplete from 'components/form/Autocomplete'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'

const VodPinDialog = (props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { idAddVodPin, isOpen, onClose } = props
  const { handleSubmit, control, setValue, formState: { errors } } = useForm()

  // States
  const [ribbonList, setRibbonList] = useState([])

  // Fetch APIs
  const { data: dataRibbon } = useQuery(['getRibbonsInfo', idAddVodPin, isOpen], async () => {
    if (!!idAddVodPin && isOpen) {
      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/ribbons_info/?limit=50`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    console.log('idAddVodPin', idAddVodPin)
    if (idAddVodPin && dataForm) {
      return await getData('POST', 'vod-entity-pin/', dataForm)
    }
  })

  // Effect
  useEffect(() => {
    if (dataRibbon) {
      const resultData = dataRibbon?.data?.results || []

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setRibbonList(selectData)
      }
    }
  }, [dataRibbon])

  useEffect(() => {
    if (idAddVodPin) {
      setValue('content', idAddVodPin)
    }
  }, [idAddVodPin])

  // Handle Event
  const handleCloseRibbonItem = () => {
    onClose(false)
  }

  const ToastCustomError = () => {
    return (
      <div>
        Something went wrong! <Link to="/error">See more</Link>
      </div>
    )
  }

  const onSubmit = async (data) => {
    try {
      const dataForm = {
        ribbon: data.ribbon.value,
        position: data.position,
        tenant: localStorage.getItem('tenant'),
        is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
        content: idAddVodPin
      }
      await mutation.mutateAsync(dataForm)
      toast.success('Entity Pin successfully')
      queryClient.invalidateQueries('getVodEntities')
      onClose(true)
    } catch (err) {
      if (err.response) {
        dispatch(setErrorHtml(err.response.data))
      }

      toast.error(ToastCustomError)
      console.log(err)
    }
  }

  return (
    <div className='vod-pin-dialog'>
      <FormDialog
        isOpen={isOpen}
        title="Add Entity Pin"
        maxWidth="sm"
        dividers
        onClose={handleCloseRibbonItem}
        onSave={handleSubmit(onSubmit)}
      >
        <Row className='pt-2'>
          <Col md={12}>
            <Autocomplete
              options={ribbonList}
              label="Ribbon"
              name="ribbon"
              required
              control={control}
              error={errors.ribbon}
              // onHandleChange={handleChangeMenu}
            />
          </Col>
        </Row>
        <Row className="pt-2">
            <Col md={12}>
              <TextField label="Position" name="position" type='number' required control={control} error={errors.position}/>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col md={12}>
              <Checkbox label="Active" name="is_active" defaultChecked control={control} />
            </Col>
          </Row>
      </FormDialog>
    </div>
  )
}

VodPinDialog.propTypes = {
  idAddVodPin: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

VodPinDialog.defaultProps = {
  idAddVodPin: '',
  isOpen: false
}

export default VodPinDialog
