import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Col from 'components/ui/Col'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { Row } from 'antd'
import '../styles/share-by-me-filter.scss'

const ShareContentByMeFilter = (props) => {
  const { onClickSearch, searchFilter } = props

  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (searchFilter) {
      setSearchText(searchFilter)
    }
  })

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleClickSearch = () => {
    onClickSearch(searchText)
  }

  const handleClearSearch = () => {
    setSearchText('')
    onClickSearch('')
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onClickSearch(searchText)
    }
  }

  return (
    <div className='share-content-by-me-filter'>
      <Card>
        <CardContent>
          <Row className='share-by-me-filter-row'>
            <Col md={5} className="offset-md-1">
              <TextField
                size="small"
                fullWidth
                label="Search by title"
                variant="standard"
                value={searchText}
                onChange={handleChangeSearch}
                onKeyDown={handleEnter}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchText !== '' &&
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  )
}

ShareContentByMeFilter.propTypes = {
  onClickSearch: PropTypes.func,
  searchFilter: PropTypes.string
}

ShareContentByMeFilter.defaultProps = {
  searchFilter: ''
}

export default ShareContentByMeFilter
