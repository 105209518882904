import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableAntd } from 'antd'
import 'antd/lib/table/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/space/style/index.css'
import './styles/table-epg-timing.scss'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  }
}

const Table = (props) => {
  const { columns, data, scroll, loading, pagination, currentIndex, isSummary, isRowSelection, onChange } = props

  return (
    <div className="table-antd-epg-timing">
      <TableAntd
        rowClassName={(record, index) => index === currentIndex ? 'table-antd-row-active' : ''}
        className={isSummary ? 'table-summary' : ''}
        columns={columns}
        dataSource={data}
        scroll={scroll}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={onChange}
        rowSelection={isRowSelection
          ? {
              type: 'checkbox',
              ...rowSelection
            }
          : null
        }
      />
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  scroll: PropTypes.object,
  loading: PropTypes.bool,
  pagination: PropTypes.bool,
  isSummary: PropTypes.bool,
  isRowSelection: PropTypes.bool,
  onChange: PropTypes.func,
  currentIndex: PropTypes.number
}

Table.defaultProps = {
  columns: [],
  data: [],
  scroll: {
    x: 1000
  },
  // scroll: null,
  loading: false,
  pagination: false,
  isSummary: false,
  isRowSelection: false,
  currentIndex: -1
}

export default Table
