import React from 'react'
import Layout from 'features/layout/components/Layout'
import RibbonDisplayForm from 'features/ribbon_display/components/RibbonDisplayForm'

const RibbonDisplayFormPage = (props) => {
  return (
    <Layout>
        <RibbonDisplayForm />
    </Layout>
  )
}

export default RibbonDisplayFormPage
