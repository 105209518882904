/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import { setErrorHtml } from 'common/slice'
import { Row, Col, Table } from 'components/ui'
import Button from 'components/form/Button'
import AlertDialog from 'components/ui/AlertDialog'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import ButtonMUI from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Pagination from '@mui/material/Pagination'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsIcon from '@mui/icons-material/Settings'
import { Select } from 'antd'
// import Highlighter from 'react-highlight-words'
// import { SearchOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import VodFilter from './VodFilter'
import VodAddToRibbon from './VodAddToRibbon'
import VodPinDialog from './VodPinDialog'
import 'antd/lib/select/style/index.css'
import VodShareContentForm from './VodShareContentForm'
import { HOSTNAME } from 'constant/appConstant'

const { Option } = Select

const Vod = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const queryClient = useQueryClient()
  // const searchInput = useRef()

  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [idDelete, setIdDelete] = useState(null)
  const [idAdd, setIdAdd] = useState(null)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenRibbonItem, setIsOpenRibbonItem] = useState(false)
  const [idAddVodPin, setIdVodPin] = useState('')
  const [isOpenVodPin, setIsOpenVodPin] = useState(false)

  // Filter
  const [typeFilter, setTypeFilter] = useState(null)
  const [categoryFilter, setCategoryFilter] = useState(null)
  const [statusFilter, setStatusFilter] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')

  // Vod Share Content
  const [isOpenShareContentForm, setIsOpenShareContentForm] = useState(false)
  // End Vod Share Content

  // Sort
  const [orderSort, setOrderSort] = useState('-release_date')

  // Menu Anchor
  const [anchorList, setAnchorList] = useState([])
  const [anchorRibbonList, setAnchorRibbonList] = useState([])

  const [groupId, setGroupId] = useState(null)

  // Table Search
  // const [searchText, setSearchText] = useState('')
  // const [searchedColumn, setSearchedColumn] = useState('')

  // Setting
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [anchorRibbonEl, setAnchorRibbonEl] = useState(null)
  const openRibbon = Boolean(anchorRibbonEl)

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery(['getVodEntities', limit, offset, typeFilter, categoryFilter, statusFilter, searchFilter, orderSort, location], async () => {
    let endpointTemp = ''

    if (location.pathname === '/singlemovie') {
      endpointTemp = '&type=1'
    } else if (location.pathname === '/showdrama') {
      endpointTemp = '&type=2'
    } else if (location.pathname === '/channel') {
      endpointTemp = '&type=5'
    } else {
      if (typeFilter) {
        endpointTemp += `&type=${typeFilter.value}`
      }
    }

    if (categoryFilter) {
      endpointTemp += `&category=${categoryFilter.value}`
    }
    if (statusFilter) {
      endpointTemp += `&status=${statusFilter.value}`
    }
    if (searchFilter !== '') {
      endpointTemp += `&q=${searchFilter}`
    }

    if (location.pathname === '/season') {
      const urlString = window.location.href
      const url = new URL(urlString)
      const groupParamUrl = url.searchParams.get('group')

      if (groupParamUrl) {
        return await getData('GET', `vod-entities/${groupParamUrl}/seasons/?limit=${limit}&offset=${offset}${endpointTemp}`)
      } else {
        return []
      }
    } else if (location.pathname === '/episode') {
      const urlString = window.location.href
      const url = new URL(urlString)
      const groupParamUrl = url.searchParams.get('group')

      if (groupParamUrl) {
        return await getData('GET', `vod-entities/${groupParamUrl}/episodes/?limit=${limit}&offset=${offset}${endpointTemp}`)
      } else {
        return []
      }
    } else if (location.pathname === '/trailer') {
      const urlString = window.location.href
      const url = new URL(urlString)
      const groupParamUrl = url.searchParams.get('group')

      if (groupParamUrl) {
        return await getData('GET', `vod-entities/${groupParamUrl}/trailers/?limit=${limit}&offset=${offset}${endpointTemp}`)
      } else {
        return []
      }
    } else {
      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/vod-entities/?order=${orderSort}&limit=${limit}&offset=${offset}${endpointTemp}`)
    }
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `vod-entities/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getVodEntities')
    }
  })

  const mutationPushNotification = useMutation(async (id) => {
    if (id) {
      return await getData('POST', `vod-entities/${id}/push-notification/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getVodEntities')
    }
  })

  useEffect(() => {
    const urlString = window.location.href
    const url = new URL(urlString)
    const groupParamUrl = url.searchParams.get('group')
    setGroupId(groupParamUrl)
  }, [])

  useEffect(() => {
    if (dataList) {
      // if (Array.isArray(dataList.data)) {
      //   const resultData = dataList.data

      //   const dataListParse = resultData.map(item => {
      //     return {
      //       key: item.id,
      //       ...item
      //     }
      //   })

      //   setTotal(resultData.length)
      //   setTotalPage(Math.ceil(resultData.length / limit))
      //   setData(dataListParse)
      // } else {
      //   //
      // }

      const resultData = dataList.data && dataList.data.results

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        setTotal(dataList.data.total)
        setTotalPage(Math.ceil(dataList.data.total / limit))
        setData(dataListParse)
      }
    }
  }, [dataList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  // Filter
  const handleChangeTypeFilter = (value) => {
    setTypeFilter(value)
  }
  const handleChangeCategoryFilter = (value) => {
    setCategoryFilter(value)
  }
  const handleChangeStatusFilter = (value) => {
    setStatusFilter(value)
  }
  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickShowModalRibbonItem = () => {
    setIsOpenRibbonItem(true)
  }

  const handleClickShowModalVodPin = (record) => {
    if (record.had_pin) {
      history.push('/entityvod-pin')
    } else {
      setIsOpenVodPin(true)
    }
  }

  const handleClickListSeason = (id) => {
    history.push(`/season?group=${id}`)
  }

  const handleClickAddSeason = (group) => {
    history.push(`/entityvod/add?type=3&group=${group}`)
  }

  // const handleClickAddSeasonAudio = (group) => {
  //   history.push(`/entityvod/add?type=12&group=${group}`)
  // }

  const handleClickListEpisode = (id) => {
    history.push(`/episode?group=${id}`)
  }

  const handleClickAddEpisode = (group) => {
    history.push(`/entityvod/add?type=4&group=${group}`)
  }

  const handleClickListTrailer = (id) => {
    history.push(`/trailer?group=${id}`)
  }

  const handleClickAddTrailer = (group) => {
    history.push(`/entityvod/add?type=6&group=${group}`)
  }

  const handleClickRecommendation = (group) => {
    history.push(`/recommendation?group=${group}`)
  }

  const handleClickNiceClip = (group) => {
    history.push(`/nice-clip?group=${group}`)
  }

  const handleClickMetadata = (group) => {
    history.push(`/vod-metadata?group=${group}`)
  }

  const handleClickEpg = (group) => {
    history.push(`/epg?group=${group}`)
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)

      setIsOpenDelete(false)
      toast.success('Entity deleted successfully')
    } catch (err) {
      setIsOpenDelete(false)
      if (err.response) {
        dispatch(setErrorHtml(err.response.data))
      }

      toast.error(ToastCustomError)
      console.log(err)
    }
  }

  // Setting
  const handleClickSetting = (event, record) => {
    if (record.type === 2 || record.type === 12) {
      setAnchorList([
        [
          {
            label: 'Add Season',
            onClick: () => {
              handleCloseMenu()
              handleClickAddSeason(record.id)

              // if (record.type === 12) {
              //   handleClickAddSeasonAudio(record.id)
              // } else {
              //   handleClickAddSeason(record.id)
              // }
            }
          },
          {
            label: 'Season List',
            onClick: () => {
              handleCloseMenu()
              handleClickListSeason(record.id)
            }
          }
        ],
        [
          {
            label: 'Add Trailer',
            onClick: () => {
              handleCloseMenu()
              handleClickAddTrailer(record.id)
            }
          },
          {
            label: 'Trailer List',
            onClick: () => {
              handleCloseMenu()
              handleClickListTrailer(record.id)
            }
          }
        ],
        [
          {
            label: 'Recommendation',
            onClick: () => {
              handleCloseMenu()
              handleClickRecommendation(record.id)
            }
          },
          {
            label: 'Nice Clip',
            onClick: () => {
              handleCloseMenu()
              handleClickNiceClip(record.id)
            }
          }
        ],
        [
          {
            label: 'Add To Ribbon',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalRibbonItem()
            }
          },
          {
            label: 'Metadata',
            onClick: () => {
              handleCloseMenu()
              handleClickMetadata(record.id)
            }
          }
        ],
        [
          {
            label: record.had_pin ? 'Pinned Entity' : 'Pin Entity',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalVodPin(record)
            }
          },
          (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING || window.location.hostname === HOSTNAME.DEMOA || window.location.hostname === HOSTNAME.DEMOB) && (
            {
              label: 'Content Sharing',
              onClick: () => {
                handleCloseMenu()
                handleOpenShareContentForm()
              }
            }
          )
        ]
      ])
    } else if (record.type === 3 || record.type === 13) {
      setAnchorList([
        [
          {
            label: 'Add Episode',
            onClick: () => {
              handleCloseMenu()
              handleClickAddEpisode(record.id)
            }
          },
          {
            label: 'Episode List',
            onClick: () => {
              handleCloseMenu()
              handleClickListEpisode(record.id)
            }
          }
        ],
        [
          {
            label: 'Recommendation',
            onClick: () => {
              handleCloseMenu()
              handleClickRecommendation(record.id)
            }
          },
          {
            label: 'Nice Clip',
            onClick: () => {
              handleCloseMenu()
              handleClickNiceClip(record.id)
            }
          }
        ],
        [
          {
            label: 'Add To Ribbon',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalRibbonItem()
            }
          },
          {
            label: 'Metadata',
            onClick: () => {
              handleCloseMenu()
              handleClickMetadata(record.id)
            }
          }
        ],
        [
          {
            label: record.had_pin ? 'Pinned Entity' : 'Pin Entity',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalVodPin(record)
            }
          },
          (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING || window.location.hostname === HOSTNAME.DEMOA || window.location.hostname === HOSTNAME.DEMOB) && (
            {
              label: 'Content Sharing',
              onClick: () => {
                handleCloseMenu()
                handleOpenShareContentForm()
              }
            }
          )
        ]
      ])
    } else if (record.type === 5) {
      setAnchorList([
        [
          {
            label: 'Recommendation',
            onClick: () => {
              handleCloseMenu()
              handleClickRecommendation(record.id)
            }
          },
          {
            label: 'Nice Clip',
            onClick: () => {
              handleCloseMenu()
              handleClickNiceClip(record.id)
            }
          }
        ],
        [
          {
            label: 'Add To Ribbon',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalRibbonItem()
            }
          },
          {
            label: 'Metadata',
            onClick: () => {
              handleCloseMenu()
              handleClickMetadata(record.id)
            }
          }
        ],
        [
          {
            label: 'EPG',
            onClick: () => {
              handleCloseMenu()
              handleClickEpg(record.id)
            }
          },
          {
            label: record.had_pin ? 'Pinned Entity' : 'Pin Entity',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalVodPin(record)
            }
          }
        ],
        [
          (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING || window.location.hostname === HOSTNAME.DEMOA || window.location.hostname === HOSTNAME.DEMOB) && (
            {
              label: 'Content Sharing',
              onClick: () => {
                handleCloseMenu()
                handleOpenShareContentForm()
              }
            }
          )
        ]
      ])
    } else {
      setAnchorList([
        [
          {
            label: 'Recommendation',
            onClick: () => {
              handleCloseMenu()
              handleClickRecommendation(record.id)
            }
          },
          {
            label: 'Nice Clip',
            onClick: () => {
              handleCloseMenu()
              handleClickNiceClip(record.id)
            }
          }
        ],
        [
          {
            label: 'Add To Ribbon',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalRibbonItem()
            }
          },
          {
            label: 'Metadata',
            onClick: () => {
              handleCloseMenu()
              handleClickMetadata(record.id)
            }
          }
        ],
        [
          {
            label: record.had_pin ? 'Pinned Entity' : 'Pin Entity',
            onClick: () => {
              handleCloseMenu()
              handleClickShowModalVodPin(record)
            }
          },
          (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING || window.location.hostname === HOSTNAME.DEMOA || window.location.hostname === HOSTNAME.DEMOB)
            ? (
                {
                  label: 'Content Sharing',
                  onClick: () => {
                    handleCloseMenu()
                    handleOpenShareContentForm()
                  }
                }
              )
            : (
                {
                  label: 'Push Notification',
                  onClick: () => {
                    handleCloseMenu()
                    handleClickPushNotification(record.id)
                  }
                }
              )
        ],
        (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING || window.location.hostname === HOSTNAME.DEMOA || window.location.hostname === HOSTNAME.DEMOB) && [
          {
            label: 'Push Notification',
            onClick: () => {
              handleCloseMenu()
              handleClickPushNotification(record.id)
            }
          }
        ]
      ])
    }

    setIdAdd(record.id)
    setIdVodPin(record.id)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleClickViewRibbon = (event, record) => {
    const ribbonArrTemp = record.ribbons
    if (Array.isArray(ribbonArrTemp)) {
      const dataArrTemp = ribbonArrTemp.map(item => {
        return {
          label: item.name,
          onClick: () => {
            handleCloseViewRibbon()
            history.push(`/ribbon-item/${item.id}`)
          }
        }
      })
      setAnchorRibbonList(dataArrTemp)
    }

    setAnchorRibbonEl(event.currentTarget)
  }

  const handleCloseViewRibbon = () => {
    setAnchorRibbonEl(null)
  }

  const handleCloseAddToRibbon = (value) => {
    setIdAdd(null)
    setIsOpenRibbonItem(value)
  }

  const handleCloseVodPin = (value) => {
    setIdVodPin('')
    setIsOpenVodPin(false)
  }

  const handleClickPushNotification = async (idParam) => {
    try {
      await mutationPushNotification.mutateAsync(idParam)
      toast.success('Successful Push Notification')
    } catch (err) {
      if (err.response) {
        dispatch(setErrorHtml(err.response.data))
      }

      toast.error(ToastCustomError)
      console.log(err)
    }
  }

  const ToastCustomError = () => {
    return (
      <div>
        Something went wrong! <Link to="/error">See more</Link>
      </div>
    )
  }

  const handleChangeTable = (pagination, filters, sorter) => {
    if (sorter) {
      if (sorter.columnKey === 'created_at') {
        if (sorter.order === 'ascend') {
          setOrderSort('created_at')
        } else if (sorter.order === 'descend') {
          setOrderSort('-created_at')
        } else {
          setOrderSort('-created_at')
        }
      }

      if (sorter.columnKey === 'release_date') {
        if (sorter.order === 'ascend') {
          setOrderSort('release_date')
        } else if (sorter.order === 'descend') {
          setOrderSort('-release_date')
        } else {
          setOrderSort('-release_date')
        }
      }
    }
  }

  // Share Content Form
  const handleOpenShareContentForm = () => {
    setIsOpenShareContentForm(true)
  }

  const handleCloseShareContentForm = () => {
    setIsOpenShareContentForm(false)
  }
  // End Share Content Form

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm()
  //   setSearchText(selectedKeys[0])
  //   setSearchedColumn(dataIndex)
  // }

  // const handleReset = (clearFilters, confirm) => {
  //   clearFilters()
  //   setSearchText('')
  //   confirm()
  // }

  // const getColumnSearchProps = dataIndex => ({
  //   // eslint-disable-next-line react/display-name
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <ButtonAntd
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 95 }}
  //         >
  //           Search
  //         </ButtonAntd>
  //         <ButtonAntd onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 95 }}>
  //           Reset
  //         </ButtonAntd>
  //         {/* <ButtonAntd
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({ closeDropdown: false })
  //             setSearchText(selectedKeys[0])
  //             setSearchedColumn(dataIndex)
  //           }}
  //         >
  //           Filter
  //         </ButtonAntd> */}
  //       </Space>
  //     </div>
  //   ),
  //   // eslint-disable-next-line react/display-name
  //   filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
  //       : '',
  //   onFilterDropdownVisibleChange: visible => {
  //     if (visible) {
  //       setTimeout(() => searchInput && searchInput.current && searchInput.current.select(), 100)
  //     }
  //   },
  //   // eslint-disable-next-line react/display-name
  //   render: text => {
  //     return searchedColumn === dataIndex
  //       ? (
  //         <Highlighter
  //           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //           searchWords={[searchText]}
  //           autoEscape
  //           textToHighlight={text ? text.toString() : ''}
  //         />
  //         )
  //       : (
  //           text
  //         )
  //   }
  // })

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '15%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <Link to={`/entityvod/${record.id}`}>
                <IconButton color="success">
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Setting" onClick={e => handleClickSetting(e, record)}>
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Title',
      key: 'title',
      width: '25%',
      // ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{(record.type === 4 || record.type === 6) ? record.episode_name : record.title}</div>
        )
      }
      // sorter: (a, b) => {
      //   return a.title.localeCompare(b.title)
      // },
      // ...getColumnSearchProps('title')
    },
    {
      title: 'VOD Type',
      key: 'type',
      width: '13%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.type === 1
              ? 'SINGLE MOVIE'
              : record.type === 2
                ? 'SHOW/DRAMA'
                : record.type === 3
                  ? 'SEASON'
                  : record.type === 4
                    ? 'EPISODE'
                    : record.type === 5
                      ? 'CHANNEL'
                      : record.type === 6
                        ? 'TRAILER'
                        : record.type === 7
                          ? 'DOCUMENT'
                          : record.type === 8
                            ? 'BREAKING NEWS'
                            : record.type === 9
                              ? 'AUDIO'
                              : record.type === 10
                                ? 'EVENT'
                                : record.type === 11
                                  ? 'VERTICAL VIDEO'
                                  : record.type === 12
                                    ? 'SHOW AUDIO'
                                    : record.type === 15
                                      ? 'NEWS REPORT'
                                      : record.type === 16
                                        ? 'SPONSOR'
                                        : record.type === 17
                                          ? 'MULTI NEWSPAPER'
                                          : record.type === 18
                                            ? 'EMAGAZINE'
                                            : record.type === 19
                                              ? 'INTERNAL NEWS'
                                              : 'Unknown'}
          </div>
        )
      }
      // sorter: (a, b) => {
      //   return a.type - b.type
      // }
    },
    {
      title: 'Status',
      key: 'status',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.status === 1
              ? 'Chờ xử lí'
              : record.status === 2
                ? 'Chờ QC'
                : record.status === 3
                  ? 'Xuất bản'
                  : record.status === 4
                    ? 'Tạm ngưng xuất bản'
                    : record.status === 5
                      ? 'Tái xuất bản'
                      : record.status === 6
                        ? 'Đóng'
                        : record.status === 7
                          ? 'Schedule publish'
                          : 'Draft'}
          </div>
        )
      }
    },
    {
      title: 'Ribbon',
      key: 'ribbon',
      width: '9%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        const ribbonArrTemp = record.ribbons
        if (Array.isArray(ribbonArrTemp)) {
          if (ribbonArrTemp.length > 0) {
            return (
              <ButtonMUI color="inherit" size="small" variant="contained" onClick={e => handleClickViewRibbon(e, record)}>
                View
              </ButtonMUI>
            )
          } else {
            return <div></div>
          }
        } else {
          return <div></div>
        }
      }
    },
    {
      title: 'Content Sharing',
      key: 'ribbon',
      width: '13%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record?.is_shared && record.is_shared) {
          return (
            <Link to={`/share-by-me?q=${record.title}`}>
              <ButtonMUI color="inherit" size="small" variant="contained">
                View
              </ButtonMUI>
            </Link>
          )
        } else {
          return <div></div>
        }
      }
    },
    {
      title: 'Release date',
      dataIndex: 'release_date',
      key: 'release_date',
      width: '14%',
      sorter: true
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '11%'
    }
  ]

  return (
    <div className="vod">
      <VodFilter
        onChangeType={handleChangeTypeFilter}
        onChangeCategory={handleChangeCategoryFilter}
        onChangeStatus={handleChangeStatusFilter}
        onClickSearch={handleClickSearchFilter}
      />

      <Row className="pt-5">
        <Col md={12}>
          <Link to={location.pathname === '/entityvod'
            ? '/entityvod/add?type=1'
            : location.pathname === '/singlemovie'
              ? '/entityvod/add?type=1'
              : location.pathname === '/showdrama'
                ? '/entityvod/add?type=2'
                : location.pathname === '/season'
                  ? `/entityvod/add?type=3&group=${groupId}`
                  : location.pathname === '/episode'
                    ? `/entityvod/add?type=4&group=${groupId}`
                    : location.pathname === '/trailer'
                      ? `/entityvod/add?type=6&group=${groupId}`
                      : '/entityvod/add?type=5'}>
            <Button className="float-right" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            data={data}
            onChange={handleChangeTable}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            width: 320
          }
        }}
      >
        {anchorList && anchorList.map((item, index) => {
          return (
            <Row key={index}>
              {Array.isArray(item) && item.map((itemChild, indexChild) => {
                return (
                  <Col md={6} key={indexChild}>
                    <MenuItem onClick={itemChild.onClick}>{itemChild.label}</MenuItem>
                  </Col>
                )
              })}
            </Row>
          )
        })}
      </Menu>

      <Menu
        anchorEl={anchorRibbonEl}
        open={openRibbon}
        onClose={handleCloseViewRibbon}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {Array.isArray(anchorRibbonList) && anchorRibbonList.map((item, index) => {
          return (
            <Row key={index}>
              <Col md={12}>
                <MenuItem onClick={item.onClick}>{item.label}</MenuItem>
              </Col>
            </Row>
          )
        })}
      </Menu>

      <VodAddToRibbon
        idAdd={idAdd}
        isOpen={isOpenRibbonItem}
        onClose={handleCloseAddToRibbon}
      />

      <VodPinDialog
        idAddVodPin={idAddVodPin}
        isOpen={isOpenVodPin}
        onClose={handleCloseVodPin}
      />

      <VodShareContentForm
        idAdd={idAdd}
        isOpen={isOpenShareContentForm}
        onClose={handleCloseShareContentForm}
      />
    </div>
  )
}

export default Vod
