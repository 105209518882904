import React from 'react'
import Layout from 'features/layout/components/Layout'
import MenuSlide from '../components/MenuSlide'

const MenuSlidePage = (props) => {
  return (
    <Layout>
      <MenuSlide />
    </Layout>
  )
}

export default MenuSlidePage
