import React from 'react'
import Layout from 'features/layout/components/Layout'
import MenuItemForm from '../components/MenuItemForm'

const MenuItemFormPage = (props) => {
  return (
    <Layout>
      <MenuItemForm />
    </Layout>
  )
}

export default MenuItemFormPage
