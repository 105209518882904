import React, { useEffect } from 'react'
import { getLoginData } from 'common/utils'
import { useForm } from 'react-hook-form'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Form from 'components/ui/Form'
import Card from 'components/ui/Card'
import TextField from 'components/form/TextField'
// import CheckboxGroup from 'components/ui/CheckboxGroup'
import Button from 'components/form/Button'
// import iconBlackImg from '../styles/images/icon_black.svg'
import '../styles/login.scss'

const Login = (props) => {
  const { handleSubmit, control, setError, formState: { errors } } = useForm()

  useEffect(() => {
    const urlStr = window.location.href
    const url = new URL(urlStr)
    const tokenParamUrl = url.searchParams.get('token')
    const refreshTokenParamUrl = url.searchParams.get('refresh_token')
    const profileIdParamUrl = url.searchParams.get('profileid')
    const showUserMenuParamUrl = url.searchParams.get('show_user_menu')
    const usernameParamUrl = url.searchParams.get('username')
    const tenantParamUrl = url.searchParams.get('tenant')

    if (tokenParamUrl && usernameParamUrl && tenantParamUrl) {
      localStorage.setItem('token', tokenParamUrl)
      localStorage.setItem('adm_token', refreshTokenParamUrl)
      localStorage.setItem('profileid', profileIdParamUrl)
      localStorage.setItem('show_user_menu', showUserMenuParamUrl)
      localStorage.setItem('username', usernameParamUrl)
      localStorage.setItem('tenant', tenantParamUrl)
      window.location.reload()
    }
  }, [])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    if (data) {
      try {
        const urlStr = window.location.href
        const urlTemp = /:\/\/([^/]+)/.exec(urlStr)[1]
        const urlArr = urlTemp.split('.')

        const result = await getLoginData('POST', 'auth/', {
          username: data.username,
          password: data.password,
          alias: urlArr[0]
        })

        if (result.success) {
          localStorage.setItem('token', result.data && result.data.access)
          localStorage.setItem('adm_token', result.data && result.data.refresh)
          localStorage.setItem('profileid', result.data && result.data.id)
          localStorage.setItem('show_user_menu', result.data && result.data.show_user_menu)
          localStorage.setItem('username', data.username)

          if (result.data && result.data.tenant) {
            localStorage.setItem('tenant', result.data.tenant)
          } else {
            localStorage.setItem('tenant', '')
          }

          // localStorage.setItem('__sessionstate__', data.password) // password
          window.location.reload()
        } else {
          setError('username', {
            type: 'manual',
            message: 'The username or password is incorrect'
          })
        }
      } catch (err) {
        if (err.response) {
          setError('username', {
            type: 'manual',
            message: err.response.data && err.response.data.message ? err.response.data.message : 'The username or password is incorrect'
          })
        }
      }
    }
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)()
    }
  }

  return (
    <div className="login">
      <Row>
        <Col className="offset-md-4 text-center" md={4}>
          {/* <img src={iconBlackImg} width={40} height={40} /> */}
          <span className="brand-name pl-2">ADMON OTT</span>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col className="offset-md-4" md={4}>
          <Card>
            <Form>
              <Row className="pt-5">
                <Col md={12}>
                  <TextField label="Username" name="username" required control={control} error={errors.username} onKeyDown={handleEnter} />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={12}>
                  <TextField type="password" label="Password" name="password" required control={control} error={errors.password} onKeyDown={handleEnter} />
                </Col>
              </Row>
              {/* <Row className="pt-3">
                  <Col md={12}>
                    <CheckboxGroup label="Remember me" />
                  </Col>
                </Row> */}
              <Row className="pt-4 pb-4">
                <Col md={12}>
                  <Button fullWidth onClick={handleSubmit(onSubmit)}>Sign in</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Login
