import React from 'react'
import Layout from 'features/layout/components/Layout'
import Comment from '../components/Comment'

const CommentPage = (props) => {
  return (
    <Layout>
      <Comment />
    </Layout>
  )
}

export default CommentPage
