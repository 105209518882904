import React from 'react'
import Layout from 'features/layout/components/Layout'
import UserPermission from '../components/UserPermission'

const UserPermissionPage = (props) => {
  return (
    <Layout>
      <UserPermission />
    </Layout>
  )
}

export default UserPermissionPage
