import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getCrawlerData } from 'common/utils'
import PropTypes from 'prop-types'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

const CrawlerFilter = (props) => {
  const { onChangeCategory, onClickSearch } = props

  const { data: dataList } = useQuery('getRssList', async () => {
    return await getCrawlerData('GET', 'vtcnews/')
  })

  const [category, setCategory] = useState(null)
  const [categoryList, setCategoryList] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (dataList) {
      const resultList = dataList.results
      if (Array.isArray(resultList)) {
        const dataTemp = resultList.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })
        setCategoryList(dataTemp)
      }
    }
  }, [dataList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeCategory = (e, value) => {
    setCategory(value)
    onChangeCategory(value)
  }

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleClickSearch = () => {
    onClickSearch(searchText)
  }

  const handleClearSearch = () => {
    setSearchText('')
    onClickSearch('')
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onClickSearch(searchText)
    }
  }

  return (
    <div className="vod-filter">
      <Card>
        <CardContent>
          <Row className="pt-2">
            <Col md={4}>
              <Autocomplete
                fullWidth
                size="small"
                value={category}
                onChange={(e, newValue) => handleChangeCategory(e, newValue)}
                options={categoryList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Category" />}
              />
            </Col>
            <Col md={7} className="offset-md-1">
              <TextField
                size="small"
                fullWidth
                label="Search by title"
                variant="standard"
                value={searchText}
                onChange={handleChangeSearch}
                onKeyDown={handleEnter}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchText !== '' &&
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  )
}

CrawlerFilter.propTypes = {
  onChangeCategory: PropTypes.func,
  onClickSearch: PropTypes.func
}

CrawlerFilter.defaultProps = {
}

export default CrawlerFilter
