import React, { useEffect, useState, useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { HOSTNAME } from 'constant/appConstant'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { getData, getUploadData, formatFormData, formatSlug } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TreeSelectGroup from 'components/ui/TreeSelectGroup'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Autocomplete from 'components/form/Autocomplete'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { Button as ButtonAntd } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import '../styles/ribbon-form.scss'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const RibbonForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()
  const { getRootProps: getAppRootProps, getInputProps: getAppInputProps, isFocused: isAppFocused, isDragAccept: isAppDragAccept, isDragReject: isAppDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setAppFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })
  const { getRootProps: getWebRootProps, getInputProps: getWebInputProps, isFocused: isWebFocused, isDragAccept: isWebDragAccept, isDragReject: isWebDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setWebFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })
  const { getRootProps: getCtvRootProps, getInputProps: getCtvInputProps, isFocused: isCtvFocused, isDragAccept: isCtvDragAccept, isDragReject: isCtvDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setCtvFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const [menuItemList, setMenuItemList] = useState([])
  const [ribbonTypeList, setRibbonTypeList] = useState([
    { value: 2, label: 'Thumbnail' },
    { value: 0, label: 'Master Banner' },
    { value: 1, label: 'Poster' },
    { value: 3, label: 'Banner' },
    { value: 4, label: 'List' },
    { value: 5, label: 'Circle' }
  ])
  const [menuList, setMenuList] = useState([])
  const [menuItem, setMenuItem] = useState(null)
  const [appFiles, setAppFiles] = useState([])
  const [webFiles, setWebFiles] = useState([])
  const [ctvFiles, setCtvFiles] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [appImage, setAppImage] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [webImage, setWebImage] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [ctvImage, setCtvImage] = useState(null)

  const termList = [
    { value: 0, label: 'Default term' },
    { value: 1, label: 'Latest term tenant' },
    { value: 2, label: 'Latest term menu' },
    { value: 3, label: 'Statistics' }
  ]

  const { data: dataInfo } = useQuery(['getInfoRibbon', id], async () => {
    if (id) {
      return await getData('GET', `ribbons/${id}/`)
    }
  })

  const { data: dataMenu } = useQuery('getRibbonFormMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataMenuItem } = useQuery(['getRibbonFormMenuItem', menuList], async () => {
    const resultTemp = []

    try {
      if (menuList && menuList.length > 0) {
        for (let i = 0; i < menuList.length; i++) {
          const resultApi = await getData('GET', `menu/${menuList[i] && menuList[i].id}/menu-items/`)
          const resultData = resultApi && resultApi.data
          if (Array.isArray(resultData)) {
            const dataParse = resultData.map((item, index) => {
              // Sub Item
              const childrenArrTemp = []
              const childrenArr = item.sub_menu_items
              if (Array.isArray(childrenArr)) {
                for (let i = 0; i < childrenArr.length; i++) {
                  childrenArrTemp.push({
                    value: childrenArr[i] && childrenArr[i].id,
                    title: childrenArr[i] && childrenArr[i].name,
                    children: []
                  })
                }
              }

              return {
                title: item.name,
                value: item.id,
                children: childrenArrTemp
              }
            })

            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              selectable: false,
              children: dataParse
            })
          } else {
            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              children: []
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    return resultTemp
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `ribbons/${id}/`, dataForm)
      } else {
        return await getData('POST', 'ribbons/', dataForm)
      }
    }
  })

  const mutationUpload = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getUploadData('PUT', `ribbons/${id}/`, dataForm)
      } else {
        return await getUploadData('POST', 'ribbons/', dataForm)
      }
    }
  })

  // Upload
  const styleApp = useMemo(() => ({
    ...baseStyle,
    ...(isAppFocused ? focusedStyle : {}),
    ...(isAppDragAccept ? acceptStyle : {}),
    ...(isAppDragReject ? rejectStyle : {})
  }), [
    isAppFocused,
    isAppDragAccept,
    isAppDragReject
  ])

  const styleWeb = useMemo(() => ({
    ...baseStyle,
    ...(isWebFocused ? focusedStyle : {}),
    ...(isWebDragAccept ? acceptStyle : {}),
    ...(isWebDragReject ? rejectStyle : {})
  }), [
    isWebFocused,
    isWebDragAccept,
    isWebDragReject
  ])

  const styleCtv = useMemo(() => ({
    ...baseStyle,
    ...(isCtvFocused ? focusedStyle : {}),
    ...(isCtvDragAccept ? acceptStyle : {}),
    ...(isCtvDragReject ? rejectStyle : {})
  }), [
    isCtvFocused,
    isCtvDragAccept,
    isCtvDragReject
  ])

  useEffect(() => {
    setValue('ord', 1)

    if (window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) {
      setRibbonTypeList([
        { value: 2, label: 'Thumbnail' },
        { value: 0, label: 'Master Banner' },
        { value: 1, label: 'Poster' },
        { value: 3, label: 'Banner' },
        { value: 4, label: 'List' },
        { value: 5, label: 'Circle' },
        { value: 6, label: 'Pano' },
        { value: 7, label: 'Special News' },
        { value: 8, label: 'Trending' },
        { value: 9, label: 'Poster News' },
        { value: 10, label: 'Audio' },
        { value: 11, label: 'Auto Play' },
        { value: 12, label: 'Album' },
        { value: 13, label: 'Banner Top' }
      ])
    } else {
      setRibbonTypeList([
        { value: 2, label: 'Thumbnail' },
        { value: 0, label: 'Master Banner' },
        { value: 1, label: 'Poster' },
        { value: 3, label: 'Banner' },
        { value: 4, label: 'List' },
        { value: 5, label: 'Circle' }
      ])
    }
  }, [])

  useEffect(() => {
    if (dataInfo && ribbonTypeList && Array.isArray(ribbonTypeList) && menuItemList && Array.isArray(menuItemList)) {
      const resultData = dataInfo.data

      if (resultData) {
        console.log(resultData)
        setValue('name', resultData.name)
        setValue('slug', resultData.slug)
        setValue('ord', resultData.ord)
        setValue('is_active', resultData.is_active)
        setValue('is_autoplay', resultData.is_autoplay)
        setValue('hidden', !resultData.hidden)
        setValue('menu_item', resultData.menu_item)

        const ribbonTypeFind = ribbonTypeList.find(item => item.value === resultData.type)
        setValue('type', ribbonTypeFind)

        const termFind = termList.find(item => item.value === resultData.term)
        setValue('term', termFind)

        // Image
        if (resultData.url_image_app) {
          setAppFiles([
            { preview: resultData.url_image_app }
          ])
        } else {
          setAppFiles([])
        }

        if (resultData.url_image_web) {
          setWebFiles([
            { preview: resultData.url_image_web }
          ])
        } else {
          setWebFiles([])
        }

        if (resultData.url_image_ctv) {
          setCtvFiles([
            { preview: resultData.url_image_ctv }
          ])
        } else {
          setCtvFiles([])
        }

        // Icon
        setAppImage(resultData.image_app)
        setWebImage(resultData.image_web)
        setCtvImage(resultData.image_ctv)
      }
    }
  }, [dataInfo, ribbonTypeList, menuItemList])

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results
      setMenuList(resultData)
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataMenuItem) {
      setMenuItemList(dataMenuItem)
    }
  }, [dataMenuItem])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        if (window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) {
          // App Image
          if (appImage) {
            await mutation.mutateAsync({
              name: formatFormData(data.name),
              slug: formatFormData(data.slug),
              type: data.type && data.type.value,
              term: data.term ? data.term.value : 0,
              hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
              ord: data.ord === '' ? 0 : data.ord,
              is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
              is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
              menu_item: data.menu_item,
              image_app: appImage
            })
          } else {
            if (appFiles.length > 0) {
              const appFileUpload = appFiles[0]

              const formData = new FormData()
              formData.append('name', formatFormData(data.name))
              formData.append('slug', formatFormData(data.slug))
              if (data.type) {
                formData.append('type', data.type.value)
              }
              if (data.term) {
                formData.append('term', data.term.value)
              }
              formData.append('hidden', typeof data.hidden === 'undefined' ? false : !data.hidden)
              formData.append('ord', data.ord ? data.ord : 0)
              formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
              formData.append('is_autoplay', typeof data.is_autoplay === 'undefined' ? true : data.is_autoplay)
              formData.append('menu_item', data.menu_item)
              formData.append('file_img_app', appFileUpload || null)
              await mutationUpload.mutateAsync(formData)
            } else {
              await mutation.mutateAsync({
                name: formatFormData(data.name),
                slug: formatFormData(data.slug),
                type: data.type && data.type.value,
                term: data.term ? data.term.value : 0,
                hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
                ord: data.ord === '' ? 0 : data.ord,
                is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
                is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
                menu_item: data.menu_item,
                image_app: null
              })
            }
          }

          // Web Image
          if (webImage) {
            await mutation.mutateAsync({
              name: formatFormData(data.name),
              slug: formatFormData(data.slug),
              type: data.type && data.type.value,
              term: data.term ? data.term.value : 0,
              hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
              ord: data.ord === '' ? 0 : data.ord,
              is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
              is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
              menu_item: data.menu_item,
              image_web: webImage
            })
          } else {
            if (webFiles.length > 0) {
              const webFileUpload = webFiles[0]

              const formData = new FormData()
              formData.append('name', formatFormData(data.name))
              formData.append('slug', formatFormData(data.slug))
              if (data.type) {
                formData.append('type', data.type.value)
              }
              if (data.term) {
                formData.append('term', data.term.value)
              }
              formData.append('hidden', typeof data.hidden === 'undefined' ? false : !data.hidden)
              formData.append('ord', data.ord ? data.ord : 0)
              formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
              formData.append('is_autoplay', typeof data.is_autoplay === 'undefined' ? true : data.is_autoplay)
              formData.append('menu_item', data.menu_item)
              formData.append('file_img_web', webFileUpload || null)
              await mutationUpload.mutateAsync(formData)
            } else {
              await mutation.mutateAsync({
                name: formatFormData(data.name),
                slug: formatFormData(data.slug),
                type: data.type && data.type.value,
                term: data.term ? data.term.value : 0,
                hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
                ord: data.ord === '' ? 0 : data.ord,
                is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
                is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
                menu_item: data.menu_item,
                image_web: null
              })
            }
          }

          // Ctv Image
          if (ctvImage) {
            await mutation.mutateAsync({
              name: formatFormData(data.name),
              slug: formatFormData(data.slug),
              type: data.type && data.type.value,
              term: data.term ? data.term.value : 0,
              hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
              ord: data.ord === '' ? 0 : data.ord,
              is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
              is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
              menu_item: data.menu_item,
              image_ctv: ctvImage
            })
          } else {
            if (ctvFiles.length > 0) {
              const ctvFileUpload = ctvFiles[0]

              const formData = new FormData()
              formData.append('name', formatFormData(data.name))
              formData.append('slug', formatFormData(data.slug))
              if (data.type) {
                formData.append('type', data.type.value)
              }
              if (data.term) {
                formData.append('term', data.term.value)
              }
              formData.append('hidden', typeof data.hidden === 'undefined' ? false : !data.hidden)
              formData.append('ord', data.ord ? data.ord : 0)
              formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
              formData.append('is_autoplay', typeof data.is_autoplay === 'undefined' ? true : data.is_autoplay)
              formData.append('menu_item', data.menu_item)
              formData.append('file_img_ctv', ctvFileUpload || null)
              await mutationUpload.mutateAsync(formData)
            } else {
              await mutation.mutateAsync({
                name: formatFormData(data.name),
                slug: formatFormData(data.slug),
                type: data.type && data.type.value,
                term: data.term ? data.term.value : 0,
                hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
                ord: data.ord === '' ? 0 : data.ord,
                is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
                is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
                menu_item: data.menu_item,
                image_ctv: null
              })
            }
          }
        } else {
          await mutation.mutateAsync({
            name: formatFormData(data.name),
            slug: formatFormData(data.slug),
            type: data.type && data.type.value,
            term: data.term ? data.term.value : 0,
            hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
            ord: data.ord === '' ? 0 : data.ord,
            is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
            is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
            menu_item: data.menu_item
          })
        }
      } else { // POST
        if (window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) {
          if (appFiles.length > 0 || webFiles.length > 0 || ctvFiles.length > 0) {
            const appFileUpload = appFiles[0]
            const webFileUpload = webFiles[0]
            const ctvFileUpload = ctvFiles[0]

            const formData = new FormData()
            formData.append('name', formatFormData(data.name))
            formData.append('slug', formatFormData(data.slug))
            if (data.type) {
              formData.append('type', data.type.value)
            }
            if (data.term) {
              formData.append('term', data.term.value)
            }
            formData.append('hidden', typeof data.hidden === 'undefined' ? false : !data.hidden)
            formData.append('ord', data.ord ? data.ord : 0)
            formData.append('is_active', typeof data.is_active === 'undefined' ? true : data.is_active)
            formData.append('is_autoplay', typeof data.is_autoplay === 'undefined' ? true : data.is_autoplay)
            formData.append('menu_item', data.menu_item)
            formData.append('tenant', localStorage.getItem('tenant'))
            if (appFileUpload) {
              formData.append('file_img_app', appFileUpload)
            }
            if (webFileUpload) {
              formData.append('file_img_web', webFileUpload)
            }
            if (ctvFileUpload) {
              formData.append('file_img_ctv', ctvFileUpload)
            }
            await mutationUpload.mutateAsync(formData)
          } else {
            await mutation.mutateAsync({
              name: formatFormData(data.name),
              slug: formatFormData(data.slug),
              type: data.type && data.type.value,
              term: data.term ? data.term.value : 0,
              hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
              ord: data.ord === '' ? 0 : data.ord,
              is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
              is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
              menu_item: data.menu_item,
              tenant: localStorage.getItem('tenant')
            })
          }
        } else {
          await mutation.mutateAsync({
            name: formatFormData(data.name),
            slug: formatFormData(data.slug),
            type: data.type && data.type.value,
            term: data.term ? data.term.value : 0,
            hidden: typeof data.hidden === 'undefined' ? true : !data.hidden,
            ord: data.ord === '' ? 0 : data.ord,
            is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
            is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay,
            menu_item: data.menu_item,
            tenant: localStorage.getItem('tenant')
          })
        }
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleChangeRibbonType = (value) => {
    // setMenu(value)
  }

  const handleChangeTerm = (value) => {
    // setMenu(value)
  }

  const handleChangeMenuItem = value => {
    setMenuItem(value)
  }

  const handleChangeName = (e) => {
    if (e && e.target) {
      setValue('slug', formatSlug(e.target.value))
    }
  }

  const handleRemoveAppImage = () => {
    setAppFiles([])
    setAppImage(null)
  }

  const handleRemoveWebImage = () => {
    setWebFiles([])
    setWebImage(null)
  }

  const handleRemoveCtvImage = () => {
    setCtvFiles([])
    setCtvImage(null)
  }

  return (
    <div className="ribbon-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Name" name="name" required control={control} error={errors.name} onHandleChange={handleChangeName} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField label="Slug" name="slug" required control={control} error={errors.slug} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                options={ribbonTypeList}
                label="Ribbon Type"
                name="type"
                required
                control={control}
                error={errors.type}
                onHandleChange={handleChangeRibbonType}
              />
            </Col>
          </Row>
          {(window.location.hostname === 'vtcnow.ott.admon.com.vn' || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING) &&
            <Row className="pt-4">
              <Col md={6} className="offset-md-3">
                <Autocomplete
                  options={termList}
                  label="Term"
                  name="term"
                  control={control}
                  error={errors.term}
                  onHandleChange={handleChangeTerm}
                />
              </Col>
            </Row>
          }
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TreeSelectGroup
                style={{ width: '100%' }}
                control={control}
                error={errors.menu_item}
                required
                label="Menu Item"
                name="menu_item"
                value={menuItem}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={menuItemList}
                placeholder="Please select"
                treeDefaultExpandAll
                bordered
                onChange={handleChangeMenuItem}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <TextField type="number" label="Order" name="ord" control={control} error={errors.ord} />
            </Col>
          </Row>

          {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) &&
            <>
              <Row className="pt-4">
                <Col md={6} className="offset-md-3">
                  <div className="pb-1">App Image</div>
                  {(appFiles && appFiles.length > 0)
                    ? <div style={{ position: 'relative', width: '400px', height: '120px', margin: '0 auto' }}>
                        <div style={{ position: 'absolute', top: '-5%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveAppImage} />
                        </div>
                        {appFiles.map((file, index) => (
                          <img
                            key={index}
                            src={file.preview}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))}
                      </div>
                    : <section>
                        <div {...getAppRootProps({ style: styleApp })}>
                          <input {...getAppInputProps()} />
                          <p>Drag and drop some files here, or click to select files</p>
                        </div>
                      </section>
                  }
                </Col>
              </Row>

              <Row className="pt-4">
                <Col md={6} className="offset-md-3">
                  <div className="pb-1">Website Image</div>
                  {(webFiles && webFiles.length > 0)
                    ? <div style={{ position: 'relative', width: '400px', height: '120px', margin: '0 auto' }}>
                        <div style={{ position: 'absolute', top: '-5%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveWebImage} />
                        </div>
                        {webFiles.map((file, index) => (
                          <img
                            key={index}
                            src={file.preview}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))}
                      </div>
                    : <section>
                        <div {...getWebRootProps({ style: styleWeb })}>
                          <input {...getWebInputProps()} />
                          <p>Drag and drop some files here, or click to select files</p>
                        </div>
                      </section>
                  }
                </Col>
              </Row>

              <Row className="pt-4">
                <Col md={6} className="offset-md-3">
                  <div className="pb-1">CTV Image</div>
                  {(ctvFiles && ctvFiles.length > 0)
                    ? <div style={{ position: 'relative', width: '400px', height: '120px', margin: '0 auto' }}>
                        <div style={{ position: 'absolute', top: '-5%', right: '-10%' }}>
                          <ButtonAntd type="default" shape="circle" icon={<CloseOutlined />} onClick={handleRemoveCtvImage} />
                        </div>
                        {ctvFiles.map((file, index) => (
                          <img
                            key={index}
                            src={file.preview}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ))}
                      </div>
                    : <section>
                        <div {...getCtvRootProps({ style: styleCtv })}>
                          <input {...getCtvInputProps()} />
                          <p>Drag and drop some files here, or click to select files</p>
                        </div>
                      </section>
                  }
                </Col>
              </Row>
            </>
          }

          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox name="is_active" control={control} defaultChecked label="Active" />
              <Checkbox name="hidden" control={control} defaultChecked={false} label="Show in list" />

              {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) &&
                <Checkbox name="is_autoplay" control={control} label="Autoplay" />
              }
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default RibbonForm
