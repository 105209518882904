import React, { useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getData } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

const ConfigForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()

  const { data: dataInfo } = useQuery(['getInfoConfig', id], async () => {
    if (id) {
      return await getData('GET', `config/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `config/${id}/`, dataForm)
      } else {
        return await getData('POST', 'config/', dataForm)
      }
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('title', resultData.title)
        setValue('slug', resultData.slug)
        setValue('value', resultData.value)
        setValue('is_active', resultData.is_active)
      }
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          title: data.title,
          value: data.value,
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active
        })
      } else {
        await mutation.mutateAsync({
          title: data.title,
          slug: data.slug,
          value: data.value,
          is_active: typeof data.is_active === 'undefined' ? true : data.is_active,
          tenant: localStorage.getItem('tenant')
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="menu-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Title" name="title" required control={control} error={errors.title} />
            </Col>
          </Row>

          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Slug" name="slug" required disabled={!!id} control={control} error={errors.slug} />
            </Col>
          </Row>

          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Value" name="value" required control={control} error={errors.value} />
            </Col>
          </Row>

          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox label="Active" name="is_active" defaultChecked control={control} />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default ConfigForm
