import React from 'react'
import Layout from 'features/layout/components/Layout'
import ShareContentWithMe from '../components/ShareContentWithMe'

const ShareContentWithMePage = (props) => {
  return (
    <Layout>
      <ShareContentWithMe />
    </Layout>
  )
}

export default ShareContentWithMePage
