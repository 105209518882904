import React from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { getData } from 'common/utils'
import CoreList from 'features/core/components/CoreList'
import Switch from '@mui/material/Switch'

const VodPin = () => {
  const queryClient = useQueryClient()
  // States

  // Fetch APIs
  const mutation = useMutation(async ({ id, dataForm }) => {
    if (id && dataForm) {
      return await getData('PUT', `vod-entity-pin/${id}/active`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getVodPin')
    }
  })

  // Effects

  // Handle Events
  const handleChangeStatus = async (e, id) => {
    try {
      await mutation.mutateAsync({
        id: id,
        dataForm: {
          is_active: e.target.checked
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Ribbon',
      key: 'ribbon',
      width: '16%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record?.ribbon?.name || ''}</div>
        )
      }
    },
    {
      title: 'Entity',
      key: 'content',
      width: '40%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={`ribbon-child-${index}`}>
            {record?.content?.title || ''}
          </div>
        )
      }
    },
    {
      title: 'Position',
      key: 'position',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <div key={`position-${index}`}>{record.position}</div>
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Switch checked={record.is_active} onChange={e => handleChangeStatus(e, record.id)} />
      }
    },
    {
      title: 'Created By',
      key: 'created_by',
      width: '12%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <div>{record.created_by}</div>
      }
    }
  ]

  return (
    <div className="vod-pin">
      <CoreList
        code="VodPin"
        endpoint="vod-entity-pin"
        url="entityvod-pin"
        columns={columns}
        isAddNew={false}
      />
    </div>
  )
}

export default VodPin
