import React from 'react'
import PropTypes from 'prop-types'
import { formatSlug } from 'common/utils'
import { Row, Col } from 'components/ui'
import { HOSTNAME } from 'constant/appConstant'
import { Editor } from '@tinymce/tinymce-react'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Autocomplete from 'components/form/Autocomplete'
import DateTimePicker from 'components/form/DateTimePicker'
import AutocompleteMultiple from 'components/form/AutocompleteMultiple'

const VodChannelForm = (props) => {
  // eslint-disable-next-line react/prop-types
  const { id, control, errors, setValue, statusList, countryCodeList, longDescription, editorRef } = props

  const handleChangeTitle = (e) => {
    if (e && e.target) {
      if (setValue) {
        setValue('slug', formatSlug(e.target.value))
      }
    }
  }

  return (
    <div className="vod-channel-form">
      <Row className="pt-4">
        <Col md={5}>
          <TextField
            label="Title"
            name="title"
            required
            // inputProps={{
            //   maxLength: window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING ? 60 : 524288
            // }}
            control={control}
            error={errors.title}
            onHandleChange={handleChangeTitle}
          />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="Slug" name="slug" required control={control} error={errors.slug} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Short description" name="short_description" control={control} error={errors.short_description} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="Link play" name="link_play" control={control} error={errors.link_play} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Link play DVR" name="dvr_link_play" control={control} error={errors.dvr_link_play} />
        </Col>
        <Col md={5} className="offset-md-2">
          {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO)
            ? <Autocomplete
                options={statusList}
                label="Status"
                name="status"
                control={control}
                error={errors.status}
                disabled={!id}
              />
            : <Autocomplete
                options={statusList}
                label="Status"
                name="status"
                control={control}
                error={errors.status}
              />
          }
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Views (Real)" name="real_views" control={control} error={errors.real_views} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="Views (Fake)" name="views" control={control} error={errors.views} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Favorites" name="favorites" control={control} error={errors.favorites} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="Order" name="ord" control={control} error={errors.ord} />
        </Col>
      </Row>

      {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) &&
        <Row className="pt-4">
          <Col md={5}>
            <DateTimePicker label="Release Date" name="release_date" control={control} />
          </Col>
          <Col md={5} className="offset-md-2">
            <DateTimePicker label="Unrelease Date" name="unrelease_date" control={control} />
          </Col>
        </Row>
      }

      {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) &&
        <Row className="pt-4">
          <Col md={5}>
            <DateTimePicker label="Schedule publish" name="schedule_date" control={control} />
          </Col>
          <Col md={5} className="offset-md-2">
            <TextField label="Reference Link" name="ref_link" control={control} error={errors.ref_link} />
          </Col>
        </Row>
      }

      {(window.location.hostname === HOSTNAME.BRT || window.location.hostname === HOSTNAME.SDTV) &&
        <Row className="pt-4">
          <Col md={5}>
            <TextField type="number" label="Duration in minutes" name="duration_minute" control={control} error={errors.duration_minute} />
          </Col>
          <Col md={5} className="offset-md-2">
            <TextField type="number" label="Duration in seconds" name="duration_second" control={control} error={errors.duration_second} />
          </Col>
        </Row>
      }

      <Row className="pt-4">
        <Col md={12}>
          <Checkbox name="released" className="pt-4 d-inline-block" control={control} label="Released State" />
          <Checkbox name="is_dvr" className="pt-4 ml-4 d-inline-block" control={control} label="Is DVR" />
          <Checkbox name="geo_check" className="pt-4 ml-4 d-inline-block" control={control} label="GEOIP Check" />
          <Checkbox name="is_samsung_preview" className="pt-4 ml-4 d-inline-block" control={control} label="Samsung Preview" />
        </Col>
      </Row>

      {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.LOCALHOST) &&
        <Row className='pt-5'>
          <Col md={12}>
            <div className="description-title">GEO Blocking</div>

            <Row className='pt-2'>
              <Col md={12}>
                <AutocompleteMultiple
                  options={countryCodeList}
                  label="Blacklist"
                  name="iso_code_blacklist"
                  control={control}
                  error={errors.iso_code_blacklist}
                />
              </Col>
            </Row>

            <Row className='pt-4'>
              <Col md={12}>
                <AutocompleteMultiple
                  options={countryCodeList}
                  label="Whitelist"
                  name="iso_code_whitelist"
                  control={control}
                  error={errors.iso_code_whitelist}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }

      <Row className="pt-4">
        <Col md={12}>
          {/* <TextField type="textarea" label="Info" name="long_description" control={control} error={errors.long_description} /> */}
          <div className="description-title">Description</div>
          <Editor
            apiKey='8xwdiofw91cl5ikp80dpkij70sj7u8uziroarh8v1420aq6y'
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            // eslint-disable-next-line no-return-assign
            onInit={(evt, editor) => {
              if (editorRef) {
                editorRef.current = editor
              }
              // setIsLoading(false)
            }}
            initialValue={longDescription}
            init={{
              height: 350,
              branding: false,
              plugins: 'advlist autolink lists link charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime table code help wordcount',
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

VodChannelForm.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  statusList: PropTypes.array,
  longDescription: PropTypes.string,
  editorRef: PropTypes.any
}

VodChannelForm.defaultProps = {
  statusList: [],
  status: null,
  longDescription: ''
}

export default VodChannelForm
