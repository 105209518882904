import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodForm from '../components/VodForm'

const VodFormPage = (props) => {
  return (
    <Layout>
      <VodForm />
    </Layout>
  )
}

export default VodFormPage
