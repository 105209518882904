import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { getData, formatFormData, formatFormDateTime, callUploadApi } from 'common/utils'
import { toast } from 'react-toastify'
import { setImageList, setNewsReports } from 'features/vod/slices/vodSlice'
import { Tabs } from 'antd'
import { HOSTNAME } from 'constant/appConstant'
import parse from 'date-fns/parse'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Autocomplete from 'components/form/Autocomplete'
import Button from 'components/form/Button'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import VodSingleMovieForm from './VodSingleMovieForm'
import VodMp3 from './VodMp3'
import VodShowDramaForm from './VodShowDramaForm'
import VodSeasonForm from './VodSeasonForm'
import VodEpisodeForm from './VodEpisodeForm'
import VodChannelForm from './VodChannelForm'
import VodTrailerForm from './VodTrailerForm'
import VodEventForm from './VodEventForm'
import VodImage from './VodImage'
import VodImageRecommend from './VodImageRecommend'
import VodNewsReports from './VodNewsReports'
import VodViewShowDrama from './VodViewShowDrama'
import VodViewSeason from './VodViewSeason'
import Video from 'components/ui/Video'
import '../styles/vod-form.scss'
import 'antd/lib/tabs/style/index.css'
import imgRadioAnimation from '../styles/images/radio_animation.gif'

const { TabPane } = Tabs

const VodForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const playerRef = useRef(null)
  const editorRef = useRef(null)
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { handleSubmit, control, setValue, getValues, formState: { errors }, setError } = useForm()
  const imageList = useSelector((state) => state.vod.imageList)
  const newsReports = useSelector((state) => state.vod.newsReports)

  const [category, setCategory] = useState(null)
  const [type, setType] = useState({ value: 1, label: 'Single Movie' })
  const [peopleTypeList, setPeopleTypeList] = useState([])
  const [peopleList, setPeopleList] = useState([])
  const [tagTopicList, setTagTopicList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [hashtags, setHashTags] = useState('')
  const [searchTagTopic, setSearchTagTopic] = useState(null)

  const [sourceId, setSourceId] = useState(null)

  const countryCodeList = [
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'AndorrA', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, The Democratic Republic of the', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Cote D\'Ivoire', value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { label: 'Holy See (Vatican City State)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran, Islamic Republic Of', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
    { label: 'Korea, Republic of', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: 'Lao People\'S Democratic Republic', value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia, Federated States of', value: 'FM' },
    { label: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestinian Territory, Occupied', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'RWANDA', value: 'RW' },
    { label: 'Saint Helena', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia and Montenegro', value: 'CS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands, British', value: 'VG' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
  ]

  // Check param url
  const [typeParam, setTypeParam] = useState(null)
  const [groupParam, setGroupParam] = useState(null)
  const [checkType, setCheckType] = useState(null)

  const [mp3Files, setMp3Files] = useState([])

  // Test Play
  const [linkPlay, setLinkPlay] = useState(null)
  const [src, setSrc] = useState(null)
  const [videoOptions, setVideoOptions] = useState({
    autoplay: true,
    controls: true
  })
  const [isOpenTestPlayVideo, setIsOpenTestPlayVideo] = useState(false)

  // Long Description
  const [longDescription, setLongDescription] = useState(null)
  const [showViewLongDescription, setShowViewLongDescription] = useState(null)
  const [seasonViewLongDescription, setSeasonViewLongDescription] = useState(null)

  // Catchup
  const [catchupLinkPlay, setCatchupLinkPlay] = useState(null)

  const { data: dataInfo } = useQuery(['getInfoVod', id], async () => {
    if (id) {
      return await getData('GET', `vod-entities/${id}/`)
    }
  })

  const { data: dataTestLinkPlay } = useQuery(['getTestLinkPlay', id], async () => {
    if (id) {
      return await getData('GET', `vod-entities/${id}/test-link-play/`)
    }
  })

  const { data: dataPeopleTypes } = useQuery('getVodPeopleTypes', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/people-types`)
  })

  const { data: dataPeople } = useQuery('getVodPeople', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/active-people`)
  })

  // Tag Topic
  const { data: dataTagTopic } = useQuery(['getVODTagTopic', searchTagTopic], async () => {
    if (window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.TERA_STAGING) {
      let arrTemp = []
      // const searchTagTopicTemp = (!searchTagTopic || searchTagTopic === '') ? (id ? '' : null) : searchTagTopic
      const searchTagTopicTemp = (!searchTagTopic || searchTagTopic === '') ? null : searchTagTopic
      for (let i = 0; i < 1; i++) {
        const resultDataTemp = await getData('GET', `tenants/${localStorage.getItem('tenant')}/tags?limit=100&offset=${i * 100}&title=${searchTagTopicTemp}`)
        const dataResultTemp = resultDataTemp.data && resultDataTemp.data.results
        arrTemp = arrTemp.concat(dataResultTemp)
      }

      // for (let i = 0; i < 17; i++) {
      //   const resultDataTemp = await getData('GET', `tenants/${localStorage.getItem('tenant')}/tags?limit=100&offset=${i * 100}`)
      //   const dataResultTemp = resultDataTemp.data && resultDataTemp.data.results
      //   arrTemp = arrTemp.concat(dataResultTemp)
      // }

      // const resultDataTemp = await getData('GET', `tenants/${localStorage.getItem('tenant')}/tags?limit=100`)
      // const dataResultTemp = resultDataTemp.data && resultDataTemp.data.results
      // console.log(dataResultTemp)

      // return await getData('GET', `tenants/${localStorage.getItem('tenant')}/tags?limit=100&offset=0&title=${searchTagTopic}`)
      return arrTemp
    } else {
      return []
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `vod-entities/${id}/`, dataForm)
      } else {
        return await getData('POST', 'vod-entities/', dataForm)
      }
    }
  }, {
    onSuccess: (dataSuccess) => {
      queryClient.invalidateQueries('getInfoVod')

      if (!id) {
        if ((type && type.value === 17) || (type && type.value === 18) || (type && type.value === 19)) {
          if (Array.isArray(mp3Files) && mp3Files.length > 0) {
            const dataTemp = dataSuccess?.data
            if (dataTemp) {
              const fileUpload = mp3Files[0]

              // Upload to server
              const formData = new FormData()
              formData.append('entity', dataTemp.id)
              formData.append('tenant', localStorage.getItem('tenant'))
              formData.append('title', getValues('img_name'))
              formData.append('file', fileUpload)
              callUploadApi('POST', 'entity_voice/', formData)
                .then(res => {
                // console.log(res)
                })
                .catch(err => {
                  console.log(err)
                })
            }
          }
        }
      }
    }
  })

  const [categoryList, setCategoryList] = useState([])

  // const typeList = [
  //   { value: 1, label: 'Single Movie' },
  //   { value: 2, label: 'Show/Drama' },
  //   // { value: 3, label: 'Season' },
  //   // { value: 4, label: 'Episode' },
  //   { value: 5, label: 'Channel' }
  //   // { value: 6, label: 'Trailer' }
  // ]

  const statusList = [
    { value: 1, label: 'Chờ xử lí' },
    { value: 2, label: 'Chờ QC' },
    { value: 3, label: 'Xuất bản' },
    { value: 4, label: 'Tạm ngưng xuất bản' },
    { value: 5, label: 'Tái xuất bản' },
    { value: 6, label: 'Đóng' },
    { value: 7, label: 'Schedule publish' },
    { value: 8, label: 'Draft' }
  ]

  useEffect(() => {
    if (window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) {
      setCategoryList([
        { value: 6, label: 'Tin tức' },
        { value: 1, label: 'Phim' },
        { value: 2, label: 'Show' }
      ])
    } else {
      setCategoryList([
        { value: 1, label: 'Phim' },
        { value: 2, label: 'Show' },
        { value: 3, label: 'Ca cổ' },
        { value: 4, label: 'Cổ tích' },
        { value: 5, label: 'Cải lương' },
        { value: 6, label: 'Tin tức' }
      ])
    }

    const urlString = window.location.href
    const url = new URL(urlString)
    const typeParamUrl = url.searchParams.get('type')
    const groupParamUrl = url.searchParams.get('group')
    // const seasonParamUrl = url.searchParams.get('season')

    if (!id) {
      setGroupParam(groupParamUrl)
      // setSeasonParam(seasonParamUrl)

      if (typeParamUrl === '13' || typeParamUrl === '14') {
        setTypeList([
          { value: 13, label: 'Season Audio' },
          { value: 4, label: 'Episode' }
        ])
      } else if (typeParamUrl === '3' || typeParamUrl === '4') {
        setTypeList([
          { value: 3, label: 'Season' },
          { value: 4, label: 'Episode' }
        ])
      } else if (typeParamUrl === '6') {
        setTypeList([
          { value: 6, label: 'Trailer' }
        ])
      } else {
        if (window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING) {
          setTypeList([
            { value: 1, label: 'Single Movie' },
            { value: 2, label: 'Show/Drama' },
            { value: 5, label: 'Channel' },
            { value: 8, label: 'Breaking News' },
            { value: 9, label: 'Audio' },
            { value: 10, label: 'Event' },
            { value: 11, label: 'Vertical Video' },
            { value: 12, label: 'Show Audio' },
            // { value: 13, label: 'Season Audio' }
            { value: 15, label: 'News Reports' },
            { value: 16, label: 'Sponsor' },
            { value: 17, label: 'MULTI NEWSPAPER' },
            { value: 18, label: 'EMAGAZINE' },
            { value: 19, label: 'INTERNAL NEWS' }
          ])
        } else if (window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING) {
          setTypeList([
            { value: 1, label: 'Single Movie' },
            { value: 2, label: 'Show/Drama' },
            { value: 5, label: 'Channel' },
            { value: 7, label: 'Document' },
            { value: 9, label: 'Audio' },
            { value: 11, label: 'Vertical Video' }
          ])
        } else {
          setTypeList([
            { value: 1, label: 'Single Movie' },
            { value: 2, label: 'Show/Drama' },
            { value: 5, label: 'Channel' },
            { value: 10, label: 'Event' },
            { value: 15, label: 'News Reports' },
            { value: 16, label: 'Sponsor' }
          ])
        }
      }

      if (typeParamUrl) {
        const typeParse = parseInt(typeParamUrl)
        setTypeParam(typeParse)
      }
    }

    // Default Select
    setValue('status', { value: 1, label: 'Chờ xử lí' })

    setValue('category', { value: 1, label: 'Phim' })
    setCategory({ value: 1, label: 'Phim' })
  }, [])

  useEffect(() => {
    if (typeList) {
      const urlString = window.location.href
      const url = new URL(urlString)
      const typeParamUrl = url.searchParams.get('type')

      if (location.pathname === '/entityvod/add') {
        dispatch(setImageList([]))
        dispatch(setNewsReports([]))
      }

      if (typeParamUrl) {
        const typeParse = parseInt(typeParamUrl)

        const typeFind = typeList.find(item => item.value === typeParse)
        if (typeFind) {
          setValue('type', typeFind)
          setType(typeFind)
        }
      }
    }
  }, [typeList])

  useEffect(() => {
    if (dataPeopleTypes) {
      const resultData = dataPeopleTypes.data
      setPeopleTypeList(resultData)
    }
  }, [dataPeopleTypes])

  useEffect(() => {
    if (dataPeople) {
      const resultData = dataPeople.data

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setPeopleList(selectData)
      }
    }
  }, [dataPeople])

  useEffect(() => {
    // if (dataTagTopic) {
    //   const resultData = dataTagTopic.data && dataTagTopic.data.results

    //   if (Array.isArray(resultData)) {
    //     const selectData = resultData.map(item => {
    //       return {
    //         value: item.id,
    //         label: item.title
    //       }
    //     })
    //     setTagTopicList(selectData)
    //   }
    // }

    if (Array.isArray(dataTagTopic) && dataTagTopic.length > 0) {
      const selectData = dataTagTopic.map((item, index) => {
        return {
          value: item.id,
          label: (index + 1) + '. ' + item.title
        }
      })
      setTagTopicList(selectData)
    } else {
      setTagTopicList([])
    }
  }, [dataTagTopic])

  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        // check type
        setCheckType(resultData.type)

        if (resultData.type === 3 || resultData.type === 4) {
          setTypeList([
            { value: 3, label: 'Season' },
            { value: 4, label: 'Episode' }
          ])
        } else if (resultData.type === 6) {
          setTypeList([
            { value: 6, label: 'Trailer' }
          ])
        } else {
          if (window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING) {
            setTypeList([
              { value: 1, label: 'Single Movie' },
              { value: 2, label: 'Show/Drama' },
              { value: 5, label: 'Channel' },
              { value: 8, label: 'Breaking News' },
              { value: 9, label: 'Audio' },
              { value: 10, label: 'Event' },
              { value: 11, label: 'Vertical Video' },
              { value: 12, label: 'Show Audio' },
              // { value: 13, label: 'Season Audio' }
              { value: 15, label: 'News Reports' },
              { value: 16, label: 'Sponsor' },
              { value: 17, label: 'MULTI NEWSPAPER' },
              { value: 18, label: 'EMAGAZINE' },
              { value: 19, label: 'INTERNAL NEWS' }
            ])
          } else if (window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING) {
            setTypeList([
              { value: 1, label: 'Single Movie' },
              { value: 2, label: 'Show/Drama' },
              { value: 5, label: 'Channel' },
              { value: 7, label: 'Document' },
              { value: 9, label: 'Audio' },
              { value: 11, label: 'Vertical Video' }
            ])
          } else {
            setTypeList([
              { value: 1, label: 'Single Movie' },
              { value: 2, label: 'Show/Drama' },
              { value: 5, label: 'Channel' },
              { value: 10, label: 'Event' },
              { value: 15, label: 'News Reports' },
              { value: 16, label: 'Sponsor' }
            ])
          }
        }

        // Long description
        setLongDescription(resultData.long_description)

        // Trường hợp cập nhật season, lưu group id
        setGroupParam(resultData.group)
        // setSeasonParam(resultData.season)

        setValue('rating', resultData.rating)
        setValue('title', resultData.title)
        setValue('known_as', resultData.known_as)
        setValue('slug', resultData.slug)
        setValue('short_description', resultData.short_description)
        setValue('release_year', resultData.release_year)
        setValue('link_play', resultData.link_play)
        setValue('source_id', resultData.source_id)
        setSourceId(resultData.source_id)
        setValue('dvr_link_play', resultData.dvr_link_play)
        setValue('real_views', resultData.real_views)
        setValue('views', resultData.views)
        setValue('favorites', resultData.favorites)
        setValue('long_description', resultData.long_description)
        setValue('total_seasons', resultData.total_seasons)
        setValue('resolution', resultData.resolution)
        setValue('season', resultData.season)
        setValue('season_name', resultData.season_name)
        setValue('intro_time', resultData.intro_time)
        setValue('ref_link', resultData.ref_link)
        setValue('released', resultData.released)
        setValue('is_single_season', resultData.is_single_season)
        setValue('is_dvr', resultData.is_dvr)
        setValue('is_vip', resultData.is_vip)
        setValue('is_drm', resultData.is_drm)
        setValue('is_live', resultData.is_live)
        setValue('is_autoplay', resultData.is_autoplay)
        setValue('geo_check', resultData.geo_check)
        setValue('is_samsung_preview', resultData.is_samsung_preview)
        setValue('episode', resultData.episode)
        setValue('episode_name', resultData.episode_name)
        setValue('runtime', resultData.runtime)

        setValue('duration_minute', resultData.duration_minute)
        setValue('duration_second', resultData.duration_second)
        setValue('ord', resultData.ord ? resultData.ord : 0)
        if (resultData.hashtags) {
          setValue('hashtags', resultData.hashtags)
          setHashTags(resultData.hashtags)
        }

        if (resultData.schedule_date) {
          const scheduleDate = parse(resultData.schedule_date, 'dd-MM-yyyy HH:m:s', new Date())
          setValue('schedule_date', scheduleDate)
        }

        if (resultData.event_start_time) {
          const eventStartTimeDate = parse(resultData.event_start_time, 'dd-MM-yyyy HH:m:s', new Date())
          setValue('event_start_time', eventStartTimeDate)
        }
        if (resultData.event_end_time) {
          const eventEndTimeDate = parse(resultData.event_end_time, 'dd-MM-yyyy HH:m:s', new Date())
          setValue('event_end_time', eventEndTimeDate)
        }

        setValue('event_countdown_before', resultData.event_countdown_before)

        if (categoryList) {
          const categoryFind = categoryList.find(item => item.value === resultData.category)
          setValue('category', categoryFind)
          setCategory(categoryFind)
        }

        // const typeFind = typeList.find(item => item.value === resultData.type)
        // setValue('type', typeFind)
        // setType(typeFind)

        const statusFind = statusList.find(item => item.value === resultData.status)
        setValue('status', statusFind)
        // setStatus(statusFind)

        if (resultData.release_date) {
          const releaseDate = parse(resultData.release_date, 'dd-MM-yyyy HH:m:s', new Date())
          setValue('release_date', releaseDate)
        }

        if (resultData.unrelease_date) {
          const unReleaseDate = parse(resultData.unrelease_date, 'dd-MM-yyyy HH:m:s', new Date())
          setValue('unrelease_date', unReleaseDate)
        }

        const partners = resultData?.partners || []
        if (partners.length > 0) {
          const partnerList = partners.map((item, index) => {
            return {
              value: item?.id || '',
              label: item?.name || ''
            }
          })
          setValue('partners', partnerList)
        }

        const imageArr = resultData.images
        if (Array.isArray(imageArr)) {
          if (imageArr.length > 0) {
            const imageData = imageArr.map((item, index) => {
              const imageObj = item.image
              return {
                key: index,
                id: imageObj && imageObj.id,
                image_type: imageObj && imageObj.type && imageObj.type.name,
                preview: imageObj.url
              }
            })
            dispatch(setImageList(imageData))
          } else {
            dispatch(setImageList([]))
          }
        }
        const newsReportsArr = resultData.news_reports
        if (Array.isArray(newsReportsArr)) {
          if (newsReportsArr.length > 0) {
            const newsReportData = newsReportsArr.map((item, index) => {
              return {
                key: index,
                id: item.id,
                title: item.title,
                sub_title: item.sub_title,
                description: item.description,
                image_url: item.image_url,
                images: item.images
              }
            })
            dispatch(setNewsReports(newsReportData))
          } else {
            dispatch(setNewsReports([]))
          }
        }

        if (peopleList && peopleTypeList) {
          const peopleArr = resultData.people
          if (Array.isArray(peopleArr)) {
            for (let i = 0; i < peopleTypeList.length; i++) {
              const peopleTypeId = peopleTypeList[i] && peopleTypeList[i].id
              const arrTemp = []
              for (let j = 0; j < peopleArr.length; j++) {
                const peopleItem = peopleArr[j] && peopleArr[j].people
                const peopleTypeItem = peopleArr[j] && peopleArr[j].type
                const peopleTypeItemId = peopleTypeItem && peopleTypeItem.id

                if (peopleTypeId === peopleTypeItemId) {
                  const peopleObj = {
                    value: peopleItem.id,
                    label: peopleItem.name
                  }
                  arrTemp.push(peopleObj)
                }
              }

              if (arrTemp.length > 0) {
                setValue(`people_types_${i}`, arrTemp)
              }
            }
          }
        }
      }

      // Geo Block
      const codeBlacklistStr = resultData.iso_code_blacklist
      if (typeof codeBlacklistStr === 'string') {
        const codeBlacklistArrTemp = codeBlacklistStr.split(',')
        if (Array.isArray(codeBlacklistArrTemp)) {
          const codeBlacklistFilterArr = countryCodeList.filter(xItem => codeBlacklistArrTemp.includes(xItem?.value))
          setValue('iso_code_blacklist', codeBlacklistFilterArr)
        }
      }

      const codeWhitelistStr = resultData.iso_code_whitelist
      if (typeof codeWhitelistStr === 'string') {
        const codeWhitelistArrTemp = codeWhitelistStr.split(',')
        if (Array.isArray(codeWhitelistArrTemp)) {
          const codeWhitelistFilterArr = countryCodeList.filter(xItem => codeWhitelistArrTemp.includes(xItem?.value))
          setValue('iso_code_whitelist', codeWhitelistFilterArr)
        }
      }

      /// /// /// /// /// /// ///
      /// PARENT EPISODE INFO
      /// /// /// /// /// /// ///
      const parentEpisodeView = resultData.parent_episode
      if (parentEpisodeView) {
        const showView = parentEpisodeView.show
        if (showView) {
          setValue('show_view_rating', showView.rating)
          setValue('show_view_title', showView.title)
          setValue('show_view_known_as', showView.known_as)
          setValue('show_view_slug', showView.slug)
          setValue('show_view_short_description', showView.short_description)
          setValue('show_view_release_year', showView.release_year)
          setValue('show_view_runtime', showView.runtime)
          setValue('show_view_resolution', showView.resolution)
          setValue('show_view_total_seasons', showView.total_seasons)
          setValue('show_view_real_views', showView.real_views)
          setValue('show_view_views', showView.views)
          setValue('show_view_favorites', showView.favorites)
          setValue('show_view_intro_time', showView.intro_time)
          setValue('show_view_is_single_season', showView.is_single_season)
          setValue('show_view_released', showView.released)
          setValue('show_view_is_vip', showView.is_vip)
          setValue('show_view_is_drm', showView.is_drm)
          setValue('show_view_is_samsung_preview', showView.is_samsung_preview)
          setValue('show_view_is_samsung_preview', showView.is_samsung_preview)
          setShowViewLongDescription(showView.long_description)

          if (showView.release_date) {
            const showViewReleaseDate = parse(showView.release_date, 'dd-MM-yyyy HH:m:s', new Date())
            setValue('show_view_release_date', showViewReleaseDate)
          }
          if (showView.unrelease_date) {
            const showViewUnReleaseDate = parse(showView.unrelease_date, 'dd-MM-yyyy HH:m:s', new Date())
            setValue('show_view_unrelease_date', showViewUnReleaseDate)
          }
          if (showView.schedule_date) {
            const showViewScheduleDate = parse(showView.schedule_date, 'dd-MM-yyyy HH:m:s', new Date())
            setValue('show_view_schedule_date', showViewScheduleDate)
          }

          const showViewStatusFind = statusList.find(item => item.value === showView.status)
          setValue('show_view_status', showViewStatusFind)

          if (peopleList && peopleTypeList) {
            const peopleArr = showView.people
            if (Array.isArray(peopleArr)) {
              for (let i = 0; i < peopleTypeList.length; i++) {
                const peopleTypeId = peopleTypeList[i] && peopleTypeList[i].id
                const arrTemp = []
                for (let j = 0; j < peopleArr.length; j++) {
                  const peopleItem = peopleArr[j] && peopleArr[j].people
                  const peopleTypeItem = peopleArr[j] && peopleArr[j].type
                  const peopleTypeItemId = peopleTypeItem && peopleTypeItem.id

                  if (peopleTypeId === peopleTypeItemId) {
                    const peopleObj = {
                      value: peopleItem.id,
                      label: peopleItem.name
                    }
                    arrTemp.push(peopleObj)
                  }
                }

                if (arrTemp.length > 0) {
                  setValue(`show_view_people_types_${i}`, arrTemp)
                }
              }
            }
          }
        }

        const seasonView = parentEpisodeView.season
        if (seasonView) {
          setValue('season_view_rating', seasonView.rating)
          setValue('season_view_title', seasonView.title)
          setValue('season_view_known_as', seasonView.known_as)
          setValue('season_view_slug', seasonView.slug)
          setValue('season_view_short_description', seasonView.short_description)
          setValue('season_view_release_year', seasonView.release_year)
          setValue('season_view_runtime', seasonView.runtime)
          setValue('season_view_resolution', seasonView.resolution)
          setValue('season_view_total_seasons', seasonView.total_seasons)
          setValue('season_view_real_views', seasonView.real_views)
          setValue('season_view_views', seasonView.views)
          setValue('season_view_favorites', seasonView.favorites)
          setValue('season_view_intro_time', seasonView.intro_time)
          setValue('season_view_season', seasonView.season)
          setValue('season_view_season_name', seasonView.season_name)
          setValue('season_view_is_single_season', seasonView.is_single_season)
          setValue('season_view_released', seasonView.released)
          setValue('season_view_is_vip', seasonView.is_vip)
          setValue('season_view_is_drm', seasonView.is_drm)
          setValue('season_view_is_samsung_preview', seasonView.is_samsung_preview)
          setValue('season_view_is_samsung_preview', seasonView.is_samsung_preview)
          setSeasonViewLongDescription(seasonView.long_description)

          if (seasonView.release_date) {
            const seasonViewReleaseDate = parse(seasonView.release_date, 'dd-MM-yyyy HH:m:s', new Date())
            setValue('season_view_release_date', seasonViewReleaseDate)
          }
          if (seasonView.unrelease_date) {
            const seasonViewUnReleaseDate = parse(seasonView.unrelease_date, 'dd-MM-yyyy HH:m:s', new Date())
            setValue('season_view_unrelease_date', seasonViewUnReleaseDate)
          }
          if (seasonView.schedule_date) {
            const seasonViewScheduleDate = parse(seasonView.schedule_date, 'dd-MM-yyyy HH:m:s', new Date())
            setValue('season_view_schedule_date', seasonViewScheduleDate)
          }

          const seasonViewStatusFind = statusList.find(item => item.value === seasonView.status)
          setValue('season_view_status', seasonViewStatusFind)

          if (peopleList && peopleTypeList) {
            const peopleArr = seasonView.people
            if (Array.isArray(peopleArr)) {
              for (let i = 0; i < peopleTypeList.length; i++) {
                const peopleTypeId = peopleTypeList[i] && peopleTypeList[i].id
                const arrTemp = []
                for (let j = 0; j < peopleArr.length; j++) {
                  const peopleItem = peopleArr[j] && peopleArr[j].people
                  const peopleTypeItem = peopleArr[j] && peopleArr[j].type
                  const peopleTypeItemId = peopleTypeItem && peopleTypeItem.id

                  if (peopleTypeId === peopleTypeItemId) {
                    const peopleObj = {
                      value: peopleItem.id,
                      label: peopleItem.name
                    }
                    arrTemp.push(peopleObj)
                  }
                }

                if (arrTemp.length > 0) {
                  setValue(`season_view_people_types_${i}`, arrTemp)
                }
              }
            }
          }
        }

        // Tags
        const tagTopicArr = resultData.tags
        const tagTopicSelectArr = Array.isArray(tagTopicArr) && tagTopicArr.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })

        setValue('tags', tagTopicSelectArr)

        // Catchup Link Play
        const currentLinkPlay = resultData.link_play
        if (typeof currentLinkPlay === 'string') {
          const arrCurrentLinkPlay = currentLinkPlay.split('/')
          if (Array.isArray(arrCurrentLinkPlay)) {
            const eventStartTimeDateTemp = parse(resultData.event_start_time, 'dd-MM-yyyy HH:m:s', new Date())
            const eventStartTimeTemp = eventStartTimeDateTemp.getTime() / 1000

            const eventEndTimeDateTemp = parse(resultData.event_end_time, 'dd-MM-yyyy HH:m:s', new Date())
            const eventEndTimeTemp = eventEndTimeDateTemp.getTime() / 1000

            const durationTemp = eventEndTimeTemp - eventStartTimeTemp

            const catchupLinkPlayTemp = arrCurrentLinkPlay[0] + '//' + arrCurrentLinkPlay[2] + '/' + arrCurrentLinkPlay[3] + `_720p/playlist_dvr_range-${eventStartTimeTemp}-${durationTemp}.m3u8`
            setCatchupLinkPlay(catchupLinkPlayTemp)
          }
        }
      }
    }
  }, [dataInfo, peopleList, peopleTypeList, categoryList])

  // useEffect(() => {
  //   if (dataInfo && Array.isArray(tagTopicList) && tagTopicList.length > 0) {
  //     const resultData = dataInfo.data

  //     if (resultData) {
  //       const tagTopicArr = resultData.tags
  //       const tagTopicSelectArr = Array.isArray(tagTopicArr) && tagTopicArr.map(item => {
  //         return {
  //           value: item.id,
  //           label: item.title
  //         }
  //       })

  //       setValue('tags', tagTopicSelectArr)
  //     }
  //   }
  // }, [dataInfo, tagTopicList])

  useEffect(() => {
    if (dataInfo && typeList && typeList.length > 0) {
      const resultData = dataInfo.data

      if (resultData) {
        const typeFind = typeList.find(item => item.value === resultData.type)
        setValue('type', typeFind)
        setType(typeFind)
      }
    }
  }, [dataInfo, typeList])

  useEffect(() => {
    if (dataTestLinkPlay) {
      const resultData = dataTestLinkPlay.data
      if (resultData) {
        setLinkPlay(resultData.link_play)
      }
    }
  }, [dataTestLinkPlay])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      const idArr = Array.isArray(imageList) && imageList.map(item => {
        return {
          image: item.id
        }
      })

      const partnerArr = data?.partners || []
      const partnerIds = Array.isArray(partnerArr) && partnerArr.map(item => {
        return {
          partner_id: item.value
        }
      })

      const idsNewsReport = Array.isArray(newsReports) && newsReports.map(item => {
        return {
          news_report: item.id
        }
      })

      let peopleArr = []
      for (let i = 0; i < peopleTypeList.length; i++) {
        const itemArr = data[`people_types_${i}`]
        if (itemArr && Array.isArray(itemArr)) {
          const itemParse = itemArr.map(item => {
            return {
              people: item.value,
              type: peopleTypeList[i] && peopleTypeList[i].id
            }
          })
          peopleArr = peopleArr.concat(itemParse)
        }
      }

      const itemTagTopicArr = data.tags
      const tagsArr = Array.isArray(itemTagTopicArr)
        ? itemTagTopicArr.map(item => {
          return {
            tag_id: item.value
          }
        })
        : []

      const itemCodeBlackListArr = data.iso_code_blacklist
      const itemCodeBlackListParseArr = Array.isArray(itemCodeBlackListArr)
        ? itemCodeBlackListArr.map(itemParam => {
          return itemParam.value
        })
        : []
      const codeBlackListParam = Array.isArray(itemCodeBlackListParseArr)
        ? itemCodeBlackListParseArr.join(',')
        : null

      const itemCodeWhiteListArr = data.iso_code_whitelist
      const itemCodeWhiteListParseArr = Array.isArray(itemCodeWhiteListArr)
        ? itemCodeWhiteListArr.map(itemParam => {
          return itemParam.value
        })
        : []
      const codeWhiteListParam = Array.isArray(itemCodeWhiteListParseArr)
        ? itemCodeWhiteListParseArr.join(',')
        : null

      if (id) {
        if ((type && type.value === 1) || (type && type.value === 7) || (type && type.value === 8) || (type && type.value === 9) || (type && type.value === 15) || (type && type.value === 16)) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            news_reports: idsNewsReport,
            hashtags: (data?.hashtags || '').toString(),
            tags: tagsArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            is_live: typeof data.is_live === 'undefined' ? false : data.is_live,
            is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay
          })
        } else if (type && type.value === 2) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            resolution: formatFormData(data.resolution),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_single_season: typeof data.is_single_season === 'undefined' ? false : data.is_single_season,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            hashtags: (data?.hashtags || '').toString(),
            tags: tagsArr
          })
        } else if (type && type.value === 3) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            status: data.status ? data.status.value : 1,
            // duration
            resolution: formatFormData(data.resolution),
            // total_seasons: formatFormData(data.total_seasons),
            episode: formatFormData(data.episode),
            season: formatFormData(data.season),
            season_name: formatFormData(data.season_name),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            tags: tagsArr,
            group: groupParam
          })
        } else if (type && type.value === 4) { // Episode
          await mutation.mutateAsync({
            type: 4,
            episode: formatFormData(data.episode),
            episode_name: formatFormData(data.episode_name),
            slug: null,
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            ord: formatFormData(data.ord),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            schedule_date: formatFormDateTime(data.schedule_date),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            // duration
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            intro_time: formatFormData(data.intro_time),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            short_description: formatFormData(data.short_description),
            ref_link: formatFormData(data.ref_link),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            tags: tagsArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            group: groupParam
          })
        } else if (type && type.value === 5) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            title: data.title,
            slug: data.slug,
            short_description: data.short_description,
            link_play: data.link_play,
            dvr_link_play: data.dvr_link_play,
            status: data.status ? data.status.value : 1,
            real_views: data.real_views,
            views: data.views,
            favorites: data.favorites,
            ord: data.ord,
            schedule_date: formatFormDateTime(data.schedule_date),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            // long_description: data.long_description,
            long_description: editorRef.current && editorRef.current.getContent(),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_dvr: typeof data.is_dvr === 'undefined' ? false : data.is_dvr,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            images: idArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam
          })
        } else if (type && type.value === 6) {
          await mutation.mutateAsync({
            type: 6,
            episode: formatFormData(data.episode),
            episode_name: formatFormData(data.episode_name),
            slug: null,
            runtime: formatFormData(data.runtime),
            ord: formatFormData(data.ord),
            link_play: formatFormData(data.link_play),
            status: data.status ? data.status.value : 1,
            source_id: formatFormData(data.source_id),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            images: idArr,
            group: groupParam
          })
        } else if ((type && type.value === 17) || (type && type.value === 18) || (type && type.value === 19)) {
          if (Array.isArray(mp3Files) && mp3Files.length > 0) {
            const fileUpload = mp3Files[0]

            // Upload to server
            const formData = new FormData()
            formData.append('entity', id)
            formData.append('tenant', localStorage.getItem('tenant'))
            formData.append('title', getValues('img_name'))
            formData.append('file', fileUpload)
            callUploadApi('POST', 'entity_voice/', formData)
              .then(res => {
                // console.log(res)
              })
              .catch(err => {
                console.log(err)
              })
          }

          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            news_reports: idsNewsReport,
            hashtags: (data?.hashtags || '').toString(),
            tags: tagsArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            is_live: typeof data.is_live === 'undefined' ? false : data.is_live,
            is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay
          })
        } else {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            event_start_time: formatFormDateTime(data.event_start_time),
            event_end_time: formatFormDateTime(data.event_end_time),
            event_countdown_before: formatFormData(data.event_countdown_before),
            hashtags: (data?.hashtags || '').toString(),
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            tags: tagsArr,
            partners: partnerIds
          })
        }
      } else { // POST
        if ((type && type.value === 1) || (type && type.value === 7) || (type && type.value === 8) || (type && type.value === 9) || (type && type.value === 15) || (type && type.value === 16)) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            news_reports: idsNewsReport,
            tenant: localStorage.getItem('tenant'),
            hashtags: (data?.hashtags || '').toString(),
            tags: tagsArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            is_live: typeof data.is_live === 'undefined' ? false : data.is_live,
            is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay
          })
        } else if (type && type.value === 2) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            resolution: formatFormData(data.resolution),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_single_season: typeof data.is_single_season === 'undefined' ? false : data.is_single_season,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            tenant: localStorage.getItem('tenant'),
            hashtags: (data?.hashtags || '').toString(),
            tags: tagsArr
          })
        } else if (type && type.value === 3) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            status: data.status ? data.status.value : 1,
            // duration
            resolution: formatFormData(data.resolution),
            // total_seasons: formatFormData(data.total_seasons),
            episode: formatFormData(data.episode),
            season: formatFormData(data.season),
            season_name: formatFormData(data.season_name),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            tags: tagsArr,
            group: groupParam,
            tenant: localStorage.getItem('tenant')
          })
        } else if (type && type.value === 4) { // Episode
          await mutation.mutateAsync({
            type: 4,
            episode: formatFormData(data.episode),
            episode_name: formatFormData(data.episode_name),
            slug: null,
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            ord: formatFormData(data.ord),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            schedule_date: formatFormDateTime(data.schedule_date),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            // duration
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            intro_time: formatFormData(data.intro_time),
            released: typeof data.released === 'undefined' ? false : data.released,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            short_description: formatFormData(data.short_description),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            tags: tagsArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            group: groupParam,
            tenant: localStorage.getItem('tenant')
            // season: seasonParam
          })
        } else if (type && type.value === 5) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            title: formatFormData(data.title),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            link_play: formatFormData(data.link_play),
            dvr_link_play: formatFormData(data.dvr_link_play),
            status: data.status ? data.status.value : 1,
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            ord: formatFormData(data.ord),
            schedule_date: formatFormDateTime(data.schedule_date),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_dvr: typeof data.is_dvr === 'undefined' ? false : data.is_dvr,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            images: idArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            tenant: localStorage.getItem('tenant')
          })
        } else if (type && type.value === 6) {
          await mutation.mutateAsync({
            type: 6,
            episode: formatFormData(data.episode),
            episode_name: formatFormData(data.episode_name),
            slug: null,
            runtime: formatFormData(data.runtime),
            ord: formatFormData(data.ord),
            link_play: formatFormData(data.link_play),
            status: data.status ? data.status.value : 1,
            source_id: formatFormData(data.source_id),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            images: idArr,
            group: groupParam,
            tenant: localStorage.getItem('tenant')
          })
        } else if ((type && type.value === 17) || (type && type.value === 18) || (type && type.value === 19)) {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            news_reports: idsNewsReport,
            tenant: localStorage.getItem('tenant'),
            hashtags: (data?.hashtags || '').toString(),
            tags: tagsArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            is_live: typeof data.is_live === 'undefined' ? false : data.is_live,
            is_autoplay: typeof data.is_autoplay === 'undefined' ? false : data.is_autoplay
          })
        } else {
          await mutation.mutateAsync({
            category: data.category ? data.category.value : 1,
            type: data.type ? data.type.value : 1,
            rating: formatFormData(data.rating),
            title: formatFormData(data.title),
            known_as: formatFormData(data.known_as),
            slug: formatFormData(data.slug),
            short_description: formatFormData(data.short_description),
            release_date: data.release_date ? formatFormDateTime(data.release_date) : formatFormDateTime(new Date()),
            unrelease_date: data.unrelease_date ? formatFormDateTime(data.unrelease_date) : null,
            release_year: formatFormData(data.release_year),
            schedule_date: formatFormDateTime(data.schedule_date),
            link_play: formatFormData(data.link_play),
            source_id: formatFormData(data.source_id),
            status: data.status ? data.status.value : 1,
            runtime: formatFormData(data.runtime),
            total_seasons: formatFormData(data.total_seasons),
            real_views: formatFormData(data.real_views),
            views: formatFormData(data.views),
            favorites: formatFormData(data.favorites),
            intro_time: formatFormData(data.intro_time),
            ref_link: formatFormData(data.ref_link),
            duration_minute: formatFormData(data.duration_minute),
            duration_second: formatFormData(data.duration_second),
            released: typeof data.released === 'undefined' ? false : data.released,
            is_vip: typeof data.is_vip === 'undefined' ? false : data.is_vip,
            is_drm: typeof data.is_drm === 'undefined' ? false : data.is_drm,
            geo_check: typeof data.geo_check === 'undefined' ? false : data.geo_check,
            is_samsung_preview: typeof data.is_samsung_preview === 'undefined' ? false : data.is_samsung_preview,
            people: peopleArr,
            // long_description: formatFormData(data.long_description),
            long_description: editorRef.current && editorRef.current.getContent(),
            images: idArr,
            tenant: localStorage.getItem('tenant'),
            event_start_time: formatFormDateTime(data.event_start_time),
            event_end_time: formatFormDateTime(data.event_end_time),
            event_countdown_before: formatFormData(data.event_countdown_before),
            hashtags: (data?.hashtags || '').toString(),
            tags: tagsArr,
            iso_code_blacklist: codeBlackListParam,
            iso_code_whitelist: codeWhiteListParam,
            partners: partnerIds
          })
        }
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status !== 403 && err.response.status > 300) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else if (err.response && err.response.status === 403 && err.response.status > 300) {
        const errData = err?.response?.data && err?.response?.data?.errors
        toast.warning(errData?.detail || 'Something went wrong!')
      } else {
        toast.error('Something went wrong!')
      }
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleChangeCategory = (value) => {
    setCategory(value)
  }

  const handleChangeType = (value) => {
    setType(value)
  }

  const handleTestPlayVideo = () => {
    setIsOpenTestPlayVideo(true)

    let fileType = 'application/x-mpegURL'

    if (typeof linkPlay === 'string') {
      if (linkPlay.indexOf('.mp3') === linkPlay.length - 4) {
        fileType = 'audio/mpeg'
        setVideoOptions({
          autoplay: true,
          controls: true,
          poster: imgRadioAnimation
        })
      } else if (linkPlay.indexOf('.mp4') === linkPlay.length - 4) {
        fileType = 'video/mp4'
        setVideoOptions({
          autoplay: true,
          controls: true
        })
      } else {
        fileType = 'application/x-mpegURL'
        setVideoOptions({
          autoplay: true,
          controls: true
        })
      }
    }

    setTimeout(() => {
      setSrc({
        type: fileType,
        src: linkPlay
      })

      const posterElement = document.getElementsByClassName('vjs-poster')
      if (posterElement && posterElement[0]) {
        if (fileType === 'audio/mpeg') {
          posterElement[0].style.display = 'block'
        } else {
          posterElement[0].style.display = 'none'
        }
      }
    }, 500)
  }

  const handleCloseTestPlayVideo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }

    setIsOpenTestPlayVideo(false)
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player
  }

  const onSearchTagTopic = (value) => {
    setSearchTagTopic(value)
  }

  const onSourceId = (value) => {
    setSourceId(value)
  }

  const onMp3Files = (value) => {
    setMp3Files(value)
  }

  return (
    <div className="vod-form">
      <Card>
        <CardContent>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              (id && (typeParam !== null || checkType !== null) && typeParam !== 2 && typeParam !== 3 && checkType !== 2 && checkType !== 3) && <Button startIcon={<PlayArrowIcon />} onClick={handleTestPlayVideo}>Test Play</Button>
            }
          >
            <TabPane tab="INFO" key="1">
              {(typeParam !== 4 && typeParam !== 6 && checkType !== 4 && checkType !== 6) &&
                <Row className="pt-2">
                  <Col md={5}>
                    <Autocomplete
                      options={categoryList}
                      label="VOD Category"
                      name="category"
                      defaultValue={category}
                      required={typeParam !== 4 && typeParam !== 6}
                      disabled={typeParam === 4 || typeParam === 6}
                      control={control}
                      error={errors.category}
                      onHandleChange={handleChangeCategory}
                    />
                  </Col>
                  <Col md={5} className="offset-md-2">
                    <Autocomplete
                      options={typeList}
                      label="VOD Type"
                      name="type"
                      defaultValue={type}
                      required={typeParam !== 4 && typeParam !== 6}
                      disabled={typeParam === 4 || typeParam === 6}
                      control={control}
                      error={errors.type}
                      onHandleChange={handleChangeType}
                    />
                  </Col>
                </Row>
              }

              {((type && type.value === 1) || (type && type.value === 7) || (type && type.value === 8) || (type && type.value === 9) || (type && type.value === 11) || (type && type.value === 15) || (type && type.value === 16))
                ? <VodSingleMovieForm
                    id={id}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    type={type && type.value}
                    statusList={statusList}
                    peopleTypeList={peopleTypeList}
                    peopleList={peopleList}
                    tagTopicList={tagTopicList}
                    countryCodeList={countryCodeList}
                    longDescription={longDescription}
                    hashtagList={hashtags}
                    editorRef={editorRef}
                    searchTagTopic={searchTagTopic}
                    onSearchTagTopic={onSearchTagTopic}
                    onSourceId={onSourceId}
                  />
                : ((type && type.value === 2) || (type && type.value === 12))
                    ? <VodShowDramaForm
                        id={id}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        statusList={statusList}
                        peopleTypeList={peopleTypeList}
                        peopleList={peopleList}
                        tagTopicList={tagTopicList}
                        longDescription={longDescription}
                        hashtagList={hashtags}
                        editorRef={editorRef}
                        searchTagTopic={searchTagTopic}
                        onSearchTagTopic={onSearchTagTopic}
                      />
                    : ((type && type.value === 3) || (type && type.value === 13))
                        ? <VodSeasonForm
                            id={id}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            statusList={statusList}
                            peopleTypeList={peopleTypeList}
                            peopleList={peopleList}
                            tagTopicList={tagTopicList}
                            longDescription={longDescription}
                            editorRef={editorRef}
                            searchTagTopic={searchTagTopic}
                            onSearchTagTopic={onSearchTagTopic}
                          />
                        : ((type && type.value === 4) || (type && type.value === 14))
                            ? <VodEpisodeForm
                                id={id}
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                statusList={statusList}
                                peopleTypeList={peopleTypeList}
                                peopleList={peopleList}
                                tagTopicList={tagTopicList}
                                countryCodeList={countryCodeList}
                                longDescription={longDescription}
                                editorRef={editorRef}
                                searchTagTopic={searchTagTopic}
                                onSearchTagTopic={onSearchTagTopic}
                                onSourceId={onSourceId}
                              />
                            : (type && type.value === 5)
                                ? <VodChannelForm
                                    id={id}
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    statusList={statusList}
                                    countryCodeList={countryCodeList}
                                    longDescription={longDescription}
                                    editorRef={editorRef}
                                  />
                                : (type && type.value === 10)
                                    ? <VodEventForm
                                        id={id}
                                        control={control}
                                        errors={errors}
                                        setValue={setValue}
                                        statusList={statusList}
                                        peopleTypeList={peopleTypeList}
                                        peopleList={peopleList}
                                        tagTopicList={tagTopicList}
                                        longDescription={longDescription}
                                        catchupLinkPlay={catchupLinkPlay}
                                        hashtagList={hashtags}
                                        countryCodeList={countryCodeList}
                                        editorRef={editorRef}
                                        searchTagTopic={searchTagTopic}
                                        onSearchTagTopic={onSearchTagTopic}
                                        onSourceId={onSourceId}
                                      />
                                    : (type && (type.value === 17 || type.value === 18 || type.value === 19))
                                        ? <VodMp3
                                            id={id}
                                            control={control}
                                            errors={errors}
                                            setValue={setValue}
                                            type={type && type.value}
                                            statusList={statusList}
                                            peopleTypeList={peopleTypeList}
                                            peopleList={peopleList}
                                            tagTopicList={tagTopicList}
                                            countryCodeList={countryCodeList}
                                            longDescription={longDescription}
                                            hashtagList={hashtags}
                                            editorRef={editorRef}
                                            searchTagTopic={searchTagTopic}
                                            onSearchTagTopic={onSearchTagTopic}
                                            onSourceId={onSourceId}
                                            onMp3Files={onMp3Files}
                                          />
                                        : <VodTrailerForm id={id} control={control} errors={errors} setValue={setValue} statusList={statusList} onSourceId={onSourceId} />
              }
            </TabPane>
            <TabPane tab="IMAGES" key="2">
              {window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING
                ? <VodImageRecommend idParam={id} sourceId={sourceId} />
                : <VodImage />
              }
            </TabPane>

            {(type && type.value === 4 && window.location.hostname === HOSTNAME.THVLI) &&
              <TabPane tab="SHOW/DRAMA" key="3">
                <VodViewShowDrama
                  control={control}
                  errors={errors}
                  statusList={statusList}
                  peopleTypeList={peopleTypeList}
                  peopleList={peopleList}
                  longDescription={showViewLongDescription}
                  editorRef={editorRef}
                />
              </TabPane>
            }

            {(type && type.value === 4 && window.location.hostname === HOSTNAME.THVLI) &&
              <TabPane tab="SEASON" key="4">
                <VodViewSeason
                  control={control}
                  errors={errors}
                  statusList={statusList}
                  peopleTypeList={peopleTypeList}
                  peopleList={peopleList}
                  longDescription={seasonViewLongDescription}
                  editorRef={editorRef}
                />
              </TabPane>
            }

            {(type && type.value === 15) &&
              <TabPane tab="REPORTS" key="5">
                <VodNewsReports />
              </TabPane>
            }
          </Tabs>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>

      {/* Test Play */}
      <Dialog
        disableEscapeKeyDown
        open={isOpenTestPlayVideo}
        onClose={handleCloseTestPlayVideo}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
        // PaperProps={{
        //   sx: {
        //     minHeight: '90vh'
        //   }
        // }}
      >
        <DialogTitle id="form-dialog-title">
          Test Play Video
        </DialogTitle>
        <DialogContent dividers style={{ height: '80vh' }}>
          <Row>
            <Col md={12}>
              <div>
                <Video options={videoOptions} onReady={handlePlayerReady} src={src} />
              </div>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={handleCloseTestPlayVideo}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default VodForm
