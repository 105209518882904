import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { getData, formatSlug, formatFormData } from 'common/utils'
import { HOSTNAME } from 'constant/appConstant'
import { Row, Col } from 'components/ui'
import { Editor } from '@tinymce/tinymce-react'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Autocomplete from 'components/form/Autocomplete'
import AutocompleteMultiple from 'components/form/AutocompleteMultiple'
import AutocompleteMultipleTagTopic from 'components/form/AutocompleteMultipleTagTopic'
import DateTimePicker from 'components/form/DateTimePicker'
import Button from 'components/form/Button'
import FormDialog from 'components/form/FormDialog'

const VodEpisodeForm = (props) => {
  const {
    id, control, errors, setValue,
    statusList, peopleTypeList, peopleList, tagTopicList, countryCodeList,
    longDescription, editorRef, searchTagTopic, onSearchTagTopic, onSourceId
  } = props

  const { handleSubmit: handleSubmitPeopleType, control: controlPeopleType, formState: { errors: errorsPeopleType }, setError: setErrorPeopleType } = useForm()
  const queryClient = useQueryClient()

  const [isOpenPeopleType, setIsOpenPeopleType] = useState(false)

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('POST', 'people-types/', dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getVodPeopleTypes')
    }
  })

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onPeopleTypeSubmit = async (data) => {
    try {
      await mutation.mutateAsync({
        name: formatFormData(data.name),
        tenant: localStorage.getItem('tenant')
      })
      setIsOpenPeopleType(false)
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setErrorPeopleType(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      }
      console.log(err)
    }
  }

  const handleChangeName = (e) => {
    if (e && e.target) {
      if (setValue) {
        setValue('slug', formatSlug(e.target.value))
      }
    }
  }

  const handleClickShowPeopleType = () => {
    setIsOpenPeopleType(true)
  }

  const handleClosePeopleType = () => {
    setIsOpenPeopleType(false)
  }

  const handleInputChangeTagTopic = (e) => {
    onSearchTagTopic(e?.target?.value)
  }

  const handleChangeSourceId = (e) => {
    onSourceId(e?.target?.value)
  }

  return (
    <div className="vod-episode-form">
      <Row className="pt-4">
        <Col md={5}>
          <TextField type="number" label="Episode" name="episode" required control={control} error={errors.episode} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField
            label="Episode Name"
            name="episode_name"
            required
            // inputProps={{
            //   maxLength: window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING ? 60 : 524288
            // }}
            control={control}
            error={errors.episode_name}
            onHandleChange={handleChangeName}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <DateTimePicker label="Release Date" name="release_date" control={control} />
        </Col>
        <Col md={5} className="offset-md-2">
          <DateTimePicker label="Schedule publish" name="schedule_date" control={control} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField type="number" label="Duration" name="runtime" control={control} error={errors.runtime} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField type="number" label="Order" name="ord" control={control} error={errors.ord} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Views (Real)" name="real_views" control={control} error={errors.real_views} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="Views (Fake)" name="views" control={control} error={errors.views} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Favorites" name="favorites" control={control} error={errors.favorites} />
        </Col>
        <Col md={5} className="offset-md-2">
          {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO)
            ? <Autocomplete
                options={statusList}
                label="Status"
                name="status"
                control={control}
                error={errors.status}
                disabled={!id}
              />
            : <Autocomplete
                options={statusList}
                label="Status"
                name="status"
                control={control}
                error={errors.status}
              />
          }
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField label="Link Play" name="link_play" control={control} error={errors.link_play} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="SourceID Play" name="source_id" control={control} error={errors.source_id} onHandleChange={handleChangeSourceId} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField type="number" label="Intro time" name="intro_time" control={control} error={errors.intro_time} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField label="Short description" name="short_description" control={control} error={errors.short_description} />
        </Col>
      </Row>

      {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) &&
        <Row className="pt-4">
          <Col md={5}>
            <TextField label="Reference Link" name="ref_link" control={control} error={errors.ref_link} />
          </Col>
        </Row>
      }

      {(window.location.hostname === HOSTNAME.BRT || window.location.hostname === HOSTNAME.SDTV) &&
        <Row className="pt-4">
          <Col md={5}>
            <TextField type="number" label="Duration in minutes" name="duration_minute" control={control} error={errors.duration_minute} />
          </Col>
          <Col md={5} className="offset-md-2">
            <TextField type="number" label="Duration in seconds" name="duration_second" control={control} error={errors.duration_second} />
          </Col>
        </Row>
      }

      <Row className="pt-4">
        <Col md={12}>
          <Checkbox name="released" className="pt-4 d-inline-block" control={control} label="Realeased State" />
          <Checkbox name="geo_check" className="pt-4 ml-4 d-inline-block" control={control} label="GEOIP Check" />
          <Checkbox name="is_samsung_preview" className="pt-4 ml-4 d-inline-block" control={control} label="Samsung Preview" />
        </Col>
      </Row>
      {peopleTypeList && Array.isArray(peopleTypeList) && peopleTypeList.map((item, index) => {
        return (
          <Row className="pt-4" key={index}>
            <Col md={12}>
              <AutocompleteMultiple
                options={peopleList}
                label={item.name}
                name={`people_types_${index}`}
                control={control}
                error={errors.people_types}
              />
            </Col>
          </Row>
        )
      })}
      <Row className="pt-3">
        <Col md={12}>
          <Button className="d-inline-block" onClick={handleClickShowPeopleType}>Add People Type</Button>
        </Col>
      </Row>

      {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) &&
        <Row className='pt-5'>
          <Col md={12}>
            {/* <div className="description-title">Tag Topic</div> */}

            <Row>
              <Col md={12}>
                <AutocompleteMultipleTagTopic
                  options={tagTopicList}
                  label="Tag Topic"
                  name="tags"
                  control={control}
                  error={errors.tags}
                  inputValue={searchTagTopic}
                  freeSolo
                  onInputChange={handleInputChangeTagTopic}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }

      {(window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.LOCALHOST) &&
        <Row className='pt-5'>
          <Col md={12}>
            <div className="description-title">GEO Blocking</div>

            <Row className='pt-2'>
              <Col md={12}>
                <AutocompleteMultiple
                  options={countryCodeList}
                  label="Blacklist"
                  name="iso_code_blacklist"
                  control={control}
                  error={errors.iso_code_blacklist}
                />
              </Col>
            </Row>

            <Row className='pt-4'>
              <Col md={12}>
                <AutocompleteMultiple
                  options={countryCodeList}
                  label="Whitelist"
                  name="iso_code_whitelist"
                  control={control}
                  error={errors.iso_code_whitelist}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }

      <Row className="pt-5">
        <Col md={12}>
          {/* <TextField type="textarea" label="Info" name="long_description" control={control} error={errors.long_description} /> */}
          <div className="description-title">Description</div>
          <Editor
            apiKey='8xwdiofw91cl5ikp80dpkij70sj7u8uziroarh8v1420aq6y'
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            // eslint-disable-next-line no-return-assign
            onInit={(evt, editor) => {
              if (editorRef) {
                editorRef.current = editor
              }
              // setIsLoading(false)
            }}
            initialValue={longDescription}
            init={{
              height: 350,
              branding: false,
              plugins: 'advlist autolink lists link charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime table code help wordcount',
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </Col>
      </Row>

      <FormDialog
        isOpen={isOpenPeopleType}
        title="Add People Type"
        onClose={handleClosePeopleType}
        onSave={handleSubmitPeopleType(onPeopleTypeSubmit)}
      >
        <Row>
          <Col md={12}>
            <TextField label="Name" name="name" required control={controlPeopleType} error={errorsPeopleType.name} />
          </Col>
        </Row>
      </FormDialog>
    </div>
  )
}

VodEpisodeForm.propTypes = {
  id: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  statusList: PropTypes.array,
  peopleTypeList: PropTypes.array,
  peopleList: PropTypes.array,
  tagTopicList: PropTypes.array,
  countryCodeList: PropTypes.array,
  longDescription: PropTypes.string,
  editorRef: PropTypes.any,
  searchTagTopic: PropTypes.any,
  onSearchTagTopic: PropTypes.func,
  onSourceId: PropTypes.func
}

VodEpisodeForm.defaultProps = {
  id: null,
  statusList: [],
  status: null,
  peopleTypeList: [],
  peopleList: [],
  tagTopicList: [],
  countryCodeList: [],
  longDescription: ''
}

export default VodEpisodeForm
