import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodNiceClip from '../components/VodNiceClip'

const VodNiceClipPage = (props) => {
  return (
    <Layout>
      <VodNiceClip />
    </Layout>
  )
}

export default VodNiceClipPage
