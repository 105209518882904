import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import Pagination from '@mui/material/Pagination'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Select } from 'antd'
import 'antd/lib/pagination/style/index.css'

const { Option } = Select

const EpgTiming = () => {
  const history = useHistory()

  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])

  const { data: dataList, isLoading, isFetching } = useQuery(['getEpgTimingChannel', limit, offset], async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/vod-entities/?limit=${limit}&offset=${offset}&type=5`)
  })

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '12%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="EPG Timing" onClick={e => handleClickSetting(e, record)}>
              <IconButton color="success">
                <AccessTimeIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Title',
      key: 'title',
      width: '27%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{(record.type === 4 || record.type === 6) ? record.episode_name : record.title}</div>
        )
      }
      // sorter: (a, b) => {
      //   return a.title.localeCompare(b.title)
      // },
      // ...getColumnSearchProps('title')
    },
    {
      title: 'VOD Type',
      key: 'type',
      width: '13%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.type === 1
              ? 'SINGLE MOVIE'
              : record.type === 2
                ? 'SHOW/DRAMA'
                : record.type === 3
                  ? 'SEASON'
                  : record.type === 4
                    ? 'EPISODE'
                    : record.type === 5
                      ? 'CHANNEL'
                      : record.type === 6
                        ? 'TRAILER'
                        : record.type === 7
                          ? 'DOCUMENT'
                          : record.type === 8
                            ? 'BREAKING NEWS'
                            : record.type === 9
                              ? 'AUDIO'
                              : record.type === 10
                                ? 'EVENT'
                                : record.type === 11
                                  ? 'VERTICAL VIDEO'
                                  : record.type === 12
                                    ? 'SHOW AUDIO'
                                    : record.type === 15
                                      ? 'NEWS REPORT'
                                      : record.type === 16
                                        ? 'SPONSOR'
                                        : record.type === 17
                                          ? 'MULTI NEWSPAPER'
                                          : record.type === 18
                                            ? 'EMAGAZINE'
                                            : record.type === 19
                                              ? 'INTERNAL NEWS'
                                              : 'Unknown'}
          </div>
        )
      }
      // sorter: (a, b) => {
      //   return a.type - b.type
      // }
    },
    {
      title: 'Status',
      key: 'status',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record.status === 1
              ? 'Chờ xử lí'
              : record.status === 2
                ? 'Chờ QC'
                : record.status === 3
                  ? 'Xuất bản'
                  : record.status === 4
                    ? 'Tạm ngưng xuất bản'
                    : record.status === 5
                      ? 'Tái xuất bản'
                      : record.status === 6
                        ? 'Đóng'
                        : record.status === 7
                          ? 'Schedule publish'
                          : 'Draft'}
          </div>
        )
      }
    },
    {
      title: 'Release date',
      dataIndex: 'release_date',
      key: 'release_date',
      width: '14%',
      sorter: true
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '12%'
    }
  ]

  useEffect(() => {
    if (dataList && limit) {
      let resultData = []
      const dataListData = dataList.data

      if (dataListData) {
        if (Array.isArray(dataListData)) {
          resultData = dataListData
          setTotal(dataListData.length)
          setTotalPage(Math.ceil(dataListData.length / limit))
        } else {
          resultData = dataListData.results
          setTotal(dataListData.total)
          setTotalPage(Math.ceil(dataListData.total / limit))
        }
      }

      // // Tạm ẩn user admin
      // if (Array.isArray(resultData)) {
      //   const dataListParse = resultData.map(item => {
      //     return {
      //       key: item.id,
      //       ...item
      //     }
      //   })
      //   setData(dataListParse)
      // }

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        setData(dataListParse)
      }
    }
  }, [dataList, limit])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickSetting = (e, record) => {
    history.push(`/epg-timing-update/${record.id}`)
  }

  return (
    <div>
      <Row className="pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            data={data}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>
    </div>
  )
}

export default EpgTiming
