import React from 'react'
import Layout from 'features/layout/components/Layout'
import ReportData from '../components/ReportData'

const ReportDataPage = (props) => {
  return (
    <Layout>
      <ReportData />
    </Layout>
  )
}

export default ReportDataPage
