import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { setErrorHtml } from 'common/slice'
import { getData } from 'common/utils'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Tree from 'components/ui/Tree'
import TreeSelect from 'components/ui/TreeSelect'
import Button from 'components/form/Button'
import AlertDialog from 'components/ui/AlertDialog'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import MenuIcon from '@mui/icons-material/Menu'
import CheckIcon from '@mui/icons-material/Check'
import ShareIcon from '@mui/icons-material/Share'
import InfoIcon from '@mui/icons-material/Info'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import { toast } from 'react-toastify'
import '../styles/ribbon.scss'
import { Popover } from '@mui/material'
import RibbonSharingForm from './RibbonSharingAddToTenant'
import { HOSTNAME } from 'constant/appConstant'

const Ribbon = (props) => {
  const dispatch = useDispatch()
  const ribbonId = useSelector((state) => state.ribbon.ribbonId)
  const queryClient = useQueryClient()

  const [menuItem, setMenuItem] = useState(null)
  const [menuList, setMenuList] = useState([])
  const [dataTree, setDataTree] = useState([])
  const [dataTreeSelect, setDataTreeSelect] = useState([])
  const [idDelete, setIdDelete] = useState(null)
  const [isOpenDelete, setIsOpenDelete] = useState(false)

  // View Ribobn Shared Info
  const [isOpenViewTenantInfo, setIsOpenViewTenantInfo] = useState(false)
  const [anchorElViewTenantInfo, setAnchorElViewTenantInfo] = useState(null)
  const [sharedRibbon, setSharedRibbon] = useState(null)
  const [idAddRibbonSharing, setIdAddRibbonSharing] = useState('')
  const [isOpenRibbonSharingForm, setIsOpenRibbonSharingForm] = useState(false)

  const { data: dataRibbon } = useQuery(['getRibbon', menuItem], async () => {
    if (menuItem) {
      return await getData('GET', `menu-items/${menuItem}/ribbons/`)
    }
  })

  const { data: dataMenu } = useQuery('getRibbonMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataMenuItem } = useQuery(['getRibbonMenuItem', menuList], async () => {
    const resultTemp = []

    try {
      if (menuList && menuList.length > 0) {
        for (let i = 0; i < menuList.length; i++) {
          const resultApi = await getData('GET', `menu/${menuList[i] && menuList[i].id}/menu-items/`)
          const resultData = resultApi && resultApi.data
          if (Array.isArray(resultData)) {
            const dataParse = resultData.map((item, index) => {
              // Sub Item
              const childrenArrTemp = []
              const childrenArr = item.sub_menu_items
              if (Array.isArray(childrenArr)) {
                for (let i = 0; i < childrenArr.length; i++) {
                  childrenArrTemp.push({
                    value: childrenArr[i] && childrenArr[i].id,
                    title: childrenArr[i] && childrenArr[i].name,
                    children: []
                  })
                }
              }

              return {
                title: item.name,
                value: item.id,
                children: childrenArrTemp
              }
            })

            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              selectable: false,
              children: dataParse
            })
          } else {
            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              children: []
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    return resultTemp
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `ribbons/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbon')
    }
  })

  const mutationUpdateOrder = useMutation(async (dataForm) => {
    if (dataForm && menuItem) {
      return await getData('PUT', `menu-items/${menuItem}/ribbons-order/`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbon')
    }
  })

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results
      setMenuList(resultData)
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataMenuItem) {
      setDataTreeSelect(dataMenuItem)
    }
  }, [dataMenuItem])

  // Trường hợp chọn ribbon ở menu
  useEffect(() => {
    if (ribbonId && dataTreeSelect.length > 0) {
      for (let i = 0; i < dataTreeSelect.length; i++) {
        const childrenArr = dataTreeSelect[i].children
        if (Array.isArray(childrenArr)) {
          const ribbonFind = childrenArr.find(obj => {
            return obj.value === ribbonId
          })

          if (ribbonFind) {
            setMenuItem(ribbonId)
          }
        }
      }
    }
  }, [ribbonId, dataTreeSelect])

  useEffect(() => {
    if (menuList) {
      // console.log(menuList)
    }
  }, [menuList])

  useEffect(() => {
    if (dataRibbon) {
      const resultData = dataRibbon.data

      if (Array.isArray(resultData)) {
        const dataTemp = resultData.map((item, index) => {
          const childrenArrTemp = []
          const childrenArr = item.sub_menu_items
          if (Array.isArray(childrenArr)) {
            for (let i = 0; i < childrenArr.length; i++) {
              const type = childrenArr[i] && childrenArr[i].type

              childrenArrTemp.push({
                key: `0-0-${i}`,
                id: childrenArr[i] && childrenArr[i].id,
                title: childrenArr[i] && childrenArr[i].name,
                type: type === 0
                  ? 'Master Banner'
                  : type === 1
                    ? 'Poster'
                    : type === 2
                      ? 'Thumbnail'
                      : type === 3
                        ? 'Banner'
                        : type === 4
                          ? 'List'
                          : type === 5
                            ? 'Circle'
                            : type === 6
                              ? 'Pano'
                              : type === 7
                                ? 'Special News'
                                : type === 8
                                  ? 'Trending'
                                  : type === 9
                                    ? 'Poster News'
                                    : type === 10
                                      ? 'Audio'
                                      : type === 11
                                        ? 'Auto Play'
                                        : type === 12
                                          ? 'Album'
                                          : 'Banner Top',
                is_active: childrenArr[i] && childrenArr[i].is_active,
                children: []
              })
            }
          }

          return {
            key: `0-${index}`,
            orderValue: index + 1,
            id: item.id,
            title: item.name,
            type: item.type === 0
              ? 'Master Banner'
              : item.type === 1
                ? 'Poster'
                : item.type === 2
                  ? 'Thumbnail'
                  : item.type === 3
                    ? 'Banner'
                    : item.type === 4
                      ? 'List'
                      : item.type === 5
                        ? 'Circle'
                        : item.type === 6
                          ? 'Pano'
                          : item.type === 7
                            ? 'Special News'
                            : item.type === 8
                              ? 'Trending'
                              : item.type === 9
                                ? 'Poster News'
                                : item.type === 10
                                  ? 'Audio'
                                  : item.type === 11
                                    ? 'Auto Play'
                                    : item.type === 12
                                      ? 'Album'
                                      : 'Banner Top',
            is_active: item.is_active,
            children: childrenArrTemp,
            sharedRibbon: item?.shared_ribbon,
            isSharedRibbon: item?.is_shared_ribbon || false
          }
        })

        setDataTree(dataTemp)
        // setExpandedKeys(['0-0', '0-0-0', '0-0-0-0'])
      }
    }
  }, [dataRibbon])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const ToastCustomError = () => {
    return (
      <div>
        Something went wrong! <Link to="/error">See more</Link>
      </div>
    )
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)
      setIsOpenDelete(false)

      toast.success('Ribbon deleted successfully')
    } catch (err) {
      setIsOpenDelete(false)

      if (err.response) {
        dispatch(setErrorHtml(err.response.data))
      }

      toast.error(ToastCustomError)
      console.log(err)
    }
  }

  const handleChangeMenuItem = value => {
    setMenuItem(value)
  }

  const onDragEnter = info => {
    //
  }

  const onDrop = info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children, key, callback)
        }
      }
    }
    const data = [...dataTree]

    // Find dragObject
    let dragObj
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj)
      })
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj)
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      })
    } else {
      let ar
      let i
      loop(data, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        ar.splice(i + 1, 0, dragObj)
      }
    }

    setDataTree(data)
  }

  const handleClickUpdateOrder = async () => {
    try {
      const arrTemp = []
      let indexTemp = 1
      let indexChildTemp = dataTree.length

      for (let i = 0; i < dataTree.length; i++) {
        const itemId = dataTree[i] && dataTree[i].id
        const itemChildren = dataTree[i] && dataTree[i].children

        if (Array.isArray(itemChildren)) {
          for (let j = 0; j < itemChildren.length; j++) {
            const itemChildrenId = itemChildren[j] && itemChildren[j].id

            arrTemp.push({
              id: itemChildrenId,
              ord: indexChildTemp
            })

            indexChildTemp += 1
          }
        }

        arrTemp.push({
          id: itemId,
          ord: indexTemp
        })

        indexTemp += 1
      }
      await mutationUpdateOrder.mutateAsync(arrTemp)

      toast.success('Update order successfully')
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickViewTenantShared = (e, sharedRibbon) => {
    setAnchorElViewTenantInfo(e.currentTarget)
    setIsOpenViewTenantInfo(true)
    setSharedRibbon(sharedRibbon)
  }

  const handleCloseViewTenantInfo = () => {
    setIsOpenViewTenantInfo(false)
    setAnchorElViewTenantInfo(null)
    setSharedRibbon(null)
  }

  const handleCloseFormRibbonSharing = () => {
    setIsOpenRibbonSharingForm(false)
    setIdAddRibbonSharing('')
  }

  const handleOpenFormRibbonSharing = (idRibbon) => {
    setIdAddRibbonSharing(idRibbon)
    setIsOpenRibbonSharingForm(true)
  }

  return (
    <div className="ribbon">
      <Row>
        <Col md={8}>
          <div className="pb-1">Menu Page</div>
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            value={menuItem}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={dataTreeSelect}
            placeholder="Please select Menu Page to see Ribbon"
            treeDefaultExpandAll
            bordered
            onChange={handleChangeMenuItem}
          />
        </Col>
        <Col md={4}>
          <div className="pb-1">&nbsp;</div>
          <Link to="/ribbon/add">
            <Button className="float-right" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Tree
            className="draggable-tree"
            // expandedKeys={expandedKeys}
            draggable
            blockNode
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            treeData={dataTree}
            titleRender={(nodeData) => {
              return (
                <div>
                  {`${(window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING) ? `${nodeData.orderValue}.` : ''}`} {nodeData.type} : {nodeData.title}
                  <Link to={`/ribbon-item/${nodeData.id}`} className="ribbon-view float-right mr-2">
                    <IconButton>
                      <MenuIcon />
                    </IconButton>
                    Item
                  </Link>
                  <div className='float-right mr-4'>
                    <div className="float-right mr-2 ribbon-delete" onClick={e => handleClickShowModalDelete(e, nodeData)}>
                      <IconButton color="error">
                        <DeleteIcon />
                      </IconButton>
                      Delete
                    </div>
                    {
                      (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING) && (
                        !nodeData?.isSharedRibbon && !nodeData?.sharedRibbon &&
                          <div className="ribbon-view float-right mr-2"
                            onClick={e => handleOpenFormRibbonSharing(nodeData?.id || '')}
                          >
                            <IconButton>
                              <ShareIcon />
                            </IconButton>
                            Share
                          </div>
                      )
                    }
                    {
                      !nodeData?.sharedRibbon &&
                      <Link to={`/ribbon/${nodeData.id}`} className="float-right mr-2 ribbon-edit">
                        <IconButton color="success">
                          <EditIcon />
                        </IconButton>
                        Edit
                      </Link>
                    }
                  </div>
                  <div className='float-right mr-5' >
                    {
                      (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING) && (
                        nodeData?.sharedRibbon &&
                          <div className='float-right mr-2'
                            onClick={e => handleClickViewTenantShared(e, nodeData?.sharedRibbon)}>
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                              Info
                          </div>)
                    }
                    {
                      (window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TERA_STAGING) && (
                        nodeData?.isSharedRibbon &&
                        <Link to={`/ribbon-sharing-by-me?q=${nodeData?.title || ''}`} className='float-right mr-2 ribbon-view'>
                          <IconButton>
                            <FolderSharedIcon />
                          </IconButton>
                          Shared
                        </Link>
                      )
                    }
                    <div className="float-right mr-2">
                      {
                        nodeData?.sharedRibbon
                          ? nodeData?.sharedRibbon?.is_active &&
                          <div>
                            <IconButton color="success">
                              <CheckIcon />
                            </IconButton>
                            Active
                          </div>
                          : nodeData.is_active &&
                          <div>
                            <IconButton color="success">
                              <CheckIcon />
                            </IconButton>
                            Active
                          </div>
                      }
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </Col>
      </Row>

      <Popover
          id={isOpenViewTenantInfo ? 'simple-popover' : ''}
          open={isOpenViewTenantInfo}
          anchorEl={anchorElViewTenantInfo}
          onClose={handleCloseViewTenantInfo}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {
            sharedRibbon && (
              <Link to={`/ribbon-sharing-with-me?q=${sharedRibbon?.ribbon?.name || ''}`}>
                <Button>
                  Shared Ribbon By {sharedRibbon?.owner?.name || ''}
                </Button>
              </Link>
            )
          }
        </Popover>

      {(dataTree && dataTree.length > 0) &&
        <Row className="pt-4">
          <Col md={12}>
            <Button onClick={handleClickUpdateOrder}>
              Update Order
            </Button>
          </Col>
        </Row>
      }

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />

      <RibbonSharingForm
        idAdd={idAddRibbonSharing}
        isOpen={isOpenRibbonSharingForm}
        onClose={handleCloseFormRibbonSharing}
      />
    </div>
  )
}

export default Ribbon
