import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import { setErrorHtml } from 'common/slice'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import { alpha, styled } from '@mui/material/styles'
import SvgIcon from '@mui/material/SvgIcon'
import TreeView from '@mui/lab/TreeView'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import FormDialog from 'components/form/FormDialog'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { toast } from 'react-toastify'

const MinusSquare = (props) => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  )
}

const PlusSquare = (props) => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  )
}

const CloseSquare = (props) => {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  )
}

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`
  },
  [`& .${treeItemClasses.expanded}`]: {
    cursor: 'auto',
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'bold'
    }
  },
  [`& .${treeItemClasses.selected}`]: {
    background: 'transparent !important'
  }
}))

const VodAddRibbon = (props) => {
  const { idAdd, isOpen, onClose } = props

  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      ribbon_items: []
    }
  })

  const [ribbonList, setRibbonList] = useState([])
  const [linkedList, setLinkedList] = useState([])
  const [expanded, setExpanded] = useState([])

  const { data: dataRibbon } = useQuery(['getVodRibbon', idAdd], async () => {
    if (idAdd) {
      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/ribbons/`)
    }
  })

  const { data: dataLinked } = useQuery(['getVodLinked', idAdd], async () => {
    if (idAdd) {
      return await getData('GET', `vod-entities/${idAdd}/ribbons/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('POST', 'ribbon-items/', dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getVodEntities')
    }
  })

  /* ----------------------------------------------- */
  /* ----------------- useEffect ------------------- */
  /* ----------------------------------------------- */
  useEffect(() => {
    if (dataRibbon) {
      const resultData = dataRibbon.data
      if (Array.isArray(resultData)) {
        setRibbonList(resultData)
      }
    }
  }, [dataRibbon])

  useEffect(() => {
    if (dataLinked) {
      const resultData = dataLinked.data
      if (Array.isArray(resultData)) {
        setLinkedList(resultData)
      }
    }
  }, [dataLinked])

  useEffect(() => {
    if (ribbonList) {
      const expandedTemp = [...expanded]
      for (let i = 0; i < ribbonList.length; i++) {
        const menuId = ribbonList[i] && ribbonList[i].id
        const menuItemArr = ribbonList[i] && ribbonList[i].menu_items
        expandedTemp.push(menuId)

        if (Array.isArray(menuItemArr)) {
          for (let j = 0; j < menuItemArr.length; j++) {
            const menuItemChildId = menuItemArr[j] && menuItemArr[j].id
            const menuItemSubMenu = menuItemArr[j] && menuItemArr[j].sub_menu_items

            if (Array.isArray(menuItemSubMenu)) {
              for (let k = 0; k < menuItemSubMenu.length; k++) {
                const menuItemSubMenuId = menuItemSubMenu[k] && menuItemSubMenu[k].id
                expandedTemp.push(menuItemSubMenuId)
              }
            }

            expandedTemp.push(menuItemChildId)
          }
        }
      }
      setExpanded(expandedTemp)
    }
  }, [ribbonList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    const ribbonItemData = data.ribbon_items

    try {
      if (ribbonItemData.length === 0) {
        toast.warning('Please select ribbon', {
          hideProgressBar: true,
          autoClose: 2000,
          position: 'top-right'
        })
      } else {
        if (ribbonItemData.length === 1) {
          await mutation.mutateAsync({
            // ord: 0,
            // is_active: true,
            ribbon: ribbonItemData[0],
            entity: idAdd,
            tenant: localStorage.getItem('tenant')
          })
        } else {
          const arrTemp = ribbonItemData.map(item => {
            return {
              // is_active: true,
              ribbon: item,
              entity: idAdd,
              tenant: localStorage.getItem('tenant')
            }
          })

          await mutation.mutateAsync(arrTemp)
        }

        onClose(false)
        setValue('ribbon_items', [])
        toast.success('Entity added successfully')
      }
    } catch (err) {
      if (err.response) {
        dispatch(setErrorHtml(err.response.data))
      }

      toast.error(ToastCustomError)
      console.log(err)
    }
  }

  const handleCloseRibbonItem = () => {
    onClose(false)
    setValue('ribbon_items', [])
  }

  const handleToggleTreeView = (e, nodeIds) => {
    setExpanded(nodeIds)
  }

  const ToastCustomError = () => {
    return (
      <div>
        Something went wrong! <Link to="/error">See more</Link>
      </div>
    )
  }

  return (
    <div className="vod-add-ribbon">
      <FormDialog
        isOpen={isOpen}
        title="Add Ribbon Item"
        maxWidth="md"
        dividers
        onClose={handleCloseRibbonItem}
        onSave={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md={12}>
            <TreeView
              aria-label="customized"
              expanded={expanded}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
              onNodeToggle={handleToggleTreeView}
              sx={{ flexGrow: 1, overflowY: 'auto' }}
            >
              {ribbonList && ribbonList.map((item, index) => {
                const menuItemArr = item.menu_items

                return (
                  <StyledTreeItem key={index} nodeId={item.id} label={item.name}>
                    {Array.isArray(menuItemArr) && menuItemArr.map((itemMenuItem, indexMenuItem) => {
                      const ribbons = itemMenuItem.ribbons
                      const subMenuItems = itemMenuItem.sub_menu_items

                      return (
                        <div key={indexMenuItem}>
                          <StyledTreeItem nodeId={itemMenuItem.id} label={itemMenuItem.name}>
                            {Array.isArray(ribbons) && ribbons.map((itemGrandChild, indexGrandChild) => {
                              let isAdded = false
                              for (let i = 0; i < linkedList.length; i++) {
                                if (itemGrandChild.id === linkedList[i]) {
                                  isAdded = true
                                }
                              }

                              return (
                                <div key={indexGrandChild}>
                                  <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                      control={<Controller
                                        name="ribbon_items"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                          return (
                                            <Checkbox
                                              disabled={isAdded}
                                              value={itemGrandChild.id}
                                              checked={isAdded === true
                                                ? true
                                                : value.some((existingValue) => existingValue === itemGrandChild.id)
                                              }
                                              onChange={(e, checked) => {
                                                if (checked) {
                                                  onChange([
                                                    ...value,
                                                    e.target.value
                                                  ])
                                                  setValue('ribbon_items', [
                                                    ...value,
                                                    e.target.value
                                                  ])
                                                } else {
                                                  onChange(value.filter(valueItem => valueItem !== e.target.value))
                                                  setValue('ribbon_items', value.filter(valueItem => valueItem !== e.target.value))
                                                }
                                              }}
                                            />
                                          )
                                        }}
                                      />}
                                      label={itemGrandChild.name}
                                      labelPlacement="end"
                                    />
                                  </FormGroup>
                                </div>
                              )
                            })}
                          </StyledTreeItem>

                          {/*  */}
                          {Array.isArray(subMenuItems) && subMenuItems.map((itemMenuItem, indexMenuItem) => {
                            const ribbons = itemMenuItem.ribbons

                            return (
                              <StyledTreeItem key={indexMenuItem} nodeId={itemMenuItem.id} label={itemMenuItem.name}>
                                {Array.isArray(ribbons) && ribbons.map((itemGrandChild, indexGrandChild) => {
                                  let isAdded = false
                                  for (let i = 0; i < linkedList.length; i++) {
                                    if (itemGrandChild.id === linkedList[i]) {
                                      isAdded = true
                                    }
                                  }

                                  return (
                                    <div key={indexGrandChild}>
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          control={<Controller
                                            name="ribbon_items"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                              return (
                                                <Checkbox
                                                  disabled={isAdded}
                                                  value={itemGrandChild.id}
                                                  checked={isAdded === true
                                                    ? true
                                                    : value.some((existingValue) => existingValue === itemGrandChild.id)
                                                  }
                                                  onChange={(e, checked) => {
                                                    if (checked) {
                                                      onChange([
                                                        ...value,
                                                        e.target.value
                                                      ])
                                                      setValue('ribbon_items', [
                                                        ...value,
                                                        e.target.value
                                                      ])
                                                    } else {
                                                      onChange(value.filter(valueItem => valueItem !== e.target.value))
                                                      setValue('ribbon_items', value.filter(valueItem => valueItem !== e.target.value))
                                                    }
                                                  }}
                                                />
                                              )
                                            }}
                                          />}
                                          label={itemGrandChild.name}
                                          labelPlacement="end"
                                        />
                                      </FormGroup>
                                    </div>
                                  )
                                })}
                              </StyledTreeItem>
                            )
                          })}
                        </div>
                      )
                    })}
                  </StyledTreeItem>
                )
              })}
            </TreeView>
          </Col>
        </Row>
      </FormDialog>
    </div>
  )
}

VodAddRibbon.propTypes = {
  idAdd: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

VodAddRibbon.defaultProps = {
  idAdd: '',
  isOpen: false
}

export default VodAddRibbon
