import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

const TranscodeFilter = (props) => {
  const { onChangeStatus, onClickSearch } = props

  const [status, setStatus] = useState(null)
  const [searchText, setSearchText] = useState('')

  const statusList = [
    { value: 0, label: 'Progressing' },
    { value: 1, label: 'Success' },
    { value: -1, label: 'Failure ' }
  ]

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeStatus = (e, value) => {
    setStatus(value)
    onChangeStatus(value)
  }

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleClickSearch = () => {
    onClickSearch(searchText)
  }

  const handleClearSearch = () => {
    setSearchText('')
    onClickSearch('')
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onClickSearch(searchText)
    }
  }

  return (
    <div className="vod-filter">
      <Card>
        <CardContent>
          <Row className="pt-2">
            <Col md={3}>
              <Autocomplete
                fullWidth
                size="small"
                value={status}
                onChange={(e, newValue) => handleChangeStatus(e, newValue)}
                options={statusList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Status" />}
              />
            </Col>
            <Col md={5} className="offset-md-4">
              <TextField
                size="small"
                fullWidth
                label="Search by file name, source id"
                variant="standard"
                value={searchText}
                onChange={handleChangeSearch}
                onKeyDown={handleEnter}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchText !== '' &&
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  )
}

TranscodeFilter.propTypes = {
  onChangeStatus: PropTypes.func,
  onClickSearch: PropTypes.func
}

TranscodeFilter.defaultProps = {
}

export default TranscodeFilter
