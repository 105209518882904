import React from 'react'
import Layout from 'features/layout/components/Layout'
import Menu from '../components/Menu'

const MenuPage = (props) => {
  return (
    <Layout>
      <Menu />
    </Layout>
  )
}

export default MenuPage
