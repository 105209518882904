import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import { Editor } from '@tinymce/tinymce-react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from 'components/form/Button'
import Overlay from 'components/ui/Overlay'
import { toast } from 'react-toastify'

const Faq = (props) => {
  const history = useHistory()
  const editorRef = useRef(null)
  const queryClient = useQueryClient()

  const [faq, setFaq] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { data: dataFaq } = useQuery('getFaq', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/faq/`)
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('PUT', `tenants/${localStorage.getItem('tenant')}/faq/`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getFaq')
    }
  })

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (dataFaq) {
      setFaq(dataFaq.data && dataFaq.data.faq)
    }
  }, [dataFaq])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleSave = async () => {
    try {
      if (editorRef.current) {
        const dataValue = editorRef.current.getContent()
        await mutation.mutateAsync({
          faq: dataValue
        })
      }

      toast.success('Faq updated successfully')
    } catch (err) {
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="faq">
      <Card>
        <CardContent>
          <Editor
            apiKey='8xwdiofw91cl5ikp80dpkij70sj7u8uziroarh8v1420aq6y'
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            // eslint-disable-next-line no-return-assign
            onInit={(evt, editor) => {
              editorRef.current = editor
              setIsLoading(false)
            }}
            initialValue={faq}
            init={{
              height: 550,
              branding: false,
              plugins: 'advlist autolink lists link charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime table code help wordcount',
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSave}>Save</Button>
        </CardActions>
      </Card>

      {isLoading && <Overlay />}
    </div>
  )
}

export default Faq
