import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { getData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Button, Popover, Typography } from '@mui/material'
import FormDialog from 'components/form/FormDialog'
import DateTimePicker from 'components/form/DateTimePicker'
import Autocomplete from 'components/form/Autocomplete'
import { toast } from 'react-toastify'
import { format, parse } from 'date-fns'
import { Select } from 'antd'
import Pagination from '@mui/material/Pagination'
import ShareContentWithMeFilter from './ShareContentWithMeFilter'
import ShareContentAddToRibbon from './ShareContentAddToRibbon'

const { Option } = Select

const ShareContentWithMe = () => {
  const queryClient = useQueryClient()
  const urlString = window.location.href
  const url = new URL(urlString)
  const searchParamsUrl = url.searchParams.get('q')

  const { handleSubmit, control, setValue, formState: { errors }, reset } = useForm()
  const statusList = [
    { value: 1, label: 'Publish' },
    { value: 2, label: 'Close' },
    { value: 3, label: 'Schedule publish' }
  ]

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  /* ----------------------------------------------- */
  /* --------------- Use States ------------------ */
  /* ----------------------------------------------- */
  const [shareContenList, setShareContentList] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [searchFilter, setSearchFilter] = useState('')
  const [anchorElViewMoreInfo, setAnchorElViewMoreInfo] = useState(null)
  const [dataMoreInfo, setDataMoreInfo] = useState(null)
  const [isOpenViewMoreInfo, setIsOpenViewMoreInfo] = useState(false)
  const [isOpenUpdateshareContent, setIsOpenUpdateSharecontent] = useState(false)
  const [idShareContent, setIdShareContent] = useState('')
  const [tenantIdUpdate, setTenantIdUpdate] = useState('')
  const [totalPage, setTotalPage] = useState(1)
  const [current, setCurrent] = useState(1)

  // Add To Ribbon
  const [idAdd, setIdAdd] = useState(null)
  const [isOpenRibbonItem, setIsOpenRibbonItem] = useState(false)

  /* ----------------------------------------------- */
  /* --------------- Fetch API ------------------ */
  /* ----------------------------------------------- */
  const { data: dataList, isLoading, isFetching } = useQuery(['getEntitySharWithMe', searchFilter], async () => {
    let endpointTemp = ''
    if (searchFilter !== '') {
      endpointTemp += `&q=${searchFilter}`
    }
    return await getData(
      'GET',
      `tenants/${localStorage.getItem('tenant')}/share-content-with-me?offset=${offset}&limit=${limit}${endpointTemp}`
    )
  })

  const mutationUpdate = useMutation(async ({ idShareContent, dataForm }) => {
    if (idShareContent) {
      return await getData('PUT', `share-content/${idShareContent}/shared-tenant-update`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getEntitySharWithMe')
    }
  })

  /* ----------------------------------------------- */
  /* --------------- Use Effect ------------------ */
  /* ----------------------------------------------- */
  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results
      setShareContentList(resultData)
      setTotalPage(Math.ceil(dataList.data.total / limit))
    }
  }, [dataList])

  useEffect(() => {
    if (searchParamsUrl) {
      setSearchFilter(searchParamsUrl)
    }
  }, [searchParamsUrl])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleClickViewMoreInfo = (event, dataMoreInfo) => {
    setDataMoreInfo(dataMoreInfo)
    setAnchorElViewMoreInfo(event.currentTarget)
    setIsOpenViewMoreInfo(true)
  }

  const handleCloseViewMoreInfo = () => {
    setIsOpenViewMoreInfo(false)
    setAnchorElViewMoreInfo(null)
  }

  const handleCloseUpdateMoreInfo = () => {
    reset()
    setIsOpenUpdateSharecontent(false)
  }

  const handleOpenUpdateShareContentData = (record) => {
    setIsOpenUpdateSharecontent(true)
    setTenantIdUpdate(record?.tenant || '')
    setIdShareContent(record.shared_content)

    if (record.release_date) {
      const releaseDate = parse(record.release_date, 'dd-MM-yyyy HH:m:s', new Date())
      setValue('release_date', releaseDate)
    }
    if (record.schedule_publish) {
      const schedulePublish = parse(record.schedule_publish, 'dd-MM-yyyy HH:m:s', new Date())
      setValue('schedule_publish', schedulePublish)
    }
    if (record.publish_date) {
      const publishDate = parse(record.publish_date, 'dd-MM-yyyy HH:m:s', new Date())
      setValue('publish_date', publishDate)
    }
    const statusFind = statusList.find(item => item.value === record.status)
    setValue('status', statusFind)
  }

  const handleSaveUpdateShareContent = async (data) => {
    let dataForm = {
      status: data?.status?.value || '',
      tenant_id: tenantIdUpdate
    }
    let publishedDate = ''
    let schedulePublish = ''
    let releaseDate = ''
    if (data?.publish_date) {
      publishedDate = format(new Date(data.publish_date), 'yyyy-MM-dd HH:m:s')
      dataForm = Object.assign(dataForm, { publish_date: publishedDate })
    }
    if (data?.schedule_publish) {
      schedulePublish = format(new Date(data.schedule_publish), 'yyyy-MM-dd HH:m:s')
      dataForm = Object.assign(dataForm, { schedule_publish: schedulePublish })
    }
    if (data?.release_date) {
      releaseDate = format(new Date(data.release_date), 'yyyy-MM-dd HH:m:s')
      dataForm = Object.assign(dataForm, { release_date: releaseDate })
    }
    if (idShareContent && tenantIdUpdate) {
      try {
        await mutationUpdate.mutateAsync({
          idShareContent, dataForm
        }).then(() => {
          toast.success('Update Success')
          setTenantIdUpdate('')
          setIdShareContent('')
          setIsOpenUpdateSharecontent(false)
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }

  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }

  const handleOpenAddToRibbon = (record) => {
    setIdAdd(record?.shared_content)
    setIsOpenRibbonItem(true)
  }

  const handleCloseAddToRibbon = () => {
    setIdAdd('')
    setIsOpenRibbonItem(false)
  }

  /* ----------------------------------------------- */
  /* --------------- Column Table ------------------ */
  /* ----------------------------------------------- */
  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Edit">
              <IconButton color="success" onClick={() => handleOpenUpdateShareContentData(record)} >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add To Ribbon">
              <IconButton color="success" onClick={() => handleOpenAddToRibbon(record)} >
                <PlaylistAddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Title',
      key: 'entity-title',
      align: 'left',
      width: '30%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.entity?.title || '' }
          </div>
        )
      }
    },
    {
      title: 'Type',
      key: 'entity-type',
      align: 'left',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
              {record.entity.type === 1
                ? 'SINGLE MOVIE'
                : record.entity.type === 2
                  ? 'SHOW/DRAMA'
                  : record.entity.type === 3
                    ? 'SEASON'
                    : record.entity.type === 4
                      ? 'EPISODE'
                      : record.entity.type === 5
                        ? 'CHANNEL'
                        : record.entity.type === 6
                          ? 'TRAILER'
                          : record.entity.type === 7
                            ? 'DOCUMENT'
                            : record.entity.type === 8
                              ? 'BREAKING NEWS'
                              : record.entity.type === 9
                                ? 'AUDIO'
                                : record.entity.type === 10
                                  ? 'EVENT'
                                  : record.entity.type === 11
                                    ? 'VERTICAL VIDEO'
                                    : record.entity.type === 12
                                      ? 'SHOW AUDIO'
                                      : record.entity.type === 15
                                        ? 'NEWS REPORT'
                                        : record.entity.type === 16
                                          ? 'SPONSOR'
                                          : record.entity.type === 17
                                            ? 'MULTI NEWSPAPER'
                                            : record.entity.type === 18
                                              ? 'EMAGAZINE'
                                              : record.entity.type === 19
                                                ? 'INTERNAL NEWS'
                                                : 'Unknown'}
          </div>
        )
      }
    },
    {
      title: 'Owner',
      key: 'owner',
      align: 'left',
      width: '12%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.owner?.name || '' }
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record?.status === 1
              ? 'PUBLISH'
              : record?.status === 2
                ? 'CLOSED'
                : record?.status === 3
                  ? 'SCHEDULE PUBLISH'
                  : 'DRAFT'}
          </div>
        )
      }
    },
    {
      title: 'Content Sharing Status',
      dataIndex: 'share_status',
      key: 'share_status',
      width: '15%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record?.share_status === 1
              ? 'SHARING'
              : record?.share_status === 2
                ? 'REVOKE'
                : record?.share_status === 3
                  ? 'ON_HOLD'
                  : 'DRAFT'}
          </div>
        )
      }
    },
    {
      title: 'More info',
      key: 'more_info',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            <Button
              aria-describedby={isOpenViewMoreInfo ? 'simple-popover' : ''}
              variant="contained" onClick={(e) => handleClickViewMoreInfo(e, record)}
            >
              View
            </Button>
          </div>
        )
      }
    }
  ]

  return (
    <div className='share-content-with-me'>
      <Row className='pt-4'>
        <Col md={12}>
          <ShareContentWithMeFilter
            searchFilter={searchFilter}
            onClickSearch={handleClickSearchFilter}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Table
            columns={columns}
            data={shareContenList}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      {/* Popover View More info */}
      <Popover
          id={isOpenViewMoreInfo ? 'simple-popover' : ''}
          open={isOpenViewMoreInfo}
          anchorEl={anchorElViewMoreInfo}
          onClose={handleCloseViewMoreInfo}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {
            dataMoreInfo && (
              <div>
                <Typography sx={{ p: 2 }}>
                    <span style={{ fontWeight: 600, marginRight: 2 }}>Publish Date:</span>{dataMoreInfo?.publish_date || ''}
                  </Typography>
                  <Typography sx={{ p: 2 }}>
                    <span style={{ fontWeight: 600, marginRight: 2 }}>Release Date:</span>{dataMoreInfo?.release_date || ''}
                  </Typography>
                  {
                    dataMoreInfo?.schedule_publish && (
                      <Typography sx={{ p: 2 }}>
                        <span style={{ fontWeight: 600, marginRight: 2 }}>
                          Schedule Publish:</span>{dataMoreInfo?.schedule_publish || ''}
                      </Typography>
                    )
                  }
                  {
                    dataMoreInfo?.reason_on_hold && (
                      <Typography sx={{ p: 2 }}>
                        <span style={{ fontWeight: 600, marginRight: 2 }}>
                          Reason:
                        </span>{dataMoreInfo?.reason_on_hold || ''}
                      </Typography>
                    )
                  }
              </div>
            )
          }
        </Popover>

      {/* Form Dialog */}
      <FormDialog
        isOpen={isOpenUpdateshareContent}
        title='Update Content Sharing'
        maxWidth='md'
        onClose={handleCloseUpdateMoreInfo}
        onSave={handleSubmit(handleSaveUpdateShareContent)}
      >
        <Row className='pt-2'>
          <Col md={12}>
            <Autocomplete
              options={statusList}
              label="Status"
              name="status"
              control={control}
              error={errors.status}
            />
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col md={12}>
            <DateTimePicker
              label="Release Date"
              name="release_date"
              control={control}
              error={errors.release_date}
            />
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col md={12}>
            <DateTimePicker
              label="Publish Date"
              name="publish_date"
              control={control}
              error={errors.publish_date}
            />
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col md={12}>
            <DateTimePicker
              label="Schedule Publish"
              name="schedule_publish"
              control={control}
              error={errors.schedule_publish}
            />
          </Col>
        </Row>
      </FormDialog>

      <ShareContentAddToRibbon
        idAdd={idAdd}
        isOpen={isOpenRibbonItem}
        onClose={handleCloseAddToRibbon}
      />
    </div>
  )
}

export default ShareContentWithMe
