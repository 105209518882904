import ErrorPage from './features/error/pages/ErrorPage'
import LoginPage from './features/login/pages/LoginPage'
import HomePage from './features/home/pages/HomePage'
import UserPage from './features/user/pages/UserPage'
import UserFormPage from './features/user/pages/UserFormPage'
import UserPermissionPage from './features/user/pages/UserPermissionPage'
import UserResetPasswordPage from './features/user/pages/UserResetPasswordPage'
import UserProfilePage from './features/user/pages/UserProfilePage'
import UserProfileUpdatePasswordPage from './features/user/pages/UserProfileUpdatePasswordPage'
import MenuPage from './features/menu/pages/MenuPage'
import MenuFormPage from './features/menu/pages/MenuFormPage'
import MenuItemPage from './features/menu/pages/MenuItemPage'
import MenuItemFormPage from './features/menu/pages/MenuItemFormPage'
import MenuSlidePage from 'features/menu/pages/MenuSlidePage'
import MenuSlideFormPage from 'features/menu/pages/MenuSlideFormPage'
import RibbonPage from './features/ribbon/pages/RibbonPage'
import RibbonItemPage from './features/ribbon/pages/RibbonItemPage'
import RibbonFormPage from './features/ribbon/pages/RibbonFormPage'
import RibbonDisplayPage from 'features/ribbon_display/pages/RibbonDisplayPage'
import RibbonDisplayFormPage from 'features/ribbon_display/pages/RibbonDisplayFormPage'
import PeoplePage from './features/people/pages/PeoplePage'
import PeopleFormPage from './features/people/pages/PeopleFormPage'
import PeopleTypeFormPage from './features/people/pages/PeopleTypeFormPage'
import GroupPage from './features/group/pages/GroupPage'
import GroupFormPage from './features/group/pages/GroupFormPage'
import GroupPermissionPage from './features/group/pages/GroupPermissionPage'
import PermissionPage from './features/permission/pages/PermissionPage'
import VodPage from './features/vod/pages/VodPage'
import VodRecommendPage from './features/vod/pages/VodRecommendPage'
import VodNiceClipPage from './features/vod/pages/VodNiceClipPage'
import VodMetadataPage from './features/vod/pages/VodMetadataPage'
import VodEpgPage from './features/vod/pages/VodEpgPage'
import VodFormPage from './features/vod/pages/VodFormPage'
import VodEpgFormPage from './features/vod/pages/VodEpgFormPage'
import VodPinPage from './features/vod/pages/VodPinPage'
import VodPinFormPage from 'features/vod/pages/VodPinFormPage'
import CrawlerPage from 'features/crawler/pages/CrawlerPage'
import RssPage from 'features/crawler/pages/RssPage'
import RssFormPage from 'features/crawler/pages/RssFormPage'
import FaqPage from './features/faq/pages/FaqPage'
import AboutUsPage from './features/faq/pages/AboutUsPage'
import AdsInfoPage from './features/faq/pages/AdsInfoPage'
import MetaDataPage from './features/metadata/pages/MetaDataPage'
import MetaDataFormPage from './features/metadata/pages/MetaDataFormPage'
import ImagePage from './features/image/pages/ImagePage'
import ImageFormPage from './features/image/pages/ImageFormPage'
import CommentPage from './features/comment/pages/CommentPage'
import ApproveCommentPage from './features/comment/pages/ApproveCommentPage'
import BannerPositionPage from './features/image/pages/BannerPositionPage'
import BannerPositionFormPage from './features/image/pages/BannerPositionFormPage'
import TranscodeAddPage from './features/transcode/pages/TranscodeAddPage'
import TranscodeEditPage from './features/transcode/pages/TranscodeEditPage'
import TranscodePage from './features/transcode/pages/TranscodePage'
import TranscodeNotMappingPage from './features/transcode/pages/TranscodeNotMappingPage'
import ReportPage from './features/report/pages/ReportPage'
import ReportDataPage from './features/report/pages/ReportDataPage'
import ReportContentPage from 'features/report/pages/ReportContentPage'
import ReportUserViewPage from 'features/report/pages/ReportUserViewPage'
import ReportStatisticPage from 'features/report/pages/ReportStatisticPage'
import MenuMobilePage from 'features/menu/pages/MenuMobilePage'
import MenuMobileFormPage from 'features/menu/pages/MenuMobileFormPage'
import ShareContentByMePage from 'features/share_content/pages/ShareContentByMePage'
import ShareContentWithMePage from 'features/share_content/pages/ShareContentWithMePage'
import ShareContentReleaseRibbonPage from 'features/share_content/pages/ShareContentReleaseRibbon'
import TagTopicPage from 'features/topic/pages/TagTopicPage'
import TagTopicFormPage from 'features/topic/pages/TagTopicFormPage'
import RibbonSharingByMePage from 'features/share_ribbon/pages/RibbonSharingByMe'
import RibbonSharingWithMePage from 'features/share_ribbon/pages/RibbonSharingWithMe'
import EpgTimingPage from 'features/epg/pages/EpgTimingPage'
import EpgTimingFormPage from 'features/epg/pages/EpgTimingFormPage'
import ConfigPage from 'features/config/pages/ConfigPage'
import ConfigFormPage from 'features/config/pages/ConfigFormPage'
import AppVersionPage from 'features/app-version/pages/AppVersionPage'
import AppVersionFormPage from 'features/app-version/pages/AppVersionFormPage'

const routes = [
  { path: '/error', private: true, component: ErrorPage, exact: true },
  { path: '/login', private: false, component: LoginPage, exact: true },
  { path: '/', private: true, component: HomePage, exact: true },

  // User
  { path: '/user', private: true, component: UserPage, exact: true },
  { path: '/user/add', private: true, component: UserFormPage, exact: false },
  { path: '/user/:id', private: true, component: UserFormPage, exact: true },
  { path: '/user/:id/permission', private: true, component: UserPermissionPage, exact: true },
  { path: '/user/:id/reset-password', private: true, component: UserResetPasswordPage, exact: true },
  { path: '/profile', private: true, component: UserProfilePage, exact: true },
  { path: '/change-password', private: true, component: UserProfileUpdatePasswordPage, exact: true },

  // Menu
  { path: '/menu-platform', private: true, component: MenuPage, exact: true },
  { path: '/menu-platform/add', private: true, component: MenuFormPage, exact: false },
  { path: '/menu-platform/:id', private: true, component: MenuFormPage, exact: false },

  // Menu Bottom (For Mobile)
  { path: '/menu-mobile', private: true, component: MenuMobilePage, exact: true },
  { path: '/menu-mobile/add', private: true, component: MenuMobileFormPage, exact: false },
  { path: '/menu-mobile/:id', private: true, component: MenuMobileFormPage, exact: false },

  // Menu Item
  { path: '/menu', private: true, component: MenuItemPage, exact: true },
  { path: '/menu/add', private: true, component: MenuItemFormPage, exact: false },
  { path: '/menu/:id', private: true, component: MenuItemFormPage, exact: false },

  // Menu Slide
  { path: '/menu-slide', private: true, component: MenuSlidePage, exact: true },
  { path: '/menu-slide/add', private: true, component: MenuSlideFormPage, exact: false },
  { path: '/menu-slide/:id', private: true, component: MenuSlideFormPage, exact: false },

  // Ribbon
  { path: '/ribbon', private: true, component: RibbonPage, exact: true },
  { path: '/ribbon-item', private: true, component: RibbonItemPage, exact: true },
  { path: '/ribbon-item/:id', private: true, component: RibbonItemPage, exact: false },
  { path: '/ribbon/add', private: true, component: RibbonFormPage, exact: false },
  { path: '/ribbon/:id', private: true, component: RibbonFormPage, exact: false },
  { path: '/ribbon-display', private: true, component: RibbonDisplayPage, exact: true },
  { path: '/ribbon-display/add', private: true, component: RibbonDisplayFormPage, exact: false },
  { path: '/ribbon-display/:id', private: true, component: RibbonDisplayFormPage, exact: false },

  // People
  { path: '/people', private: true, component: PeoplePage, exact: true },
  { path: '/people/add', private: true, component: PeopleFormPage, exact: false },
  { path: '/people/:id', private: true, component: PeopleFormPage, exact: false },
  { path: '/people-types/add', private: true, component: PeopleTypeFormPage, exact: false },
  { path: '/people-types/:id', private: true, component: PeopleTypeFormPage, exact: false },

  // Tag Topic
  { path: '/tag-topic', private: true, component: TagTopicPage, exact: true },
  { path: '/tag-topic/add', private: true, component: TagTopicFormPage, exact: false },
  { path: '/tag-topic/:id', private: true, component: TagTopicFormPage, exact: false },

  // Group
  { path: '/group', private: true, component: GroupPage, exact: true },
  { path: '/group/add', private: true, component: GroupFormPage, exact: false },
  { path: '/group/:id', private: true, component: GroupFormPage, exact: true },
  { path: '/group/:id/permission', private: true, component: GroupPermissionPage, exact: true },

  // Permission
  { path: '/permission', private: true, component: PermissionPage, exact: true },

  // VOD
  { path: '/entityvod', private: true, component: VodPage, exact: true },
  { path: '/season', private: true, component: VodPage, exact: true },
  { path: '/episode', private: true, component: VodPage, exact: true },
  { path: '/trailer', private: true, component: VodPage, exact: true },
  { path: '/entityvod/add', private: true, component: VodFormPage, exact: false },
  { path: '/entityvod/:id', private: true, component: VodFormPage, exact: false },
  { path: '/singlemovie', private: true, component: VodPage, exact: false },
  { path: '/showdrama', private: true, component: VodPage, exact: false },
  { path: '/channel', private: true, component: VodPage, exact: false },
  { path: '/recommendation', private: true, component: VodRecommendPage, exact: true },
  { path: '/nice-clip', private: true, component: VodNiceClipPage, exact: true },
  { path: '/vod-metadata', private: true, component: VodMetadataPage, exact: true },
  { path: '/epg', private: true, component: VodEpgPage, exact: true },
  { path: '/epg/:id', private: true, component: VodEpgFormPage, exact: true },
  { path: '/entityvod-pin', private: true, component: VodPinPage, exact: true },
  { path: '/entityvod-pin/:id', private: true, component: VodPinFormPage, exact: true },

  // Crawler
  { path: '/crawler', private: true, component: CrawlerPage, exact: true },

  // RSS
  { path: '/rss', private: true, component: RssPage, exact: true },
  { path: '/rss/add', private: true, component: RssFormPage, exact: false },
  { path: '/rss/:id', private: true, component: RssFormPage, exact: false },

  // Comment
  { path: '/comments', private: true, component: CommentPage, exact: true },
  { path: '/approve-comment', private: true, component: ApproveCommentPage, exact: true },

  // Faq
  { path: '/faq', private: true, component: FaqPage, exact: true },
  { path: '/about-us', private: true, component: AboutUsPage, exact: true },
  { path: '/ads-info', private: true, component: AdsInfoPage, exact: true },

  // Meta Data
  { path: '/metadata', private: true, component: MetaDataPage, exact: true },
  { path: '/metadata/add', private: true, component: MetaDataFormPage, exact: false },
  { path: '/metadata/:id', private: true, component: MetaDataFormPage, exact: true },

  // Meta Data
  { path: '/image', private: true, component: ImagePage, exact: true },
  { path: '/image/add', private: true, component: ImageFormPage, exact: false },
  { path: '/image/:id', private: true, component: ImageFormPage, exact: true },
  { path: '/banner-position', private: true, component: BannerPositionPage, exact: true },
  { path: '/banner-position/add', private: true, component: BannerPositionFormPage, exact: false },
  { path: '/banner-position/:id', private: true, component: BannerPositionFormPage, exact: true },

  // Transcode
  { path: '/transcode/add', private: true, component: TranscodeAddPage, exact: true },
  { path: '/transcode/edit/:id', private: true, component: TranscodeEditPage, exact: true },
  { path: '/transcode/index', private: true, component: TranscodePage, exact: true },
  { path: '/transcode-not-mapping/index', private: true, component: TranscodeNotMappingPage, exact: true },

  // Report
  { path: '/report', private: true, component: ReportPage, exact: true },
  { path: '/report-data', private: true, component: ReportDataPage, exact: true },
  { path: '/report-content', private: true, component: ReportContentPage, exact: true },
  { path: '/report-view-user', private: true, component: ReportUserViewPage, exact: true },
  { path: '/report-statistic', private: true, component: ReportStatisticPage, exact: true },

  // EPG Timing
  { path: '/epg-timing', private: true, component: EpgTimingPage, exact: true },
  { path: '/epg-timing-update/:id', private: true, component: EpgTimingFormPage, exact: true },

  // Share Content
  { path: '/share-with-me', private: true, component: ShareContentWithMePage, exact: true },
  { path: '/share-by-me', private: true, component: ShareContentByMePage, exact: true },
  { path: '/release-content-sharing', private: true, component: ShareContentReleaseRibbonPage, exact: true },

  // Ribbon Sharing
  { path: '/ribbon-sharing-with-me', private: true, component: RibbonSharingWithMePage, exact: true },
  { path: '/ribbon-sharing-by-me', private: true, component: RibbonSharingByMePage, exact: true },

  // Config
  { path: '/config', private: true, component: ConfigPage, exact: true },
  { path: '/config/add', private: true, component: ConfigFormPage, exact: false },
  { path: '/config/:id', private: true, component: ConfigFormPage, exact: true },

  // App Version
  { path: '/app-version', private: true, component: AppVersionPage, exact: true },
  { path: '/app-version/add', private: true, component: AppVersionFormPage, exact: false },
  { path: '/app-version/:id', private: true, component: AppVersionFormPage, exact: true }
]

export default routes
