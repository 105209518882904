/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { getData, getTenantClientData, formatFormData } from 'common/utils'
// import { parse } from 'date-fns'
import Tooltip from '@mui/material/Tooltip'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Table from 'components/ui/Table'
import TableEpgTiming from 'components/ui/TableEpgTiming'
import Video from 'components/ui/Video'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { toast } from 'react-toastify'
import '../styles/epg-timing-form.scss'
import { HOSTNAME } from 'constant/appConstant'

const EpgTimingForm = () => {
  const { id } = useParams()
  const playerRef = useRef(null)

  const [src, setSrc] = useState(null)
  const [dateValue, setDateValue] = useState(new Date())
  const [list_epg, setList_epg] = useState([])
  const [listMeta, setListMeta] = useState([])
  const [player, setPlayer] = useState(null)

  // const [milliseconds, setMilliseconds] = useState(null)
  const startDateObjRef = useRef(null)
  const currentDateObjRef = useRef(null)
  const currentTimePlayerRef = useRef(0)
  const timingValueRef = useRef(0)
  const epgTimeCounterTextRef = useRef(null)
  const playPauseTextRef = useRef(null)
  const timingAtTextRef = useRef(null)
  const statusLockTextRef = useRef(null)
  const epgCurrentTitleTextRef = useRef(null)

  const dataEpgTimingRef = useRef(null)
  const dataEpgTimingEndRef = useRef(null)

  const tzoffset = (new Date()).getTimezoneOffset() * 60000
  const delta = 30 * 60 // 30mins)

  const [currentEpgIndex, setCurrentEpgIndex] = useState(-1)

  // eslint-disable-next-line no-unused-vars
  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('PUT', `vod-entities/${id}/epg-feeds-rematch-time-cachup/`, dataForm)
    }
  })

  const eventListener = useCallback(() => {
  }, [player, src, dateValue, list_epg, listMeta, currentEpgIndex])

  // const channel_dvr_linkplay = 'https://brt051220.cdn.vnns.io/<token>/BRT/playlist_dvr_range-<time>-<duration>.m3u8'
  // const token = '-eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDE3NjUwMTMsInVyaSI6Ii9CUlQifQ.6J36yVYTH36JfJxSQrQSbyPadmgCOCTuwU-rVPTKR6U-'

  const videoOptions = {
    controlBar: {
      fullscreenToggle: false,
      remainingTimeDisplay: false,
      // volumePanel: false,
      subsCapsButton: false,
      playToggle: false,
      pictureInPictureToggle: false,
      currentTimeDisplay: true,
      timeDivider: true,
      durationDisplay: true
    },
    controls: true,
    fluid: true,
    // muted: true,
    preload: 'auto',
    userActions: {
      doubleClick: false
    }
  }

  const columns = [
    {
      title: '#',
      key: 'index',
      width: '10px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{index + 1}</div>
        )
      }
    },
    {
      title: 'Handle',
      key: 'operation',
      // fixed: 'left',
      // align: 'center',
      width: '20px',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Button variant="contained" color="inherit" onClick={e => handleClickLoadEpgFromSource(e, record, index)}>
              Load
            </Button>
          </div>
        )
      }
    },
    {
      title: 'Time',
      key: 'time',
      width: '25px',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record.time}</div>
        )
      }
    },
    {
      title: 'Title',
      key: 'title',
      width: '100px',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record.entity__title}</div>
        )
      }
    }
  ]

  const columnsEpg = [
    {
      title: 'Key',
      key: 'key',
      width: '10px',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record.key}</div>
        )
      }
    },
    {
      title: 'Value',
      key: 'value',
      width: '100px',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record.value}</div>
        )
      }
    }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery(['getChannelFeed', id, dateValue], async () => {
    if (id) {
      if (dateValue) {
        const dateParse = formatFormData(dateValue)
        if (typeof dateParse === 'string' && dateParse.length === 10) {
          return await getData('GET', `vod-entities/${id}/epg-feeds-by-playdate?play_date=${dateParse}`)
        }
      } else {
        return await getData('GET', `vod-entities/${id}/epg-feeds-by-playdate`)
      }
    }
  })

  useEffect(() => {
    playPauseTextRef.current.innerHTML = 'PLAY'
    timingAtTextRef.current.innerHTML = 'N/A'
    statusLockTextRef.current.innerHTML = 'N/A'
    epgCurrentTitleTextRef.current.innerHTML = 'EPG TITLE'

    // const fetchEpg = async () => {
    //   const dateParse = formatFormData(dateValue)
    //   const resultTemp = await getTenantClientData('GET', `epg/${id}/?play-date=${dateParse}`, 'brt')
    //   console.log(resultTemp)
    // }

    // fetchEpg()
  }, [])

  useEffect(() => {
    if (player) {
      player.on('emptied', function () {
        statusLockTextRef.current.classList.remove('text-warning')
        statusLockTextRef.current.classList.remove('text-success')
        timingAtTextRef.current.innerHTML = 'N/A'
        statusLockTextRef.current.innerHTML = 'N/A'
      })
      player.on('waiting', function () {
        statusLockTextRef.current.classList.remove('text-success')
        statusLockTextRef.current.classList.add('text-warning')
        statusLockTextRef.current.innerHTML = 'Loading...'
      })
      player.on('seeking', function () {
        statusLockTextRef.current.classList.remove('text-success')
        statusLockTextRef.current.classList.add('text-warning')
        statusLockTextRef.current.innerHTML = 'Loading...'
      })
      player.on('canplay', function () {
        statusLockTextRef.current.classList.remove('text-warning')
        statusLockTextRef.current.classList.add('text-success')
        statusLockTextRef.current.innerHTML = 'Done'
      })
      player.on('canplaythrough', function () {
        statusLockTextRef.current.classList.remove('text-warning')
        statusLockTextRef.current.classList.add('text-success')
        statusLockTextRef.current.innerHTML = 'Done'
      })
      player.on('timeupdate', function () {
        // console.info('player timeupdate');
        displayTimeCounter(player.currentTime())
        // timeCounterElem.innerHTML = displayValue;
      })
      // player.on('seeked', function() {
      //     currentTimePlayer = player.currentTime();
      //     // console.info('player seeked', player.currentTime());
      //     $('#time_counter').css({'color':'green'}).fadeOut(70, function() {
      //         $(this).css({'color':'yellow'}).fadeIn(100);
      //     })
      // })
      player.on('loadedmetadata', function () {
        // console.info('player event', 'loadedmetadata');
        player.currentTime(delta)
        player.pause()
      })
      player.on('pause', function () {
        playPauseTextRef.current.innerHTML = 'PLAY'
      })
      player.on('play', function () {
        playPauseTextRef.current.innerHTML = 'PAUSE'
      })
    }
  }, [eventListener])

  // useEffect(() => {
  //   setSrc({
  //     type: 'application/x-mpegURL',
  //     src: 'https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8'
  //   })
  // }, [])

  useEffect(() => {
    if (dataList) {
      // const resultData = dataList.data && dataList.data.results
      const resultData = dataList.data

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map((item, index) => {
          // const itemStartAt = item.start_at
          // const itemEndAt = item.end_at
          // const startAtStr = typeof itemStartAt === 'string' ? itemStartAt.slice(11) : ''
          // const endAtStr = typeof itemEndAt === 'string' ? itemEndAt.slice(11) : ''

          // let _start_at = (new Date(parseInt(item.start_at * 1000) - tzoffset)).toISOString().slice(0, -5).replace('T', ' ')
          let _start_at_arr = (new Date(parseInt(item.start_at * 1000) - tzoffset)).toISOString().slice(0, -5).split('T')
          let _end_at_arr = (new Date(parseInt(item.end_at * 1000) - tzoffset)).toISOString().slice(0, -5).split('T')
          let display_time = _start_at_arr[1] + ' - ' + _end_at_arr[1]

          return {
            key: item.id + index,
            time: display_time,
            ...item
          }
        })

        setList_epg(dataListParse)
      }
    }
  }, [dataList])

  // const getMonthHuman = (monthIndex) => {
  //   return monthIndex + 1
  // }

  // const padZero = (n) => {
  //   return ((parseInt(n) < 10) ? ('0' + n) : n).toString()
  // }

  // const checkAndReturnMiliSeconds = (milliseconds) => {
  //   let _mili_tmp = milliseconds
  //   if (Math.abs(Date.now() - _mili_tmp) < Math.abs(Date.now() - _mili_tmp * 1000)) {
  //     _mili_tmp = parseInt(_mili_tmp)
  //   } else {
  //     console.log('seconds')
  //     _mili_tmp = parseInt(_mili_tmp * 1000)
  //   }
  //   return _mili_tmp
  // }

  // const convertMilisecondToVideoFrame = (time, fps) => {
  //   const _milisec = (parseFloat(time) < 0) ? parseFloat(time) : parseFloat(time) / 1000
  //   return padZero((parseFloat(_milisec) > 0 ? (Math.ceil(parseFloat(_milisec) * fps) - 1) : 0))
  // }

  const displayTiming = (timestamp_mili) => {
    let localISOTime = (new Date(timestamp_mili - tzoffset)).toISOString().slice(0, -1)
    let _tmp = localISOTime.split('T')
    let _tmp_1 = (_tmp[1]).split('.')
    let localDateStr = _tmp[0]
    let localTimeStr = _tmp_1[0]
    // let localMiliStr = _tmp_1[1]
    // let forFrame = convertMilisecondToVideoFrame(localMiliStr, fps_selected)
    let result = localDateStr + ' | ' + localTimeStr
    return result
  }

  const displayTimeCounter = (time) => {
    // let start = 1698432156 // 13 * 60 * 60;

    let militime = time * 1000
    const currentDateObjTemp = new Date(startDateObjRef.current?.getTime() + militime)
    currentDateObjRef.current = currentDateObjTemp

    let localISOTime = (new Date(currentDateObjTemp.getTime() - tzoffset)).toISOString().slice(0, -1)
    let _tmp = localISOTime.split('T')
    let _tmp_1 = (_tmp[1]).split('.')
    let localDateStr = _tmp[0]
    let localTimeStr = _tmp_1[0]
    // let localMiliStr = _tmp_1[1]
    // let forFrame = convertMilisecondToVideoFrame(localMiliStr, fps_selected)
    let result = localDateStr + ' | ' + localTimeStr
    currentTimePlayerRef.current = currentDateObjTemp.getTime()
    epgTimeCounterTextRef.current.innerHTML = result
    return result
  }

  const updateItemPlaying = (epg_id) => {
  //   $("tr.epg-item-tr").each(function(){
  //     if ($(this).data('epg_id') === epg_id) {
  //         $(this).addClass('epg-item-playing');
  //     } else {
  //         $(this).removeClass('epg-item-playing');
  //     }
  //  });
  }

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handlePlayerReady = (playerParam) => {
    playerRef.current = playerParam
    setPlayer(playerParam)
  }

  const handleChangeDate = (value) => {
    setDateValue(value)
  }

  const handleClickLoadEpgFromSource = (e, data, index) => {
    let current_timestamp_in_sec = Math.floor(Date.now() / 1000)
    let _data = JSON.parse(JSON.stringify(data))
    timingValueRef.current = _data

    // let _id = _data.id
    // let _epg_index = _data.epg_index
    let _epg_id = _data.id
    let _title = _data.entity__title
    // let _start = Math.floor((parse(_data.start_at, 'dd-MM-yyyy HH:mm:ss', new Date())) / 1000)
    let _start = _data.start_at
    // let _end = _data.epg_end
    // let _begin_time = (_start - (delta * 3))
    let _begin_time = (_start - delta)

    if (current_timestamp_in_sec < _start) {
      alert('EPG "' + _title + '" chưa đến giờ phát sóng')
      return false
    }

    // let _duration = delta + 0 + delta
    let _duration = 3 * 60 * 60
    // setStartDateObj(new Date((_begin_time) * 1000))
    startDateObjRef.current = new Date((_begin_time) * 1000)

    const fetchEpg = async () => {
      const dateParse = formatFormData(dateValue)

      const tenantSlugTemp = (window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING)
        ? 'tayninh'
        : 'brt'

      const resultTemp = await getTenantClientData('GET', `epg/${id}/?play-date=${dateParse}`, tenantSlugTemp)
      if (resultTemp) {
        const dataTemp = resultTemp.data
        if (dataTemp) {
          const itemArrTemp = dataTemp.items
          if (Array.isArray(itemArrTemp)) {
            const firstLinkPlayTemp = itemArrTemp[0]
            if (firstLinkPlayTemp) {
              let linkPlayDvrTemp = firstLinkPlayTemp.link_play
              // let linkPlayDvrTempSubStr = typeof linkPlayDvrTemp === 'string' ? linkPlayDvrTemp.substring(0, linkPlayDvrTemp.indexOf('/BRT')) : ''
              // let linkPlayTemp = linkPlayDvrTempSubStr + '/BRT/playlist_dvr_range-<time>-<duration>.m3u8'

              let linkPlayDvrTempSubStr = typeof linkPlayDvrTemp === 'string' ? linkPlayDvrTemp.substring(0, linkPlayDvrTemp.indexOf('/playlist_dvr_range')) : ''
              let linkPlayTemp = linkPlayDvrTempSubStr + '/playlist_dvr_range-<time>-<duration>.m3u8'

              // let linkplay = linkPlayDvrTemp.replace('<token>', token).replace('<time>', _begin_time).replace('<duration>', _duration)
              let linkplay = linkPlayTemp.replace('<time>', _begin_time).replace('<duration>', _duration)

              // const linkPlayDataTemp = (window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING)
              //   ? linkPlayDvrTemp
              //   : linkplay
              setSrc(linkplay)
              playPauseTextRef.current.innerHTML = 'PLAY'

              const currentDateObjTemp = new Date(_start * 1000)
              currentDateObjRef.current = currentDateObjTemp

              const timingValueTemp = JSON.parse(JSON.stringify(currentDateObjTemp.getTime()))
              timingValueRef.current = timingValueTemp

              let _val = displayTiming(timingValueTemp)
              timingAtTextRef.current.innerHTML = _val
              epgCurrentTitleTextRef.current.innerHTML = _title

              if (typeof _data === 'object') {
                const dataTemp = Object.keys(_data).map(item => {
                  return {
                    key: item,
                    value: _data[item]
                  }
                })
                setListMeta(dataTemp)
              }

              setCurrentEpgIndex(index)
              updateItemPlaying(_epg_id)
            }
          }
        }
      }
    }

    fetchEpg()
  }

  const handleClickPrev = () => {
    setCurrentEpgIndex(currentEpgIndex - 1)
    handleClickLoadEpgFromSource(null, list_epg[currentEpgIndex - 1], currentEpgIndex - 1)
  }

  const handleClickNext = () => {
    setCurrentEpgIndex(currentEpgIndex + 1)
    handleClickLoadEpgFromSource(null, list_epg[currentEpgIndex + 1], currentEpgIndex + 1)
  }

  const handleClickPlayPause = () => {
    if (player) {
      if (player.paused()) {
        player.play()
      } else {
        player.pause()
      }
    }
  }

  const handleClickStop = () => {
    player.currentTime(delta)
    player.pause()
  }

  const handleClickSetTime = () => {
    const timingValueTemp = JSON.parse(JSON.stringify(currentDateObjRef.current?.getTime()))
    timingValueRef.current = timingValueTemp
    let _val = displayTiming(timingValueTemp)
    timingAtTextRef.current.innerHTML = _val

    if (player) {
      player.pause()
    }

    dataEpgTimingRef.current = Math.round(timingValueTemp / 1000)

    // currentPlayingEl.attr("data-epg_timing", Math.round(timingValue / 1000));
  }

  const handleClickSetEndTime = () => {
    const timingValueTemp = JSON.parse(JSON.stringify(currentDateObjRef.current?.getTime()))
    timingValueRef.current = timingValueTemp
    let _val = displayTiming(timingValueTemp)
    timingAtTextRef.current.innerHTML = _val

    if (player) {
      player.pause()
    }

    dataEpgTimingEndRef.current = Math.round(timingValueTemp / 1000)

    // currentPlayingEl.attr("data-epg_timing", Math.round(timingValue / 1000));
  }

  const handleClickPlayTime = () => {
    // let start_at = (timingValueRef.current - startDateObjRef.current?.getTime()) / 1000

    if (player) {
      // player.currentTime(start_at)
      player.currentTime(delta)
      player.play()
    }
  }

  const handleClickTimerSeek = (e, valueParam) => {
    player.currentTime(player.currentTime() + valueParam * 1)
  }

  const handleClickReset = () => {
    if (startDateObjRef.current) {
      const timingValueTemp = JSON.parse(JSON.stringify(startDateObjRef.current.getTime()))
      let start_at = (timingValueTemp - startDateObjRef.current.getTime()) / 1000
      let _val = displayTiming(timingValueRef.current)
      timingAtTextRef.current.innerHTML = _val

      if (player) {
        player.currentTime(start_at)
        player.pause()
      }
    }
  }

  const handleClickConfirm = () => {
    let gap = parseInt(dataEpgTimingRef.current - list_epg[currentEpgIndex].start_at)

    const listEpgTemp = [...list_epg]

    for (let i = 0; i < listEpgTemp.length; i++) {
      const item = listEpgTemp[i]

      if (i === currentEpgIndex) {
        item.start_gap = gap
        // const startAtGap = parseInt(item.start_at) + gap
        // const endAtGap = parseInt(item.end_at) + gap

        const startAtGap = dataEpgTimingRef.current ? parseInt(dataEpgTimingRef.current) : parseInt(item.start_at)
        const endAtGap = dataEpgTimingEndRef.current ? parseInt(dataEpgTimingEndRef.current) : parseInt(item.end_at)

        item.start_at = startAtGap
        item.end_at = endAtGap

        let _start_at_arr = (new Date(parseInt(startAtGap * 1000) - tzoffset)).toISOString().slice(0, -5).split('T')
        let _end_at_arr = (new Date(parseInt(endAtGap * 1000) - tzoffset)).toISOString().slice(0, -5).split('T')
        let display_time = _start_at_arr[1] + ' - ' + _end_at_arr[1]
        item.time = display_time

        if ((window.location.hostname === HOSTNAME.LOCALHOST || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING)) {
          //
        } else {
          if (i > 0 && typeof listEpgTemp[i - 1] !== 'undefined') {
            let _prev_item = listEpgTemp[i - 1]
            _prev_item.end_at = parseInt(parseInt(item.start_at))
            listEpgTemp[i - 1] = _prev_item
          }

          if (i > 0 && typeof listEpgTemp[i + 1] !== 'undefined') {
            let _next_item = listEpgTemp[i + 1]
            _next_item.start_at = parseInt(parseInt(item.end_at))
            listEpgTemp[i + 1] = _next_item
          }
        }
      }

      listEpgTemp[i] = item
    }

    setList_epg(listEpgTemp)

    // handleClickLoadEpgFromSource(null, list_epg[currentEpgIndex], currentEpgIndex)

    // currentDateObjRef.current = null
    // startDateObjRef.current = null
  }

  const handleClickSaveUpdate = async () => {
    try {
      const itemParseTemp = list_epg.map(item => {
        return {
          id: item.id,
          start_time: item.start_at,
          end_time: item.end_at
        }
      })

      await mutation.mutateAsync({
        batchs: itemParseTemp
      })

      toast.success('EPG updated successfully')
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong!')
    }
  }

  return (
    <div className="epg-timing-update">
      <Row>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <div className="epg-current-title-text" ref={epgCurrentTitleTextRef}></div>
              <div className="epg-current-title-text" ref={epgTimeCounterTextRef}>YYYY/MM/DD HH:MM:SS</div>
              <div className="epg-timing-update-video">
                <Video options={videoOptions} onReady={handlePlayerReady} src={src} />
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <div className="epg-timing-btn-container">
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, -60)}>-60</div>
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, -10)}>-10</div>
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, -3)}>-3</div>
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, -1)}>-1</div>
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, 1)}>+1</div>
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, 3)}>+3</div>
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, 10)}>+10</div>
                <div className="epg-timing-btn" onClick={e => handleClickTimerSeek(e, 60)}>+60</div>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={3}>
              <Tooltip title="Về EPG phía trước">
                <Button variant="contained" color="success" fullWidth onClick={handleClickPrev}>PREV</Button>
              </Tooltip>
            </Col>
            <Col md={3}>
              <Tooltip title="Ngưng phát & quay về giờ bắt đầu">
                <Button variant="contained" color="success" fullWidth onClick={handleClickStop}>STOP</Button>
              </Tooltip>
            </Col>
            <Col md={3}>
              <Tooltip title="Tạm dừng / Phát tiếp">
                <Button variant="contained" color="success" fullWidth onClick={handleClickPlayPause} ref={playPauseTextRef}></Button>
              </Tooltip>
            </Col>
            <Col md={3}>
              <Tooltip title="Lên EPG kế tiếp">
                <Button variant="contained" color="success" fullWidth onClick={handleClickNext}>NEXT</Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="mt-5">
            {/* <Col md={3}>
              <Tooltip title="Đặt thời gian bắt đầu">
                <Button variant="contained" color="warning" fullWidth onClick={handleClickSetTime}>SET TIME</Button>
              </Tooltip>
            </Col> */}
            <Col md={4}>
              <Tooltip title="Phát từ thời gian bắt đầu">
                <Button variant="contained" color="primary" fullWidth onClick={handleClickPlayTime}>PLAYTIME</Button>
              </Tooltip>
            </Col>
            <Col md={4}>
              <Tooltip title="Xác nhận phần vừa chỉnh sửa">
                <Button variant="contained" color="success" fullWidth onClick={handleClickConfirm}>CONFIRM</Button>
              </Tooltip>
            </Col>
            <Col md={4}>
              <Tooltip title="Reset">
                <Button variant="contained" color="inherit" fullWidth onClick={handleClickReset}>RESET</Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col md={6}>
              <Tooltip title="Đặt thời gian bắt đầu">
                <Button variant="contained" color="warning" fullWidth onClick={handleClickSetTime}>SET START TIME</Button>
              </Tooltip>
            </Col>
            <Col md={6}>
              <Tooltip title="Đặt thời gian kết thúc">
                <Button variant="contained" color="warning" fullWidth onClick={handleClickSetEndTime}>SET END TIME</Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <div className="d-inline-block">Timing: <span ref={timingAtTextRef}></span></div>
              <div className="d-inline-block float-right">Load status: <span ref={statusLockTextRef}></span></div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Table
                columns={columnsEpg}
                data={listMeta}
                loading={isLoading || isFetching}
              />
            </Col>
          </Row>
        </Col>

        <Col md={1}></Col>

        <Col md={7}>
          <Row>
            <Col md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={dateValue}
                  clearable
                  // inputFormat="dd/mm/yyyy"
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} size="small" variant="standard" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={4}></Col>
            <Col md={4}>
              <div className="text-right mt-3">
                <Button variant="contained" color="primary" onClick={handleClickSaveUpdate}>Lưu chỉnh sửa</Button>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <div className="epg-timing-update-table">
                <TableEpgTiming
                  columns={columns}
                  data={list_epg}
                  currentIndex={currentEpgIndex}
                  loading={isLoading || isFetching}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default EpgTimingForm
