import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getData, formatFormData } from 'common/utils'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Autocomplete from '@mui/material/Autocomplete'
import Button from 'components/form/Button'
import Tree from 'components/ui/Tree'
import { Select } from 'antd'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import DownloadIcon from '@mui/icons-material/Download'
import Pagination from '@mui/material/Pagination'
import { toast } from 'react-toastify'
import 'antd/lib/select/style/index.css'
import '../styles/report.scss'

const { Option } = Select

const ReportData = (props) => {
  const queryClient = useQueryClient()

  const [isAllow, setIsAllow] = useState(true)
  const [exportList, setExportList] = useState([])
  const [exportWorkingList, setExportWorkingList] = useState([])
  const [menuList, setMenuList] = useState([])
  const [menu, setMenu] = useState(null)
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())

  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [offset, setOffset] = useState(0)

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataMenu } = useQuery('getMenuItemMenuData', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataExportEntityByMenu } = useQuery(['getExportEntityByMenuData', limit, offset], async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/export-entity-by-menu/?limit=${limit}&offset=${offset}`)
  })

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setMenuList(selectData)
      }
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataExportEntityByMenu) {
      const dataResult = dataExportEntityByMenu.data
      if (dataResult) {
        const resultArr = dataResult.results
        if (Array.isArray(resultArr) && resultArr.length > 0) {
          const dataTemp = resultArr.map((item, index) => {
            if (item.status !== 1) {
              setIsAllow(false)
            }

            return {
              key: `0-${index}`,
              id: item.id,
              title: item.name,
              location: item.location,
              is_active: item.status
            }
          })

          setTotal(dataResult.total)
          setTotalPage(Math.ceil(dataResult.total / limit))

          // const dataTempWorkingParse = dataTemp.filter(el => {
          //   return el.is_active !== 1
          // })
          // setExportWorkingList(dataTempWorkingParse)

          const dataTempParse = dataTemp.filter(el => {
            return el.is_active === 1
          })
          setExportList(dataTempParse)
        }
      }
    }
  }, [dataExportEntityByMenu])

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      setMenu(menuList[0])
    }
  }, [menuList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeMenu = (e, value) => {
    setMenu(value)
  }

  const handleChangeFromDate = value => {
    setFromDate(value)
  }

  const handleChangeToDate = value => {
    setToDate(value)
  }

  const handleClickExport = (e, item) => {
    if (item) {
      const link = document.createElement('a')
      link.download = `${item.name}.xlsx`
      link.href = item.location
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleClickExportData = async () => {
    try {
      // const ribbonIdParam = item.id
      const fromDateParam = formatFormData(fromDate)
      const toDateParam = formatFormData(toDate)

      // const resultExport = await getData(
      //   'GET',
      //   `export_excel_brt/?ribbon_id=${ribbonIdParam}&from_date=${fromDateParam}&to_date=${toDateParam}`,
      //   '6068de35-ee56-48e7-9e17-fd2e9535cb62'
      //   // localStorage.getItem('tenant')
      // )

      const resultExport = await getData(
        'POST',
        'export-data/',
        {
          tenant: localStorage.getItem('tenant'),
          menu: menu?.value,
          from_date: fromDateParam,
          to_date: toDateParam
        }
      )

      if (resultExport) {
        const dataExportWorking = resultExport.data
        if (dataExportWorking) {
          setExportWorkingList([
            {
              key: '0-0',
              id: dataExportWorking.id,
              title: dataExportWorking.name,
              location: dataExportWorking.location,
              is_active: 0
            }
          ])
        }

        queryClient.invalidateQueries('getExportEntityByMenuData')
        toast.success('Prepare data successfully')
        // const resultData = resultExport.data
        // if (resultData) {
        //   const dataLocation = resultData.location
        //   if (dataLocation && dataLocation !== '') {
        //     const link = document.createElement('a')
        //     link.download = `${resultData.name}.xlsx`
        //     link.href = resultData.location
        //     document.body.appendChild(link)
        //     link.click()
        //     document.body.removeChild(link)
        //   } else {
        //     toast.warning('Pending')
        //   }
        // }
      }
    } catch (err) {
      toast.error('Something went wrong!')
    }
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  return (
    <div className="report">
      <Card>
        <CardContent>
          <Row className="pt-2 align-items-end">
            <Col md={4}>
              <Autocomplete
                fullWidth
                disableClearable
                size="small"
                options={menuList}
                value={menu}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(e, newValue) => handleChangeMenu(e, newValue)}
                renderInput={(params) => <TextField {...params} label="Platform Menu" />}
              />
            </Col>
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="From Date"
                  value={fromDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeFromDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="To Date"
                  value={toDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeToDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={2}>
              {isAllow &&
                <div className="float-right">
                  <Button onClick={handleClickExportData}>Export</Button>
                </div>
              }
            </Col>
          </Row>
        </CardContent>
      </Card>

      {/* <Row className="pt-5 pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row> */}

      {(Array.isArray(exportWorkingList) && exportWorkingList.length > 0) &&
        <>
          <Row className="pt-5 pb-2">
            <Col md={12}>
              <div className="history-export-text">Đang export:</div>
            </Col>
          </Row>

          <Row className='pt-1'>
            <Col md={12}>
              <Tree
                className="draggable-tree"
                // expandedKeys={expandedKeys}
                blockNode
                draggable={false}
                treeData={exportWorkingList}
                titleRender={(nodeData) => {
                  return (
                    <div>
                      {nodeData.title}
                    </div>
                  )
                }}
              />
            </Col>
          </Row>
        </>
      }

      <Row className="pt-5 pb-2">
        <Col md={12}>
          <div className="history-export-text">Lịch sử export:</div>
        </Col>
      </Row>

      <Row className='pt-1'>
        <Col md={12}>
          <Tree
            className="draggable-tree"
            // expandedKeys={expandedKeys}
            blockNode
            draggable={false}
            treeData={exportList}
            titleRender={(nodeData) => {
              return (
                <div>
                  {nodeData.title}

                  {nodeData.is_active === 1 &&
                    <div className="ribbon-view float-right mr-2">
                      <Button startIcon={<DownloadIcon />} onClick={e => handleClickExport(e, nodeData)}>Download</Button>
                    </div>
                  }

                  {/* <div className="float-right mr-5">
                    {nodeData.is_active &&
                      <div>
                        <IconButton color="success">
                          <CheckIcon />
                        </IconButton>
                        Active
                      </div>
                    }
                  </div> */}
                </div>
              )
            }}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>
    </div>
  )
}

export default ReportData
