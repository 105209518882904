import React from 'react'
import PropTypes from 'prop-types'
import { Tree as TreeAntd } from 'antd'
import 'antd/lib/tree/style/index.css'
import './styles/tree.scss'

const Tree = (props) => {
  const { className, draggable, treeData, onDragEnter, onDrop, titleRender } = props

  return (
    <div className={`adm-tree${className === '' ? '' : (' ' + className)}`}>
      <TreeAntd
        className="draggable-tree"
        draggable={draggable}
        blockNode
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        treeData={treeData}
        titleRender={titleRender}
      />
    </div>
  )
}

Tree.propTypes = {
  className: PropTypes.string,
  draggable: PropTypes.bool,
  treeData: PropTypes.array,
  expandedKeys: PropTypes.array,
  onDragEnter: PropTypes.func,
  onDrop: PropTypes.func,
  titleRender: PropTypes.any
}

Tree.defaultProps = {
  className: '',
  draggable: true,
  treeData: [],
  expandedKeys: []
}

export default Tree
