import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useAuth, ProvideAuth } from 'common/context'
import routes from './routes'
import store from 'common/store'
import NotFoundPage from 'features/core/pages/NotFoundPage'
import './index.scss'
import reportWebVitals from './reportWebVitals'

const PrivateRoute = ({ ...rest }) => {
  const auth = useAuth()
  return auth && auth.token
    ? (
      <Route {...rest} />
      )
    : (
      <Redirect to={{ pathname: '/login' }} />
      )
}

const PublicRoute = ({ ...rest }) => {
  const auth = useAuth()
  return auth && !auth.token
    ? (
      <Route {...rest} />
      )
    : (
      <Redirect to={{ pathname: '/' }} />
      )
}

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0
      }
    }
  })

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ProvideAuth>
          <BrowserRouter>
            <Switch>
              {routes.map((route, index) => {
                return route.private
                  ? (
                    <PrivateRoute key={index} path={route.path} component={route.component} exact={route.exact} />
                    )
                  : (
                    <PublicRoute key={index} path={route.path} component={route.component} exact={route.exact} />
                    )
              })}
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </BrowserRouter>
        </ProvideAuth>
      </QueryClientProvider>
    </Provider>
  )
}

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
