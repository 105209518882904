import React, { useState } from 'react'
import { getTenantData, formatFormData } from 'common/utils'
import { HOSTNAME } from 'constant/appConstant'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Button from 'components/form/Button'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import DownloadIcon from '@mui/icons-material/Download'
import '../styles/report.scss'

const ReportContent = (props) => {
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeFromDate = value => {
    setFromDate(value)
  }

  const handleChangeToDate = value => {
    setToDate(value)
  }

  const handleClickExport = async () => {
    const fromDateParam = formatFormData(fromDate)
    const toDateParam = formatFormData(toDate)

    const resultExport = await getTenantData(
      'GET',
      `export/content-released/?from_date=${fromDateParam}&to_date=${toDateParam}`,
      localStorage.getItem('tenant')
    )
    if (resultExport) {
      const resultData = resultExport.data
      if (resultData) {
        const link = document.createElement('a')
        link.download = 'report.xlsx'
        link.href = resultData.excel_url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const handleClickExportTopView = async () => {
    const resultExport = await getTenantData(
      'GET',
      'export/topview-content-released/',
      localStorage.getItem('tenant')
    )
    if (resultExport) {
      const resultData = resultExport.data
      if (resultData) {
        const link = document.createElement('a')
        link.download = 'report_topview.xlsx'
        link.href = resultData.excel_url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  return (
    <div className="report">
      <Card>
        <CardContent>
          <Row>
            <Col md={12}>
              <Typography variant="h5" component="div">
                List of published content
              </Typography>
            </Col>
          </Row>
          <Row className="pt-4 align-items-end">
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="From Date"
                  value={fromDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeFromDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="To Date"
                  value={toDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeToDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={3}>
              <Button startIcon={<DownloadIcon />} onClick={handleClickExport}>Export CSV</Button>
            </Col>
          </Row>
        </CardContent>
      </Card>

      {window.location.hostname === HOSTNAME.SDTV &&
        <Card className="mt-5">
          <CardContent>
            <Row>
              <Col md={12}>
                <Typography variant="h5" component="div">
                  List of top view content
                </Typography>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col md={3}>
                <Button startIcon={<DownloadIcon />} onClick={handleClickExportTopView}>Export CSV</Button>
              </Col>
            </Row>
          </CardContent>
        </Card>
      }
    </div>
  )
}

export default ReportContent
