import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodPinForm from '../components/VodPinForm'

const VodPinFormPage = (props) => {
  return (
    <Layout>
      <VodPinForm />
    </Layout>
  )
}

export default VodPinFormPage
