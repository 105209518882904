import React from 'react'
import Layout from 'features/layout/components/Layout'
import AppVersionForm from '../components/AppVersionForm'

const AppVersionFormPage = (props) => {
  return (
    <Layout>
      <AppVersionForm />
    </Layout>
  )
}

export default AppVersionFormPage
