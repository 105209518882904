import React from 'react'
import Layout from 'features/layout/components/Layout'
import MetaDataForm from '../components/MetaDataForm'

const MetaDataFormPage = (props) => {
  return (
    <Layout>
      <MetaDataForm />
    </Layout>
  )
}

export default MetaDataFormPage
