import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { getData } from 'common/utils'
import { Row, Col } from 'components/ui'
import Button from 'components/form/Button'
import AlertDialog from 'components/ui/AlertDialog'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import { Table } from 'antd'
import { toast } from 'react-toastify'
import 'antd/lib/table/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/space/style/index.css'
import 'antd/lib/pagination/style/index.css'
import '../../comment/styles/comment.scss'

const AppVersion = (props) => {
  const queryClient = useQueryClient()

  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [idDelete, setIdDelete] = useState(null)

  const { data: dataList, isLoading, isFetching } = useQuery('getAppVersion', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/app_versions`)
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `app_versions/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getAppVersion')
    }
  })

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <Link to={`/app-version/${record.id}`}>
                <IconButton color="success">
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      width: '22%'
    },
    {
      title: 'Platform',
      dataIndex: 'platform_str',
      key: 'platform_str',
      width: '16%'
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%'
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '20%'
    },
    {
      title: 'Force update',
      key: 'is_force_update',
      align: 'center',
      width: '12%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.is_force_update) {
          return <CheckIcon color="success" />
        } else {
          return null
        }
      }
    }
  ]

  useEffect(() => {
    if (dataList) {
      let resultData = []
      const dataListData = dataList.data

      if (dataListData) {
        if (Array.isArray(dataListData)) {
          resultData = dataListData
          setTotal(dataListData.length)
        } else {
          resultData = dataListData.results
          setTotal(dataListData.total)
        }
      }

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        setData(dataListParse)
      }
    }
  }, [dataList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)

      setIsOpenDelete(false)
      toast.success('Comment deleted successfully')
    } catch (err) {
      setIsOpenDelete(false)
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  return (
    <div className="comments">
      <Row className="pb-2">
        <Col md={12}>
          <Link to="/app-version/add">
            <Button className="float-right" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={12}>
          <div className="total-text-custom-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{
              x: 1000
            }}
            bordered
            pagination={false}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
    </div>
  )
}

export default AppVersion
