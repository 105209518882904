import React from 'react'
import Layout from 'features/layout/components/Layout'
import ReportStatistic from '../components/ReportStatistic'

const ReportStatisticPage = (props) => {
  return (
    <Layout>
      <ReportStatistic />
    </Layout>
  )
}

export default ReportStatisticPage
