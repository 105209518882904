import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodRecommend from '../components/VodRecommend'

const VodRecommendPage = (props) => {
  return (
    <Layout>
      <VodRecommend />
    </Layout>
  )
}

export default VodRecommendPage
