import React from 'react'
import Layout from 'features/layout/components/Layout'
import BannerPositionForm from '../components/BannerPositionForm'

const BannerPositionFormPage = (props) => {
  return (
    <Layout>
      <BannerPositionForm />
    </Layout>
  )
}

export default BannerPositionFormPage
