import React from 'react'
import Layout from 'features/layout/components/Layout'
import RibbonDisplay from '../components/RibbonDisplay'

const RibbonDisplayPage = (props) => {
  return (
    <Layout>
      <RibbonDisplay />
    </Layout>
  )
}

export default RibbonDisplayPage
