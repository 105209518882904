import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import FormDialog from 'components/form/FormDialog'
import PropTypes from 'prop-types'
import { Col, Row, Table } from 'components/ui'
import { getData } from 'common/utils'
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material'
import { toast } from 'react-toastify'
import Button from 'components/form/Button'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'
import GroupsIcon from '@mui/icons-material/Groups'
import { DatePicker } from '@mui/lab'
import { format } from 'date-fns'

const VodShareContentForm = (props) => {
  const stepLength = 1 // Count From 0
  const { idAdd, isOpen, onClose } = props
  const queryClient = useQueryClient()

  /* ----------------------------------------------- */
  /* ----------------- useState ------------------- */
  /* ----------------------------------------------- */
  const [tenantList, setTenantList] = useState([])
  const [tenantLinked, setTenantLinked] = useState([])
  const [tenantShared, setTenantShared] = useState([])
  const [idShareContent, setIdShareContent] = useState('')

  const [step, setStep] = useState(0)
  const [datePickerChange, setDatePickerChange] = useState({})
  const [isOpenPlatform, setIsOpenPlatform] = useState(false)

  /* ----------------------------------------------- */
  /* ----------------- Fetch API ------------------- */
  /* ----------------------------------------------- */
  const { data: tenantListData } = useQuery(['getTenantListShortInfo', idAdd, isOpen], async () => {
    if (idAdd && isOpen) {
      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/tenant-short-info`)
    }
  })

  const { data: tenantLinkedData } = useQuery(['getTenantLinkedData', idAdd, isOpen], async () => {
    if (idAdd && isOpen) {
      return await getData('GET', `share-content/${idAdd}/tenant-linked`)
    }
  })

  const mutation = useMutation(async ({ idShareContent, idAdd, dataForm }) => {
    if (idShareContent === '') {
      if (idAdd) {
        return await getData('POST', 'share-content', dataForm)
      }
    } else {
      return await getData('PUT', `share-content/${idShareContent}`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getTenantLinkedData')
    }
  })

  /* ----------------------------------------------- */
  /* ----------------- useEffect ------------------- */
  /* ----------------------------------------------- */
  useEffect(() => {
    if (tenantListData) {
      const resultDataTenant = tenantListData?.data || []
      if (Array.isArray(resultDataTenant)) {
        const selectData = resultDataTenant.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setTenantList(selectData)
      }
    }
  }, [tenantListData])

  useEffect(() => {
    if (tenantLinkedData) {
      const resultDataTenant = tenantLinkedData?.data?.tenant_linked || []
      const id = tenantLinkedData?.data?.id || ''
      setIdShareContent(id)
      if (Array.isArray(resultDataTenant)) {
        setTenantLinked(resultDataTenant)
      }
    }
  }, [tenantLinkedData])

  useEffect(() => {
    if (datePickerChange?.date) {
      tenantShared[datePickerChange.index].expireAt = datePickerChange.date
      setDatePickerChange({})
    }
  }, [datePickerChange])

  /* ----------------------------------------------- */
  /* ---------------- handle event ----------------- */
  /* ----------------------------------------------- */
  const handleCloseForm = () => {
    setIsOpenPlatform(false)
    onClose(false)
    setStep(0)
    setTenantShared([])
  }

  const handleAddTenantShared = (value) => () => {
    const currentIndex = tenantShared.findIndex(item => item.tenant.value === value.value)
    const newChecked = [...tenantShared]

    if (currentIndex === -1) {
      newChecked.push({
        tenant: value,
        range: 0,
        expireAt: format(new Date(), 'yyyy-MM-dd')
      })
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTenantShared(newChecked)
  }

  const handleNextStep = () => {
    if (step === stepLength) {
      setStep(step - 1)
    } else {
      setStep(step + 1)
    }
  }

  const handleChangeDayRange = (e, index) => {
    const newTenantShared = [...tenantShared]
    newTenantShared[index].range = e?.target?.value || 0
    setTenantShared(newTenantShared)
  }

  const handleChangeDatePicker = (e, index) => {
    const date = format(new Date(e), 'yyyy-MM-dd')
    setDatePickerChange({ index: index, date: date })
  }

  const handleOpenPlatform = () => {
    setIsOpenPlatform(true)
    onClose(false)
  }

  const handleClosePlatformContentSharing = () => {
    onClose(false)
  }

  const handleOpenFeatureIsDeveloping = () => {
    toast.info('The feature that is currently under development')
  }

  const onSubmit = () => {
    const shareTenantsData = tenantShared.map(item => {
      return { tenant_id: item.tenant.value, expire_at: item.expireAt, range: item?.range || 0 }
    })
    if (shareTenantsData.length > 0) {
      const dataForm = {
        owner: localStorage.getItem('tenant'),
        entity: idAdd,
        share_tenants_data: shareTenantsData
      }
      try {
        mutation.mutateAsync({ idShareContent, idAdd, dataForm }).then(() => {
          toast.success('Content Sharing Successful!')
          handleCloseForm()
          setStep(0)
          setTenantShared([])
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }

  const columns = [
    {
      title: 'Tenant',
      key: 'tenant',
      align: 'left',
      width: '30%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.tenant?.label || '' }
          </div>
        )
      }
    },
    {
      title: 'Range',
      key: 'range',
      align: 'left',
      width: '15%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            <TextField
              id='range-expire'
              label='Day(s) Range'
              type='number'
              InputProps={{ inputProps: { min: 0 } }}
              defaultValue={0}
              onBlur={(e) => handleChangeDayRange(e, index)}
              />
          </div>
        )
      }
    },
    {
      title: 'Expire At',
      key: 'expire_at',
      align: 'left',
      width: '20%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={false}
                label={'Expire at'}
                ampm={false}
                inputFormat='dd-MM-yyyy'
                mask="__-__-____"
                value={record?.expireAt}
                onChange={(e) => handleChangeDatePicker(e, index)}
                renderInput={(params) => <TextField {...params} size={'medium'} fullWidth />}
              />
            </LocalizationProvider>
          </div>
        )
      }
    }
  ]

  return (
    <div className='vod-share-content-form'>
      {/* Open Platform Sharing */}
      <FormDialog
        title='Content Sharing On Platform'
        maxWidth="sm"
        dividers
        isOpen={isOpen}
        onClose={e => handleClosePlatformContentSharing()}
        isSave={false}
      >
        <Box>
          <List>
            <ListItem disablePadding onClick={e => handleOpenPlatform()}>
              <ListItemButton>
                <ListItemIcon>
                  <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="TENANTS" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={e => handleOpenFeatureIsDeveloping()}>
              <ListItemButton>
                <ListItemIcon>
                  <YouTubeIcon />
                </ListItemIcon>
                <ListItemText primary="YOUTUBE" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={e => handleOpenFeatureIsDeveloping()}>
              <ListItemButton>
                <ListItemIcon>
                  <FacebookIcon />
                </ListItemIcon>
                <ListItemText primary="FACEBOOK" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </FormDialog>
      {/* Open Platform Sharing */}

      {/* Feature Developing */}
      {/* Feature Developing */}

      {/* Add Tenant */}
      <FormDialog
        title='Content Sharing For Tenants'
        maxWidth="md"
        isOpen={isOpenPlatform}
        dividers
        onClose={handleCloseForm}
        onSave={() => onSubmit()}
        customButton={
          <Button variant="contained" color="success" className="ml-2" onClick={handleNextStep}>
            { step >= stepLength ? 'Back' : 'Next' }
          </Button>
        }
      >
        {step === 0 && (
          <Row>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {
                tenantList.map((t, key) => {
                  const labelId = `checkbox-list-label-${t.value}`
                  let isAdded = false
                  if (tenantLinked.includes(t.value)) {
                    isAdded = true
                  }
                  const checked = tenantShared.find(item => item.tenant.value === t.value)
                  return (
                    <ListItem
                      key={key}
                      disabled={isAdded}
                      disablePadding
                    >
                      <ListItemButton onClick={handleAddTenantShared(t)} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={isAdded ? true : !!checked}
                            tabIndex={-1}
                            disableRipple={isAdded}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${t.label}`} />
                      </ListItemButton>
                    </ListItem>
                  )
                })
              }
            </List>
          </Row>
        )}
        {
          step === 1 && (
            <Row className="pt-4">
              <Col md={12}>
                <Table
                  scroll={{
                    x: 800
                  }}
                  columns={columns}
                  data={tenantShared}
                />
              </Col>
            </Row>
          )
        }
      </FormDialog>
      {/* Add Tenant */}
    </div>
  )
}

VodShareContentForm.propTypes = {
  idAdd: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

VodShareContentForm.defaultProps = {
  idAdd: '',
  isOpen: false
}

export default VodShareContentForm
