import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../styles/navbar.scss'

const Navbar = (props) => {
  const { logo, onLogout, onCollapse } = props

  const [username, setUsername] = useState('')
  const [collapseChecked, setCollapseChecked] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('username')) {
      setUsername(localStorage.getItem('username'))
    }

    const sidebarCollapse = localStorage.getItem('sidebar_collapse')
    if (sidebarCollapse) {
      if (sidebarCollapse === 'collapse') {
        document.body.classList.add('mini-sidebar')
        setCollapseChecked(true)
        onCollapse(true)
      } else {
        document.body.classList.remove('mini-sidebar')
        setCollapseChecked(false)
        onCollapse(false)
      }
    }
  }, [])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleCollapseSidebar = (e) => {
    e.preventDefault()

    if (!collapseChecked) {
      setCollapseChecked(true)
      onCollapse(true)
      document.body.classList.add('mini-sidebar')
      localStorage.setItem('sidebar_collapse', 'collapse')
    } else {
      setCollapseChecked(false)
      onCollapse(false)
      document.body.classList.remove('mini-sidebar')
      localStorage.setItem('sidebar_collapse', 'expand')
    }
  }

  return (
    <nav className="navbar-vnns navbar custom-navbar navbar-expand-lg py-2">
      <div className="container-fluid px-0">
        <a href="#!" className="menu_toggle"><i className="fa fa-align-left"></i></a>
        <a href="#" className="navbar-brand"><img src={logo} alt="admon" /> OTT Platform</a>
        <div id="navbar_main">
          <ul className="navbar-nav mr-auto hidden-xs">
            <li className="nav-item page-header">
            <ul className="breadcrumb">
              <li className="breadcrumb-item"><a href="#" onClick={handleCollapseSidebar}><i className="fa fa-bars"></i></a></li>
              {/* <li className="breadcrumb-item active">Dashboard</li> */}
            </ul>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item hidden-xs">
              <form className="form-inline main_search">
                  <input className="form-control form-control-sm mr-sm-2" type="search" placeholder="Search..." aria-label="Search" />
              </form>
            </li> */}
            <li className="nav-item dropdown">
              <a className="nav-link nav-link-icon" href="#" id="navbar_1_dropdown_3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Welcome, {username}
                <i className="fa fa-user ml-4"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {/* <h6 className="dropdown-header">User menu</h6>
                <a className="dropdown-item" href="#"><i className="fa fa-user text-primary"></i>My Profile</a>
                <a className="dropdown-item" href="#"><i className="fa fa-cog text-primary"></i>Settings</a> */}
                <Link className="dropdown-item" to="/profile"><i className="fa fa-user text-primary"></i>Profile</Link>
                <Link className="dropdown-item" to="/change-password"><i className="fa fa-eye text-primary"></i>Change Password</Link>
                <div className="dropdown-divider" role="presentation"></div>
                <a className="dropdown-item" href="#" onClick={onLogout}><i className="fa fa-sign-out text-primary"></i>Sign out</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  logo: PropTypes.string,
  onLogout: PropTypes.func,
  onCollapse: PropTypes.func
}

Navbar.defaultProps = {
  logo: ''
}

export default Navbar
