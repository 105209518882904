import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getData, deleteFormData } from 'common/utils'
import { Col, Row, Table } from 'components/ui'
import { Select } from 'antd'
import { toast } from 'react-toastify'
import Pagination from '@mui/material/Pagination'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import ButtonMUI from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import FormDialog from 'components/form/FormDialog'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { DatePicker } from '@mui/lab'
import { format } from 'date-fns'
import AlertDialog from 'components/ui/AlertDialog'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import RibbonSharingByMeFilter from './RibbonSharingByMeFilter'

const { Option } = Select

const pageList = [
  { value: 10, label: '10 / page' },
  { value: 20, label: '20 / page' },
  { value: 50, label: '50 / page' }
]

const RibbonSharingByMe = () => {
  const queryClient = useQueryClient()
  const urlString = window.location.href
  const url = new URL(urlString)
  const searchParamsUrl = url.searchParams.get('q')

  const [dataSharingByMe, setDataSharingByMe] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [current, setCurrent] = useState(1)
  const [searchFilter, setSearchFilter] = useState('')
  const [idRibbonSharing, setIdRibbonSharing] = useState('')
  const [tenantData, setTenantData] = useState([])

  // View Tenant
  const [shareInfo, setShareInfo] = useState([])
  const [isOpenViewShareInfo, setIsOpenViewShareInfo] = useState(false)

  // Delete
  const [idDelete, setIdDelete] = useState('')
  const [isOpenFormDelete, setIsOpenFormDelete] = useState(false)

  // Add more tenant
  const [isOpenAddMoreTenant, setIsOpenAddMoreTenant] = useState(false)
  const [timeExpireAddMoreTenant, setTimeExpireAddMoreTenant] = useState('')
  const [tenantObj, setTenantObj] = useState(null)
  const [ribbonId, setRibbonId] = useState('')

  // Revoke Tenant
  const [isOpenDialogRevoke, setIsOpenDialogRevoke] = useState(false)
  const [idTenantRevoke, setIDTenantRevoke] = useState('')

  // OnHold Tenant
  const [isOpenFormOnholdTenant, setIsOpenFormOnholdTenant] = useState(false)
  const [idTenantOnHold, setIdTenantOnHold] = useState('')
  const [reasonOnHold, setReasonOnHold] = useState('')

  // Extend Tenant
  const [isOpenFormExtendTenant, setIsOpenFormExtendTenant] = useState(false)
  const [idTenantExtend, setIdTenantExtend] = useState('')
  const [expireExtend, setExpireExtend] = useState('')

  /* ----------------------------------------------- */
  /* --------------- Fetch API ------------------ */
  /* ----------------------------------------------- */
  const { data: dataList, isLoading, isFetching } = useQuery(['getRibbonsShareByMe', searchFilter], async () => {
    let endpointTemp = ''
    if (searchFilter !== '') {
      endpointTemp += `&q=${searchFilter}`
    }
    return await getData(
      'GET',
      `tenants/${localStorage.getItem('tenant')}/share-ribbon-by-me?offset=${offset}&limit=${limit}${endpointTemp}`
    )
  })

  const { data: tenantList } = useQuery('getTenantListShortInfo', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/tenant-short-info`)
  })

  const mutationDeleteRibbonSharing = useMutation(async ({ idDelete }) => {
    if (idDelete) {
      return await deleteFormData('DELETE', 'share-ribbon/', idDelete)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonsShareByMe')
    }
  })

  const mutationAddMoreTenant = useMutation(async ({ idRibbonSharing, ribbonId, tenant }) => {
    const dataForm = {
      ribbon: ribbonId,
      owner: localStorage.getItem('tenant'),
      tenants: tenant
    }
    if (idRibbonSharing) {
      return await getData('PUT', `share-ribbon/${idRibbonSharing}`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonsShareByMe')
    }
  })

  const mutationRevokeRibbonSharing = useMutation(async ({ idRibbonSharing, idTenantRevoke }) => {
    if (idRibbonSharing && idTenantRevoke) {
      const dataForm = {
        tenant_id: idTenantRevoke,
        owner_id: localStorage.getItem('tenant')
      }
      return await getData('PUT', `share-ribbon/${idRibbonSharing}/revoke-share-ribbon`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonsShareByMe')
    }
  })

  const mutationSharingRibbon = useMutation(async ({ idRibbonSharing, idTenantSharing }) => {
    if (idRibbonSharing && idTenantSharing) {
      const dataForm = {
        tenant_id: idTenantSharing,
        owner_id: localStorage.getItem('tenant')
      }
      return await getData('PUT', `share-ribbon/${idRibbonSharing}/open-sharing-share-ribbon`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonsShareByMe')
    }
  })

  const mutationOnHoldRibbonSharing = useMutation(async ({ idRibbonSharing, idTenantOnHold, reasonOnHold }) => {
    if (idRibbonSharing && idTenantOnHold) {
      const dataForm = {
        owner_id: localStorage.getItem('tenant'),
        tenant_id: idTenantOnHold, // Tenant Hold
        reason: reasonOnHold
      }
      return await getData('PUT', `share-ribbon/${idRibbonSharing}/on-hold-share-ribbon`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonsShareByMe')
    }
  })

  const mutationExtendExpireTime = useMutation(async ({ idRibbonSharing, idTenantExtend, expireExtend }) => {
    if (idRibbonSharing && idTenantExtend) {
      const dataForm = {
        owner_id: localStorage.getItem('tenant'),
        tenant_id: idTenantExtend, // Tenant Hold
        expire_at: expireExtend
      }
      return await getData('PUT', `share-ribbon/${idRibbonSharing}/extend-share-ribbon`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonsShareByMe')
    }
  })

  /* ----------------------------------------------- */
  /* --------------- useEffect ------------------ */
  /* ----------------------------------------------- */
  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results
      setDataSharingByMe(resultData)
      setTotalPage(Math.ceil(dataList.data.total / limit))
      setOffset(0)
      setLimit(10)
    }
  }, [dataList])

  useEffect(() => {
    if (tenantList) {
      const resultData = tenantList?.data || []
      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setTenantData(selectData)
      }
    }
  }, [tenantList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  // Handle Page
  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }
  // Handle Page

  // Filter
  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }
  useEffect(() => {
    if (searchParamsUrl) {
      setSearchFilter(searchParamsUrl)
    }
  }, [searchParamsUrl])
  // Filter

  // Share Info
  const handleCloseViewShareInfo = () => {
    setIsOpenViewShareInfo(false)
    setShareInfo([])
  }
  const handleOpenViewShareInfo = (shareInfo, idRibbonSharing) => {
    setShareInfo(shareInfo)
    setIsOpenViewShareInfo(true)
    setIdRibbonSharing(idRibbonSharing)
  }
  // Share Info

  // Handle Delete
  const handleClickShowModalDelete = (e, record) => {
    setIsOpenFormDelete(true)
    setIdDelete(record?.id || '')
  }
  const handleCloseDelete = () => {
    setIdDelete('')
    setIsOpenFormDelete(false)
  }
  const handleClickDelete = async () => {
    try {
      if (idDelete) {
        await mutationDeleteRibbonSharing.mutateAsync({ idDelete }).then(() => {
          setIsOpenFormDelete(false)
          setIdDelete('')
        })
      }
    } catch (err) {
      toast.error('Something went wrong!')
    }
  }
  // Handle Delete

  // Handle Add More Tenant
  const handleOpenAddMoreTenant = (shareContentId, ribbonId) => {
    setIdRibbonSharing(shareContentId)
    setRibbonId(ribbonId)
    setIsOpenAddMoreTenant(true)
    setTimeExpireAddMoreTenant(format(new Date(), 'yyyy-MM-dd'))
  }
  const handleCloseAddMoreTenant = () => {
    setIsOpenAddMoreTenant(false)
    setIdRibbonSharing('')
    setTimeExpireAddMoreTenant('')
    setRibbonId('')
  }
  const handleChangeTenant = (e, value) => {
    setTenantObj(value)
  }
  const handleChangeDatePickerAddMoreTenant = (e) => {
    setTimeExpireAddMoreTenant(format(new Date(e), 'yyyy-MM-dd'))
  }
  const handleSaveAddMoreTenant = async (idRibbonSharing, ribbonId, tenantObj) => {
    if (idRibbonSharing && tenantObj && ribbonId) {
      const tenant = [
        { tenant_id: tenantObj.value, expire_at: timeExpireAddMoreTenant }
      ]
      await mutationAddMoreTenant.mutateAsync({
        idRibbonSharing, ribbonId, tenant
      }).then(() => {
        setTenantObj(null)
        setIsOpenAddMoreTenant(false)
        setTimeExpireAddMoreTenant('')
        setRibbonId('')
      }).catch(() => {
        toast.error('Something went wrong!')
      })
    }
  }
  // Handle Add More Tenant

  // Handle Revoke Ribbon Sharing
  const handleRevokeClose = () => {
    setIsOpenDialogRevoke(false)
    setIDTenantRevoke('')
  }
  const handleOpenDialogRevoke = (idTenant) => {
    setIsOpenDialogRevoke(true)
    setIDTenantRevoke(idTenant)
  }
  const handleClickSaveRevoke = async () => {
    if (idRibbonSharing && idTenantRevoke) {
      try {
        await mutationRevokeRibbonSharing.mutateAsync({ idRibbonSharing, idTenantRevoke }).then(() => {
          setIDTenantRevoke('')
          setIdRibbonSharing('')
          setIsOpenDialogRevoke(false)
          setIsOpenViewShareInfo(false)
          toast.success('Revoke Ribbon Sharing Success')
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }
  // Handle Revoke Ribbon Sharing

  // Handle Open share Ribbon
  const handleSharingData = async (idRibbonSharing, idTenantSharing) => {
    if (idRibbonSharing && idTenantSharing) {
      try {
        await mutationSharingRibbon.mutateAsync({ idRibbonSharing, idTenantSharing }).then(() => {
          setIdRibbonSharing('')
          setIsOpenViewShareInfo(false)
          toast.success('Share Ribbon For Tenant Success')
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }
  // Handle Open share Ribbon

  // Handle OnHold Share Ribbon
  const handleOnHoldShareRibbon = (tenantIdOnHold) => {
    setIsOpenFormOnholdTenant(true)
    setIdTenantOnHold(tenantIdOnHold)
  }
  const handleOnHoldClose = () => {
    setIsOpenFormOnholdTenant(false)
    setIdTenantOnHold('')
    setReasonOnHold('')
  }
  const handleOnChangeReasonOnHold = (e) => {
    setReasonOnHold(e.target.value)
  }
  const handleSaveOnHoldShareRibbon = async () => {
    if (idRibbonSharing && idTenantOnHold) {
      try {
        await mutationOnHoldRibbonSharing.mutateAsync({ idRibbonSharing, idTenantOnHold, reasonOnHold }).then(() => {
          setIdTenantOnHold('')
          setIdRibbonSharing('')
          setIsOpenFormOnholdTenant(false)
          setReasonOnHold('')
          setIsOpenViewShareInfo(false)
          toast.success('Hold Ribbon Sharing Success')
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }
  // Handle OnHold Share Ribbon

  // Handle Extend Tenant
  const handleChangeDatePickerFormExtend = (e) => {
    const date = format(new Date(e), 'yyyy-MM-dd')
    setExpireExtend(date)
  }
  const handleOpenFormExtend = (tenantId, expireAt) => {
    setIsOpenFormExtendTenant(true)
    setIdTenantExtend(tenantId)
    if (expireAt === '') {
      setExpireExtend(format(new Date(), 'yyyy-MM-dd'))
    } else {
      setExpireExtend(expireAt)
    }
  }
  const handleCloseFormExtend = () => {
    setIsOpenFormExtendTenant(false)
    setIdTenantExtend('')
    setExpireExtend('')
  }
  const handleExtendExpireTimeForShareRibbon = async () => {
    if (idRibbonSharing && idTenantExtend) {
      try {
        await mutationExtendExpireTime.mutateAsync({ idRibbonSharing, idTenantExtend, expireExtend }).then(() => {
          setIdTenantExtend('')
          setIdRibbonSharing('')
          setIsOpenFormExtendTenant(false)
          setExpireExtend('')
          setIsOpenViewShareInfo(false)
          toast.success('Extend Ribbon Sharing Success')
        })
      } catch (error) {
        toast.error('Expire Time Invalid!')
      }
    }
  }
  // Handle Extend Tenant

  /* ----------------------------------------------- */
  /* --------------- Columns ------------------ */
  /* ----------------------------------------------- */
  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete" onClick={(e) => handleClickShowModalDelete(e, record)}>
              <IconButton color="error">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Add tenant for ribbon sharing"
              onClick={() => handleOpenAddMoreTenant(record.id, record?.ribbon?.id || '')}
            >
              <IconButton color="success" >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Ribbon',
      key: 'ribbon-name',
      align: 'left',
      width: '30%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.ribbon?.name || '' }
          </div>
        )
      }
    },
    {
      title: 'Type',
      key: 'type',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={`${index}-type`}>
            {
              record?.ribbon?.type === 0
                ? 'Master Banner'
                : record?.ribbon?.type === 1
                  ? 'Poster'
                  : record?.ribbon?.type === 2
                    ? 'Thumbnail'
                    : record?.ribbon?.type === 3
                      ? 'Banner'
                      : record?.ribbon?.type === 4
                        ? 'List'
                        : record?.ribbon?.type === 5
                          ? 'Circle'
                          : record?.ribbon?.type === 6
                            ? 'Pano'
                            : record?.ribbon?.type === 7
                              ? 'Special News'
                              : record?.ribbon?.type === 8
                                ? 'Trending'
                                : record?.ribbon?.type === 9
                                  ? 'Poster News'
                                  : record?.ribbon?.type === 10
                                    ? 'Audio'
                                    : record?.ribbon?.type === 11
                                      ? 'Auto Play'
                                      : record?.ribbon?.type === 12
                                        ? 'Album'
                                        : 'Banner Top'
            }
          </div>
        )
      }
    },
    {
      title: 'Tenants',
      key: 'tenants',
      width: '10%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <ButtonMUI color="inherit" size="small" variant="contained"
            onClick={() => handleOpenViewShareInfo(record?.share_info || [], record?.id || '')}
          >
            View
          </ButtonMUI>
        )
      }
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '14%'
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '14%'
    }
  ]

  const columnsShareInfo = [
    {
      title: 'Action',
      key: 'action',
      fixed: 'left',
      align: 'center',
      width: '16%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {
              record?.share_status === 1
                ? <>
                  <Tooltip title="Revoke Entity">
                    <ButtonMUI color="error" size="small"
                      variant="contained"
                      onClick={() => handleOpenDialogRevoke(record?.tenant?.id)}
                    >
                      Revoke
                    </ButtonMUI>
                  </Tooltip>
                  <Tooltip title="On Hold Entity" style={{ marginLeft: 5 }}>
                    <ButtonMUI color="warning" size="small"
                      variant="contained"
                      onClick={() => handleOnHoldShareRibbon(record?.tenant?.id)}
                    >
                      On Hold
                    </ButtonMUI>
                  </Tooltip>
                </>
                : <>
                  <Tooltip title="Share Entity">
                    <ButtonMUI color="inherit" size="small"
                      variant="contained"
                      onClick={() => handleSharingData(idRibbonSharing, record?.tenant?.id || '')}
                    >
                      Share
                    </ButtonMUI>
                  </Tooltip>
                </>
            }

            <Tooltip title="Extend Expire Time Of Entity" style={{ marginLeft: 5 }}>
              <ButtonMUI color="primary" size="small"
                variant="contained"
                onClick={() => handleOpenFormExtend(record?.tenant?.id || '', record?.expire_at)}
              >
                Extend
              </ButtonMUI>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Tenant Name',
      key: 'tenant_name',
      width: '16%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.tenant?.name}
          </div>
        )
      }
    },
    {
      title: 'Description',
      key: 'tenant_description',
      width: '18%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.tenant?.description}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'share_status',
      width: '7%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record?.share_status === 1
              ? 'Sharing'
              : record?.share_status === 2
                ? 'Revoke'
                : record?.share_status === 3
                  ? 'On Hold'
                  : 'Draft'}
          </div>
        )
      }
    },
    {
      title: 'Shared at',
      key: 'shared_at',
      width: '12%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.shared_at}
          </div>
        )
      }
    },
    {
      title: 'Expire at',
      key: 'expire_at',
      width: '10%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.expire_at}
          </div>
        )
      }
    }
  ]

  return (
    <div className='ribbon-sharing-by-me'>
      <Row className='pt-4'>
        <Col md={12}>
          <RibbonSharingByMeFilter
            onClickSearch={handleClickSearchFilter}
            searchFilter={searchFilter}
          />
        </Col>
      </Row>
      <Row className='pt-4'>
        <Col md={12}>
          <Table
            columns={columns}
            data={dataSharingByMe}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      {/* Share Info */}
      <FormDialog
        isOpen={isOpenViewShareInfo}
        title={ 'Ribbon Sharing For Tenant' }
        maxWidth='xl'
        isSave={false}
        onClose={handleCloseViewShareInfo}
      >
        <Table
            columns={columnsShareInfo}
            data={shareInfo}
          />
      </FormDialog>
      {/* Share Info */}

      {/* Delete Item */}
      <AlertDialog
        isOpen={isOpenFormDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />
      {/* Delete Item */}

      {/* Add More Tenant */}
      <FormDialog
        isOpen={isOpenAddMoreTenant}
        title={'Add More Tenant'}
        maxWidth='xs'
        onSave={() => handleSaveAddMoreTenant(idRibbonSharing, ribbonId, tenantObj)}
        onClose={handleCloseAddMoreTenant}
      >
        <Row className="pt-3">
          <Col md={12}>
            <Autocomplete
              fullWidth
              value={tenantObj}
              onChange={(e, newValue) => handleChangeTenant(e, newValue)}
              options={tenantData}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Tenant" />}
            />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={false}
                label={'Expire at'}
                ampm={false}
                required={false}
                inputFormat='dd-MM-yyyy'
                mask="__-__-____"
                value={timeExpireAddMoreTenant}
                onChange={(e) => handleChangeDatePickerAddMoreTenant(e)}
                renderInput={(params) => <TextField {...params} size={'medium'} fullWidth />}
              />
            </LocalizationProvider>
          </Col>
        </Row>
      </FormDialog>
      {/* Add More Tenant */}

      {/* Revoke Tenant */}
      <AlertDialog
        isOpen={isOpenDialogRevoke}
        type="revoke"
        title="Notification"
        contentText="Are you sure you want to revoke this item?"
        onClose={handleRevokeClose}
        onSave={handleClickSaveRevoke}
      />
      {/* Revoke Tenant */}

      {/* OnHold Tenant */}
      <FormDialog
        isOpen={isOpenFormOnholdTenant}
        onClose={handleOnHoldClose}
        onSave={handleSaveOnHoldShareRibbon}
        type='on_hold'
        title='Hold Ribbon Sharing'
        dividers={true}
        isSave={true}
      >
        <Row>
          <Col>
            <TextField
              label="Reason"
              name="reason"
              defaultValue={reasonOnHold}
              fullWidth
              multiline
              minRows={4}
              onBlur={(e) => handleOnChangeReasonOnHold(e)} />
          </Col>
        </Row>
      </FormDialog>
      {/* OnHold Tenant */}

      {/* Extend Tenant */}
      <FormDialog
        isOpen={isOpenFormExtendTenant}
        onClose={handleCloseFormExtend}
        onSave={handleExtendExpireTimeForShareRibbon}
        type='extend_ribbon_sharing'
        title='Extend Expire Time Of Ribbon Sharing'
        dividers={true}
        isSave={true}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={false}
            label={'Expire at'}
            ampm={false}
            required={false}
            inputFormat='dd-MM-yyyy'
            mask="__-__-____"
            value={expireExtend}
            onChange={(e) => handleChangeDatePickerFormExtend(e)}
            renderInput={(params) => <TextField {...params} size={'medium'} fullWidth />}
          />
        </LocalizationProvider>
      </FormDialog>
      {/* Extend Tenant */}

    </div>
  )
}

export default RibbonSharingByMe
