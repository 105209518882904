import React from 'react'
import Layout from 'features/layout/components/Layout'
import AppVersion from '../components/AppVersion'

const AppVersionPage = (props) => {
  return (
    <Layout>
      <AppVersion />
    </Layout>
  )
}

export default AppVersionPage
