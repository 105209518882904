import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData, deleteFormData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import { Select } from 'antd'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import Pagination from '@mui/material/Pagination'
import ButtonMUI from '@mui/material/Button'
import FormDialog from 'components/form/FormDialog'
import AddIcon from '@mui/icons-material/Add'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AlertDialog from 'components/ui/AlertDialog'
import ShareContentByMeFilter from './ShareContentByMeFilter'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { DatePicker } from '@mui/lab'
import { format } from 'date-fns'

const { Option } = Select

const ShareContentByMe = (props) => {
  const queryClient = useQueryClient()
  const urlString = window.location.href
  const url = new URL(urlString)
  const searchParamsUrl = url.searchParams.get('q')

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [current, setCurrent] = useState(1)
  const [entityShareByMe, setEntityShareByMe] = useState([])
  const [shareInfos, setShareInfo] = useState([])
  const [isOpenViewSharedContentTenant, setIsOpenViewSharedContentTenant] = useState(false)
  // Add More Tenant
  const [isOpenAddMoreTenant, setIsOpenAddMoreTenant] = useState(false)
  const [timeExpireAddMoreTenant, setTimeExpireAddMoreTenant] = useState('')

  const [idShareContent, setIDShareContent] = useState('')
  const [tenantData, setTenantData] = useState([])
  const [tenantObj, setTenantObj] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [idDelete, setIdDelete] = useState('')

  // Revoke
  const [tenantIdRevoke, setTenantRevokeID] = useState('')
  const [isOpenRevoke, setIsOpenRevoke] = useState(false)

  // On Hold
  const [isOpenOnHold, setIsOpenOnHold] = useState(false)
  const [reasonOnHold, setReasonOnHold] = useState('')

  // Extend
  const [tenantIdExtend, setTenantIdExtend] = useState('')
  const [tenantExpireTime, setTenantExpireTime] = useState('')
  const [isOpenFormExtend, setIsOpenFormExtend] = useState(false)

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  /* ----------------------------------------------- */
  /* --------------- Fetch API ------------------ */
  /* ----------------------------------------------- */
  const { data: dataList, isLoading, isFetching } = useQuery(['getEntityShareByMe', searchFilter], async () => {
    let endpointTemp = ''
    if (searchFilter !== '') {
      endpointTemp += `&q=${searchFilter}`
    }
    return await getData(
      'GET',
      `tenants/${localStorage.getItem('tenant')}/share-content-by-me?offset=${offset}&limit=${limit}${endpointTemp}`
    )
  })

  const { data: tenantList } = useQuery('getTenantListShortInfo', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/tenant-short-info`)
  })

  const mutationAddMoreTenant = useMutation(async ({ idShareContent, shareTenantsData }) => {
    const dataForm = {
      share_tenants_data: shareTenantsData
    }
    if (idShareContent) {
      return await getData('PUT', `share-content/${idShareContent}`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getEntityShareByMe')
    }
  })

  const mutationDeleteShareContent = useMutation(async ({ idDelete }) => {
    if (idDelete) {
      return await deleteFormData('DELETE', 'share-content/', idDelete)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getEntityShareByMe')
    }
  })

  const mutationRevokeShareContent = useMutation(async ({ idShareContent, tenantIdRevoke }) => {
    if (idShareContent && tenantIdRevoke) {
      const dataForm = {
        tenant_id: tenantIdRevoke
      }
      return await getData('PUT', `share-content/${idShareContent}/revoke-content`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getEntityShareByMe')
    }
  })

  const mutationSharingContent = useMutation(async ({ idShareContent, tenantIdSharing }) => {
    if (idShareContent && tenantIdSharing) {
      const dataForm = {
        tenant_id: tenantIdSharing
      }
      return await getData('PUT', `share-content/${idShareContent}/sharing-content`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getEntityShareByMe')
    }
  })

  const mutationOnHoldContentSharing = useMutation(async ({ idShareContent, tenantIdRevoke, reasonOnHold }) => {
    if (idShareContent && tenantIdRevoke) {
      const dataForm = {
        tenant_owner_id: localStorage.getItem('tenant'),
        tenant_id: tenantIdRevoke, // Tenant Hold
        reason: reasonOnHold
      }
      return await getData('PUT', `share-content/${idShareContent}/on-hold`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getEntityShareByMe')
    }
  })

  const mutationExtendExpireTime = useMutation(async ({ idShareContent, tenantIdExtend, tenantExpireTime }) => {
    if (idShareContent && tenantIdExtend) {
      const dataForm = {
        tenant_id: localStorage.getItem('tenant'),
        tenant_extend_id: tenantIdExtend, // Tenant Hold
        expire_at: tenantExpireTime
      }
      return await getData('PUT', `share-content/${idShareContent}/extend`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getEntityShareByMe')
    }
  })

  // UseEffect
  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results
      setEntityShareByMe(resultData)
      setTotalPage(Math.ceil(dataList.data.total / limit))
      setOffset(0)
      setLimit(10)
    }
  }, [dataList])

  useEffect(() => {
    if (searchParamsUrl) {
      setSearchFilter(searchParamsUrl)
    }
  }, [searchParamsUrl])

  useEffect(() => {
    if (tenantList) {
      const resultData = tenantList?.data || []
      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setTenantData(selectData)
      }
    }
  }, [tenantList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleOpenViewTenantShared = (record) => {
    const sharedInfo = record?.shares_info || []
    setShareInfo(sharedInfo)
    setIDShareContent(record?.id || '')
    setIsOpenViewSharedContentTenant(true)
  }

  const handleCloseViewSharedContentTenant = () => {
    setIsOpenViewSharedContentTenant(false)
  }

  // Add More Tenant
  const handleOpenAddMoreTenant = (shareContentId) => {
    setIDShareContent(shareContentId)
    setIsOpenAddMoreTenant(true)
    setTimeExpireAddMoreTenant(format(new Date(), 'yyyy-MM-dd'))
  }

  const handleCloseAddMoreTenant = () => {
    setIsOpenAddMoreTenant(false)
    setIDShareContent('')
    setTimeExpireAddMoreTenant('')
  }

  const handleChangeDatePickerAddMoreTenant = (e) => {
    setTimeExpireAddMoreTenant(format(new Date(e), 'yyyy-MM-dd'))
  }

  const handleSaveAddMoreTenant = async (idShareContent, tenantObj) => {
    if (idShareContent && tenantObj) {
      const shareTenantsData = [
        { tenant_id: tenantObj.value, expire_at: timeExpireAddMoreTenant }
      ]
      await mutationAddMoreTenant.mutateAsync({
        idShareContent, shareTenantsData
      }).then(() => {
        setTenantObj(null)
        setIsOpenAddMoreTenant(false)
        setTimeExpireAddMoreTenant('')
      }).catch(() => {
        toast.error('Something went wrong!')
      })
    }
  }

  // End Add More Tenant

  const handleChangeTenant = (e, value) => {
    setTenantObj(value)
  }

  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record?.id || '')
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleClickDelete = async () => {
    try {
      if (idDelete) {
        await mutationDeleteShareContent.mutateAsync({ idDelete }).then(() => {
          setIsOpenDelete(false)
          setIDShareContent('')
        })
      }
    } catch (err) {
      toast.error('Something went wrong!')
    }
  }

  // Revoke Content
  const handleRevokeShareContent = (tenantIdRevoke) => {
    setIsOpenRevoke(true)
    setTenantRevokeID(tenantIdRevoke)
  }

  const handleRevokeClose = () => {
    setIsOpenRevoke(false)
    setTenantRevokeID('')
  }

  const handleClickRevoke = async () => {
    if (idShareContent && tenantIdRevoke) {
      try {
        await mutationRevokeShareContent.mutateAsync({ idShareContent, tenantIdRevoke }).then(() => {
          setTenantRevokeID('')
          setIDShareContent('')
          setIsOpenRevoke(false)
          setIsOpenViewSharedContentTenant(false)
          toast.success('Revoke Content Sharing Success')
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }

  const handleSharingData = async (tenantIdSharing) => {
    if (idShareContent && tenantIdSharing) {
      try {
        await mutationSharingContent.mutateAsync({ idShareContent, tenantIdSharing }).then(() => {
          setIDShareContent('')
          setIsOpenViewSharedContentTenant(false)
          toast.success('Share Content For Tenant Success')
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }

  // End Revoke Content

  // OnHold Content
  const handleOnHoldShareContent = (tenantIdRevoke) => {
    setIsOpenOnHold(true)
    setTenantRevokeID(tenantIdRevoke)
  }

  const handleOnHoldClose = () => {
    setIsOpenOnHold(false)
    setTenantRevokeID('')
    setReasonOnHold('')
  }

  const handleOnChangeReasonOnHold = (e) => {
    setReasonOnHold(e.target.value)
  }

  const handleSaveOnHoldShareContent = async () => {
    if (idShareContent && tenantIdRevoke) {
      try {
        await mutationOnHoldContentSharing.mutateAsync({ idShareContent, tenantIdRevoke, reasonOnHold }).then(() => {
          setTenantRevokeID('')
          setIDShareContent('')
          setIsOpenOnHold(false)
          setReasonOnHold('')
          setIsOpenViewSharedContentTenant(false)
          toast.success('Hold Content Sharing Success')
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }

  // End OnHold Content

  // Extend Expire Time
  const handleOpenFormExtend = (tenantId, expireAt) => {
    setIsOpenFormExtend(true)
    setTenantIdExtend(tenantId)
    if (expireAt === '') {
      setTenantExpireTime(format(new Date(), 'yyyy-MM-dd'))
    } else {
      setTenantExpireTime(expireAt)
    }
  }

  const handleCloseFormExtend = () => {
    setIsOpenFormExtend(false)
    setTenantIdExtend('')
    setTenantExpireTime('')
  }

  const handleChangeDatePickerFormExtend = (e) => {
    const date = format(new Date(e), 'yyyy-MM-dd')
    setTenantExpireTime(date)
  }

  const handleExtendExpireTimeForShareContent = async () => {
    if (idShareContent && tenantIdExtend) {
      try {
        await mutationExtendExpireTime.mutateAsync({ idShareContent, tenantIdExtend, tenantExpireTime }).then(() => {
          setTenantRevokeID('')
          setIDShareContent('')
          setIsOpenFormExtend(false)
          setTenantExpireTime('')
          setTenantIdExtend('')
          setIsOpenViewSharedContentTenant(false)
          toast.success('Extend Content Sharing Success')
        })
      } catch (error) {
        toast.error('Something went wrong!')
      }
    }
  }

  // End Extend Expire Time

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Delete" onClick={(e) => handleClickShowModalDelete(e, record)}>
              <IconButton color="error">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add tenant for share content">
              <IconButton color="success" onClick={() => handleOpenAddMoreTenant(record.id)} >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Title',
      key: 'entity-title',
      align: 'left',
      width: '30%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            { record?.entity?.title || '' }
          </div>
        )
      }
    },
    {
      title: 'Type',
      key: 'entity-type',
      align: 'left',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
              {record.entity.type === 1
                ? 'SINGLE MOVIE'
                : record.entity.type === 2
                  ? 'SHOW/DRAMA'
                  : record.entity.type === 3
                    ? 'SEASON'
                    : record.entity.type === 4
                      ? 'EPISODE'
                      : record.entity.type === 5
                        ? 'CHANNEL'
                        : record.entity.type === 6
                          ? 'TRAILER'
                          : record.entity.type === 7
                            ? 'DOCUMENT'
                            : record.entity.type === 8
                              ? 'BREAKING NEWS'
                              : record.entity.type === 9
                                ? 'AUDIO'
                                : record.entity.type === 10
                                  ? 'EVENT'
                                  : record.entity.type === 11
                                    ? 'VERTICAL VIDEO'
                                    : record.entity.type === 12
                                      ? 'SHOW AUDIO'
                                      : record.entity.type === 15
                                        ? 'NEWS REPORT'
                                        : record.entity.type === 16
                                          ? 'SPONSOR'
                                          : record.entity.type === 17
                                            ? 'EMAGAZINE'
                                            : record.entity.type === 18
                                              ? 'MULTI NEWSPAPER'
                                              : record.entity.type === 19
                                                ? 'INTERNAL NEWS'
                                                : 'Unknown'}
          </div>
        )
      }
    },
    {
      title: 'Tenants',
      key: 'tenants',
      width: '10%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <ButtonMUI color="inherit" size="small" variant="contained"
            onClick={() => handleOpenViewTenantShared(record)}>
            View
          </ButtonMUI>
        )
      }
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '14%'
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '14%'
    }
  ]

  const columnsSharedInfo = [
    {
      title: 'Action',
      key: 'action',
      fixed: 'left',
      align: 'center',
      width: '16%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {
              record?.share_status === 1
                ? <>
                  <Tooltip title="Revoke Entity">
                    <ButtonMUI color="error" size="small"
                      variant="contained"
                      onClick={() => handleRevokeShareContent(record?.tenant?.id)}
                    >
                      Revoke
                    </ButtonMUI>
                  </Tooltip>
                  <Tooltip title="On Hold Entity" style={{ marginLeft: 5 }}>
                    <ButtonMUI color="warning" size="small"
                      variant="contained"
                      onClick={() => handleOnHoldShareContent(record?.tenant?.id)}
                    >
                      On Hold
                    </ButtonMUI>
                  </Tooltip>
                </>
                : <>
                  <Tooltip title="Share Entity">
                    <ButtonMUI color="inherit" size="small"
                      variant="contained"
                      onClick={() => handleSharingData(record?.tenant?.id || '')}
                    >
                      Share
                    </ButtonMUI>
                  </Tooltip>
                </>
            }

            <Tooltip title="Extend Expire Time Of Entity" style={{ marginLeft: 5 }}>
              <ButtonMUI color="primary" size="small"
                variant="contained"
                onClick={() => handleOpenFormExtend(record?.tenant?.id || '', record?.expire_at)}
              >
                Extend
              </ButtonMUI>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'Tenant Name',
      key: 'tenant_name',
      width: '16%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.tenant?.name}
          </div>
        )
      }
    },
    {
      title: 'Description',
      key: 'tenant_description',
      width: '18%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.tenant?.description}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'share_status',
      width: '7%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record?.share_status === 1
              ? 'Sharing'
              : record?.share_status === 2
                ? 'Revoke'
                : record?.share_status === 3
                  ? 'On Hold'
                  : 'Draft'}
          </div>
        )
      }
    },
    {
      title: 'Shared at',
      key: 'shared_at',
      width: '12%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.shared_at}
          </div>
        )
      }
    },
    {
      title: 'Expire at',
      key: 'expire_at',
      width: '10%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {record?.expire_at}
          </div>
        )
      }
    }
  ]

  return (
    <div className='share-content-by-me'>
      <Row className='pt-4'>
        <Col md={12}>
          <ShareContentByMeFilter
            onClickSearch={handleClickSearchFilter}
            searchFilter={searchFilter}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Table
            columns={columns}
            data={entityShareByMe}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      <FormDialog
        isOpen={isOpenViewSharedContentTenant}
        title={ 'Tenant Shared' }
        maxWidth='xl'
        isSave={false}
        onClose={handleCloseViewSharedContentTenant}
      >
        <Table
            columns={columnsSharedInfo}
            data={shareInfos}
          />
      </FormDialog>

      <FormDialog
        isOpen={isOpenAddMoreTenant}
        title={'Add More Tenant'}
        maxWidth='xs'
        onSave={() => handleSaveAddMoreTenant(idShareContent, tenantObj)}
        onClose={handleCloseAddMoreTenant}
      >
        <Row className="pt-3">
          <Col md={12}>
            <Autocomplete
              fullWidth
              value={tenantObj}
              onChange={(e, newValue) => handleChangeTenant(e, newValue)}
              options={tenantData}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Tenant" />}
            />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={false}
                label={'Expire at'}
                ampm={false}
                required={false}
                inputFormat='dd-MM-yyyy'
                mask="__-__-____"
                value={timeExpireAddMoreTenant}
                onChange={(e) => handleChangeDatePickerAddMoreTenant(e)}
                renderInput={(params) => <TextField {...params} size={'medium'} fullWidth />}
              />
            </LocalizationProvider>
          </Col>
        </Row>
      </FormDialog>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />

      <AlertDialog
        isOpen={isOpenRevoke}
        type="revoke"
        title="Notification"
        contentText="Are you sure you want to revoke this item?"
        onClose={handleRevokeClose}
        onSave={handleClickRevoke}
      />

      <FormDialog
        isOpen={isOpenOnHold}
        onClose={handleOnHoldClose}
        onSave={handleSaveOnHoldShareContent}
        type='on_hold'
        title='Hold Content Sharing'
        dividers={true}
        isSave={true}
      >
        <Row>
          <Col>
            <TextField
              label="Reason"
              name="reason"
              defaultValue={reasonOnHold}
              fullWidth
              multiline
              minRows={4}
              onBlur={(e) => handleOnChangeReasonOnHold(e)} />
          </Col>
        </Row>
      </FormDialog>

      <FormDialog
        isOpen={isOpenFormExtend}
        onClose={handleCloseFormExtend}
        onSave={handleExtendExpireTimeForShareContent}
        type='extend_content_sharing'
        title='Extend Expire Time Of Content Sharing'
        dividers={true}
        isSave={true}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={false}
            label={'Expire at'}
            ampm={false}
            required={false}
            inputFormat='dd-MM-yyyy'
            mask="__-__-____"
            value={tenantExpireTime}
            onChange={(e) => handleChangeDatePickerFormExtend(e)}
            renderInput={(params) => <TextField {...params} size={'medium'} fullWidth />}
          />
        </LocalizationProvider>
      </FormDialog>
    </div>
  )
}

export default ShareContentByMe
