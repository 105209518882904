import React from 'react'
import PropTypes from 'prop-types'
import TreeItem from '@mui/lab/TreeItem'

const FolderTree = (props) => {
  const { data, onClick } = props

  const handleClick = (e, prefix) => {
    onClick(prefix)
  }

  return (
    <>
      {data && data.map((item, index) => {
        return (
          <TreeItem key={index} nodeId={item.path} label={item.key} onClick={e => handleClick(e, item.path)}>
            {(item.children && Array.isArray(item.children)) &&
              <FolderTree data={item.children} onClick={onClick} />
            }
          </TreeItem>
        )
      })}
    </>
  )
}

FolderTree.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func
}

FolderTree.defaultProps = {
  data: []
}

export default FolderTree
