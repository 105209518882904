import React from 'react'
import Layout from 'features/layout/components/Layout'
import ImageForm from '../components/ImageForm'

const ImageFormPage = (props) => {
  return (
    <Layout>
      <ImageForm />
    </Layout>
  )
}

export default ImageFormPage
