import axios from 'axios'

const callApi = (method, endpoint, data) => {
  const sessionId = localStorage.getItem('token')

  return axios({
    headers: {
      Authorization: `Bearer ${sessionId}`
    },
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    // url: `${process.env.REACT_APP_API_URL}?${endpoint}`,
    data: data
  })
}

const callTenantApi = (method, endpoint, tenantId, data) => {
  const sessionId = localStorage.getItem('token')

  return axios({
    headers: {
      Authorization: `Bearer ${sessionId}`
    },
    method: method,
    url: `${process.env.REACT_APP_API_URL}tenants/${tenantId}/${endpoint}`,
    // url: `${process.env.REACT_APP_API_URL}?${endpoint}`,
    data: data
  })
}

const callTenantClientApi = (method, endpoint, tenantSlug, data) => {
  const sessionId = localStorage.getItem('token')

  return axios({
    headers: {
      Authorization: `Bearer ${sessionId}`
    },
    method: method,
    url: `${process.env.REACT_APP_API_URL}tenant/${tenantSlug}/${endpoint}`,
    // url: `${process.env.REACT_APP_API_URL}?${endpoint}`,
    data: data
  })
}

const callCrawlerApi = (method, endpoint, data) => {
  return axios({
    method: method,
    url: `${process.env.REACT_APP_API_CRAWLER_URL}${endpoint}`,
    // url: `${process.env.REACT_APP_API_URL}?${endpoint}`,
    data: data
  })
}

export const getTenantClientData = (method, endpoint, tenantSlug, dataSend) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callTenantClientApi(method, endpoint, tenantSlug, dataSend)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        callApi('POST', 'auth/refresh/', {
          refresh: localStorage.getItem('adm_token')
        })
          .then(async res => {
            const accessToken = res.data.data && res.data.data.access
            localStorage.setItem('token', accessToken)

            const result = await callTenantClientApi(method, endpoint, tenantSlug, dataSend)
            const { data } = result
            return data ? resolve(data) : resolve(null)
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              localStorage.removeItem('token')
              window.location.reload()
            } else {
              return reject(err)
            }

            console.log(err)
          })
      } else {
        return reject(err)
      }
    }
  })
}

const callLoginApi = (method, endpoint, data) => {
  return axios({
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    // url: `${process.env.REACT_APP_API_URL}?${endpoint}`,
    data: data
  })
}

export const callUploadApi = (method, endpoint, data) => {
  const sessionId = localStorage.getItem('token')

  return axios({
    method: method,
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    data: data,
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getData = (method, endpoint, dataSend) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApi(method, endpoint, dataSend)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        callApi('POST', 'auth/refresh/', {
          refresh: localStorage.getItem('adm_token')
        })
          .then(async res => {
            const accessToken = res.data.data && res.data.data.access
            localStorage.setItem('token', accessToken)

            const result = await callApi(method, endpoint, dataSend)
            const { data } = result
            return data ? resolve(data) : resolve(null)
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              localStorage.removeItem('token')
              window.location.reload()
            } else {
              return reject(err)
            }

            console.log(err)
          })
      } else {
        return reject(err)
      }
    }
  })
}

export const getCrawlerData = (method, endpoint, dataSend) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callCrawlerApi(method, endpoint, dataSend)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getTenantData = (method, endpoint, tenantId, dataSend) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callTenantApi(method, endpoint, tenantId, dataSend)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        callApi('POST', 'auth/refresh/', {
          refresh: localStorage.getItem('adm_token')
        })
          .then(async res => {
            const accessToken = res.data.data && res.data.data.access
            localStorage.setItem('token', accessToken)

            const result = await callTenantApi(method, endpoint, tenantId, dataSend)
            const { data } = result
            return data ? resolve(data) : resolve(null)
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              localStorage.removeItem('token')
              window.location.reload()
            } else {
              return reject(err)
            }

            console.log(err)
          })
      } else {
        return reject(err)
      }
    }
  })
}

export const getUploadData = (method, endpoint, dataSend) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callUploadApi(method, endpoint, dataSend)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        callApi('POST', 'auth/refresh/', {
          refresh: localStorage.getItem('adm_token')
        })
          .then(async res => {
            const accessToken = res.data.data && res.data.data.access
            localStorage.setItem('token', accessToken)

            const result = await callApi(method, endpoint, dataSend)
            const { data } = result
            return data ? resolve(data) : resolve(null)
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              localStorage.removeItem('token')
              window.location.reload()
            } else {
              return reject(err)
            }

            console.log(err)
          })
      } else {
        return reject(err)
      }
    }
  })
}

export const getLoginData = (method, endpoint, dataSend) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callLoginApi(method, endpoint, dataSend)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

export const formatDate = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear()

    let month = (1 + date.getMonth()).toString()
    month = month.length > 1 ? month : '0' + month

    let day = date.getDate().toString()
    day = day.length > 1 ? day : '0' + day

    return year + month + day
  } else {
    return ''
  }
}

export const formatChartDate = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear()

    let month = (1 + date.getMonth()).toString()
    month = month.length > 1 ? month : '0' + month

    let day = date.getDate().toString()
    day = day.length > 1 ? day : '0' + day

    return year + '-' + month + '-' + day
  } else {
    return ''
  }
}

export const formatVietNamDate = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear()

    let month = (1 + date.getMonth()).toString()
    month = month.length > 1 ? month : '0' + month

    let day = date.getDate().toString()
    day = day.length > 1 ? day : '0' + day

    return day + '/' + month + '/' + year
  } else {
    return ''
  }
}

export const formatVietNamDateTime = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear()

    let month = (1 + date.getMonth()).toString()
    month = month.length > 1 ? month : '0' + month

    let day = date.getDate().toString()
    day = day.length > 1 ? day : '0' + day

    let hour = date.getHours().toString()
    hour = hour.length > 1 ? hour : '0' + hour

    let minute = date.getMinutes().toString()
    minute = minute.length > 1 ? minute : '0' + minute

    let second = date.getSeconds().toString()
    second = second.length > 1 ? second : '0' + second

    return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second
  } else {
    return ''
  }
}

export const formatYearMonth = (date) => {
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  return year + month
}

export const formatThousand = (number) => {
  if (number) {
    if (typeof number === 'string') {
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  }
  return '0'
}

export const formatFormData = (value) => {
  if (typeof value === 'string') {
    return value.trim()
  } else if (value instanceof Date) {
    const year = value.getFullYear()

    let month = (1 + value.getMonth()).toString()
    month = month.length > 1 ? month : '0' + month

    let day = value.getDate().toString()
    day = day.length > 1 ? day : '0' + day

    return year + '-' + month + '-' + day
  } else {
    return value
  }
}

export const formatFormDateTime = (value) => {
  if (value instanceof Date) {
    const year = value.getFullYear()

    let month = (1 + value.getMonth()).toString()
    month = month.length > 1 ? month : '0' + month

    let day = value.getDate().toString()
    day = day.length > 1 ? day : '0' + day

    let hour = value.getHours().toString()
    hour = hour.length > 1 ? hour : '0' + hour

    let minute = value.getMinutes().toString()
    minute = minute.length > 1 ? minute : '0' + minute

    // let second = value.getSeconds().toString()
    // second = second.length > 1 ? second : '0' + second
    const second = '00'

    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  } else {
    return value
  }
}

export const deleteFormData = (method, endpoint, dataSend) => {
  const newEnpoit = `${endpoint}${dataSend}`
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      await callApi(method, newEnpoit)
      return resolve(null)
    } catch (err) {
      if (err.response && err.response.status === 401) {
        callApi('POST', 'auth/refresh/', {
          refresh: localStorage.getItem('adm_token')
        })
          .then(async res => {
            const accessToken = res.data.data && res.data.data.access
            localStorage.setItem('token', accessToken)

            await callApi(method, newEnpoit)
            return resolve(null)
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              localStorage.removeItem('token')
              window.location.reload()
            } else {
              return reject(err)
            }

            console.log(err)
          })
      } else {
        return reject(err)
      }
    }
  })
}

const toLowerCaseNonAccentVietnamese = (str) => {
  str = str.toLowerCase()
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư
  return str
}

export const formatSlug = (value) => {
  const valueParse = toLowerCaseNonAccentVietnamese(value)
  let valueTemp = ''

  if (typeof valueParse === 'string') {
    valueTemp = valueParse.toLowerCase()
    valueTemp = valueTemp.replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
  }

  return valueTemp
}
