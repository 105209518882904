import React, { useEffect, useState, useRef } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import { TextField as TextFieldMUI } from '@mui/material'
import { HOSTNAME } from 'constant/appConstant'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckIcon from '@mui/icons-material/Check'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import DeleteIcon from '@mui/icons-material/Delete'
import Overlay from 'components/ui/Overlay'
import Pagination from '@mui/material/Pagination'
import AlertDialog from 'components/ui/AlertDialog'
// import FormDialog from 'components/form/FormDialog'
import TranscodeFilter from './TranscodeFilter'
import Video from 'components/ui/Video'
import { toast } from 'react-toastify'
import { Select } from 'antd'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import InputAdornment from '@mui/material/InputAdornment'

const { Option } = Select

const Transcode = (props) => {
  const queryClient = useQueryClient()
  const playerRef = useRef(null)

  const [src, setSrc] = useState(null)

  const [data, setData] = useState([])
  const [isLoadingCheckStatus, setIsLoadingCheckStatus] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenTestPlayVideo, setIsOpenTestPlayVideo] = useState(false)
  const [idDelete, setIdDelete] = useState(null)

  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [offset, setOffset] = useState(0)

  const [statusFilter, setStatusFilter] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [orderSort, setOrderSort] = useState('-created_at')

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const videoOptions = {
    autoplay: true,
    controls: true
    // responsive: true,
    // fluid: true
  }

  const { data: dataList, isLoading, isFetching } = useQuery(['getTranscode', limit, offset, statusFilter, searchFilter, orderSort], async () => {
    let endpointTemp = ''

    if (statusFilter) {
      endpointTemp += `&status=${statusFilter.value}`
    }
    if (searchFilter !== '') {
      // endpointTemp += `&file_name=${searchFilter}`
      endpointTemp += `&q=${searchFilter}`
    }

    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/transcodes/?limit=${limit}&offset=${offset}&order=${orderSort}${endpointTemp}`)
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `transcode/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getTranscode')
    }
  })

  const mutationRetry = useMutation(async (dataForm) => {
    if (dataForm) {
      const { id, filePath } = dataForm

      return await getData('PUT', `transcode/${id}/`, {
        file_path: filePath
      })
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getTranscode')
    }
  })

  const columns = [
    {
      title: 'Action',
      key: 'operation',
      fixed: 'left',
      align: 'center',
      width: '20%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip title="Retry">
              <IconButton color="primary" onClick={e => handleClickRetry(e, record)}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Check Status">
              <IconButton color="primary" onClick={e => handleCheckStatus(e, record)}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Test Play Video">
              <IconButton color="primary" disabled={record.status !== 1} onClick={e => handleTestPlayVideo(e, record)}>
                <PlayArrowIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      width: '25%',
      ellipsis: true
    },
    {
      title: 'SourceID',
      dataIndex: 'source_id',
      key: 'source_id',
      width: '30%',
      ellipsis: true
    },
    (
      (window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.TERA_STAGING || window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING || window.location.hostname === HOSTNAME.LOCALHOST)
        ? {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            ellipsis: true,
            // eslint-disable-next-line react/display-name
            render: (text, record, index) => {
              return (
                <div>
                  {record?.type === 0 ? 'RAW' : 'CATCHUP'}
                </div>
              )
            }
          }
        : {}
    ),
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '15%',
      ellipsis: true,
      sorter: true
    },
    {
      title: 'Status',
      key: 'status',
      sorter: true,
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div>
            {(record.status === 0 || record.status === 2)
              ? 'Progressing'
              : record.status === 1
                ? 'Success'
                : 'Failure'}
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    if (dataList) {
      const resultData = dataList.data && dataList.data.results

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        setData(dataListParse)

        if (dataList.data) {
          setTotal(dataList.data && dataList.data.total)
          setTotalPage(Math.ceil(dataList.data.total / limit))
        }
      }
    }
  }, [dataList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeStatusFilter = (value) => {
    setStatusFilter(value)
  }
  const handleClickSearchFilter = (value) => {
    setSearchFilter(value)
  }

  const handleChangeTable = (pagination, filters, sorter) => {
    if (sorter) {
      if (sorter.columnKey === 'created_at') {
        if (sorter.order === 'ascend') {
          setOrderSort('created_at')
        } else if (sorter.order === 'descend') {
          setOrderSort('-created_at')
        } else {
          setOrderSort('-created_at')
        }
      }
      if (sorter.columnKey === 'status') {
        if (sorter.order === 'ascend') {
          setOrderSort('status')
        } else if (sorter.order === 'descend') {
          setOrderSort('-status')
        } else {
          setOrderSort('-status')
        }
      }
    }

    console.log(sorter)
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // // You can handle player events here, for example:
    // player.on('waiting', () => {
    //   player.log('player is waiting')
    // })

    // player.on('dispose', () => {
    //   player.log('player will dispose')
    // })
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleChangeLimit = (value) => {
    setLimit(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limit)
  }

  const handleCheckStatus = async (e, record) => {
    try {
      setIsLoadingCheckStatus(true)

      const resultApi = await getData('GET', `transcode/${record && record.id}/check-status/`)
      const resultData = resultApi && resultApi.data
      const message = resultData && resultData.message

      setIsLoadingCheckStatus(false)
      toast.success(message)
    } catch (err) {
      console.log(err)
      setIsLoadingCheckStatus(false)
      toast.error('Something went wrong!')
    }
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)
      setIsOpenDelete(false)
      toast.success('Job deleted successfully')
    } catch (err) {
      if (err.response && err.response.status > 300) {
        const errData = err.response.data && err.response.data.errors
        toast.error(errData?.message || 'Something went wrong!')
        setIsOpenDelete(false)
      } else {
        toast.error('Something went wrong!')
      }
    }
  }

  const handleTestPlayVideo = async (e, record) => {
    try {
      if (record.status === 1) {
        setIsOpenTestPlayVideo(true)

        const resultApi = await getData('GET', `transcode/${record && record.id}/link-play/`)
        const resultData = resultApi && resultApi.data

        setSrc({
          type: 'application/x-mpegURL',
          src: resultData && resultData.url
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCloseTestPlayVideo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }

    setIsOpenTestPlayVideo(false)
  }

  const handleClickRetry = async (e, record) => {
    try {
      await mutationRetry.mutateAsync({
        id: record && record.id,
        filePath: record && record.file_path
      })

      toast.success('Retry success')
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong!')
    }
  }

  const handleCopyLinkPlay = async () => {
    const copyValue = src?.src ? src?.src : ''

    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(copyValue)
    } else {
      const copyElement = document.createElement('input')
      copyElement.value = copyValue

      copyElement.style.position = 'absolute'
      copyElement.style.left = '-999999px'

      document.body.prepend(copyElement)
      copyElement.select()

      try {
        document.execCommand('copy')
      } catch (error) {
        console.error(error)
      } finally {
        copyElement.remove()
      }
    }

    alert('Sao chép thành công')
  }

  return (
    <div className="transcode">
      <TranscodeFilter
        onChangeStatus={handleChangeStatusFilter}
        onClickSearch={handleClickSearchFilter}
      />

      <Row className="pt-5 pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Table
            columns={columns}
            data={data}
            onChange={handleChangeTable}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limit} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
              className="float-right"
              count={totalPage}
              page={current}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              color="standard"
            />
        </Col>
      </Row>

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />

      <Dialog
        disableEscapeKeyDown
        open={isOpenTestPlayVideo}
        onClose={handleCloseTestPlayVideo}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
        // PaperProps={{
        //   sx: {
        //     minHeight: '90vh'
        //   }
        // }}
      >
        <DialogTitle id="form-dialog-title">
          Test Play Video
        </DialogTitle>
        <DialogContent dividers style={{ height: '80vh' }}>
          <Row>
            <Col md={12}>
              <div>
                <Video options={videoOptions} onReady={handlePlayerReady} src={src} />
              </div>
            </Col>
          </Row>

          {(window.location.hostname === HOSTNAME.DEMO || window.location.hostname === HOSTNAME.TERA || window.location.hostname === HOSTNAME.TERA_STAGING) &&
            <Row className="mt-5">
              <Col md={12}>
                <TextFieldMUI
                  disabled
                  size="small"
                  fullWidth
                  label="Link play"
                  value={src?.src ? src?.src : ''}
                  onChange={() => {}}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <IconButton onClick={handleCopyLinkPlay}>
                            <CopyAllIcon />
                          </IconButton>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Col>
            </Row>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={handleCloseTestPlayVideo}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {isLoadingCheckStatus && <Overlay />}
    </div>
  )
}

export default Transcode
