/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import CoreList from 'features/core/components/CoreList'
import CheckIcon from '@mui/icons-material/Check'
import { Input, Button as ButtonAntd, Space } from 'antd'
import Chip from '@mui/material/Chip'
import Highlighter from 'react-highlight-words'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import LockIcon from '@mui/icons-material/Lock'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { SearchOutlined } from '@ant-design/icons'

const User = (props) => {
  const searchInput = useRef()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters, confirm) => {
    clearFilters()
    setSearchText('')
    confirm()
  }

  const getColumnSearchProps = dataIndex => ({
    // eslint-disable-next-line react/display-name
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonAntd
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 95 }}
          >
            Search
          </ButtonAntd>
          <ButtonAntd onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 95 }}>
            Reset
          </ButtonAntd>
          {/* <ButtonAntd
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </ButtonAntd> */}
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/display-name
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select(), 100)
      }
    },
    // eslint-disable-next-line react/display-name
    render: text => {
      return searchedColumn === dataIndex
        ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
          )
        : (
            text
          )
    }
  })

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: '30%',
      ellipsis: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      ...getColumnSearchProps('username')
    },
    {
      title: 'Groups',
      key: 'groups',
      width: '20%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        const groupArr = record && record.groups
        if (Array.isArray(groupArr)) {
          if (groupArr.length > 0) {
            return groupArr.map((item, index) => {
              return (
                <div key={index} className={`pt-${index === 0 ? '0' : '2'}`}>
                  <Chip label={item.name} size="small" className="p-2" />
                </div>
              )
            })
          } else {
            return null
          }
        } else {
          return null
        }
      }
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '18%'
    },
    {
      title: 'Active',
      key: 'is_active',
      align: 'center',
      width: '12%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.is_active) {
          return <CheckIcon color="success" />
        } else {
          return null
        }
      },
      sorter: (a, b) => {
        return b.is_active - a.is_active
      }
    }
  ]

  return (
    <div className="user">
      <CoreList
        code="User"
        endpoint="users"
        url="user"
        columns={columns}
        customAction={(record) => {
          if (record && record.is_tenant_manager) {
            return (
              <>
                <Tooltip title="Reset Password">
                  <Link to={`/user/${record && record.id}/reset-password`}>
                    <IconButton>
                      <VisibilityIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="User is a tenant manager">
                  <IconButton style={{ opacity: '0.5' }}>
                    <LockIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          } else {
            return (
              <>
                <Tooltip title="Reset Password">
                  <Link to={`/user/${record && record.id}/reset-password`}>
                    <IconButton>
                      <VisibilityIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Permission">
                  <Link to={`/user/${record && record.id}/permission`}>
                    <IconButton>
                      <LockIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </>
            )
          }
        }}
      />
    </div>
  )
}

export default User
