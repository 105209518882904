import React from 'react'
import Layout from 'features/layout/components/Layout'
import MenuItem from '../components/MenuItem'

const MenuItemPage = (props) => {
  return (
    <Layout>
      <MenuItem />
    </Layout>
  )
}

export default MenuItemPage
