import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { getData } from 'common/utils'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import IconButton from '@mui/material/IconButton'
import { toast } from 'react-toastify'
import '../styles/transcode-add.scss'

const Transcode = (props) => {
  const { id } = useParams()

  const [fileName, setFileName] = useState(null)
  const [filePath, setFilePath] = useState(null)

  const { data: dataInfo } = useQuery('getTranscodeInfo', async () => {
    return await getData('GET', `transcode/${id}/`)
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('PUT', 'transcode/', dataForm)
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setFileName(resultData.file_name)
        setFilePath(resultData.file_path)
      }
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickTranscode = async (e, item) => {
    try {
      await mutation.mutateAsync({
        file_path: filePath
      })

      toast.success('Add job transcode success')
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong!')
    }
  }

  return (
    <div className="transcode-add">
      <div className="row">
        <div className="col-12">
          <div className="alert alert-primary" role="alert">
            {/* <p>Sử dụng S3 Browser để upload file muốn transcode lên server hoặc nhập dường dẫn http/fpt trực tiếp</p> */}
            Nhấn &quot;Transcode&quot; để thực hiện quá trình.
          </div>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-4">
          <div className="text-primary">FOLDER LIST</div>
          <hr />
        </div>
        <div className="col-8">
          <div className="text-primary">FILE LIST</div>
          <hr />

          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-inline-block name">{fileName}</div>
              <div className="d-inline-block float-right name">Transcode</div>
              <div className="d-inline-block float-right">
                <IconButton onClick={handleClickTranscode}>
                  <PlayArrowIcon />
                </IconButton>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Transcode
