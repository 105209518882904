import React from 'react'
import Layout from 'features/layout/components/Layout'
import GroupForm from '../components/GroupForm'

const GroupFormPage = (props) => {
  return (
    <Layout>
      <GroupForm />
    </Layout>
  )
}

export default GroupFormPage
