/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import CoreList from 'features/core/components/CoreList'
import CheckIcon from '@mui/icons-material/Check'
import { Input, Button as ButtonAntd, Space } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'

const Menu = (props) => {
  const searchInput = useRef()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters, confirm) => {
    clearFilters()
    setSearchText('')
    confirm()
  }

  const getColumnSearchProps = dataIndex => ({
    // eslint-disable-next-line react/display-name
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonAntd
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 95 }}
          >
            Search
          </ButtonAntd>
          <ButtonAntd onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 95 }}>
            Reset
          </ButtonAntd>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/display-name
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select(), 100)
      }
    },
    // eslint-disable-next-line react/display-name
    render: text => {
      return searchedColumn === dataIndex
        ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
          )
        : (
            text
          )
    }
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ellipsis: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      ...getColumnSearchProps('name')
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: '25%',
      ellipsis: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      ...getColumnSearchProps('slug')
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '20%'
    },
    {
      title: 'Active',
      key: 'is_active',
      align: 'center',
      width: '15%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record.is_active) {
          return <CheckIcon color="success" />
        } else {
          return null
        }
      },
      sorter: (a, b) => {
        return b.is_active - a.is_active
      }
    }
  ]

  return (
    <div className="menu">
      <CoreList
        code="Menu"
        endpoint="menu"
        url="menu-platform"
        columns={columns}
      />
    </div>
  )
}

export default Menu
