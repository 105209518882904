import React from 'react'
import Layout from 'features/layout/components/Layout'
import EpgTiming from '../components/EpgTiming'

const EpgTimingPage = (props) => {
  return (
    <Layout>
      <EpgTiming />
    </Layout>
  )
}

export default EpgTimingPage
