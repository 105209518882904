import React from 'react'
import Layout from 'features/layout/components/Layout'
import MenuForm from '../components/MenuForm'

const MenuFormPage = (props) => {
  return (
    <Layout>
      <MenuForm />
    </Layout>
  )
}

export default MenuFormPage
