import React from 'react'
import Layout from 'features/layout/components/Layout'
import GroupPermission from '../components/GroupPermission'

const GroupPermissionPage = (props) => {
  return (
    <Layout>
      <GroupPermission />
    </Layout>
  )
}

export default GroupPermissionPage
