import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'components/ui'
import { Editor } from '@tinymce/tinymce-react'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Autocomplete from 'components/form/Autocomplete'
import AutocompleteMultiple from 'components/form/AutocompleteMultiple'
import DateTimePicker from 'components/form/DateTimePicker'

const VodViewSeason = (props) => {
  const { control, errors, statusList, peopleTypeList, peopleList, longDescription, editorRef } = props

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  return (
    <div className="vod-view-season">
      <Row className="pt-4">
        <Col md={5}>
          <TextField disabled label="Rating (Voted)" name="season_view_rating" control={control} error={errors.season_view_rating} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField
            disabled
            label="Title"
            name="season_view_title"
            required
            control={control}
            error={errors.season_view_title}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField disabled label="English name" name="season_view_known_as" control={control} error={errors.season_view_known_as} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField disabled label="Slug" name="season_view_slug" required control={control} error={errors.season_view_slug} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField disabled label="Short description" name="season_view_short_description" control={control} error={errors.season_view_short_description} />
        </Col>
        <Col md={5} className="offset-md-2">
          <DateTimePicker disabled label="Release Date" name="season_view_release_date" control={control} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <DateTimePicker disabled label="Unrelease Date" name="season_view_unrelease_date" control={control} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField disabled type="number" label="Release Year" name="season_view_release_year" control={control} error={errors.season_view_release_year} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <DateTimePicker disabled label="Schedule publish" name="season_view_schedule_date" control={control} />
        </Col>
        <Col md={5} className="offset-md-2">
          <Autocomplete
            disabled
            options={statusList}
            label="Status"
            name="season_view_status"
            control={control}
            error={errors.season_view_status}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField disabled type="number" label="Duration" name="season_view_runtime" control={control} error={errors.season_view_runtime} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField disabled label="Resolution" name="season_view_resolution" control={control} error={errors.season_view_resolution} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField disabled type="number" label="Total Episode" name="season_view_total_seasons" control={control} error={errors.season_view_total_seasons} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField disabled type="number" label="Season Index" name="season_view_season" control={control} error={errors.season_view_season} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField disabled label="Season Name" name="season_view_season_name" control={control} error={errors.season_view_season_name} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField disabled type="number" label="Favorites" name="season_view_favorites" control={control} error={errors.season_view_favorites} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={5}>
          <TextField disabled type="number" label="Views (Fake)" name="season_view_views" control={control} error={errors.season_view_views} />
        </Col>
        <Col md={5} className="offset-md-2">
          <TextField disabled type="number" label="Views (Real)" name="season_view_real_views" control={control} error={errors.season_view_real_views} />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col md={12}>
          <Checkbox disabled name="season_view_released" className="d-inline-block" control={control} label="Realeased State" />
          <Checkbox disabled name="season_view_is_samsung_preview" className="pt-4 ml-4 d-inline-block" control={control} label="Samsung Preview" />
        </Col>
      </Row>
      {peopleTypeList && Array.isArray(peopleTypeList) && peopleTypeList.map((item, index) => {
        return (
          <Row className="pt-4" key={index}>
            <Col md={12}>
              <AutocompleteMultiple
                disabled
                options={peopleList}
                label={item.name}
                name={`season_view_people_types_${index}`}
                control={control}
                error={errors.people_types}
              />
            </Col>
          </Row>
        )
      })}
      <Row className="pt-5">
        <Col md={12}>
          {/* <TextField type="textarea" label="Info" name="long_description" control={control} error={errors.long_description} /> */}
          <div className="description-title">Description</div>
          <Editor
            disabled
            apiKey='8xwdiofw91cl5ikp80dpkij70sj7u8uziroarh8v1420aq6y'
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            // eslint-disable-next-line no-return-assign
            onInit={(evt, editor) => {
              if (editorRef) {
                editorRef.current = editor
              }
              // setIsLoading(false)
            }}
            initialValue={longDescription}
            init={{
              height: 350,
              branding: false,
              plugins: 'advlist autolink lists link charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime table code help wordcount',
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

VodViewSeason.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  statusList: PropTypes.array,
  peopleTypeList: PropTypes.array,
  peopleList: PropTypes.array,
  longDescription: PropTypes.string,
  editorRef: PropTypes.any
}

VodViewSeason.defaultProps = {
  statusList: [],
  peopleTypeList: [],
  peopleList: [],
  longDescription: ''
}

export default VodViewSeason
