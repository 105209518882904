import React, { useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getData } from 'common/utils'
import { toast } from 'react-toastify'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from 'components/form/TextField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Autocomplete from 'components/form/Autocomplete'

const AppVersionForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, formState: { errors }, setError } = useForm()

  const platformList = [
    { value: 0, label: 'iOS' },
    { value: 1, label: 'Android' }
  ]

  const { data: dataInfo } = useQuery(['getInfoAppVersion', id], async () => {
    if (id) {
      return await getData('GET', `app_versions/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `app_versions/${id}/`, dataForm)
      } else {
        return await getData('POST', 'app_versions/', dataForm)
      }
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const resultData = dataInfo.data

      if (resultData) {
        setValue('version', resultData.version)
        setValue('is_force_update', resultData.is_force_update)

        const platformFind = platformList.find(item => item.value === resultData.platform)
        setValue('platform', platformFind)
      }
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          version: data.version,
          platform: data.platform && data.platform.value,
          is_force_update: typeof data.is_force_update === 'undefined' ? false : data.is_force_update
        })
      } else {
        await mutation.mutateAsync({
          version: data.version,
          platform: data.platform && data.platform.value,
          is_force_update: typeof data.is_force_update === 'undefined' ? false : data.is_force_update,
          tenant: localStorage.getItem('tenant')
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          const messageErr = errData.message
          if (Array.isArray(messageErr)) {
            setError('version', {
              type: 'manual',
              message: messageErr[0]
            })
          } else if (typeof messageErr === 'string') {
            setError('version', {
              type: 'manual',
              message: messageErr
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="menu-form">
      <Card>
        <CardContent>
          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <TextField label="Version" name="version" required control={control} error={errors.version} />
            </Col>
          </Row>

          <Row className="pt-3">
            <Col md={6} className="offset-md-3">
              <Autocomplete
                options={platformList}
                label="Platform"
                name="platform"
                required
                control={control}
                error={errors.platform}
              />
            </Col>
          </Row>

          <Row className="pt-4">
            <Col md={6} className="offset-md-3">
              <Checkbox label="Force update" name="is_force_update" control={control} />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button className="mr-2 d-inline-block" color="error" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default AppVersionForm
