import React from 'react'
import Layout from 'features/layout/components/Layout'
import UserResetPassword from '../components/UserResetPassword'

const UserResetPasswordPage = (props) => {
  return (
    <Layout>
      <UserResetPassword />
    </Layout>
  )
}

export default UserResetPasswordPage
