import React from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { getData } from 'common/utils'
import CoreList from 'features/core/components/CoreList'
import Switch from '@mui/material/Switch'
import { Image } from 'components/ui'
// import Tooltip from '@mui/material/Tooltip'
// import IconButton from '@mui/material/IconButton'
// import EditIcon from '@mui/icons-material/Edit'
// import DeleteIcon from '@mui/icons-material/Delete'

const MenuSlide = () => {
  const queryClient = useQueryClient()
  // States

  // Fetch APIs
  const mutation = useMutation(async ({ id, dataForm }) => {
    if (id && dataForm) {
      return await getData('PUT', `menu-item-category/${id}/`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getMenuItemCategory')
    }
  })

  // Handle Event
  const handleChangeStatus = async (e, item) => {
    console.log(item)
    try {
      await mutation.mutateAsync({
        id: item?.id,
        dataForm: {
          title: item?.title,
          ord: item?.ord,
          is_active: e.target.checked,
          icon: item?.icon
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Title Menu',
      key: 'title',
      width: '20%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{record?.title || ''}</div>
        )
      }
    },
    {
      title: 'Position',
      key: 'ord',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <div key={`position-${index}`}>{record.ord}</div>
      }
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <>
          {
            (record?.url && record?.url !== '' && record?.icon !== 'null')
              ? <Image src={record.url} width={100} height={60} />
              : <div></div>
          }
          </>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '8%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Switch checked={record.is_active} onChange={e => handleChangeStatus(e, record)} />
      }
    }
  ]

  return (
    <div className='ribbon-display'>
      <CoreList
        code="MenuItemCategory"
        endpoint="menu-item-category"
        url="menu-slide"
        columns={columns}
      />
    </div>
  )
}

export default MenuSlide
