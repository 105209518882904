import React from 'react'
import Layout from 'features/layout/components/Layout'
import UserForm from '../components/UserForm'

const UserFormPage = (props) => {
  return (
    <Layout>
      <UserForm />
    </Layout>
  )
}

export default UserFormPage
