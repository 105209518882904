import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { getData } from 'common/utils'
import TreeView from '@mui/lab/TreeView'
// import TreeItem from '@mui/lab/TreeItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import IconButton from '@mui/material/IconButton'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FolderTree from './FolderTree'
import { toast } from 'react-toastify'
import '../styles/transcode-add.scss'
// import PlayArrowIcon from '@mui/icons-material/PlayArrow'

const Transcode = (props) => {
  const [folderList, setFolderList] = useState([])
  const [fileList, setFileList] = useState([])
  const [folder, setFolder] = useState(null)

  const [expanded, setExpanded] = useState([])
  const [selected, setSelected] = useState([])

  const [updateSelect, setUpdateSelect] = useState('-updated_at')

  const { data: dataObject } = useQuery(['getObjects', folder, updateSelect], async () => {
    if (folder) {
      if (folder === '/') {
        return await getData('GET', `tenants/${localStorage.getItem('tenant')}/objects/?order=${updateSelect}`)
      } else {
        return await getData('GET', `tenants/${localStorage.getItem('tenant')}/objects/?order=${updateSelect}&prefix=${folder}`)
      }
    } else {
      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/objects/?order=${updateSelect}`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('POST', 'transcode/', dataForm)
    }
  })

  const recursiveFolderList = (folderListParam, folderChildrenParam) => {
    for (let i = 0; i < folderListParam.length; i++) {
      if (folderListParam[i].path === folder) {
        folderListParam[i].children = folderChildrenParam || []
        break
      }

      const children = folderListParam[i].children
      if (children && Array.isArray(children)) {
        recursiveFolderList(children, folderChildrenParam)
      }
    }

    return folderListParam
  }

  useEffect(() => {
    if (dataObject) {
      console.log(dataObject)
      const resultData = dataObject.data

      const resultFolder = resultData && resultData.folders
      const resultFile = resultData && resultData.files

      if (folder) {
        const folderListClone = [...folderList]
        recursiveFolderList(folderListClone, resultFolder)
      } else {
        const listTemp = [
          {
            key: '/',
            path: '/',
            children: resultFolder
          }
        ]

        if (Array.isArray(resultFolder)) {
          setFolderList(listTemp)

          const expandedTemp = [...expanded]
          expandedTemp.push('/')
          setExpanded(expandedTemp)
        } else {
          setFolderList(listTemp)
        }
        // setFolderList(Array.isArray(resultFolder) ? resultFolder : [])
      }

      setFileList(Array.isArray(resultFile) ? resultFile : [])
    }
  }, [dataObject])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds)
  }

  const handleClickFolder = (prefix) => {
    setFolder(prefix)

    const expandedTemp = [...expanded]
    expandedTemp.push(prefix)
    setExpanded(expandedTemp)
  }

  const handleClickTranscode = async (e, item) => {
    try {
      await mutation.mutateAsync({
        file_path: item && item.path,
        tenant: localStorage.getItem('tenant')
      })

      toast.success('Add job transcode success')
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong!')
    }
  }

  const handleChangeUpdate = (event) => {
    setUpdateSelect(event.target.value)
  }

  return (
    <div className="transcode-add">
      <div className="row">
        <div className="col-12">
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="filled" severity="info">Click &quot;Transcode&quot; to do the process</Alert>
          </Stack>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-4">
          <div className="folder-container">
            <div className="text-primary">FOLDER LIST</div>
          </div>

          <hr />

          <div>
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              multiSelect
            >
              <FolderTree data={folderList} onClick={handleClickFolder} />
            </TreeView>
          </div>
        </div>
        <div className="col-8">
          <div className="file-container">
            <div className="text-primary">FILE LIST</div>

            <div className="sort-container">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Sort by</InputLabel>
                <Select
                  value={updateSelect}
                  label="Sort by"
                  onChange={handleChangeUpdate}
                >
                  {/* <MenuItem value="-updated_at">
                    <em>Default</em>
                  </MenuItem> */}
                  <MenuItem value="-updated_at">Newest first</MenuItem>
                  <MenuItem value="updated_at">Oldest first</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <hr />

          <ul className="list-group">
            {fileList && fileList.map((item, index) => {
              return (
                <li key={index} className="list-group-item">
                  <div className="d-inline-block name">{item.key}</div>
                  <div className="d-inline-block float-right name">Transcode</div>
                  <div className="d-inline-block float-right">
                    <IconButton onClick={e => handleClickTranscode(e, item)}>
                      <PlayArrowIcon />
                    </IconButton>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Transcode
