import React from 'react'
import Layout from 'features/layout/components/Layout'
import ApproveComment from '../components/ApproveComment'

const ApproveCommentPage = (props) => {
  return (
    <Layout>
      <ApproveComment />
    </Layout>
  )
}

export default ApproveCommentPage
