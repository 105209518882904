import React from 'react'
import Layout from 'features/layout/components/Layout'
import VodMetadata from '../components/VodMetadata'

const VodMetadataPage = (props) => {
  return (
    <Layout>
      <VodMetadata />
    </Layout>
  )
}

export default VodMetadataPage
