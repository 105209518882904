import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getData, getTenantData, formatFormData } from 'common/utils'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import Tree from 'components/ui/Tree'
import TreeSelect from 'components/ui/TreeSelect'
import Button from 'components/form/Button'
import IconButton from '@mui/material/IconButton'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CheckIcon from '@mui/icons-material/Check'
import DownloadIcon from '@mui/icons-material/Download'
import '../styles/report.scss'

const Report = (props) => {
  const [menuItem, setMenuItem] = useState(null)
  const [menuList, setMenuList] = useState([])
  const [dataTree, setDataTree] = useState([])
  const [dataTreeSelect, setDataTreeSelect] = useState([])
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())

  const { data: dataRibbon } = useQuery(['getRibbon', menuItem], async () => {
    if (menuItem) {
      return await getData('GET', `menu-items/${menuItem}/ribbons/`)
    }
  })

  const { data: dataMenu } = useQuery('getRibbonMenu', async () => {
    return await getData('GET', `tenants/${localStorage.getItem('tenant')}/menu/`)
  })

  const { data: dataMenuItem } = useQuery(['getRibbonMenuItem', menuList], async () => {
    const resultTemp = []

    try {
      if (menuList && menuList.length > 0) {
        for (let i = 0; i < menuList.length; i++) {
          const resultApi = await getData('GET', `menu/${menuList[i] && menuList[i].id}/menu-items/`)
          const resultData = resultApi && resultApi.data
          if (Array.isArray(resultData)) {
            const dataParse = resultData.map((item, index) => {
              // Sub Item
              const childrenArrTemp = []
              const childrenArr = item.sub_menu_items
              if (Array.isArray(childrenArr)) {
                for (let i = 0; i < childrenArr.length; i++) {
                  childrenArrTemp.push({
                    value: childrenArr[i] && childrenArr[i].id,
                    title: childrenArr[i] && childrenArr[i].name,
                    children: []
                  })
                }
              }

              return {
                title: item.name,
                value: item.id,
                children: childrenArrTemp
              }
            })

            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              selectable: false,
              children: dataParse
            })
          } else {
            resultTemp.push({
              title: menuList[i] && menuList[i].name,
              value: menuList[i] && menuList[i].id,
              children: []
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    return resultTemp
  })

  useEffect(() => {
    if (dataMenu) {
      const resultData = dataMenu.data && dataMenu.data.results
      setMenuList(resultData)
    }
  }, [dataMenu])

  useEffect(() => {
    if (dataMenuItem) {
      setDataTreeSelect(dataMenuItem)
    }
  }, [dataMenuItem])

  useEffect(() => {
    if (menuList) {
      // console.log(menuList)
    }
  }, [menuList])

  useEffect(() => {
    if (dataRibbon) {
      const resultData = dataRibbon.data

      if (Array.isArray(resultData)) {
        const dataTemp = resultData.map((item, index) => {
          const childrenArrTemp = []
          const childrenArr = item.sub_menu_items
          if (Array.isArray(childrenArr)) {
            for (let i = 0; i < childrenArr.length; i++) {
              const type = childrenArr[i] && childrenArr[i].type

              childrenArrTemp.push({
                key: `0-0-${i}`,
                id: childrenArr[i] && childrenArr[i].id,
                title: childrenArr[i] && childrenArr[i].name,
                type: type === 0
                  ? 'Master Banner'
                  : type === 1
                    ? 'Poster'
                    : type === 2
                      ? 'Thumbnail'
                      : type === 3
                        ? 'Banner'
                        : type === 4
                          ? 'List'
                          : type === 5
                            ? 'Circle'
                            : type === 6
                              ? 'Pano'
                              : type === 7
                                ? 'Special News'
                                : type === 8
                                  ? 'Trending'
                                  : type === 9
                                    ? 'Poster News'
                                    : type === 10
                                      ? 'Audio'
                                      : type === 11
                                        ? 'Auto Play'
                                        : type === 12
                                          ? 'Album'
                                          : 'Banner Top',
                is_active: childrenArr[i] && childrenArr[i].is_active,
                children: []
              })
            }
          }

          return {
            key: `0-${index}`,
            id: item.id,
            title: item.name,
            type: item.type === 0
              ? 'Master Banner'
              : item.type === 1
                ? 'Poster'
                : item.type === 2
                  ? 'Thumbnail'
                  : item.type === 3
                    ? 'Banner'
                    : item.type === 4
                      ? 'List'
                      : item.type === 5
                        ? 'Circle'
                        : item.type === 6
                          ? 'Pano'
                          : item.type === 7
                            ? 'Special News'
                            : item.type === 8
                              ? 'Trending'
                              : item.type === 9
                                ? 'Poster News'
                                : item.type === 10
                                  ? 'Audio'
                                  : item.type === 11
                                    ? 'Auto Play'
                                    : item.type === 12
                                      ? 'Album'
                                      : 'Banner Top',
            is_active: item.is_active,
            children: childrenArrTemp
          }
        })

        setDataTree(dataTemp)
        // setExpandedKeys(['0-0', '0-0-0', '0-0-0-0'])
      }
    }
  }, [dataRibbon])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeMenuItem = value => {
    setMenuItem(value)
  }

  const handleChangeFromDate = value => {
    setFromDate(value)
  }

  const handleChangeToDate = value => {
    setToDate(value)
  }

  const handleClickExport = async (e, item) => {
    const ribbonIdParam = item.id
    const fromDateParam = formatFormData(fromDate)
    const toDateParam = formatFormData(toDate)

    const resultExport = await getTenantData(
      'GET',
      `export_excel_brt/?ribbon_id=${ribbonIdParam}&from_date=${fromDateParam}&to_date=${toDateParam}`,
      '6068de35-ee56-48e7-9e17-fd2e9535cb62'
      // localStorage.getItem('tenant')
    )
    if (resultExport) {
      const resultData = resultExport.data
      if (resultData) {
        const link = document.createElement('a')
        link.download = 'report.xlsx'
        link.href = resultData.excel_url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  return (
    <div className="report">
      <Card>
        <CardContent>
          <Row className="pt-2 align-items-end">
            <Col md={6}>
              <div className="pb-1">Menu Page</div>
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                value={menuItem}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={dataTreeSelect}
                placeholder="Please select Menu Page to see Ribbon"
                treeDefaultExpandAll
                bordered
                onChange={handleChangeMenuItem}
              />
            </Col>
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="From Date"
                  value={fromDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeFromDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
            <Col md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  forma
                  label="To Date"
                  value={toDate}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleChangeToDate}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </LocalizationProvider>
            </Col>
          </Row>
        </CardContent>
      </Card>

      <Row className="pt-5">
        <Col md={12}>
          <Tree
            className="draggable-tree"
            // expandedKeys={expandedKeys}
            blockNode
            draggable={false}
            treeData={dataTree}
            titleRender={(nodeData) => {
              return (
                <div>
                  {nodeData.type} : {nodeData.title}
                  <div className="ribbon-view float-right mr-2">
                    <Button startIcon={<DownloadIcon />} onClick={e => handleClickExport(e, nodeData)}>Export CSV</Button>
                  </div>
                  <div className="float-right mr-5">
                    {nodeData.is_active &&
                      <div>
                        <IconButton color="success">
                          <CheckIcon />
                        </IconButton>
                        Active
                      </div>
                    }
                  </div>
                </div>
              )
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Report
