import React from 'react'
import Layout from 'features/layout/components/Layout'
import RibbonSharingByMe from '../components/RibbonSharingByMe'

const RibbonSharingByMePage = () => {
  return (
    <Layout>
      <RibbonSharingByMe />
    </Layout>
  )
}

export default RibbonSharingByMePage
