import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HOSTNAME } from 'constant/appConstant'
import PropTypes from 'prop-types'
import Row from 'components/ui/Row'
import Col from 'components/ui/Col'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

const VodFilter = (props) => {
  const { onChangeType, onChangeCategory, onChangeStatus, onClickSearch } = props

  const location = useLocation()

  const [type, setType] = useState(null)
  const [category, setCategory] = useState(null)
  const [status, setStatus] = useState(null)
  const [searchText, setSearchText] = useState('')

  const typeList = window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO
    ? [
        { value: 1, label: 'Single Movie' },
        { value: 2, label: 'Show/Drama' },
        { value: 3, label: 'Season' },
        { value: 4, label: 'Episode' },
        { value: 5, label: 'Channel' },
        { value: 6, label: 'Trailer' },
        { value: 8, label: 'Breaking News' },
        { value: 9, label: 'Audio' },
        { value: 10, label: 'Event' },
        { value: 11, label: 'Vertical Video' },
        { value: 12, label: 'Show Audio' },
        { value: 15, label: 'News Reports' },
        { value: 16, label: 'Sponsor' },
        { value: 17, label: 'MULTI NEWSPAPER' },
        { value: 18, label: 'EMAGAZINE' },
        { value: 19, label: 'INTERNAL NEWS' }
        // { value: 13, label: 'Season Audio' }
      ]
    : window.location.hostname === HOSTNAME.TAYNINH || window.location.hostname === HOSTNAME.TAYNINH_STAGING
      ? [
          { value: 1, label: 'Single Movie' },
          { value: 2, label: 'Show/Drama' },
          { value: 3, label: 'Season' },
          { value: 4, label: 'Episode' },
          { value: 5, label: 'Channel' },
          { value: 6, label: 'Trailer' },
          { value: 7, label: 'Document' },
          { value: 11, label: 'Vertical Video' }
        ]
      : [
          { value: 1, label: 'Single Movie' },
          { value: 2, label: 'Show/Drama' },
          { value: 3, label: 'Season' },
          { value: 4, label: 'Episode' },
          { value: 5, label: 'Channel' },
          { value: 6, label: 'Trailer' }
        ]

  const [categoryList, setCategoryList] = useState([])

  const statusList = [
    { value: 1, label: 'Chờ xử lí' },
    { value: 2, label: 'Chờ QC' },
    { value: 3, label: 'Xuất bản' },
    { value: 4, label: 'Tạm ngưng xuất bản' },
    { value: 5, label: 'Tái xuất bản' },
    { value: 6, label: 'Đóng' },
    { value: 7, label: 'Schedule publish' },
    { value: 8, label: 'Draft' }
  ]

  useEffect(() => {
    if (window.location.hostname === HOSTNAME.VTCNOW || window.location.hostname === HOSTNAME.VTCNOW_STAGING || window.location.hostname === HOSTNAME.DEMO) {
      setCategoryList([
        { value: 6, label: 'Tin tức' },
        { value: 1, label: 'Phim' },
        { value: 2, label: 'Show' }
      ])
    } else {
      setCategoryList([
        { value: 1, label: 'Phim' },
        { value: 2, label: 'Show' },
        { value: 3, label: 'Ca cổ' },
        { value: 4, label: 'Cổ tích' },
        { value: 5, label: 'Cải lương' },
        { value: 6, label: 'Tin tức' }
      ])
    }
  }, [])

  useEffect(() => {
    if (location) {
      if (location.pathname === '/singlemovie') {
        setType({ value: 1, label: 'Single Movie' })
      } else if (location.pathname === '/showdrama') {
        setType({ value: 2, label: 'Show/Drama' })
      } else if (location.pathname === '/channel') {
        setType({ value: 5, label: 'Channel' })
      }
    }
  }, [location])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangeType = (e, value) => {
    setType(value)
    onChangeType(value)
  }

  const handleChangeCategory = (e, value) => {
    setCategory(value)
    onChangeCategory(value)
  }

  const handleChangeStatus = (e, value) => {
    setStatus(value)
    onChangeStatus(value)
  }

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleClickSearch = () => {
    onClickSearch(searchText)
  }

  const handleClearSearch = () => {
    setSearchText('')
    onClickSearch('')
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onClickSearch(searchText)
    }
  }

  return (
    <div className="vod-filter">
      <Card>
        <CardContent>
          <Row className="pt-2">
            <Col md={2}>
              <Autocomplete
                fullWidth
                disabled={location.pathname === '/singlemovie' || location.pathname === '/showdrama' || location.pathname === '/channel'}
                size="small"
                value={type}
                onChange={(e, newValue) => handleChangeType(e, newValue)}
                options={typeList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Vod Type" />}
              />
            </Col>
            <Col md={2}>
              <Autocomplete
                fullWidth
                size="small"
                value={category}
                onChange={(e, newValue) => handleChangeCategory(e, newValue)}
                options={categoryList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Vod Category" />}
              />
            </Col>
            <Col md={2}>
              <Autocomplete
                fullWidth
                size="small"
                value={status}
                onChange={(e, newValue) => handleChangeStatus(e, newValue)}
                options={statusList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Status" />}
              />
            </Col>
            <Col md={5} className="offset-md-1">
              <TextField
                size="small"
                fullWidth
                label="Search by title, slug"
                variant="standard"
                value={searchText}
                onChange={handleChangeSearch}
                onKeyDown={handleEnter}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchText !== '' &&
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  )
}

VodFilter.propTypes = {
  onChangeType: PropTypes.func,
  onChangeCategory: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onClickSearch: PropTypes.func
}

VodFilter.defaultProps = {
}

export default VodFilter
