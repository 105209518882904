import React from 'react'
import Layout from 'features/layout/components/Layout'
import Transcode from '../components/Transcode'

const TranscodePage = (props) => {
  return (
    <Layout>
      <Transcode />
    </Layout>
  )
}

export default TranscodePage
