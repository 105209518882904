import React from 'react'
import Layout from 'features/layout/components/Layout'
import RibbonSharingWithMe from '../components/RibbonSharingWithMe'

const RibbonSharingWithMePage = () => {
  return (
    <Layout>
        <RibbonSharingWithMe />
    </Layout>
  )
}

export default RibbonSharingWithMePage
