import React from 'react'
import Layout from 'features/layout/components/Layout'
import ShareContentByMe from '../components/ShareContentByMe'

const ShareContentByMePage = (props) => {
  return (
    <Layout>
      <ShareContentByMe />
    </Layout>
  )
}

export default ShareContentByMePage
